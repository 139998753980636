import React from "react";
import IconButton from "@mui/material/IconButton";
import { SxProps } from "@mui/system";

type DbaIconButtonProps = {
  icon: React.ReactNode;
  size?: "large" | "medium" | "small";
  onClick?: (arg?: any) => void;
  sx?: SxProps;
  disabled?: boolean;
  className?: string;
  color?:
    | "inherit"
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning";
};

export const DbaIconButton: React.FC<DbaIconButtonProps> = ({
  icon,
  size,
  onClick,
  sx,
  disabled,
  className,
  color,
}) => {
  return (
    <IconButton
      color={color}
      className={className}
      sx={sx}
      onClick={onClick}
      size={size}
      disabled={disabled}
    >
      {icon}
    </IconButton>
  );
};
