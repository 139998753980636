import React from "react";
import {
  Column,
  ColumnDef,
  ColumnOrderState,
  Header,
  PaginationState,
  SortingState,
  Table,
  RowData,
} from "@tanstack/react-table";
import { TTableHeaderColumnFieldType } from "../../features/serviceSlices/SharedTypes";

export type TDbaTable = {
  id?: string;
  data: TData[];
  columns: ColumnDef<TData>[];
  stylesSettings?: TStylesSettings;
  pagination?: TPagination;
  sorting?: TSorting;
  columnSorting?: TColumnSorting;
  headToolbar?: THeadToolbar;
  selectionMode?: TSelectionMode;
  filters?: TFilters;
  fixControls?: TFixControls;
  isLoading?: boolean;
};

export type TStylesSettings = {
  backgroundColorDarkThemeOverride?: string;
  autoColumnsWidth?: boolean;
  cellStyles?: { [key: string]: string | number };
  heightReducer?: string;
};

export const ALL_ITEMS_PER_PAGE = "all";
export type TPagination = {
  showPagination?: boolean;
  showRowsPerPageSelector?: boolean;
  rowsPerPageSelector?: {
    defaultValue: number;
    options?: (number | typeof ALL_ITEMS_PER_PAGE)[];
  };
  autoResetPagination?: boolean;
  enableServerPagination?: boolean;
  serverRowsCount?: number;
  serverRowsPerPage?: number;
  serverPageIndex?: PaginationState["pageIndex"];
  onServerPageChange?: (event: any, newPage: number) => void;
};

export type TSorting = {
  enableSorting?: boolean;
  enableServerSorting?: boolean;
  // Должна быть мемоизированной
  onSortingChange?: (sortingState: TSortOptions) => void;
};

export type TColumnSorting = {
  enableColumnSorting?: boolean;
  onColumnSortingChange?: (order: ColumnOrderState) => void;
};

export type THeadToolbar = {
  showHeadToolbar?: boolean;
  showHeadToolbarSearchField?: boolean;
  onAddButtonClick?: () => void;
  showDeletedSwitch?: boolean;
  deletedSwitchValue?: boolean;
  onDeletedSwitchChange?: (event: React.BaseSyntheticEvent) => void;
};

export type TSelectionMode = {
  enableSelectionMode?: boolean;
  onRowClick?: (row: TData) => void;
};

export type TFilters = {
  enableFilters?: boolean;
  filtersList?: TFilterData[];
  onFiltersListChange?: (filters: TFilterData[]) => void;
};

export type TFixControls = {
  enableFixControls?: boolean;
};

export type TTable = Table<TData>;

export type TTableHeadToolbar = {
  darkMode?: boolean;
  backgroundColorDarkThemeOverride?: TStylesSettings["backgroundColorDarkThemeOverride"];
  showHeadToolbar?: THeadToolbar["showHeadToolbar"];
  showHeadToolbarSearchField?: THeadToolbar["showHeadToolbarSearchField"];
  onAddButtonClick?: THeadToolbar["onAddButtonClick"];
  headToolbarSearchText?: string;
  setHeadToolbarSearchText?: (
    text: TTableHeadToolbar["headToolbarSearchText"]
  ) => void;
  showDeletedSwitch?: THeadToolbar["showDeletedSwitch"];
  deletedSwitchValue?: THeadToolbar["deletedSwitchValue"];
  onDeletedSwitchChange?: THeadToolbar["onDeletedSwitchChange"];
  enableFixControls: TFixControls["enableFixControls"];
};

export type TTableHead = {
  table: TTable;
  darkMode?: boolean;
  backgroundColorDarkThemeOverride?: TStylesSettings["backgroundColorDarkThemeOverride"];
  autoColumnsWidth?: TStylesSettings["autoColumnsWidth"];
  enableSorting?: TSorting["enableSorting"];
  onSortingChange?: TSorting["onSortingChange"];
  sortingState?: SortingState;
  enableColumnSorting?: TColumnSorting["enableColumnSorting"];
  onColumnSortingChange?: TColumnSorting["onColumnSortingChange"];
  enableFilters?: TFilters["enableFilters"];
  filtersList?: TFilters["filtersList"];
  onFiltersListChange?: TFilters["onFiltersListChange"];
  showHeadToolbar?: THeadToolbar["showHeadToolbar"];
  enableFixControls?: TFixControls["enableFixControls"];
};

export type TTableDraggableColumnHeader = {
  header: Header<TData, unknown>;
  table: Table<TData>;
  autoColumnsWidth?: TStylesSettings["autoColumnsWidth"];
  enableSorting?: TSorting["enableSorting"];
  enableColumnSorting?: TColumnSorting["enableColumnSorting"];
  onColumnSortingChange?: TColumnSorting["onColumnSortingChange"];
  enableFilters?: TFilters["enableFilters"];
  filtersList?: TFilters["filtersList"];
  onFiltersListChange?: TFilters["onFiltersListChange"];
};

export type TFilter = {
  header: Header<TData, unknown>;
  filtersList?: TFilters["filtersList"];
  onChange?: (value: TFilterData[]) => void;
};

export type TBasicFilter = {
  filter?: TFilterData;
  onChange: (value: TFilterData) => void;
};
export type TStringFilter = TBasicFilter;
export type TNumberFilter = TBasicFilter;
export type TBooleanFilter = TBasicFilter;
export type TDateFilter = TBasicFilter;

export type TTableBody = {
  table: TTable;
  darkMode?: boolean;
  backgroundColorDarkThemeOverride?: TStylesSettings["backgroundColorDarkThemeOverride"];
  enableSelectionMode?: TSelectionMode["enableSelectionMode"];
  onRowClick?: TSelectionMode["onRowClick"];
  cellStyles?: TStylesSettings["cellStyles"];
  showPagination?: TPagination["showPagination"];
};

export type TTableFootToolbar = {
  data: TDbaTable["data"];
  table: TTable;
  darkMode?: boolean;
  backgroundColorDarkThemeOverride?: TStylesSettings["backgroundColorDarkThemeOverride"];
  showPagination?: TPagination["showPagination"];
  showRowsPerPageSelector?: TPagination["showRowsPerPageSelector"];
  rowsPerPageSelector?: TPagination["rowsPerPageSelector"];
  enableServerPagination?: TPagination["enableServerPagination"];
  serverRowsCount?: TPagination["serverRowsCount"];
  serverPageIndex?: TPagination["serverPageIndex"];
  onServerPageChange?: (event: any, newPage: number) => void;
  serverPaginationState: PaginationState;
  setServerPaginationState?: (state: PaginationState) => void;
  enableFixControls?: TFixControls["enableFixControls"];
  isLoading?: TDbaTable["isLoading"];
};

export type TData = any;
export type TColumnId = Column<TData, unknown>["id"];

export type TSortOrder = "Asc" | "Desc";
export type TSortOptions =
  | {
      columnName: string;
      orderType: TSortOrder;
    }
  | undefined;

declare module "@tanstack/table-core" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    dataType: TTableHeaderColumnFieldType;
  }
}

export type TFilterData = {
  fieldName: string;
  fieldType: string;
  type?: string;
  value: any;
};
