import { DbaPieChart } from "../../../../common/Diagrams";

export const PieChart = ({ data, widgetSettings }) => {
  const diagramSettings = widgetSettings.diagram;
  const indexes = [].concat.apply(
    [],
    data.map((item) => {
      return item.data.map((item) => {
        return item[diagramSettings.indexBy];
      });
    })
  );
  const merged = [].concat.apply(
    [],
    data.map((item) => {
      return item.data.map((item) => {
        return item;
      });
    })
  );
  const objs = indexes.map((item) => {
    let obj = { [diagramSettings.indexBy]: item };
    const objects = merged.filter((x) => x[diagramSettings.indexBy] === item);
    objects.map((item) => (obj = { ...obj, ...item }));
    return obj;
  });

  return <DbaPieChart data={objs} widgetSettings={widgetSettings} />;
};
