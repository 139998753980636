import React, { useEffect, useState } from "react";
import { FilterWidgetProps, SelectedValueType } from "./Types";
import {
  addFilter,
  deleteFilter,
} from "../../../../features/slices/App/appSlice";
import { useAppDispatch, useAppSelector } from "../../../../utils/reduxHooks";
import { CSSFilterWidgetContainer } from "./FilterWidget.styles";
import { WidgetMenu } from "../WidgetMenu";
import { DbaIconButton } from "../../../../DbaComponents";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import { getDataInputsConfig } from "./DataInputs/dataInputsConfig/dataInputsConfig";
import { AuthGuard } from "../../../Guards/AuthGuard/AuthGuard";
import { parseJSON } from "../../../../utils/helpers/functions";

export const FilterWidget = ({
  data,
  selectedElement,
  setSelectedElement,
}: FilterWidgetProps) => {
  const dispatch = useAppDispatch();
  const {
    editMode,
    filters,
    role: { variableDefaultValues },
  } = useAppSelector((state) => state.app);
  const [selectedValue, setSelectedValue] = useState<SelectedValueType>(null);
  const dataInputsConfig = getDataInputsConfig({
    address: {
      filterLocations: data.addressRestrictions,
      defaultQuery: variableDefaultValues.find(
        (defaultFilter) => defaultFilter.key === data.variable
      )?.query,
    },
  });

  const DataInputComponent = dataInputsConfig[data.filterType].component;
  const dataInputGetSelectedFilter =
    dataInputsConfig[data.filterType].getSelectedFilter;
  const dataInputGetValueToSave =
    dataInputsConfig[data.filterType].getValueToSave;
  const extraProps = dataInputsConfig[data.filterType].extra;

  useEffect(() => {
    if (filters.length <= 0) {
      return;
    }
    const findObject = filters.find((filter) =>
      Object.keys(filter).includes(data.variable)
    );
    if (!findObject) {
      const newValue = parseJSON(data.defaultValue)?.value ?? null;
      if (newValue) {
        dispatch(
          addFilter({
            [String(data.variable)]: newValue,
          })
        );
      }
      return;
    }
    const selectedFilter = dataInputGetSelectedFilter({
      findObject,
      data,
    });
    if (selectedFilter) {
      setSelectedValue(selectedFilter);
    }
  }, [
    data,
    data.value,
    data.variable,
    filters,
    filters.length,
    dataInputGetSelectedFilter,
    dispatch,
  ]);

  const handleChange = (value: SelectedValueType) => {
    if (value) {
      setSelectedValue(value);
      dispatch(
        addFilter({ [data.variable]: dataInputGetValueToSave({ value }) })
      );
      return;
    }
    setSelectedValue(value ?? null);
    const parsedDefaultValueObj = parseJSON(data.defaultValue);
    const isAddressFilter = parsedDefaultValueObj?.id;
    const defaultValue = isAddressFilter
      ? parsedDefaultValueObj?.id
      : parsedDefaultValueObj?.value;
    defaultValue
      ? dispatch(addFilter({ [data.variable]: defaultValue }))
      : dispatch(deleteFilter(data.variable));
  };

  return (
    <CSSFilterWidgetContainer>
      <DataInputComponent
        data={data}
        selectedValue={selectedValue}
        handleChange={handleChange}
        extra={extraProps ? { ...extraProps } : undefined}
      />
      <AuthGuard>
        <WidgetMenu
          dashboardId={data.widgetSettings?.dashboardID}
          widgetID={data.widgetSettings?.widgetID}
          settingsId={data.widgetSettings!.id!}
          type="Filter"
          sx={{ position: "relative", top: "auto", right: "auto" }}
          editMode={editMode}
          widgetLinks={data.widgetLinks}
        />
      </AuthGuard>
      {editMode && (
        <DbaIconButton
          className="grid-item"
          sx={{
            cursor: "move",
          }}
          icon={<OpenWithIcon fontSize="small" />}
        />
      )}
    </CSSFilterWidgetContainer>
  );
};
