import React from "react";
import { TRoleGuard } from "./Types";
import { AuthorizationInfo } from "../../../features/serviceSlices/Authentication/Types";
import { useAppSelector } from "../../../utils/reduxHooks";
import { getUserRoleFromToken } from "../../../utils/helpers/functions";

export const RoleGuard: React.FC<TRoleGuard> = ({
  children,
  placeholder = null,
  allowedRoles,
}) => {
  const token: AuthorizationInfo["token"] | undefined = useAppSelector(
    (state) => state.app.authorizationInfo?.token
  );
  const userRole = token ? getUserRoleFromToken(token) : "";
  return allowedRoles.includes(userRole) ? <>{children}</> : <>{placeholder}</>;
};
