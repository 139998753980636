import React, { useState, useEffect } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { DbaTabPanel } from "../../DbaComponents";
import { useIntl } from "react-intl";
import { useParams } from "react-router";
import { ReturnButton } from "../../components/common";
import { useLazyGetMLDataSetSummaryQuery } from "../../features/serviceSlices/serviceHooks";
import { MLStatusTab } from "./tabs/MLStatusTab/MLStatusTab";
import { MLResultTab } from "./tabs/MLResultTab/MLResultTab";
import { MLDataQualityTab } from "./tabs/MLDataQualityTab/MLDataQualityTab";
import { MLDataTypesTab } from "./tabs/MLDataTypesTab/MLDataTypesTab";
import { LoadingScreen } from "../../components/common";
import { ErrorMessage } from "../../components/common";
import { tabIndexes } from "./Types";

export const MLDataSetHandler: React.FC = () => {
  const intl = useIntl();
  const { id } = useParams<Record<string, string | undefined>>();

  const [tabIndex, setTabIndex] = useState<number>(tabIndexes.STATUS_TAB_INDEX);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) =>
    setTabIndex(newValue);

  const [getMlDataSetSummary, mlDataSetSummary] =
    useLazyGetMLDataSetSummaryQuery();

  const isStatusTabDisabled = (): boolean => {
    return mlDataSetSummary.isLoading;
  };

  const isResultTabDisabled = (): boolean => {
    return (
      mlDataSetSummary.isLoading ||
      ["Creation", "DataTypesDetection", "DataQualityCheck"].includes(
        mlDataSetSummary.data?.stage ?? ""
      )
    );
  };

  const isDataQualityTabDisabled = (): boolean => {
    return (
      mlDataSetSummary.isLoading ||
      mlDataSetSummary.data?.typesDetected !== "Confirmed"
    );
  };

  const isDataTypesTabDisabled = (): boolean => {
    return mlDataSetSummary.isLoading;
  };

  useEffect(() => {
    getMlDataSetSummary(id as string);
  }, [getMlDataSetSummary, id]);

  useEffect(() => {
    if (mlDataSetSummary.data?.errorDescription) {
      setTabIndex(tabIndexes.STATUS_TAB_INDEX);
    } else if (
      ["Undefined", "AwaitingConfirmation"].includes(
        mlDataSetSummary.data?.typesDetected ?? ""
      )
    ) {
      setTabIndex(tabIndexes.DATA_TYPES_TAB_INDEX);
    }
  }, [
    mlDataSetSummary.data?.errorDescription,
    mlDataSetSummary.data?.typesDetected,
  ]);

  if (
    mlDataSetSummary.isError ||
    (!mlDataSetSummary.isLoading && !mlDataSetSummary.data)
  ) {
    return (
      <Box>
        <Box sx={{ marginBottom: "16px" }}>
          <ReturnButton color="error" />
        </Box>
        <ErrorMessage />
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ marginBottom: "16px" }}>
        <ReturnButton color="error" />
      </Box>
      <Box sx={{ borderBottom: 1, borderTop: 1, borderColor: "divider" }}>
        <Tabs variant="fullWidth" value={tabIndex} onChange={handleTabChange}>
          <Tab
            label={intl.messages["status"]}
            disabled={isStatusTabDisabled()}
          />
          <Tab
            label={intl.messages["result"]}
            disabled={isResultTabDisabled()}
          />
          <Tab
            label={intl.messages["dataQuality"]}
            disabled={isDataQualityTabDisabled()}
          />
          <Tab
            label={intl.messages["dataTypes"]}
            disabled={isDataTypesTabDisabled()}
          />
        </Tabs>
      </Box>

      {!mlDataSetSummary.isLoading ? (
        <>
          <DbaTabPanel value={tabIndex} index={tabIndexes.STATUS_TAB_INDEX}>
            <MLStatusTab
              mlDataSetSummary={mlDataSetSummary.data}
              setTabIndex={setTabIndex}
              isDataQualityTabDisabled={isDataQualityTabDisabled}
              isDataTypesTabDisabled={isDataTypesTabDisabled}
            />
          </DbaTabPanel>
          <DbaTabPanel value={tabIndex} index={tabIndexes.RESULT_TAB_INDEX}>
            <MLResultTab mlDataSetSummary={mlDataSetSummary.data} />
          </DbaTabPanel>
          <DbaTabPanel
            value={tabIndex}
            index={tabIndexes.DATA_QUALITY_TAB_INDEX}
          >
            <MLDataQualityTab
              mlDataSetSummary={mlDataSetSummary.data}
              setTabIndex={(tabIndex) => setTabIndex(tabIndex)}
            />
          </DbaTabPanel>
          <DbaTabPanel value={tabIndex} index={tabIndexes.DATA_TYPES_TAB_INDEX}>
            <MLDataTypesTab
              mlDataSetSummary={mlDataSetSummary.data}
              setTabIndex={(tabIndex) => setTabIndex(tabIndex)}
            />
          </DbaTabPanel>
        </>
      ) : (
        <Box sx={{ height: "300px" }}>
          <LoadingScreen />
        </Box>
      )}
    </Box>
  );
};
