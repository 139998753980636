import React from "react";
import { TData, TTableDraggableColumnHeader } from "../../../../Types";
import { flexRender, Column } from "@tanstack/react-table";
import { useDrag, useDrop } from "react-dnd";
import { CSSSortTitle } from "../../../../DbaTable.styles";
import { TableSortLabel } from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import { reorderColumn } from "../../../../helpers/helpers";
import { Filter } from "../Filters/Filter";

export const TableDraggableColumnHeader: React.FC<
  TTableDraggableColumnHeader
> = ({
  header,
  table,
  autoColumnsWidth,
  enableSorting,
  enableColumnSorting,
  onColumnSortingChange,
  enableFilters,
  filtersList,
  onFiltersListChange,
}) => {
  const [{ isDragging }, dragRef, previewRef] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => header.column,
    type: "column",
    canDrag: enableColumnSorting,
  });

  const [, dropRef] = useDrop({
    accept: "column",
    drop: (draggedColumn: Column<TData, unknown>) => {
      const newColumnOrder = reorderColumn(
        draggedColumn.id,
        header.column.id,
        table.getState().columnOrder
      );
      table.setColumnOrder(newColumnOrder);
      onColumnSortingChange && onColumnSortingChange(newColumnOrder);
    },
    canDrop: () => !!enableColumnSorting,
  });

  const sortIconWidth = "26px";
  const noSortIconFiller = <div style={{ width: `calc(${sortIconWidth})` }} />;

  return (
    <th
      ref={dropRef}
      colSpan={header.colSpan}
      style={{
        textAlign: "left",
        paddingRight: "10px",
        width: autoColumnsWidth ? "auto" : header.getSize(),
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      <CSSSortTitle
        ref={previewRef}
        enableSorting={!!(enableSorting && header.column.getCanSort())}
        onClick={header.column.getToggleSortingHandler()}
      >
        {enableFilters ? (
          <Filter
            header={header}
            filtersList={filtersList}
            onChange={onFiltersListChange}
          />
        ) : null}
        {header.column.getIsSorted() ? (
          <span style={{ position: "relative", left: "-4px" }}>
            <TableSortLabel
              active={!!header.column.getIsSorted()}
              direction={header.column.getIsSorted() as "asc" | "desc"}
              IconComponent={SortIcon}
            />
          </span>
        ) : null}
        <span ref={dragRef}>
          {header.isPlaceholder
            ? null
            : flexRender(header.column.columnDef.header, header.getContext())}
        </span>
        {!header.column.getIsSorted() ? noSortIconFiller : null}
      </CSSSortTitle>
    </th>
  );
};
