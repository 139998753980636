import Select, { SelectChangeEvent } from "@mui/material/Select";
import { DbaSimpleSelectProps, optionTypes } from "./DbaSimpleSelect.types";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { useIntl } from "react-intl";
import FormLabel from "@mui/material/FormLabel";

const getOption = (option: optionTypes) => {
  switch (typeof option) {
    case "string":
    case "number":
      return (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      );
    case "object":
      if (option.description) {
        return (
          <MenuItem key={option.value} value={option.value}>
            <strong>{option.label}</strong>
            <span>&nbsp;</span>
            {option.description ? option.description : ""}
          </MenuItem>
        );
      }
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
  }
};

const getSelectedValueLabel = (
  selectedValue: optionTypes,
  options: optionTypes[]
) => {
  if (typeof selectedValue === "object" && selectedValue.label) {
    return selectedValue.label;
  }
  if (typeof options[0] !== "object") {
    return selectedValue;
  }

  if (typeof options[0] === "object") {
    const label = options.find(
      (option) => typeof option === "object" && option.value === selectedValue
    );
    if (typeof label === "object") {
      return label.label;
    }
  }
  return "";
};

export const DbaSimpleSelect = ({
  selectedValue,
  setSelectedValue,
  label,
  options,
  input,
  onBlur,
  multiple,
  maxWidth,
  size = "small",
  required,
  error = false,
  sx = {},
}: DbaSimpleSelectProps) => {
  const intl = useIntl();

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedValue(event.target.value);
  };

  return (
    <FormControl sx={{ maxWidth }} error={error}>
      {label && (
        <InputLabel required={required} id={intl.messages[label] as string}>
          {intl.messages[label]}
        </InputLabel>
      )}
      <Select
        data-testid={label ? (intl.messages[label] as string) : null}
        error={error}
        onBlur={onBlur}
        input={input}
        inputProps={{ type: "text" }}
        size={size}
        //@ts-ignore
        value={getSelectedValueLabel(selectedValue, options)}
        onChange={handleChange}
        label={label ? intl.messages[label] : undefined}
        labelId={label ? (intl.messages[label] as string) : undefined}
        renderValue={(value) => <span>{value}</span>}
        multiple={multiple}
        sx={sx}
      >
        {options.map((option) => getOption(option))}
      </Select>
      {error && (
        <FormLabel error sx={{ fontSize: 12, margin: "3px 14px 0 14px" }}>
          {intl.messages["requiredField"]}
        </FormLabel>
      )}
    </FormControl>
  );
};
