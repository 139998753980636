import { DbaLineChart } from "../../../../common/Diagrams";

export const LineChart = ({ data, settings, widgetSettings }) => {

  const diagramData =
    Array.isArray(data) && data.length > 0
      ? data.map((dataObj) => {
          const indexes = [].concat.apply(
            [],
            dataObj.data.map((dataObj) => {
              return dataObj[settings.indexBy];
            })
          );

          const objs = indexes.map((item) => {
            let obj = { x: item };
            const objects = dataObj.data.filter(
              (x) => x[settings.indexBy] === item
            );
            objects.map((item) => {
              return (obj = { ...obj, y: item[settings.value] });
            });
            return obj;
          });

          return { id: dataObj.id, data: objs };
        })
      : [];

  return (
    <DbaLineChart
      data={diagramData}
      settings={settings}
      widgetSettings={widgetSettings}
    />
  );
};
