import { ChartSettingsProps } from "../Types";
import { WidgetSettingContainer } from "../../common";
import Switch from "@mui/material/Switch";

export const HeatmapChartBaseSettings = ({
  settings,
  setSettings,
}: ChartSettingsProps) => {
  return (
    <>
      <WidgetSettingContainer title="forceSquare">
        <Switch
          checked={settings.diagram.forceSquare ?? false}
          onChange={(e) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                forceSquare: e.target.checked,
              },
            }))
          }
        />
      </WidgetSettingContainer>
    </>
  );
};
