import React, { useState } from "react";
import { TDbaCopyToClipboardButton } from "./Types";
import Box from "@mui/material/Box";
import { DbaIconButton } from "../DbaIconButton";
import DoneIcon from "@mui/icons-material/Done";
import green from "@mui/material/colors/green";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { Tooltip } from "@mui/material";
import { useIntl } from "react-intl";

const SHOWING_OK_TIME = 3000;

export const DbaCopyToClipboardButton: React.FC<TDbaCopyToClipboardButton> = ({
  text,
  beforeComponent,
}) => {
  const intl = useIntl();

  const [isCopiedToClipboardSuccess, setIsCopiedToClipboardSuccess] =
    useState<boolean>(false);

  const onClipboardButtonClick = () => {
    navigator.clipboard.writeText(text).then(function () {
      setIsCopiedToClipboardSuccess(true);
      setTimeout(() => {
        setIsCopiedToClipboardSuccess(false);
      }, SHOWING_OK_TIME);
    });
  };

  return (
    <Box sx={{ display: "flex", gap: "3px" }}>
      {beforeComponent ? (
        <Tooltip
          title={
            isCopiedToClipboardSuccess
              ? `${intl.messages["copied"]}!`
              : intl.messages["copyToClipboard"]
          }
          placement="top-end"
          arrow
        >
          <Box
            onClick={
              !isCopiedToClipboardSuccess ? onClipboardButtonClick : () => {}
            }
          >
            {beforeComponent}
          </Box>
        </Tooltip>
      ) : null}
      <Tooltip
        title={
          isCopiedToClipboardSuccess
            ? `${intl.messages["copied"]}!`
            : intl.messages["copyToClipboard"]
        }
        placement="top"
        arrow
      >
        <Box>
          <DbaIconButton
            sx={{ marginTop: "-8px" }}
            disabled={isCopiedToClipboardSuccess}
            icon={
              isCopiedToClipboardSuccess ? (
                <DoneIcon sx={{ color: green[500] }} />
              ) : (
                <ContentPasteIcon />
              )
            }
            onClick={onClipboardButtonClick}
          />
        </Box>
      </Tooltip>
    </Box>
  );
};
