import { configureStore } from "@reduxjs/toolkit";
import appReducer from "../features/slices/App/appSlice";
import playlistReducer from "../features/slices/Playlist/playlistSlice";
import { rootApi } from "../features/serviceSlices/serviceRoot";
import { rtkQueryErrorHandler } from "./ErrorHandlingMiddleware";

export const store = configureStore({
  reducer: {
    app: appReducer,
    playlist: playlistReducer,
    [rootApi.reducerPath]: rootApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(rootApi.middleware)
      .concat(rtkQueryErrorHandler),
  devTools: process.env.NODE_ENV !== "production",
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
