import { useContext, useState } from "react";
import { TableWidgetConstructorProps } from "./TableWidgetConstructor.types";
import {
  CSSConstructorContainer,
  CSSEditableWidgetContainer,
} from "./TableWidgetConstructor.styles";
import { WidgetConstructorToolbar } from "../WidgetConstructorToolbar";
import { WidgetContainer } from "../../../../components/Widgets/common";
import { DbaErrorBoundary } from "../../../../DbaComponents";
import { ThemeContext } from "../../../../utils/ThemeContext";
import { WidgetElementTypes } from "../DataWidgetConstructor/DataWidgetConstructor.types";
import { TableWidget } from "../../../../components/Widgets/common";
import { BROWSER_TIMEZONE_ID } from "../../../../DbaComponents/DbaTimezonePicker/constants";

export const TableWidgetConstructor = ({
  settings,
  setSettings,
  widgetData,
}: TableWidgetConstructorProps) => {
  const { darkMode } = useContext(ThemeContext);
  const [selectedElement, setSelectedElement] =
    useState<WidgetElementTypes | null>("body");
  const dataWithConstructorSettings = {
    ...widgetData,
    widgetSettings: {
      ...widgetData.widgetSettings,
      settings: {
        ...widgetData.widgetSettings.settings,
        widget: {
          ...widgetData.widgetSettings.settings.widget,
          ...settings.widget,
        },
      },
    },
  };
  return (
    <CSSConstructorContainer>
      <WidgetConstructorToolbar
        widgetType="Table"
        settings={settings}
        setSettings={setSettings}
        selectedElement={selectedElement}
        setSelectedElement={setSelectedElement}
      />
      <CSSEditableWidgetContainer>
        <DbaErrorBoundary>
          <WidgetContainer
            type="Table"
            darkMode={darkMode}
            body={settings.widget.body}
          >
            <TableWidget
              editMode
              id="table-widget-constructor-form-table"
              data={dataWithConstructorSettings}
              appTimezone={BROWSER_TIMEZONE_ID}
              setSelectedElement={setSelectedElement}
              selectedElement={selectedElement}
            />
          </WidgetContainer>
        </DbaErrorBoundary>
      </CSSEditableWidgetContainer>
    </CSSConstructorContainer>
  );
};
