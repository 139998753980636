import { rootApi } from "../../serviceRoot";
import {
  CreateFilterWidgetRequest,
  FilterWidgetResponse,
  TFilterWidgetDataRequest,
  TFilterWidgetDataResponse,
  UpdateFilterWidgetRequest,
} from "./Types";

export const filterWidgetApiSlice = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    createFilterWidget: builder.mutation<
      FilterWidgetResponse,
      CreateFilterWidgetRequest
    >({
      query: (payload) => ({
        url: "api/FilterWidget/Create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Widgets"],
    }),
    updateFilterWidget: builder.mutation<
      FilterWidgetResponse,
      UpdateFilterWidgetRequest
    >({
      query: (payload) => ({
        url: "api/FilterWidget/Update",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: (result, error, arg) => {
        return ["filterWidget", "Widgets"];
      },
    }),
    getFilterWidget: builder.query<FilterWidgetResponse, { id: string }>({
      query: (payload) => ({
        url: "api/FilterWidget/Get",
        method: "POST",
        body: payload,
      }),
    }),
    getFilterWidgetData: builder.query<
      TFilterWidgetDataResponse,
      TFilterWidgetDataRequest
    >({
      query: (params) => ({
        url: "api/Widget/GetData",
        method: "GET",
        params: {
          widgetId: params.widgetId,
          dashboardId: params.dashboardId,
          startDate: params.startDate,
          endDate: params.endDate,
          periodType: params.periodType,
        },
      }),
      providesTags: ["filterWidget"],
    }),
  }),
});

export const {
  useCreateFilterWidgetMutation,
  useGetFilterWidgetQuery,
  useLazyGetFilterWidgetQuery,
  useUpdateFilterWidgetMutation,
  useGetFilterWidgetDataQuery,
  useLazyGetFilterWidgetDataQuery,
} = filterWidgetApiSlice;
