import { ResponsiveAreaBump } from '@nivo/bump';
import { GetTheme } from '../Theme';
import { DbaPortalWrapper } from "../../../../DbaComponents/DbaPortalWrapper";
import { DbaDiagramTooltip } from "./DbaDiagramTooltip";
import { useRef } from "react";

export const DbaAreaBumpChart = ({ data, settings }) => {
  const tooltipContainerRef = useRef(null);

  return (
    <ResponsiveAreaBump
      data={data}
      margin={settings.margin}
      align={settings.align}
      axisTop={settings.axisTop}
      axisBottom={settings.axisBottom}
      interpolation={settings.interpolation}
      spacing={settings.spacing}
      xPadding={settings.xPadding}
      colors={settings.colors}
      tooltip={(props) => {
        return (
          <div ref={tooltipContainerRef}>
            <DbaPortalWrapper
              left={tooltipContainerRef.current?.getBoundingClientRect().left}
              top={tooltipContainerRef.current?.getBoundingClientRect().top}>
              <DbaDiagramTooltip
                markerColor={props.serie.color}
                label={props.serie.id}
                value=""/>
            </DbaPortalWrapper>
          </div>
        );
      }}
      blendMode={settings.blendMode}
      fillOpacity={settings.fillOpacity}
      activeFillOpacity={settings.activeFillOpacity}
      inactiveFillOpacity={settings.inactiveFillOpacity}
      borderWidth={settings.borderWidth}
      activeBorderWidth={settings.activeBorderWidth}
      inactiveBorderWidth={settings.inactiveBorderWidth}
      borderColor={settings.borderColor}
      borderOpacity={settings.borderOpacity}
      activeBorderOpacity={settings.activeBorderOpacity}
      inactiveBorderOpacity={settings.inactiveBorderOpacity}
      startLabel={settings.startLabel}
      startLabelPadding={settings.startLabelPadding}
      startLabelTextColor={settings.startLabelTextColor}
      endLabel={settings.endLabel}
      endLabelPadding={settings.endLabelPadding}
      endLabelTextColor={settings.endLabelTextColor}
      enableGridX={settings.enableGridX}
      tickSize={settings.tickSize}
      tickPadding={settings.tickPadding}
      tickRotation={settings.tickRotation}
      legend={settings.legend}
      legendOffset={settings.legendOffset}
      isInteractive={settings.isInteractive}
      animate={settings.animate}
      motionConfig={settings.motionConfig}
      theme={GetTheme(settings)} 
    />
  )
}

