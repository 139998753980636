import Select, { SelectChangeEvent } from "@mui/material/Select";
import { DbaColorPaletteSelectProps } from "./Types";
import MenuItem from "@mui/material/MenuItem";
import styled from "@emotion/styled";

type ColorContainerProps = {
  background: string;
};

const CSSLabelContainer = styled.span`
  width: 240px;
`;
const CSSMenuItemRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const CSSColorsContainer = styled.div`
  display: flex;
  align-items: center;
`;
const CSSColorContainer = styled.div<ColorContainerProps>`
  width: 12px;
  height: 12px;
  background: ${({ background }) => background};
`;

export const DbaColorPaletteSelect = ({
  selectedValue,
  setSelectedValue,
  label,
  options,
  input,
  onBlur,
}: DbaColorPaletteSelectProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    setSelectedValue(event.target.value);
  };

  return (
    <Select
      onBlur={onBlur}
      input={input}
      inputProps={{ type: "text" }}
      size="small"
      value={selectedValue}
      onChange={handleChange}
      label={label}
      sx={{ minWidth: 250 }}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 272,
          },
        },
      }}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          <CSSMenuItemRow>
            <CSSLabelContainer>{option.label}</CSSLabelContainer>
            <CSSColorsContainer>
              {option.colors.map((item) => (
                <CSSColorContainer key={item} background={item} />
              ))}
            </CSSColorsContainer>
          </CSSMenuItemRow>
        </MenuItem>
      ))}
    </Select>
  );
};
