import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
import { useIntl } from "react-intl";
import ErrorIcon from "@mui/icons-material/Error";

type SaveButtonType = {
  fulfilledHandler?: () => void;
  status: string;
  onClick: () => void;
  text?: string;
  showIcon?: boolean;
  icon?: React.ReactNode;
  disabled?: boolean;
};

type ButtonState = "initial" | "pending" | "fulfilled" | "error";

export const AddButton = ({
  fulfilledHandler,
  status,
  onClick,
  text,
  showIcon = true,
  icon,
  disabled,
}: SaveButtonType) => {
  const [state, setState] = useState<ButtonState>("initial");
  const intl = useIntl();
  useEffect(() => {
    if (status === "uninitialized") {
      setState("initial");
    } else if (status === "pending") {
      setState("pending");
    } else if (status === "fulfilled" && state !== "initial") {
      setState("fulfilled");
      setTimeout(() => {
        setState("initial");
        if (fulfilledHandler) {
          fulfilledHandler();
        }
      }, 1250);
    } else if (
      status === "error" ||
      (status === "rejected" && state !== "initial")
    ) {
      setState("error");
      setTimeout(() => {
        setState("initial");
      }, 1250);
    }
  }, [status, state, fulfilledHandler]);

  const styleButton = {
    width: "fit-content",
    height: "fit-content",
    display: "flex",
    flexShrink: "0",
  };

  return state === "initial" ? (
    <Button
      disabled={disabled}
      startIcon={showIcon ? icon ? icon : <AddIcon /> : null}
      onClick={onClick}
      color="primary"
      sx={styleButton}
      variant="contained"
    >
      {text ? intl.messages[text] : intl.messages["add"]}
    </Button>
  ) : state === "pending" ? (
    <Button
      startIcon={<CircularProgress size={20} sx={{ color: "white" }} />}
      onClick={onClick}
      color="primary"
      sx={styleButton}
      variant="contained"
    >
      {intl.messages["loading"]}
    </Button>
  ) : state === "fulfilled" ? (
    <Button
      startIcon={<CheckIcon />}
      onClick={onClick}
      color="success"
      sx={styleButton}
      variant="contained"
    >
      {intl.messages["success"]}
    </Button>
  ) : (
    <Button
      startIcon={<ErrorIcon />}
      onClick={onClick}
      color="error"
      sx={styleButton}
      variant="contained"
    >
      {intl.messages["error"]}
    </Button>
  );
};
