import React, { useContext } from "react";
import { ThemeContext } from "../../../../../utils/ThemeContext";
import { periodTypes } from "../../../../../utils/Periods";
import colors from "../../../../../Variables.module.scss";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { DbaDatepicker } from "../../../../../DbaComponents";
import { TPlaylistPeriodSelectorProps } from "./Types";
import { FormControlLabel, Switch, useMediaQuery } from "@mui/material";
import {
  CSSPeriodButton,
  CSSDatePickerContainer,
} from "./PlaylistPeriodSelector.styles";
import { TDashboardPeriodType } from "../../../../../features/serviceSlices/Playlists/Types";
import { useIntl } from "react-intl";

const PERIOD_TYPES: TDashboardPeriodType[] = ["Day", "Month", "Year"];

export const PlaylistPeriodSelector: React.FC<TPlaylistPeriodSelectorProps> = ({
  type,
  onStartDateChange,
  onPeriodChange,
  startDate,
  isCurrent,
  onIsCurrentChange,
  disabled,
}) => {
  const { darkMode } = useContext(ThemeContext);
  const intl = useIntl();
  const mobileView = useMediaQuery("(max-width:1000px)");

  const anchorOrigin = {
    vertical: "top",
    horizontal: "right",
  };

  const transformOrigin = {
    vertical: "center",
    horizontal: -16,
  };

  return (
    <CSSDatePickerContainer mobileView={mobileView}>
      <ToggleButtonGroup
        color="primary"
        value={type}
        exclusive
        onChange={onPeriodChange}
        size="small"
        sx={{
          background: darkMode ? colors.darkBlue : "",
          width: "fit-content",
        }}
        disabled={disabled}
      >
        {PERIOD_TYPES.map((period) => (
          <CSSPeriodButton key={period} value={period}>
            {(periodTypes as any)[period]}
          </CSSPeriodButton>
        ))}
      </ToggleButtonGroup>

      <FormControlLabel
        sx={{ mr: 0, width: "190px" }}
        control={
          <Switch
            checked={isCurrent}
            onChange={onIsCurrentChange}
            disabled={disabled}
          />
        }
        label={`${intl.messages["current"]} ${(
          intl.messages[type.toLowerCase()] as string
        ).toLowerCase()}`}
      />

      {!isCurrent ? (
        <>
          {type === "Day" ? (
            <DbaDatepicker
              width="110px"
              isDashboar
              label="day"
              setDate={onStartDateChange}
              date={startDate}
              disabled={disabled}
              anchorOrigin={anchorOrigin}
              // DbaDatepicker needs to be TS
              // @ts-ignore
              transformOrigin={transformOrigin}
            />
          ) : type === "Month" ? (
            <DbaDatepicker
              width="140px"
              isDashboar
              filter="month"
              dateFormat="LLLL yyy"
              label="month"
              setDate={onStartDateChange}
              date={startDate}
              disabled={disabled}
              anchorOrigin={anchorOrigin}
              // DbaDatepicker needs to be TS
              // @ts-ignore
              transformOrigin={transformOrigin}
            />
          ) : type === "Year" ? (
            <DbaDatepicker
              width="64px"
              isDashboar
              filter="year"
              dateFormat="yyy"
              label="year"
              setDate={onStartDateChange}
              date={startDate}
              disabled={disabled}
              anchorOrigin={anchorOrigin}
              // DbaDatepicker needs to be TS
              // @ts-ignore
              transformOrigin={transformOrigin}
            />
          ) : null}
        </>
      ) : null}
    </CSSDatePickerContainer>
  );
};
