import { useIntl } from "react-intl";
import colors from "../../../../Variables.module.scss";
import React from "react";

export const PieChartTooltip = ({ datum, displayedValues }) => {
  const intl = useIntl();

  const contentWithPercentage = (
    <>
      {datum.label}: <strong>{datum.data.formattedPercentage}</strong>
    </>
  );

  const contentWithAbsolute = (
    <>
      {datum.label}: <strong>{datum.formattedValue}</strong>
    </>
  );

  const contentWitthAbsoluteAndPercentage = (
    <>
      {datum.label}: <strong>{datum.formattedValue} </strong>
      {`${intl.messages["fraction"]} - `}
      <strong>{datum.data.formattedPercentage}</strong>
    </>
  );

  return (
    <div
      style={{
        background: colors.white,
        fontSize: 13,
        borderRadius: 2,
        boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 2px",
        padding: "5px 9px",
      }}
    >
      <div
        style={{
          whiteSpace: "pre",
          display: "flex",
          alignItems: "center",
          color: colors.dark,
        }}
      >
        <span
          style={{
            display: "block",
            width: 12,
            height: 12,
            background: datum.color,
            marginRight: 7,
          }}
        />
        <span>
          {displayedValues === "absolute" ? contentWithPercentage : null}
          {displayedValues === "percentage" ? contentWithAbsolute : null}
          {displayedValues === "percentageabsolute"
            ? contentWitthAbsoluteAndPercentage
            : null}
          {!displayedValues ? contentWithPercentage : null}
        </span>
      </div>
    </div>
  );
};
