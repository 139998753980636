import { rootApi } from "../serviceRoot";
import {
  TFileCategoryGetAllResponse,
  TFileCategoryGetResponse,
  TFileCategoryCreateResponse,
  TFileCategoryCreateRequest,
  TFileCategoryUpdateResponse,
  TFileCategoryUpdateRequest,
  TFileCategoryDeleteResponse,
  TFileCategoryDeleteRequest,
} from "./Types";

export const fileCategoriesApiSlice = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllFileCategories: builder.query<TFileCategoryGetAllResponse, void>({
      query: () => "api/category/getall",
      providesTags: ["FileCategories"],
    }),
    getFileCategory: builder.query<TFileCategoryGetResponse, string>({
      query: (id) => `api/category/get?ID=${id}`,
      providesTags: ["FileCategories"],
    }),
    createFileCategory: builder.mutation<
      TFileCategoryCreateResponse,
      TFileCategoryCreateRequest
    >({
      query: (payload) => ({
        url: "api/category/create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["FileCategories"],
    }),
    updateFileCategory: builder.mutation<
      TFileCategoryUpdateResponse,
      TFileCategoryUpdateRequest
    >({
      query: (payload) => ({
        url: "api/category/update",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["FileCategories", "Files"],
    }),
    deleteFileCategory: builder.mutation<
      TFileCategoryDeleteResponse,
      TFileCategoryDeleteRequest
    >({
      query: (id) => ({
        url: "api/category/delete",
        method: "POST",
        body: { id },
      }),
      invalidatesTags: ["FileCategories", "Files"],
    }),
  }),
});

export const {
  useGetAllFileCategoriesQuery,
  useCreateFileCategoryMutation,
  useGetFileCategoryQuery,
  useLazyGetFileCategoryQuery,
  useUpdateFileCategoryMutation,
  useDeleteFileCategoryMutation,
} = fileCategoriesApiSlice;
