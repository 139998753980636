import Alert from "@mui/material/Alert";
import { useIntl } from "react-intl";
import { AlertProps } from "@mui/material";

type DbaAlertProps = AlertProps & { text: string };

export const DbaAlert = ({ text, ...props }: DbaAlertProps) => {
  const intl = useIntl();
  return <Alert {...props}> {intl.messages[text] || text}</Alert>;
};
