import styled from "@emotion/styled";

export const CSSPlaylistStopButton = styled.div`
  display: flex;
  margin: 7px auto 0;
  align-items: center;
  cursor: pointer;
  gap: 4px;
  will-change: auto;
  position: relative;
  left: -29px;

  & > div {
    transition: transform 0.3s ease;
  }

  &:hover {
    & > div {
      transform: scale(0.97);
    }
  }
`;

export const CSSSelectContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
