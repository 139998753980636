import { ResponsiveTimeRange } from "@nivo/calendar";
import { useContext, useRef } from "react";
import { ThemeContext } from "../../../../utils/ThemeContext";
import { getLocalizedMonths } from "../../../../utils/locale";
import colors from "../../../../Variables.module.scss";
import { DbaPortalWrapper } from "../../../../DbaComponents/DbaPortalWrapper";
import { DbaDiagramTooltip } from "./DbaDiagramTooltip";
import { format, startOfYear } from "date-fns";

export const DbaTimeRangeChart = ({ data, settings }) => {
  const { darkMode } = useContext(ThemeContext);

  const tooltipContainerRef = useRef(null);

  return (
    <ResponsiveTimeRange
      monthLegend={getLocalizedMonths}
      data={data}
      from={settings.from ?? startOfYear(Date.now()).toISOString()}
      to={settings.to ?? format(Date.now(), "yyyy-MM-dd")}
      emptyColor={settings.emptyColor}
      colors={settings.colors}
      minValue={settings.minValue}
      maxValue={settings.maxValue}
      margin={settings.margin}
      yearLegendOffset={settings.yearLegendOffset}
      monthBorderColor={settings.monthBorderColor}
      monthLegendOffset={settings.monthLegendOffset}
      daySpacing={settings.daySpacing}
      dayBorderWidth={settings.dayBorderWidth}
      dayBorderColor={settings.dayBorderColor}
      legends={settings.legends}
      direction={settings.direction}
      align={settings.align}
      tooltip={(props) => {
        return (
          <div ref={tooltipContainerRef}>
            <DbaPortalWrapper
              left={tooltipContainerRef.current?.getBoundingClientRect().left}
              top={tooltipContainerRef.current?.getBoundingClientRect().top}
            >
              <DbaDiagramTooltip
                markerColor={props.color}
                label={props.day}
                value={props.value}
              />
            </DbaPortalWrapper>
          </div>
        );
      }}
      yearSpacing={settings.yearSpacing}
      yearLegendPosition={settings.yearLegendPosition}
      monthSpacing={settings.monthSpacing}
      monthBorderWidth={settings.monthBorderWidth}
      monthLegendPosition={settings.monthLegendPosition}
      weekdayTicks={settings.weekdayTicks}
      isInteractive={settings.isInteractive}
      square={settings.square}
      dayRadius={settings.dayRadius}
      weekdayLegendOffset={settings.weekdayLegendOffset}
      theme={{
        textColor: darkMode ? colors.white : colors.darkBlue,
        tooltip: { container: { color: colors.darkBlue } },
        legends: { text: { fill: darkMode ? colors.white : colors.darkBlue } },
      }}
    />
  );
};
