import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../utils/reduxHooks";
import { periodChange, dateChange } from "../../features/slices/App/appSlice";
import { TimePeriods } from "../../features/serviceSlices/SharedTypes";
import { PeriodSelector } from "./PeriodSelector";
import {
  convertDateToISOUsingExternalTimeZone,
  convertISODateToYYYMMddWithTargetTimeZone,
  createNewDateOnPeriodEnd,
} from "../../utils/helpers/dateTime";

export default function DbaPeriodSelector() {
  const dispatch = useAppDispatch();
  const { type, startDate, endDate, role, timezone } = useAppSelector(
    (state) => state.app
  );

  const parsedStartDate = new Date(
    convertISODateToYYYMMddWithTargetTimeZone(startDate, timezone.id)
  );

  let parsedEndDate = null;
  if (endDate) {
    parsedEndDate = new Date(
      convertISODateToYYYMMddWithTargetTimeZone(endDate, timezone.id)
    );
  }

  const onPeriodChangeHandler = (
    event: React.MouseEvent<HTMLElement>,
    value: TimePeriods
  ) => {
    if (value !== null) {
      dispatch(periodChange(value));
    }
  };

  useEffect(() => {
    if (type === "" && role?.defaultPeriod && role?.defaultPeriod !== "") {
      dispatch(periodChange(role.defaultPeriod));
    }
  });

  const onStartDateChangeHandler = (date: Date) => {
    dispatch(dateChange({ startDate: date }));
    if (type !== "Custom") {
      dispatch(
        dateChange({
          endDate: createNewDateOnPeriodEnd(
            type,
            convertDateToISOUsingExternalTimeZone(date, timezone.id)
          ),
        })
      );
    }
  };

  const onEndDateChangeHandler = (date: Date) => {
    dispatch(dateChange({ endDate: date }));
  };

  return (
    <PeriodSelector
      type={type}
      startDate={parsedStartDate}
      endDate={parsedEndDate}
      onStartDateChange={onStartDateChangeHandler}
      onEndDateChange={onEndDateChangeHandler}
      onPeriodChange={onPeriodChangeHandler}
    />
  );
}
