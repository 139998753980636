import { messagesInRussian } from "../../../../../../utils/locale";

const radarCurveOptions = [
  {
    value: "linearClosed",
    label: messagesInRussian.linearClosed,
  },
  {
    value: "basisClosed",
    label: messagesInRussian.basisClosed,
  },
  {
    value: "cardinalClosed",
    label: messagesInRussian.cardinalClosed,
  },
  {
    value: "catmullRomClosed",
    label: messagesInRussian.catmullRomClosed,
  },
];

const curveOptions = [
  {
    value: "natural",
    label: messagesInRussian.natural,
  },
  {
    value: "linear",
    label: messagesInRussian.linearClosed,
  },
  {
    value: "basis",
    label: messagesInRussian.basis,
  },
  {
    value: "cardinal",
    label: messagesInRussian.cardinal,
  },
  {
    value: "catmullRom",
    label: messagesInRussian.catmullRom,
  },
  {
    value: "monotoneX",
    label: messagesInRussian.monotoneX,
  },
  {
    value: "monotoneY",
    label: messagesInRussian.monotoneY,
  },
  {
    value: "step",
    label: messagesInRussian.step,
  },
  {
    value: "stepAfter",
    label: messagesInRussian.stepAfter,
  },
  {
    value: "stepBefore",
    label: messagesInRussian.stepBefore,
  },
];

export { radarCurveOptions, curveOptions };
