import styled from "@emotion/styled";
import { ContainerProps } from "./Types";
import colors from "../../../../Variables.module.scss";

const CSSContainer = styled.div<ContainerProps>`
  border: 1px solid
    ${({ darkMode }) => (darkMode ? colors.darkBlue : colors.purple500)};
  display: flex;
  flex-direction: column;
  min-width: 650px;
  max-width: 650px;
  border-radius: 12px;
  max-height: 400px;
  overflow-y: auto;
`;

const CSSTitle = styled.div<ContainerProps>`
  background: ${({ darkMode }) =>
    darkMode ? colors.darkBlue : colors.purple500};
  border-radius: 10px 10px 0 0;
  padding: 1rem 1rem 1rem 1rem;
  font-size: 1.25rem;
  color: ${({ darkMode }) => (darkMode ? "" : colors.white)};
`;

const CSSSettings = styled.div`
  padding-left: 1rem;
`;
export { CSSContainer, CSSTitle, CSSSettings };
