
import { intl } from "../../../../utils/locale";

export const MLDataTypesDetectionStatusLabels = {
  Undefined: intl.messages["notDefined"],
  AwaitingConfirmation: intl.messages["awaitingСonfirmation"],
  Confirmed: intl.messages["confirmed"],
};

export const MLDataSetProcessingStatusLabels = {
  Creation: intl.messages["creation"],
  DataTypesDetection: intl.messages["dataTypesDetection"],
  DataQualityCheck: intl.messages["dataQualityCheck"],
  DataCorrection: intl.messages["completed"],
  Completed: intl.messages["completed"],
  Error: intl.messages["error"],
};

export const MLTaskStatusLabels = {
  InWork: intl.messages["inWork"],
  InQueue: intl.messages["inQueue"],
};
