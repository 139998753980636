import React from "react";
import { Title } from "../../../common/Title";
import { getTitle } from "../../menuConstant";
import { DashboardHeader } from "./components/DashboardHeader/DashboardHeader";
import { TPropsPageHeader } from "./PageHeader.types";

export const PageHeader: React.FC<TPropsPageHeader> = ({
  menuState,
}: TPropsPageHeader) => {
  if (menuState.isDashboardPage) {
    return <DashboardHeader menuState={menuState} />;
  }
  return <Title text={getTitle(menuState.path)} />;
};
