import React, { useState, useMemo, useEffect } from "react";
import { useIntl } from "react-intl";
import { ErrorMessage } from "../../components/common";
import { DbaIconButton } from "../DbaIconButton";
import { DbaFileSelectorProps } from "./Types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import TextField from "@mui/material/TextField";
import { DbaModal } from "../DbaModal";
import { DbaTable } from "../DbaTable/DbaTable";
import { TFile } from "../../features/serviceSlices/Files/Types";
import { useGetFilesByExtensionsQuery } from "../../features/serviceSlices/Files/filesService";
import Box from "@mui/material/Box";
import { DbaButton } from "../DbaButton";
import { ColumnDef } from "@tanstack/react-table";

export const DbaFileSelector: React.FC<DbaFileSelectorProps> = ({
  onFileSelect,
  selectedFileId,
  attributes,
}) => {
  const intl = useIntl();
  const [open, setOpen] = useState<boolean>(false);
  const [file, setFile] = useState<TFile | undefined>(undefined);

  const files = useGetFilesByExtensionsQuery(attributes);
  const tableData = useMemo(() => files.data, [files.data]);
  const uploadButtonLabel = !file ? "chooseFile" : "chooseAnotherFile";

  const columns: ColumnDef<TFile>[] = useMemo(
    () => [
      {
        id: intl.messages["file"] as string,
        accessorFn: (row) => `${row.name}.${row.extension}`,
      },
      {
        id: intl.messages["category"] as string,
        accessorFn: (row) => row.categoryName,
      },
      {
        id: intl.messages["fileUploadDate"] as string,
        accessorFn: (row) => new Date(row.uploadedDate).toLocaleDateString(),
      },
    ],
    [intl.messages]
  );

  const selectFileHandler = (row: TFile) => {
    setFile(row);
    setOpen(false);
  };

  useEffect(() => {
    if (selectedFileId && tableData) {
      setFile(tableData.find((x) => x.id === selectedFileId));
    }
  }, [tableData, selectedFileId]);

  useEffect(() => {
    if (file && selectedFileId !== file.id) {
      onFileSelect(file.id);
    }
  }, [file, selectedFileId, onFileSelect]);

  if (files.isError) {
    return <ErrorMessage />;
  }

  return (
    <div>
      <Box sx={{ display: "flex", gap: "10px", alignItems: "start" }}>
        <Box
          sx={{
            position: "relative",
            overflow: "hidden",
            display: "flex",
          }}
        >
          <DbaButton
            text={uploadButtonLabel}
            onClick={() => setOpen(true)}
            color="success"
            sx={{ paddingLeft: "25px", paddingRight: "25px", height: "56px" }}
            disabled={files.isLoading}
          />
        </Box>
        <Box sx={{ display: "flex", flex: 1 }}>
          <TextField
            disabled={true}
            value={file ? `${file.name}.${file.extension}` : " "}
            fullWidth={true}
            error={!!files.isError}
            helperText={files.isError}
          />
          {file ? (
            <DbaIconButton
              icon={<HighlightOffIcon />}
              onClick={() => setFile(undefined)}
            />
          ) : (
            ""
          )}
        </Box>
      </Box>
      <DbaModal
        onCloseHandler={() => setOpen(false)}
        fullWidth
        title={intl.messages["files"] as string}
        open={open}
        setOpen={setOpen}
      >
        <>
          <DbaTable
            columns={columns}
            data={tableData ?? []}
            selectionMode={{
              enableSelectionMode: true,
              onRowClick: selectFileHandler,
            }}
            fixControls={{ enableFixControls: false }}
          />
        </>
      </DbaModal>
    </div>
  );
};
