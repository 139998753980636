import React from "react";
import { TTableHeadToolbar } from "../../Types";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { CSSTableToolbar } from "../../DbaTable.styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import colors from "../../../../Variables.module.scss";
import { useIntl } from "react-intl";
import Switch from "@mui/material/Switch";

export const TableHeadToolbar: React.FC<TTableHeadToolbar> = ({
  darkMode,
  backgroundColorDarkThemeOverride,
  showHeadToolbar,
  showHeadToolbarSearchField,
  onAddButtonClick,
  headToolbarSearchText,
  setHeadToolbarSearchText,
  showDeletedSwitch,
  deletedSwitchValue,
  onDeletedSwitchChange,
  enableFixControls,
}) => {
  const intl = useIntl();

  const onSearchInputChange = (event: React.BaseSyntheticEvent) =>
    setHeadToolbarSearchText
      ? setHeadToolbarSearchText(event.target.value)
      : undefined;

  const deletedSwitchHandler = (event: React.BaseSyntheticEvent) => {
    if (onDeletedSwitchChange) {
      onDeletedSwitchChange(event);
    }
  };

  return showHeadToolbar ? (
    <CSSTableToolbar
      className="dba-table-toolbar"
      darkMode={!!darkMode}
      backgroundColorDarkThemeOverride={backgroundColorDarkThemeOverride}
      enableFixControls={enableFixControls}
    >
      {onAddButtonClick ? (
        <IconButton
          onClick={onAddButtonClick}
          sx={{ width: "fit-content", margin: "-3px 0" }}
        >
          <AddCircleOutlineIcon />
        </IconButton>
      ) : null}
      {showHeadToolbarSearchField ? (
        <InputBase
          startAdornment={<SearchIcon sx={{ marginRight: ".5rem" }} />}
          sx={{
            border: `1px solid ${colors.lavander500} `,
            padding: ".5rem",
            height: "2rem",
            width: "100%",
            borderRadius: ".25rem",
          }}
          value={headToolbarSearchText}
          onChange={onSearchInputChange}
          placeholder={intl.messages["enterSearchQuery"] as string}
        />
      ) : null}
      {showDeletedSwitch && (
        <label
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            width: "281px",
          }}
        >
          {intl.messages["showDeletedWidget"]}
          <Switch
            checked={deletedSwitchValue}
            onChange={deletedSwitchHandler}
          />
        </label>
      )}
    </CSSTableToolbar>
  ) : null;
};
