import React from "react";
import { TMLDataFoundErrors } from "../../../../features/serviceSlices/MLDataSet/Types";
import { keysToLocalesKeysMap } from "./keysToLocalesKeysMap";
import { Box, Tooltip } from "@mui/material";
import { TTableCell } from "./Types";
import { CSSErrorCellBackground } from "./MLResultTab.styles";
import { useIntl } from "react-intl";
import { UNIQUE_SYSTEM_ROW_IDENTIFIER_FIELD_NAME } from "../../constants";

const CELL_CONTENT_TRUNCATE_LENGTH = 18;

export const TableCell: React.FC<TTableCell> = ({
  original,
  allMLFoundErrors = [],
  columnKey,
}) => {
  const intl = useIntl();

  const mlFoundErrors: TMLDataFoundErrors | undefined = allMLFoundErrors.find(
    (item) =>
      item[UNIQUE_SYSTEM_ROW_IDENTIFIER_FIELD_NAME] ===
      original[UNIQUE_SYSTEM_ROW_IDENTIFIER_FIELD_NAME]
  );
  const mlFoundErrorsKeys = mlFoundErrors
    ? Object.keys(mlFoundErrors).filter(
        (item) => item !== UNIQUE_SYSTEM_ROW_IDENTIFIER_FIELD_NAME
      )
    : [];
  const currentFieldErrors = mlFoundErrors
    ? mlFoundErrorsKeys.filter(
        (errorTypeKey) =>
          // @ts-ignore
          Array.isArray(mlFoundErrors[errorTypeKey]) &&
          // @ts-ignore
          mlFoundErrors[errorTypeKey].includes(columnKey)
      )
    : [];
  const content = (
    <Box>
      {currentFieldErrors.length ? <CSSErrorCellBackground /> : null}
      <Box sx={{ position: "relative" }}>
        {String(original[columnKey]).length < CELL_CONTENT_TRUNCATE_LENGTH
          ? original[columnKey]
          : `${String(original[columnKey]).slice(
              0,
              CELL_CONTENT_TRUNCATE_LENGTH
            )}...`}
      </Box>
    </Box>
  );

  return currentFieldErrors.length ? (
    <Tooltip
      placement="top"
      arrow={true}
      title={
        <div>
          {currentFieldErrors.map((currentFieldError) => (
            <div key={currentFieldError}>
              {
                intl.messages[
                  (keysToLocalesKeysMap as { [key: string]: string })[
                    currentFieldError
                  ]
                ]
              }
            </div>
          ))}
        </div>
      }
    >
      {content}
    </Tooltip>
  ) : (
    content
  );
};
