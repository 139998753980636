import { messagesInRussian } from "../../../../../../utils/locale";

const MotionConfigOptions = [
  {
    value: "default",
    label: messagesInRussian.default,
  },
  {
    value: "gentle",
    label: messagesInRussian.gentle,
  },
  {
    value: "wobbly",
    label: messagesInRussian.wobbly,
  },
  {
    value: "slow",
    label: messagesInRussian.slow,
  },
  {
    value: "stiff",
    label: messagesInRussian.stiff,
  },
  {
    value: "molasses",
    label: messagesInRussian.molasses,
  },
];

export { MotionConfigOptions };
