import styled from "@emotion/styled";

const CSSListsContainer = styled.div`
  label: ListsContainer;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  width: 100%;
`;

const CSSListContainer = styled.div`
  label: ListContainer;
  min-width: 200px;
`;

export { CSSListsContainer, CSSListContainer };
