import { EditableDataWidgetProps } from "./Types";
import { WidgetContainer, DataWidget } from "../../common";
import { useContext } from "react";
import { ThemeContext } from "../../../../utils/ThemeContext";

export const EditableDataWidget = ({
  data,
  selectedElement,
  setSelectedElement,
}: EditableDataWidgetProps) => {
  const { darkMode } = useContext(ThemeContext);
  return (
    <WidgetContainer
      darkMode={darkMode}
      body={data.widgetSettings.settings.widget.body}
    >
      <DataWidget
        data={data}
        editMode
        setSelectedElement={setSelectedElement}
        selectedElement={selectedElement}
      />
    </WidgetContainer>
  );
};
