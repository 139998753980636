import { TFilterAddressRestriction } from "../../../../../../../features/serviceSlices/SharedTypes";

/**
 * Функция поиска ограничений, у которых уровень выше, чем у нового добавляемого ограничения (aka проверка что новое ограничение бесполезное)
 * @param newRestriction - Добавляемое новое ограничение
 * @param restriction - Список ограничений, с которыми будет сравниваться добавляемое
 * @returns Список тех ограничений (если они есть), которые будут по уровню выше, чем у добавляемого
 * @remarks добавляем Барнаул, уже есть Алтайский край и Омск, получим Алтайский край как объект, у которого уровень выше, чем у Барнаула
 */
export const getUselessRestrictions = (
  newRestriction: TFilterAddressRestriction,
  restriction: TFilterAddressRestriction[]
): TFilterAddressRestriction[] => {
  const streetCheck = (x: TFilterAddressRestriction) =>
    (x.settlement === newRestriction.settlement ||
      x.city === newRestriction.city ||
      x.area === newRestriction.area ||
      x.region === newRestriction.region) &&
    !x.street;
  const settlementCheck = (x: TFilterAddressRestriction) =>
    (x.area === newRestriction.area && !x.settlement) ||
    (x.region === newRestriction.region && !x.city);
  const cityCheck = (x: TFilterAddressRestriction) =>
    ((x.area === newRestriction.area && !!x.area) ||
      (x.region === newRestriction.region && !!x.region)) &&
    !x.city;
  const areaCheck = (x: TFilterAddressRestriction) =>
    x.region === newRestriction.region && !x.area;

  if (newRestriction.street) {
    const sameR = restriction.filter(streetCheck);
    if (sameR.length) {
      return sameR;
    }
  } else if (newRestriction.settlement) {
    const sameR = restriction.filter(settlementCheck);
    if (sameR.length) {
      return sameR;
    }
  } else if (newRestriction.city) {
    const sameR = restriction.filter(cityCheck);
    if (sameR.length) {
      return sameR;
    }
  } else if (newRestriction.area) {
    const sameR = restriction.filter(areaCheck);
    if (sameR.length) {
      return sameR;
    }
  }
  return [];
};

/**
 * Поиск ограничений, которые стали бесполезными, т.к. было добавлено ограничение более выского уровня
 * @param newRestriction - добавляемое ограничение
 * @param restriction - список уже добавленных ограничений
 * @returns 1. флаг - что были найдены ограничения, которые теперь не имеют смысла, и 2. список валидных ограничений без бессмысленных
 * @remarks добавляем Алтайский край, уже есть Барнаул и Омск, получим true и [Омск], т.к. Барнаул не имеет смысла и теперь его нет в списке с ограничениями
 * (флаг нужен, т.к. может вернуться пустой список, и не будет понятно пустой он потому что все ограничения стали невалидными или потому что их не было найдено вообще)
 */
export const getRestrictionsWithoutUseless = (
  newRestriction: TFilterAddressRestriction,
  filterRestrictions: TFilterAddressRestriction[]
): {
  isRemoveUseless: boolean;
  validRestrictions: TFilterAddressRestriction[];
} => {
  const settlementCheck = (x: TFilterAddressRestriction) =>
    x.settlement === newRestriction.settlement && x.street;
  const cityCheck = (x: TFilterAddressRestriction) =>
    x.city === newRestriction.city && x.street;
  const areaCheck = (x: TFilterAddressRestriction) =>
    x.area === newRestriction.area && x.settlement;
  const regionCheck = (x: TFilterAddressRestriction) =>
    x.region === newRestriction.region && (x.settlement || x.city || x.area);

  if (newRestriction.settlement) {
    const sameR = filterRestrictions.filter(settlementCheck);
    if (sameR.length) {
      const onlyValidRestrictions = filterRestrictions.filter(
        (x) => !settlementCheck(x)
      );
      return {
        isRemoveUseless: true,
        validRestrictions: onlyValidRestrictions,
      };
    }
  } else if (newRestriction.city) {
    const sameR = filterRestrictions.filter(cityCheck);
    if (sameR.length) {
      const onlyValidRestrictions = filterRestrictions.filter(
        (x) => !cityCheck(x)
      );
      return {
        isRemoveUseless: true,
        validRestrictions: onlyValidRestrictions,
      };
    }
  } else if (newRestriction.area) {
    const sameR = filterRestrictions.filter(areaCheck);
    if (sameR.length) {
      const onlyValidRestrictions = filterRestrictions.filter(
        (x) => !areaCheck(x)
      );
      return {
        isRemoveUseless: true,
        validRestrictions: onlyValidRestrictions,
      };
    }
  } else {
    const sameR = filterRestrictions.filter(regionCheck);
    if (sameR.length) {
      const onlyValidRestrictions = filterRestrictions.filter(
        (x) => !regionCheck(x)
      );
      return {
        isRemoveUseless: true,
        validRestrictions: onlyValidRestrictions,
      };
    }
  }
  return { isRemoveUseless: false, validRestrictions: [] };
};
