export const dataSetToFilterTypeMapper: Record<string, string> = {
  "DateTime64(3, 'Etc/UTC')": "Date",
  String: "String",
  "Decimal(10,5)": "Number",
  Float64: "Number",
  Float32: "Number",
  Int64: "Number",
  Int32: "Number",
  Bool: "Boolean",
  UInt64: "Number",
  UInt8: "Boolean",
  UUID: "String",
};
