import React, { useRef, useState } from "react";
import {
  AddressSuggestions,
  DaDataAddress,
  DaDataSuggestion,
} from "react-dadata";
import { useIntl } from "react-intl";
import constants from "../../../../../../../utils/constants";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  TFilterAddressRestriction,
  TFilterDefaultValueFromJson,
} from "../../../../../../../features/serviceSlices/SharedTypes";
import {
  Alert,
  Chip,
  Snackbar,
  Stack,
  Tooltip,
} from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { useSnackbar } from "../../../../../../../utils/hooks/useSnackbar";
import {
  getUselessRestrictions,
  getRestrictionsWithoutUseless,
} from "./RestrictionValidation";
import {
  CSSChipsContainer,
  CSSRestrictionFieldContainer,
} from "./FilterAddressRestrictions.styles";
import { DbaButton } from "../../../../../../../DbaComponents";

const DELAY_TO_FILTER_APPLY = 700;
const MIN_CHARS_TO_FILTER_APPLY = 3;
const SHOW_SNACKBAR_PERIOD = 10000;

const StyledAddressInput: React.FC<{
  [key: string]: any;
  options: Record<string, any>;
}> = (props) => (
  <TextField
    {...props}
    sx={{ height: "20%" }}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton onClick={props.options?.onCloserPress} size="small">
            <CloseIcon />
          </IconButton>
        </InputAdornment>
      ),
    }}
  />
);

type TFilterAddressRestrictions = {
  filterRestrictions: TFilterAddressRestriction[];
  setFilterRestrictions: (arg: TFilterAddressRestriction[]) => void;
  clearDefaultValueCallback: () => void;
  filterDefaultValue: TFilterDefaultValueFromJson;
};

export const FilterAddressRestrictions: React.FC<
  TFilterAddressRestrictions
> = ({
  filterRestrictions,
  setFilterRestrictions,
  clearDefaultValueCallback,
  filterDefaultValue,
}) => {
  const intl = useIntl();
  const addressRestrictionsRef = useRef<AddressSuggestions>(null);
  const [restrictionAddressText, setRestrictionAddressText] =
    useState<string>("");

  const [
    isShowAlertBeforeDefaultValueReset,
    setIsShowAlertBeforeDefaultValueReset,
  ] = useState<boolean>(false);

  //временное хранение новых ограничений, т.к. устанавливаются через алерт
  const [tmpRestrictions, setTmpRestrictions] =
    useState<{
      newData: DaDataSuggestion<DaDataAddress>;
      correctedData?: TFilterAddressRestriction[];
    }>();

  const { isShowSnackbar, snackbarMessage, snackbarSeverity, blinkSnackbar } =
    useSnackbar();

  const snackbar = (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={isShowSnackbar}
      autoHideDuration={null}
    >
      <Alert elevation={6} severity={snackbarSeverity} sx={{ width: "100%" }}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );

  const blinkIfRestrictionIsPointless = (
    importantRestrictions: TFilterAddressRestriction[]
  ) => {
    blinkSnackbar(
      (intl.messages["unusedRestrictionAddeddMessage"] as string) +
        `: ${JSON.stringify(importantRestrictions.map((x) => x.fullAddress))}`,
      "error",
      SHOW_SNACKBAR_PERIOD
    );
  };

  const onRestrictionsChange = (
    data: DaDataSuggestion<DaDataAddress> | undefined
  ) => {
    if (data) {
      if (filterRestrictions.some((x) => x.fullAddress === data?.value)) {
        blinkSnackbar(
          intl.messages["restrictionAlreadyExists"] as string,
          "error"
        );
        return;
      }
      const newRestriction: TFilterAddressRestriction = {
        region: data?.data.region,
        area: data?.data.area ?? undefined,
        city: data?.data.city ?? undefined,
        settlement: data?.data.settlement ?? undefined,
        street: data?.data.street ?? undefined,
        fullAddress: data?.value,
      };

      const uselessRestrictions = getUselessRestrictions(
        newRestriction,
        filterRestrictions
      );
      if (uselessRestrictions.length) {
        blinkIfRestrictionIsPointless(uselessRestrictions);
        clearRestrictionInput();
        return;
      }

      const validatonResult = getRestrictionsWithoutUseless(
        newRestriction,
        filterRestrictions
      );
      if (validatonResult.isRemoveUseless) {
        tryToUpdateFilterRestrictions(data, validatonResult.validRestrictions);
        return;
      }
      tryToUpdateFilterRestrictions(data);
    }
  };

  const updateFilterRestrictions = () => {
    if (tmpRestrictions) {
      setFilterRestrictions([
        ...(tmpRestrictions.correctedData ?? filterRestrictions),
        {
          area: tmpRestrictions.newData?.data.area ?? undefined,
          city: tmpRestrictions.newData?.data.city ?? undefined,
          region: tmpRestrictions.newData?.data.region,
          settlement:
            tmpRestrictions.newData?.data.settlement ?? undefined,
          street:
            tmpRestrictions.newData?.data.street ?? undefined,
          fullAddress: tmpRestrictions.newData?.value,
        },
      ]);
    }
  };

  const tryToUpdateFilterRestrictions = (
    newData: DaDataSuggestion<DaDataAddress>,
    correctedData?: TFilterAddressRestriction[]
  ) => {
    clearRestrictionInput();
    if (filterDefaultValue?.value) {
      setTmpRestrictions({ newData, correctedData });
      setIsShowAlertBeforeDefaultValueReset(true);
    } else {
      setFilterRestrictions([
        ...(correctedData ?? filterRestrictions),
        {
          area: newData?.data.area ?? undefined,
          city: newData?.data.city ?? undefined,
          region: newData?.data.region,
          settlement: newData?.data.settlement ?? undefined,
          street: newData?.data.street ?? undefined,
          fullAddress: newData?.value,
        },
      ]);
    }
  };

  const onRestrictionInputTextChange = (event: any) => {
    setRestrictionAddressText(event.target.value);
  };

  const onRestrictionsInputTextBlur = () => {
    if (!restrictionAddressText) {
      clearRestrictionInput();
    }
  };

  const onRestrictionAddressInputTextKeyPress = (event: any) => {
    if (event.key === "Enter" && !restrictionAddressText) {
      clearRestrictionInput();
    }
  };

  const clearRestrictionInput = () => {
    if (addressRestrictionsRef.current) {
      addressRestrictionsRef.current.setInputValue("");
    }
  };

  const onDeleteRestrictionChip = (index: number) => {
    const newArr = [...filterRestrictions];
    newArr.splice(index, 1);
    setFilterRestrictions(newArr);
  };

  const onAgreeToResetDefaultFilterValue = () => {
    if (tmpRestrictions) {
      updateFilterRestrictions();
    }
    setTmpRestrictions(undefined);
    clearDefaultValueCallback();
    setIsShowAlertBeforeDefaultValueReset(false);
  };

  const onDisagreeToResetDefaultFilterValue = () => {
    setTmpRestrictions(undefined);
    setIsShowAlertBeforeDefaultValueReset(false);
  };

  return (
    <>
      <Stack direction={"row"} alignItems="center" spacing={1}>
        <CSSRestrictionFieldContainer>
          <AddressSuggestions
            ref={addressRestrictionsRef}
            token={constants.daDataApiKey}
            defaultQuery={undefined}
            value={undefined}
            onChange={onRestrictionsChange}
            delay={DELAY_TO_FILTER_APPLY}
            minChars={MIN_CHARS_TO_FILTER_APPLY}
            filterFromBound="country"
            inputProps={{
              onChange: onRestrictionInputTextChange,
              onBlur: onRestrictionsInputTextBlur,
              onKeyDown: onRestrictionAddressInputTextKeyPress,
              label: intl.messages["addressRestriction"] as string,
              //@ts-ignore
              options: {
                onCloserPress: clearRestrictionInput,
              },
            }}
            customInput={StyledAddressInput}
          />
        </CSSRestrictionFieldContainer>
        <Tooltip
          arrow={true}
          placement="left-end"
          title={intl.messages["addRestrictionsAlert"]}
        >
          <InfoOutlined sx={{ width: 15, height: 15, color: "#888888" }} />
        </Tooltip>
      </Stack>
      {!!filterRestrictions.length && (
        <CSSChipsContainer>
          {filterRestrictions.map((x, index) => {
            return (
              <Chip
                key={JSON.stringify(x)}
                label={x.fullAddress}
                onDelete={() => onDeleteRestrictionChip(index)}
              />
            );
          })}
        </CSSChipsContainer>
      )}
      {isShowAlertBeforeDefaultValueReset ? (
        <>
          <Alert severity="warning">
            {intl.messages["alertDefaultValueWillReset"]}
          </Alert>
          <Stack direction={"row"} alignItems="center" spacing={1}>
            <DbaButton
              color="error"
              text="cancel"
              onClick={onDisagreeToResetDefaultFilterValue}
            />
            <DbaButton
              text="add"
              onClick={onAgreeToResetDefaultFilterValue}
            />
          </Stack>
        </>
      ) : null}
      {snackbar}
    </>
  );
};
