import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { useIntl } from "react-intl";

import HoverPopover from "material-ui-popup-state/HoverPopover";
import {
  usePopupState,
  bindHover,
  bindPopover,
} from "material-ui-popup-state/hooks";
import { useMediaQuery } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import {
  CSSListItemIcon,
  CSSMenuItem,
  CSSSubMenuItem,
} from "./NavMenuItem.styles";
import { TNavMenuItem } from "./NavMenuItem.types";

export const NavMenuItem: React.FC<TNavMenuItem> = ({
  text,
  icon,
  link,
  onClick,
  ...rest
}: TNavMenuItem) => {
  const mobileView = useMediaQuery("(max-width:1000px)");
  const intl = useIntl();

  const { pathname } = useLocation();
  const params = useParams();
  const popupState = usePopupState({
    variant: "popover",
    popupId: "popoverId",
  });

  const menuItemHover = {
    ...(popupState._childPopupState !== undefined
      ? {
          ...bindHover(popupState),
        }
      : popupState.close()),
  };

  const getRoutesFromLink = () => {
    return [
      link.slice(0, -1),
      `${link.slice(0, -1)}/${params.id}`,
      `/edit-${link.slice(1, -1)}/${params.id}`,
    ];
  };

  const isActive: boolean = getRoutesFromLink().includes(pathname);
  const isActiveLinkWidget: boolean =
    link === "/" && pathname.includes("widgetEditor");
  const isOpenPopover: boolean = popupState.isOpen;

  const getClassName = () => {
    return `${isActive ? "active" : ""} ${
      isOpenPopover ? "openPopoverHover" : ""
    } ${isActiveLinkWidget ? "active" : ""}`;
  };

  const handleClick = () => {
    onClick && onClick();
    popupState._setChildPopupState(undefined);
  };

  if (mobileView) {
    return (
      <CSSMenuItem onClick={handleClick} to={link} className={getClassName()}>
        <ListItem>
          <CSSListItemIcon>{icon}</CSSListItemIcon>
          <ListItemText primary={intl.messages[text]} />
        </ListItem>
      </CSSMenuItem>
    );
  }
  return (
    <>
      <CSSMenuItem
        onClick={handleClick}
        to={link}
        className={getClassName()}
        {...menuItemHover}
      >
        <ListItem onMouseEnter={() => popupState._setChildPopupState(null)}>
          <CSSListItemIcon>{icon}</CSSListItemIcon>
        </ListItem>
      </CSSMenuItem>

      <HoverPopover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <CSSSubMenuItem onClick={popupState.close} to={link}>
          {intl.messages[text]}
        </CSSSubMenuItem>
      </HoverPopover>
    </>
  );
};
