import styled from "@emotion/styled";

const CSSEditorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export { CSSEditorContainer };
