import styled from "@emotion/styled";
import colors from "../../../Variables.module.scss";

export const CSSPlaylistsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const TABLE_WIDTH = 700;

export const CSSPlaylistsTable = styled.div<{ darkMode: boolean }>`
  & > div {
    width: ${TABLE_WIDTH}px;
    margin: -15px auto 0;
    position: static;
  }

  & .dba-table-toolbar {
    background: ${({ darkMode }: any) =>
      darkMode ? colors.dark : colors.light};
    position: absolute;
    width: ${TABLE_WIDTH - 2}px;
    z-index: 10;
    border-bottom: ${(props) =>
      `1px solid ${props.darkMode ? colors.darkBlue : colors.lavander500}`};
  }

  & table {
    margin-top: 73px;
  }

  & thead {
    display: none;
  }

  & td {
    border: none;
    max-width: 0;
  }

  & td:first-of-type,
  & td:last-of-type {
    border: none;
  }
`;
