import React, { useState, useMemo, useEffect, useCallback } from "react";
import {
  TGeneratedColumnRow,
  GenerateColumnsResponse,
} from "../../features/serviceSlices/DataSet/Types";
import { ColumnTypes } from "../../features/serviceSlices/DataSet/Types";
import { useIntl } from "react-intl";
import Checkbox from "@mui/material/Checkbox";
import { DbaTable, DbaSimpleSelect } from "../../DbaComponents";
import { CSSStyledInput } from "./Datasets.styles";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { ColumnDef } from "@tanstack/react-table";
import { DeleteButton } from "../../components/TableHelpers/DeleteButton";

const CustomInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    fontSize: 16,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: ["Roboto", "sans-serif"].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

type EditableTableProps = {
  tableData: GenerateColumnsResponse | [];
  setTableData: (arg: GenerateColumnsResponse | any) => void;
  columnTypes: ColumnTypes;
  skipPageReset?: boolean;
};

export const EditableTable = ({
  tableData,
  setTableData,
  columnTypes,
}: EditableTableProps) => {
  const intl = useIntl();

  const addNewRowHandler = useCallback(() => {
    const newObj: TGeneratedColumnRow = {
      name: "",
      type: "String",
      orderBy: null,
      partitionBy: false,
      isNullable: false,
    };
    const newArr = [...tableData];
    newArr.splice(0, 0, newObj);
    setTableData(newArr);
  }, [setTableData, tableData]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, data: TGeneratedColumnRow) => {
      const editableObjIdx = tableData.findIndex((item) => item === data);
      const orderValuesArr = tableData
        .map((item) => item.orderBy)
        .filter((item) => item !== null);

      const maxOrderValue =
        orderValuesArr.length === 0
          ? 0
          : Math.max(...(orderValuesArr as number[]));

      if (editableObjIdx !== -1) {
        const newObj = {
          ...data,
          isNullable: !event.target.checked,
          orderBy: event.target.checked ? maxOrderValue + 1 : null,
          key: event.target.checked,
        };
        const newDataArr = [...tableData];
        newDataArr.splice(editableObjIdx, 1, newObj);
        setTableData(newDataArr);
      }
    },
    [setTableData, tableData]
  );

  const deleteHandler = useCallback(
    (obj: TGeneratedColumnRow) => {
      const newArr = [...tableData].filter((item) => item !== obj);
      setTableData(newArr);
    },
    [setTableData, tableData]
  );

  const updateMyData = (rowIndex: number, columnId: string, value: string) => {
    setTableData((old: GenerateColumnsResponse) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  // Требует дальнейшей оптимизации так как использовуется в dependency array
  const EditableCell = (props: any) => {
    const initialValue = props.getValue();
    const {
      row: { index, original },
      column: { id },
    } = props;
    const [value, setValue] = useState(initialValue);
    const [displayValue, setDisplayValue] = useState<string | undefined>(
      () => columnTypes.find((x) => x.type === initialValue)?.name
    );

    const onChange = (e: any) => {
      if (id === "orderBy") {
        setValue(e.target.value === "0" ? null : parseInt(e.target.value));
      } else if (id === "partitionBy" || id === "isNullable") {
        setValue(e.target.checked);
      } else if (id === "type") {
        const typeValue = columnTypes.find((x) => x.name === e)?.type;
        setValue(typeValue);
      } else {
        setValue(e.target.value);
      }
    };

    const onBlur = () => {
      updateMyData(index, id, value);
    };

    useEffect(() => {
      setValue(initialValue);
      if (id === "type") {
        setDisplayValue(columnTypes.find((x) => x.type === value)?.name);
      }
    }, [initialValue]);

    useEffect(() => {
      if (id === "type") {
        setDisplayValue(columnTypes.find((x) => x.type === value)?.name);
      }
    }, [id, value]);

    const getCellContent = (id: string) => {
      switch (id as string) {
        case "name":
          return (
            <CSSStyledInput
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              placeholder="Наименование"
            />
          );
        case "type":
          return (
            <DbaSimpleSelect
              onBlur={onBlur}
              sx={{ marginTop: "6px" }}
              input={<CustomInput />}
              selectedValue={displayValue}
              setSelectedValue={onChange}
              options={columnTypes.map((item) => item.name)}
              error={!displayValue}
            />
          );
        case "orderBy":
          return (
            <CSSStyledInput
              pattern="[0-9]*"
              type="number"
              value={value === null ? "" : value}
              onChange={onChange}
              onBlur={onBlur}
              disabled
            />
          );
        case "isNullable":
          return (
            <Checkbox
              size="small"
              checked={value}
              onChange={onChange}
              onBlur={onBlur}
              disabled={original.key && id === "isNullable"}
            />
          );
        default:
          return value;
      }
    };
    return getCellContent(id as string);
  };

  const columns: ColumnDef<TGeneratedColumnRow>[] = useMemo(
    () => [
      {
        header: intl.messages["name"] as string,
        id: "name",
        accessorFn: (row) => row.name,
        cell: EditableCell,
      },
      {
        header: intl.messages["type"] as string,
        id: "type",
        accessorFn: (row) => row.type,
        cell: EditableCell,
      },
      {
        header: intl.messages["isNullable"] as string,
        id: "isNullable",
        accessorFn: (row) => row.isNullable,
        cell: EditableCell,
      },
      {
        id: intl.messages["key"] as string,
        accessorFn: (row) => row.key,
        cell: (props) => {
          return (
            <Checkbox
              size="small"
              checked={props.row.original.key}
              onChange={(e) => handleChange(e, props.row.original)}
            />
          );
        },
      },
      {
        header: intl.messages["orderBy"] as string,
        id: "orderBy",
        accessorFn: (row) => row.orderBy,
        cell: EditableCell,
      },
      {
        id: "deletion",
        header: "",
        cell: (props) => (
          <DeleteButton row={props.row} deleteDataHandler={deleteHandler} />
        ),
      },
    ],
    [EditableCell, deleteHandler, handleChange, intl.messages]
  );

  return (
    <DbaTable
      columns={columns}
      data={tableData}
      headToolbar={{ onAddButtonClick: addNewRowHandler }}
      fixControls={{ enableFixControls: false }}
    />
  );
};
