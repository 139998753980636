import styled from "@emotion/styled";

const CSSPeriodsContainer = styled.div`
  label: PeriodsContainer;
  display: flex;
  gap: 32px;
  width: 100%;
`;

const CSSDefaultPeriodSelectContainer = styled.div`
  width: 100%;
  label: DefaultPeriodSelectContainer;
`;

const CSSSwitch = styled.div<{ isMarginBottom: boolean }>`
  margin-bottom: ${({ isMarginBottom }) => (isMarginBottom ? "18px" : "6px")};
`;

export { CSSPeriodsContainer, CSSDefaultPeriodSelectContainer, CSSSwitch };
