import styled from "@emotion/styled";

export const CSSErrorCellBackground = styled.div`
  background: rgba(255, 60, 0, 0.2);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;
