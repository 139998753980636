import React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useIntl } from "react-intl";

type DbaSwitchProps = {
  text: string;
  checked: boolean;
  setChecked: (arg: boolean) => void;
};

export const DbaSwitch = ({ text, checked, setChecked }: DbaSwitchProps) => {
  const intl = useIntl();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={<Switch checked={checked} onChange={handleChange} />}
        label={(intl.messages[text] as string) || text}
      />
    </FormGroup>
  );
};
