import { rootApi } from "../../serviceRoot";

import { TGetWidgetDataRequest, WidgetDataRequest } from "../../Widget/Types";
import {
  TTableWidgetSaveResponse,
  TTableWidgetResponse,
  TTableWidgetSaveRequest,
  TTableWidgetDataResponse,
} from "./Types";

export const tableWidgetApiSlice = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getTableWidget: builder.mutation<TTableWidgetResponse, WidgetDataRequest>({
      query: (payload) => ({
        url: "api/ReportWidget/Get",
        method: "POST",
        body: payload,
      }),
    }),
    getTableWidgetData: builder.query<
      TTableWidgetDataResponse,
      TGetWidgetDataRequest
    >({
      query: (params) => ({
        url: "api/Widget/GetData",
        method: "GET",
        params: {
          widgetId: params.widgetId,
          dashboardId: params.dashboardId,
          startDate: params.startDate,
          endDate: params.endDate,
          periodType: params.periodType,
          filters: params.filters,
          "SortOptions.ColumnName": params.sortOptions?.columnName,
          "SortOptions.OrderType": params.sortOptions?.orderType,
          filterOptions: params.filterOptions,
        },
      }),
      providesTags: (result, error, arg) =>
        result
          ? [{ id: result.widgetData?.id, type: "tableWidget" }]
          : ["tableWidget"],
    }),
    createTableWidget: builder.mutation<any, TTableWidgetSaveRequest>({
      query: (payload) => ({
        url: "api/ReportWidget/Create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Widgets"],
    }),
    updateTableWidget: builder.mutation<
      TTableWidgetSaveResponse,
      TTableWidgetSaveRequest
    >({
      query: (payload) => ({
        url: "api/ReportWidget/Update",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: (result, error, arg) => {
        return result
          ? [{ type: "tableWidget", id: result.id }, "Widgets"]
          : ["tableWidget", "Widgets"];
      },
    }),
  }),
});

export const {
  useGetTableWidgetDataQuery,
  useCreateTableWidgetMutation,
  useUpdateTableWidgetMutation,
  useGetTableWidgetMutation,
  useLazyGetTableWidgetDataQuery,
} = tableWidgetApiSlice;
