import styled from "@emotion/styled";

export const CSSVideo = styled.div`
  width: 100%;
  height: 100%;

  video {
    width: 100%;
    height: 100%;
  }
`;
