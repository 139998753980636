import React from "react";
import { DataInputsConfigType } from "../../Types";
import * as AutocompleteDropdown from "../AutocompleteDropdown/AutocompleteDropdown";
import * as TextInput from "../TextInput/TextInput";
import * as AddressInput from "../AddressInput/AddressInput";

export const getDataInputsConfig = (extra?: {
  select?: Record<string | number, any>;
  input?: Record<string | number, any>;
  address?: Record<string | number, any>;
}): DataInputsConfigType => ({
  Select: {
    component: AutocompleteDropdown.AutocompleteDropdown as React.FC,
    getSelectedFilter: AutocompleteDropdown.getSelectedFilter,
    getValueToSave: AutocompleteDropdown.getValueToSave,
    extra: extra?.select,
  },
  Input: {
    component: TextInput.TextInput as React.FC,
    getSelectedFilter: TextInput.getSelectedFilter,
    getValueToSave: TextInput.getValueToSave,
    extra: extra?.input,
  },
  Address: {
    component: AddressInput.AddressInput as React.FC,
    getSelectedFilter: AddressInput.getSelectedFilter,
    getValueToSave: AddressInput.getValueToSave,
    extra: extra?.address,
  },
});
