import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { useEffect } from "react";
import { LoadingScreen } from "../../../components/common";
import { useLazyGetSelectOptionsByEnumQuery } from "../../../features/serviceSlices/serviceHooks";
import { EnumSelectType } from "./Types";

export const EnumSelect = ({
  enumName,
  inputLabel,
  setter,
  initialValue,
  styles,
  firstOptionIsInitialValue,
}: EnumSelectType) => {
  const [selectedValue, setSelectedValue] = React.useState(initialValue ?? "");
  const [getOptions, optionsResponse] = useLazyGetSelectOptionsByEnumQuery();

  //запрос списка опций с бэка
  useEffect(() => {
    getOptions(enumName);
  }, [getOptions, enumName]);

  //установка первого значения из списка опций
  useEffect(() => {
    if (
      optionsResponse.isSuccess &&
      optionsResponse.data &&
      firstOptionIsInitialValue &&
      selectedValue === ""
    ) {
      setSelectedValue(optionsResponse.data[0].id);
      setter(optionsResponse.data[0].id);
    }
  }, [firstOptionIsInitialValue, optionsResponse, selectedValue]);

  const handleSelect = (event: SelectChangeEvent) => {
    setSelectedValue(event.target.value as string);
    setter(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="selectEnum">{inputLabel}</InputLabel>
      <Select
        labelId="selectEnum"
        id="selectEnum"
        value={selectedValue}
        label={inputLabel}
        onChange={handleSelect}
        sx={{
          minWidth: styles?.minWidth !== undefined ? styles?.minWidth : "250px",
          width: styles?.width !== undefined ? styles?.width : "auto",
        }}
      >
        {optionsResponse.isSuccess ? (
          optionsResponse.data.map((item, index) => (
            <MenuItem key={index} value={item.id}>
              {item.name}
            </MenuItem>
          ))
        ) : (
          <LoadingScreen />
        )}
      </Select>
    </FormControl>
  );
};
