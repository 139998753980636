import { DbaRadarChart } from '../../../../common/Diagrams';

export const RadarChart = ({ data, settings }) => {

  const indexes = [...new Set([].concat.apply([], data.map((item) => {
    return item.data.map((item) => {
      return item[settings.indexBy]
    })
  })))];

  const merged = [].concat.apply([], data.map((item) => {
    return item.data.map((item) => {
      return item
    })
  }));

  const objs = indexes.map((item) => {
    let obj = { [settings.indexBy]: item }
    const objects = merged.filter(x => x[settings.indexBy] === item);
    objects.map((item) => obj = { ...obj, ...item })
    return obj
  })

  return (
    <DbaRadarChart data={objs} settings={settings} />
  )
}

