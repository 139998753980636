import { useState, useMemo, useCallback, useEffect } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useIntl } from "react-intl";
import { Checkbox, Stack } from "@mui/material";
import { ErrorMessage, LoadingScreen } from "../../components/common";
import { DbaSelect, DbaTable } from "../../DbaComponents";
import { DeleteButton } from "../../components/TableHelpers/DeleteButton";
import { Dashboard } from "../../features/serviceSlices/Dashboards/Types";
import {
  useAddDashboardToGroupMutation,
  useDeleteDashboardFromGroupMutation,
  useGetDashboardsQuery,
  useGetUserGroupDashboardsQuery,
  useSetDefaultDashboardMutation,
} from "../../features/serviceSlices/serviceHooks";
import { UserGroupTab } from "./Types";
import { GroupDashboard } from "../../features/serviceSlices/Group/Types";

export const DashboardsTab: React.FC<UserGroupTab> = ({
  groupId,
  snackbarMessage,
  setSnackbarMessage,
}) => {
  const intl = useIntl();
  const dashboards = useGetDashboardsQuery();
  const groupDashboards = useGetUserGroupDashboardsQuery(groupId!);
  const [addDashboard, addDashboardResponse] = useAddDashboardToGroupMutation();
  const [setDefaultDashboard, setDefaultDashboardResponse] =
    useSetDefaultDashboardMutation();
  const [deleteDashboard, deleteDashboardResponse] =
    useDeleteDashboardFromGroupMutation();

  const [selectedDashboard, setSelectedDashboard] = useState<Dashboard | null>(
    null
  );

  const [showDashboardsErrorMessage, setShowDashboardsErrorMessage] =
    useState(false);
  const [error, setError] = useState(false);

  const onAddDashboardHandler = (selectedDashboard: Dashboard) => {
    setSnackbarMessage({
      ...snackbarMessage,
      open: false,
      severity: "success",
    });
    setSelectedDashboard(selectedDashboard);
    if (!selectedDashboard?.id) {
      setError(true);
    } else if (
      groupDashboards?.data &&
      groupDashboards?.data.find((i) => i.dashboardID === selectedDashboard.id)
    ) {
      setShowDashboardsErrorMessage(true);
    } else {
      setShowDashboardsErrorMessage(false);
      setError(false);
      addDashboard({ dashboardID: selectedDashboard.id, groupID: groupId });
    }
  };

  const onDeleteDashboardHandler = useCallback(
    (obj: GroupDashboard) =>
      deleteDashboard({ dashboardID: obj.dashboardID, groupID: groupId }),
    [deleteDashboard, groupId]
  );

  const handleChangeDefault = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, dashboardId: string) => {
      if (groupId !== undefined) {
        setDefaultDashboard({
          dashboardID: dashboardId,
          groupID: groupId,
        });
      }
    },
    [groupId, setDefaultDashboard]
  );

  const groupDashboardsColumns: ColumnDef<GroupDashboard>[] = useMemo(
    () => [
      {
        id: intl.messages["name"] as string,
        accessorFn: (row) => row.name,
      },
      {
        id: intl.messages["numOfWidgets"] as string,
        accessorFn: (row) => row.widgetsCount,
      },
      {
        id: intl.messages["description"] as string,
        accessorFn: (row) => row.description,
      },
      {
        id: intl.messages["showByDefault"] as string,
        accessorFn: (row) => row.name,
        cell: (props) => {
          return (
            <Checkbox
              checked={props.row.original.isDefault}
              onChange={(e) =>
                handleChangeDefault(e, props.row.original.dashboardID)
              }
              disabled={setDefaultDashboardResponse.isLoading}
            />
          );
        },
      },
      {
        id: "deletion",
        header: "",
        cell: (props) => (
          <div
            style={{
              textAlign: "right",
            }}
          >
            <DeleteButton
              row={props.row}
              deleteDataHandler={onDeleteDashboardHandler}
            />
          </div>
        ),
      },
    ],
    [
      handleChangeDefault,
      intl.messages,
      onDeleteDashboardHandler,
      setDefaultDashboardResponse.isLoading,
    ]
  );

  useEffect(() => {
    if (addDashboardResponse.isSuccess) {
      setSnackbarMessage({
        open: true,
        message: "dashboardAddedSuccessfully",
        severity: "success",
      });
      setSelectedDashboard(null);
    }
    if (addDashboardResponse.isError) {
      setSnackbarMessage({
        open: true,
        message: "dashboardAddedError",
        severity: "error",
      });
    }
  }, [addDashboardResponse, setSnackbarMessage]);

  useEffect(() => {
    if (deleteDashboardResponse.isSuccess) {
      setSnackbarMessage({
        open: true,
        message: "dashboardRemovedSuccessfully",
        severity: "success",
      });
    }
    if (deleteDashboardResponse.isError) {
      setSnackbarMessage({
        open: true,
        message: "dashboardRemovedError",
        severity: "error",
      });
    }
  }, [deleteDashboardResponse, setSnackbarMessage]);

  return (
    <>
      {groupDashboards.isLoading || dashboards.isLoading ? (
        <LoadingScreen />
      ) : groupDashboards.isError || dashboards.isError ? (
        <ErrorMessage />
      ) : (
        <Stack direction="column" spacing={2}>
          <DbaSelect
            required
            error={error && !selectedDashboard}
            selectedValue={selectedDashboard}
            setSelectedValue={onAddDashboardHandler}
            options={dashboards.data?.filter(
              (dashboard) =>
                !groupDashboards.data
                  ?.map((groupDashboard) => groupDashboard.dashboardID)
                  .includes(dashboard.id)
            )}
            label="chooseDashboard"
          />
          {showDashboardsErrorMessage && (
            <ErrorMessage title="addNewItemError" />
          )}
          <DbaTable
            data={groupDashboards.data ?? []}
            columns={groupDashboardsColumns}
            headToolbar={{ showHeadToolbar: false }}
            isLoading={groupDashboards.isFetching}
            fixControls={{ enableFixControls: false }}
          />
        </Stack>
      )}
    </>
  );
};
