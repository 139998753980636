import React, { useContext } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import {
  useGetDashboardQuery,
  useGetWidgetQuery,
} from "../../../../features/serviceSlices/serviceHooks";
import { DbaIconButton } from "../../../../DbaComponents";
import { ErrorMessage } from "../../../common";
import { ThemeContext } from "../../../../utils/ThemeContext";
import colors from "../../../../Variables.module.scss";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CSSFullScreenWidgetNavMenuContainer } from "./FullScreenWidgetNavMenu.styles";

export const FullScreenWidgetNavMenu: React.FC = () => {
  const { darkMode } = useContext(ThemeContext);
  const params = useParams();
  const { data, error, isLoading } = useGetDashboardQuery(params.dashboardID!);
  const [searchParams] = useSearchParams();
  const widgetID = searchParams.get("id");
  const widget = useGetWidgetQuery(widgetID!);
  const navigate = useNavigate();

  return (
    <CSSFullScreenWidgetNavMenuContainer>
      <DbaIconButton
        size="medium"
        icon={
          <ArrowBackIcon
            sx={{
              color: darkMode ? "" : colors.darkBlue,
            }}
            fontSize="large"
          />
        }
        onClick={() => navigate(params.dashboardID!)}
      />
      {error || widget.isError ? (
        <ErrorMessage />
      ) : isLoading || widget.isLoading ? (
        <Skeleton
          sx={{ borderRadius: "8px" }}
          variant="rectangular"
          width={430}
          height={42}
        />
      ) : data && widget.data ? (
        <Typography variant="h5" component="p">
          {data?.name} {" / "}
          {widget.data.label}
        </Typography>
      ) : null}
    </CSSFullScreenWidgetNavMenuContainer>
  );
};
