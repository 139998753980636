import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import { ErrorMessage, LoadingScreen } from "../../components/common";
import { DbaSelect, DbaTable } from "../../DbaComponents";
import { DeleteButton } from "../../components/TableHelpers/DeleteButton";
import { UserResponse } from "../../features/serviceSlices/Authentication/Types";
import {
  useAddUserToGroupMutation,
  useDeleteUserFromGroupMutation,
  useGetAllUsersQuery,
  useGetGroupUsersQuery,
} from "../../features/serviceSlices/serviceHooks";
import { UserGroupTab } from "./Types";
import { GroupUser } from "../../features/serviceSlices/Group/Types";

const ACTION_COLUMN_SIZE = 44;

export const UsersTab: React.FC<UserGroupTab> = ({
  groupId,
  snackbarMessage,
  setSnackbarMessage,
}) => {
  const intl = useIntl();
  const allUsers = useGetAllUsersQuery(false);
  const groupUsers = useGetGroupUsersQuery(groupId!);
  const [addUser, addUserResponse] = useAddUserToGroupMutation();
  const [deleteUser, deleteUserResponse] = useDeleteUserFromGroupMutation();

  const [selectedUser, setSelectedUser] = useState<UserResponse | null>(null);

  const [showUsersErrorMessage, setShowUsersErrorMessage] = useState(false);
  const [error, setError] = useState(false);

  const onAddUserHandler = (selectedUser: UserResponse) => {
    setSnackbarMessage({
      ...snackbarMessage,
      open: false,
      severity: "success",
    });
    setSelectedUser(selectedUser);
    if (
      groupUsers?.data &&
      groupUsers?.data.find((i) => i.userID === selectedUser.id)
    ) {
      setShowUsersErrorMessage(true);
    } else {
      setShowUsersErrorMessage(false);
      setError(false);
      addUser({
        userID: selectedUser.id,
        groupID: groupId,
      });
    }
  };

  const onDeleteUserHandler = useCallback(
    (user: GroupUser) => {
      deleteUser({ userID: user.userID, groupID: groupId });
    },
    [deleteUser, groupId]
  );

  useEffect(() => {
    if (addUserResponse.isSuccess) {
      setSnackbarMessage({
        open: true,
        message: "userAddedSuccessfully",
        severity: "success",
      });
      setSelectedUser(null);
    }
    if (addUserResponse.isError) {
      setSnackbarMessage({
        open: true,
        message: "userAddedError",
        severity: "error",
      });
    }
  }, [addUserResponse, setSnackbarMessage]);

  useEffect(() => {
    if (deleteUserResponse.isSuccess) {
      setSnackbarMessage({
        open: true,
        message: "userRemovedSuccessfully",
        severity: "success",
      });
    }
    if (deleteUserResponse.isError) {
      setSnackbarMessage({
        open: true,
        message: "userRemovedError",
        severity: "error",
      });
    }
  }, [deleteUserResponse, setSnackbarMessage]);

  const groupUsersColumns: ColumnDef<GroupUser>[] = useMemo(
    () => [
      {
        id: intl.messages["nameLogin"] as string,
        accessorFn: (row) => row.userName,
        cell: (props) => {
          return `${props.row.original.lastName} ${props.row.original.firstName} ${props.row.original.secondName} (${props.row.original.userName})`;
        },
      },
      {
        id: "deletion",
        header: "",
        cell: (props) => (
          <div
            style={{
              textAlign: "right",
            }}
          >
            <DeleteButton
              row={props.row}
              deleteDataHandler={onDeleteUserHandler}
            />
          </div>
        ),
        size: ACTION_COLUMN_SIZE,
      },
    ],
    [intl.messages, onDeleteUserHandler]
  );

  return (
    <>
      {groupUsers.isLoading || allUsers.isLoading ? (
        <LoadingScreen />
      ) : groupUsers.isError || allUsers.isError ? (
        <ErrorMessage />
      ) : (
        <Box
          component="form"
          sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          noValidate
          autoComplete="off"
        >
          <DbaSelect
            required
            error={error && !selectedUser}
            selectedValue={selectedUser}
            setSelectedValue={onAddUserHandler}
            options={allUsers.data?.filter(
              (user) =>
                !groupUsers.data
                  ?.map((groupUser) => groupUser.userID)
                  .includes(user.id)
            )}
            label="chooseUser"
            optionLabel="fullName"
          />
          {showUsersErrorMessage && <ErrorMessage title="addNewItemError" />}
          {groupUsers.data !== undefined ? (
            <DbaTable
              data={groupUsers.data}
              columns={groupUsersColumns}
              headToolbar={{ showHeadToolbar: false }}
              isLoading={groupUsers.isFetching || groupUsers.isLoading}
              fixControls={{ enableFixControls: false }}
            />
          ) : (
            ""
          )}
        </Box>
      )}
    </>
  );
};
