import { WidgetRelatedReportType } from "../../../../features/serviceSlices/Widget/Types";

export type WidgetRelatedReportsActionTypes =
  | {
      type: "addWidgetRelatedReport";
      payload: WidgetRelatedReportType;
    }
  | {
      type: "changeWidgetRelatedReport";
      payload: WidgetRelatedReportType;
      initialObj: WidgetRelatedReportType;
    }
  | {
      type: "deleteWidgetRelatedReport";
      payload: WidgetRelatedReportType;
    }
  | {
      type: "loadWidgetRelatedReports";
      payload: WidgetRelatedReportType[];
    };

export const widgetRelatedReportsReducer = (
  state: WidgetRelatedReportType[] | null,
  action: WidgetRelatedReportsActionTypes
) => {
  switch (action.type) {
    case "addWidgetRelatedReport":
      if (state) {
        return !state.find((item) => item.id === action.payload.id)
          ? [...state, action.payload]
          : state;
      }
      return [action.payload];
    case "changeWidgetRelatedReport":
      return state
        ? state.map((item) =>
            item === action.initialObj ? action.payload : item
          )
        : state;
    case "deleteWidgetRelatedReport":
      return state ? state.filter((item) => item !== action.payload) : state;
    case "loadWidgetRelatedReports":
      return action.payload;
    default:
      return state;
  }
};

export const initialWidgetRelatedReportsState = null;
