import React, { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from "@mui/icons-material/Menu";
import { DbaIconButton } from "../../DbaComponents";
import Box from "@mui/material/Box";
import { useLocation, useParams } from "react-router";
import { Outlet, useSearchParams } from "react-router-dom";
import { PageHeader } from "./components/PageHeader/PageHeader";
import { CSSPageTemplate, CSSSidebarToolbar } from "./PageTemplate.styles";
import { TSidebarMenuState } from "./PageTemplate.types";
import { NavMenu } from "./components/NavMenuItems/NavMenu";

export const PageTemplate: React.FC = () => {
  const mobileView = useMediaQuery("(max-width:1000px)");
  const location = useLocation();
  const params = useParams();
  const [searchParams] = useSearchParams();

  const [openSidebar, setOpenSidebar] = useState<boolean>(false);

  const menuState: TSidebarMenuState = {
    dashboardID: params.dashboardID,
    path: location.pathname.toLowerCase(),
    isMobile: mobileView,
    isDashboardPage: !!(location.pathname === "/" || params.dashboardID),
    isFullscreenMode: !!(
      location.pathname.includes("/widget") &&
      (searchParams.get("mode") || searchParams.get("id"))
    ),
    isPlaylistMode: !!searchParams.get("playlist"),
  };

  useEffect(() => {
    if (menuState.path) {
      setOpenSidebar(false);
    }
  }, [menuState.path]);

  useEffect(() => {
    if (!menuState.isMobile && openSidebar) {
      setOpenSidebar(false);
    }
  }, [menuState.isMobile, openSidebar]);

  return (
    <CSSPageTemplate>
      <CssBaseline />
      <MuiAppBar
        position="fixed"
        sx={{
          boxShadow: "none",
          width: menuState.isMobile ? "100%" : "calc(100% - 57px)",
          zIndex: 500,
        }}
      >
        <CSSSidebarToolbar>
          {menuState.isMobile && (
            <DbaIconButton
              onClick={() => setOpenSidebar(true)}
              icon={<MenuIcon />}
            />
          )}

          <PageHeader menuState={menuState} />
        </CSSSidebarToolbar>
      </MuiAppBar>
      <NavMenu
        menuState={menuState}
        openSidebar={openSidebar}
        setOpenSidebar={setOpenSidebar}
      />
      <Box
        component="main"
        sx={{
          marginTop: "56px",
          height: "calc(100% - 56px)",
          width: "100%",
          overflowY: "auto",
          padding: menuState.isDashboardPage ? 0 : "1rem",
        }}
      >
        <Outlet />
      </Box>
    </CSSPageTemplate>
  );
};
