import { useEffect, useCallback, useMemo, useRef } from "react";
import { useAppSelector, useAppDispatch } from "../../../utils/reduxHooks";
import {
  start,
  stop,
  goToNextDashboard,
} from "../../../features/slices/Playlist/playlistSlice";
import { TDashboardReplacementPeriodForResponse } from "../../../features/serviceSlices/Playlists/Types";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  diasRoleChange,
  periodChange,
  dateChange,
  timezoneReset,
} from "../../../features/slices/App/appSlice";
import { TimeoutId } from "@reduxjs/toolkit/dist/query/core/buildMiddleware/types";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  createNewDateOnPeriodStart,
  createNewDateOnPeriodEnd,
} from "../../../utils/helpers/dateTime";
import {
  useGetAllPlaylistsQuery,
  useLazyGetDashboardByIdQuery,
} from "../../../features/serviceSlices/serviceHooks";
import { TUseModeReturn } from "../Types";
import { TISODateString } from "../../../features/serviceSlices/SharedTypes";

const ERROR_SHOWING_INTERVAL = 7000;

type TUsePlaylistMode = {
  skip: boolean;
};

/**
 * Хук включает и выключает режим воспроизвдения плейлиста взависимости от наличия get параметра playlist.
 * Параметр playlist должен содержать id плейлиста.
 */
export const usePlaylistMode = (options: TUsePlaylistMode): TUseModeReturn => {
  const timeout = useRef<TimeoutId | null>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { dashboardID } = useParams<Record<string, string | undefined>>();
  const [searchParams] = useSearchParams();
  const playlistSearchParam = searchParams.get("playlist");

  const playlists = useGetAllPlaylistsQuery(
    options.skip ? skipToken : undefined
  );
  const [getDashboard, dashboard] = useLazyGetDashboardByIdQuery();

  const playlist = useAppSelector((store) => store.playlist);

  const prevIsPlayingValue = useRef<boolean>();

  const getReplacementPeriod = useCallback(
    (
      replacementPeriod: TDashboardReplacementPeriodForResponse | undefined
    ): number => {
      return (
        ((replacementPeriod?.hours ?? 0) * 60 * 60 +
          (replacementPeriod?.minutes ?? 0) * 60 +
          (replacementPeriod?.seconds ?? 0)) *
        1000
      );
    },
    []
  );

  const replacementPeriodMS = useMemo(
    () =>
      getReplacementPeriod(
        playlist.data
          ?.replacementPeriod as TDashboardReplacementPeriodForResponse
      ),
    [getReplacementPeriod, playlist.data?.replacementPeriod]
  );

  /**
   * Запуск плейлиста при переходе по ссылке с параметром playlist
   */
  useEffect(() => {
    if (playlist.isPlaying) {
      return;
    }
    const currentPlaylistId = playlistSearchParam;
    if (!(dashboardID && currentPlaylistId)) {
      return;
    }
    const currentPlaylist = playlists.data?.find(
      (playlist) => playlist.id === currentPlaylistId
    );
    if (!currentPlaylist) {
      return;
    }
    // dispatch(periodChange("NotSet")); - ради оптимизации. Теперь не будет лишних запросов в режиме
    // воспроизвдения в случае если в редьюсере еще не обновились параметры времени и периода соответствующие
    // дашборду из плейлиста
    dispatch(periodChange("NotSet"));
    dispatch(timezoneReset());
    dispatch(start(currentPlaylist));
  }, [
    dashboardID,
    dispatch,
    options.skip,
    playlist.isPlaying,
    playlistSearchParam,
    playlists.data,
  ]);

  /**
   * Остановка плейлиста если из урла пропал параметр playlist
   */
  useEffect(() => {
    if (playlist.isPlaying && !playlistSearchParam) {
      dispatch(stop());
    }
  }, [dispatch, playlist.isPlaying, playlistSearchParam]);

  /**
   * При изменении значения редьюсера с isPlaying на !isPlaying всегда делаем некоторые действия
   */
  useEffect(() => {
    if (options.skip) {
      return;
    }
    if (prevIsPlayingValue.current && !playlist.isPlaying) {
      clearTimeout(timeout.current!);
    }
    prevIsPlayingValue.current = playlist.isPlaying;
  }, [dispatch, options.skip, playlist.isPlaying]);

  /**
   * При размонтировании всегда останавливаем воспроизведение и очищаем таймер
   */
  useEffect(() => {
    return () => {
      if (playlist.isPlaying) {
        dispatch(stop());
        clearTimeout(timeout.current!);
      }
    };
  }, [dispatch, playlist.isPlaying]);

  /**
   * Логика автоматического переключения дашбордов из плейлиста
   */
  useEffect(() => {
    if (playlist.isPlaying) {
      if (replacementPeriodMS) {
        if (playlist.currentPlayingDashboard!.dashboardId !== dashboardID) {
          navigate(
            `/${
              playlist.currentPlayingDashboard!.dashboardId
            }?playlist=${playlistSearchParam}`,
            {
              replace: true,
            }
          );
        }
        clearTimeout(timeout.current!);
        timeout.current = setTimeout(() => {
          dispatch(goToNextDashboard());
        }, replacementPeriodMS);
      }
    }
  }, [
    dashboardID,
    dispatch,
    navigate,
    playlist.currentPlayingDashboard,
    playlist.data?.replacementPeriod,
    playlist.isPlaying,
    playlistSearchParam,
    replacementPeriodMS,
  ]);

  /**
   * В случае ошибки загрузки дашборда показываем ее заданное количество времени,
   * по истечении которого переключаемся на следующий дашборд
   */
  useEffect(() => {
    if (playlist.isPlaying && dashboard.error) {
      clearTimeout(timeout.current!);
      timeout.current = setTimeout(() => {
        dispatch(goToNextDashboard());
      }, ERROR_SHOWING_INTERVAL);
    }
  }, [dashboard.error, dispatch, playlist.isPlaying]);

  /**
   * Для загрузки виджетов на дашбордах нужны значения периода и типа периода для каждого дашборда.
   * Здесь устанавливаем эти параметры.
   */
  useEffect(() => {
    if (playlist.isPlaying) {
      getDashboard(playlist.currentPlayingDashboard!.dashboardId)
        .unwrap()
        .then((dashboard) => {
          dispatch(periodChange(playlist.currentPlayingDashboard!.periodType));
          dispatch(diasRoleChange(dashboard));
          if (playlist.currentPlayingDashboard!.period) {
            dispatch(
              dateChange({
                startDate: new Date(playlist.currentPlayingDashboard!.period),
              })
            );
            dispatch(
              dateChange({
                endDate: createNewDateOnPeriodEnd(
                  playlist.currentPlayingDashboard!.periodType,
                  playlist.currentPlayingDashboard!.period as TISODateString
                ),
              })
            );
          } else {
            dispatch(
              dateChange({
                startDate: createNewDateOnPeriodStart(
                  playlist.currentPlayingDashboard!.periodType
                ),
              })
            );
            dispatch(
              dateChange({
                endDate: createNewDateOnPeriodEnd(
                  playlist.currentPlayingDashboard!.periodType
                ),
              })
            );
          }
        });
    }
  }, [
    dispatch,
    playlist.isPlaying,
    playlist.currentPlayingDashboard,
    getDashboard,
  ]);

  return {
    isLoading: !options.skip && dashboard.isFetching,
    isSuccess: !options.skip && dashboard.isSuccess,
    error: !options.skip && dashboard.error,
  };
};
