import React, { useContext } from "react";
import { useIntl } from "react-intl";
import {
  useAppSelector,
  useAppDispatch,
} from "../../../../../../../utils/reduxHooks";
import { editModeChange } from "../../../../../../../features/slices/App/appSlice";
import { ThemeContext } from "../../../../../../../utils/ThemeContext";
import { useSaveLayoutMutation } from "../../../../../../../features/serviceSlices/serviceHooks";
import { RoleGuard } from "../../../../../../Guards/RoleGuard/RoleGuard";
import { Alert, ListItemButton, Snackbar, Stack, Tooltip } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import SaveIcon from "@mui/icons-material/Save";
import { TPropsDashboardEditorToolbar } from "./DashboardEditorToolbar.types";
import colors from "../../../../../../../Variables.module.scss";
import { useSnackbar } from "../../../../../../../utils/hooks/useSnackbar";
import { isFetchBaseQueryErrorTypeGuard } from "../../../../../../../utils/TypeGuards";

export const DashboardEditorToolbar: React.FC<TPropsDashboardEditorToolbar> = ({
  menuState,
}) => {
  const intl = useIntl();
  const [showTooltip, setShowTooltip] = React.useState(false);
  const { darkMode } = useContext(ThemeContext);
  const dispatch = useAppDispatch();
  const { role, editMode, layouts, type } = useAppSelector(
    (state) => state.app
  );
  const dashboardIsSwitching = !type || type === "NotSet";
  const [saveLayout, saveLayoutResponse] = useSaveLayoutMutation();
  const { isShowSnackbar, snackbarMessage, snackbarSeverity, blinkSnackbar } =
    useSnackbar();

  const saveLayoutHandler = () => {
    if (layouts) {
      saveLayout({
        dashboardID: role.id,
        layout: layouts,
      })
        .unwrap()
        .catch((error) => {
          blinkSnackbar(
            isFetchBaseQueryErrorTypeGuard(error)
              ? error.data.message
              : (intl.messages["errorMessage"] as string),
            "error"
          );
        })
        .then(() => {
          blinkSnackbar(intl.messages["editSuccesResponse"] as string);
        });
    }
  };

  const iconStyle = {
    color: darkMode ? colors.light : colors.darkBlue,
  };

  const getSnackbar = (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={isShowSnackbar}
      autoHideDuration={null}
    >
      <Alert elevation={6} severity={snackbarSeverity} sx={{ width: "100%" }}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );

  return (
    <RoleGuard allowedRoles={["Admin"]}>
      <>
        <Stack direction="row" justifyContent="flex-start" alignItems="center">
          {editMode ? (
            <>
              <Tooltip
                title={intl.messages["closeEditDashboard"]}
                placement="right"
                arrow
                open={showTooltip}
                disableHoverListener
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <ListItemButton
                  onClick={() => {
                    setShowTooltip(false);
                    dispatch(editModeChange(false));
                  }}
                  component="button"
                >
                  <DoNotDisturbIcon sx={iconStyle} />
                </ListItemButton>
              </Tooltip>
              <Tooltip title={intl.messages["save"]} placement="right" arrow>
                <ListItemButton onClick={saveLayoutHandler} component="button">
                  {saveLayoutResponse.isLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <SaveIcon sx={iconStyle} />
                  )}
                </ListItemButton>
              </Tooltip>
            </>
          ) : (
            <Tooltip
              title={intl.messages["editDashboardMode"]}
              placement="right"
              arrow
              open={showTooltip}
              disableHoverListener
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              <ListItemButton
                disabled={dashboardIsSwitching}
                onClick={() => {
                  setShowTooltip(false);
                  dispatch(editModeChange(true));
                }}
                component="button"
              >
                <ModeEditIcon sx={iconStyle} />
              </ListItemButton>
            </Tooltip>
          )}
        </Stack>
        {getSnackbar}
      </>
    </RoleGuard>
  );
};
