import { useLocation, Navigate } from "react-router-dom";
import { useAppSelector } from "../utils/reduxHooks";
import { LoadAuthInfo } from "./AuthService";

export default function AuthRoute({
  children,
  isAdminRequired,
  preventRedirect = false,
}: {
  children: JSX.Element;
  isAdminRequired?: boolean;
  preventRedirect?: boolean;
}) {
  let location = useLocation();
  const { authorizationInfo } = useAppSelector((state) => state.app);

  if (authorizationInfo !== null) {
    if (!authorizationInfo?.isAuth) {
      return <Navigate to="/login" state={{ from: location }} />;
    }
    if (isAdminRequired && !authorizationInfo?.isAdmin) {
      return <Navigate to="/" state={{ from: location }} />;
    }
    return children;
  } else {
    let result = LoadAuthInfo();
    if (result) {
      return children;
    }
  }

  return preventRedirect ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
}
