import styled from "@emotion/styled";
import MuiDrawer from "@mui/material/Drawer";

export const CSSMobileDrawer = styled(MuiDrawer)(
  ({ theme, ...other }: any) => ({
    zIndex: 1000,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    width: `calc(${theme.spacing(7)} + 1px)`,
    "& .MuiDrawer-paper": {
      overflowX: "hidden",
      width: 300,
      borderRight: "none",
    },
  })
);

export const CSSDesktopDrawer = styled(MuiDrawer)(
  ({ theme, ...other }: any) => ({
    zIndex: 1000,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    width: `calc(${theme.spacing(7)} + 1px)`,
    "& .MuiDrawer-paper": {
      overflowX: "hidden",
      width: `calc(${theme.spacing(7)} + 1px)`,
      borderRight: "none",
    },
  })
);
