import ClickAwayListener from "@mui/material/ClickAwayListener";
import Box from "@mui/material/Box";
import { DbaColorInput } from "../../../../../../DbaComponents";
import { intl } from "../../../../../../utils/locale";
import CheckIcon from "@mui/icons-material/Check";
import Typography from "@mui/material/Typography";
import { PaletteOptions } from "./ColorOptions";
import {
  CSSDbaPopper,
  CSSPaletteContainer,
  CSSPaletteRow,
  CSSColorSwatch,
} from "./ColorSelector.styles";

export type PaletteType = { label: string; colors: string[] };

type PaletteProps = PaletteType & {
  onColorChangeHandler: (value: string) => void;
  selectedColor: string;
};

const Palette = ({
  label,
  colors,
  onColorChangeHandler,
  selectedColor,
}: PaletteProps) => {
  return (
    <CSSPaletteRow>
      <Typography>{label}</Typography>
      {colors.map((color) => (
        <CSSColorSwatch
          key={color}
          color={color}
          onClick={() => onColorChangeHandler(color)}
          isSelected={selectedColor === color}
        >
          {selectedColor === color && <CheckIcon sx={{ fontSize: 16 }} />}
        </CSSColorSwatch>
      ))}
    </CSSPaletteRow>
  );
};

type ColorSelectorProps = {
  anchorEl: null | HTMLElement;
  setAnchorEl: (arg: null | HTMLElement) => void;
  color: string;
  onColorChangeHandler: (value: string) => void;
};

export const ColorSelector = ({
  anchorEl,
  setAnchorEl,
  color,
  onColorChangeHandler,
}: ColorSelectorProps) => {
  const open = Boolean(anchorEl);
  const id = open ? (intl.messages["chooseColor"] as string) : undefined;

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  return (
    <CSSDbaPopper id={id} open={open} anchorEl={anchorEl} placement="left-start">
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box
          sx={{ border: 1, p: 1, bgcolor: "background.paper", borderRadius: 2 }}
        >
          <CSSPaletteContainer>
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              {intl.messages["chooseColor"]}
            </Typography>
            {PaletteOptions.map(({ label, colors }) => (
              <Palette
                key={label}
                label={label}
                colors={colors}
                selectedColor={color}
                onColorChangeHandler={onColorChangeHandler}
              />
            ))}
            <CSSPaletteRow className="customColor">
              <Typography>{intl.messages["select"]}</Typography>
              <DbaColorInput
                value={color}
                onChange={(e) => onColorChangeHandler(e.target.value)}
              />
            </CSSPaletteRow>
          </CSSPaletteContainer>
        </Box>
      </ClickAwayListener>
    </CSSDbaPopper>
  );
};
