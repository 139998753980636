import { BaseSettingsProps } from "./Types";
import { SettingsContainer } from "../../SettingsContainer";
import { WidgetSettingContainer } from "./common/WidgetSettingContainer";
import Switch from "@mui/material/Switch";
import { DbaSlider, DbaSimpleSelect } from "../../../../../DbaComponents";

const dotLabelOptions = [
  {
    value: "value",
    label: "Значение",
  },
  {
    value: "formattedValue",
    label: "Отформатированное значение",
  },
  {
    value: "index",
    label: "Индекс",
  },
  {
    value: "key",
    label: "Ключ",
  },
];

export const DotsSettings = ({ settings, setSettings }: BaseSettingsProps) => {
  return (
    <SettingsContainer title="points">
      <WidgetSettingContainer title="enablePoints">
        <Switch
          checked={settings.diagram.enableDots ?? true}
          onChange={(e) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                enableDots: e.target.checked,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="pointSize">
        <DbaSlider
          fullWidth
          sx={{ width: 300 }}
          value={settings.diagram.dotSize ?? 6}
          min={2}
          max={64}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                dotSize: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="pointBorderWidth">
        <DbaSlider
          fullWidth
          sx={{ width: 300 }}
          value={settings.diagram.dotBorderWidth ?? 0}
          min={0}
          max={10}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                dotBorderWidth: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="enablePointLabel">
        <Switch
          checked={settings.diagram.enableDotLabel ?? false}
          onChange={(e) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                enableDotLabel: e.target.checked,
              },
            }))
          }
        />
        {settings.diagram.enableDotLabel && (
          <WidgetSettingContainer title="enablePointLabel">
            <DbaSimpleSelect
              setSelectedValue={(value) => {
                setSettings((prevState: any) => ({
                  ...prevState,
                  diagram: {
                    ...prevState.diagram,
                    dotLabel: value,
                  },
                }));
              }}
              selectedValue={
                settings.diagram.dotLabel === undefined
                  ? dotLabelOptions.find(
                      (option) => option.value === "formattedValue"
                    )
                  : dotLabelOptions.find(
                      (option) => option.value === settings.diagram.dotLabel
                    )
              }
              options={dotLabelOptions}
            />
          </WidgetSettingContainer>
        )}
      </WidgetSettingContainer>
    </SettingsContainer>
  );
};
