import { WidgetSettingContainer } from "../../common";
import type { ChartSettingsProps } from "../Types";
import { DbaColorInput, DbaSlider } from "../../../../../../../DbaComponents";
import Switch from "@mui/material/Switch";

export const TimeRangeChartStyleSettings = ({
  settings,
  setSettings,
}: ChartSettingsProps) => {
  return (
    <>
      <WidgetSettingContainer title="forceSquare">
        <Switch
          checked={settings.diagram.square ?? true}
          onChange={(e) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                square: e.target.checked,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="dayRadius">
        <DbaSlider
          sx={{ width: 150 }}
          value={settings.diagram.dayRadius ?? 0}
          min={0}
          max={20}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                dayRadius: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="emptyCellsColor">
        <DbaColorInput
          value={settings.diagram.emptyColor}
          onChange={(event) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                emptyColor: event.target.value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="paddingHeatMap">
        <DbaSlider
          sx={{ width: 120 }}
          value={settings.diagram.daySpacing ?? 0}
          min={0}
          max={20}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                daySpacing: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="cellBorderWidth">
        <DbaSlider
          sx={{ width: 150 }}
          value={settings.diagram.dayBorderWidth ?? 1}
          min={0}
          max={20}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                dayBorderWidth: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="dayBorderColor">
        <DbaColorInput
          value={settings.diagram.dayBorderColor}
          onChange={(event) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                dayBorderColor: event.target.value,
              },
            }))
          }
        />
      </WidgetSettingContainer>
    </>
  );
};
