import { getDiagram } from "../../components/Widgets/Widgets/Diagram/Diagram";
import { CSSFullScreenWidgetContainer } from "./FullScreenWidget.styles";
import { WidgetLayout } from "../../components/Widgets/common";
import { TDiagramWidgetData } from "../../features/serviceSlices/SpecificWidgets/DiagramWidget/Types";

export const FullScreenDiagram = ({
  data,
  isFetching,
}: {
  data: TDiagramWidgetData;
  isFetching: boolean;
}) => {
  return (
    <CSSFullScreenWidgetContainer body={data.widgetSettings.settings.widget.body}>
      <WidgetLayout
        fetching={isFetching}
        settings={data.widgetSettings.settings.widget}
        label={data.label}
        type="Diagram"
        settingsId={data.widgetSettings.id}
        dashboardId={data.widgetSettings.dashboardID}
        widgetID={data.widgetSettings.widgetID}
      >
        {data.widgetSettings?.settings?.diagram?.diagramType
          ? getDiagram(data.widgetSettings.settings.diagram.diagramType, data)
          : "Необходимо выбрать тип диаграммы"}
      </WidgetLayout>
    </CSSFullScreenWidgetContainer>
  );
};
