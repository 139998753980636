import styled from "@emotion/styled";
import colors from "../../Variables.module.scss";

export const CSSErrorMessagesContainer = styled.div<{ darkMode: boolean }>`
  & p {
    font-size: 20px;
    font-weight: 600;
    color: ${({ darkMode }) =>
      darkMode ? colors.white : colors.darkBlue} !important;
    padding: 0 20px;
    margin: 100px auto 0;
    text-align: center;
    white-space: break-spaces;
  }
`;
