import React from "react";
import { ErrorMessage } from "./ErrorMessage";
import { PageNotFound } from "../../Pages";
import { EErrorCodes } from "../../features/serviceSlices/SharedTypes";

type TErrorMessageByCode = { code: EErrorCodes };

export const ErrorMessageByCode: React.FC<TErrorMessageByCode> = ({ code }) => {
  const errorMap: Record<any, any> = new Map();
  errorMap[EErrorCodes.NOT_FOUND] = <PageNotFound />;
  errorMap[EErrorCodes.FORBIDDEN] = <ErrorMessage title="forbiddenError" />;

  return errorMap[code] ?? <ErrorMessage />;
};
