import { BaseSettingsProps } from "./Types";
import { SettingsContainer } from "../../SettingsContainer";
import { SettingsType } from "../../../../../features/serviceSlices/WidgetSettings/Types"
import {
  LineChartAxesSettings,
  BarChartAxesSettings,
  HeatmapChartAxesSettings,
  RadialChartAxesSettings,
  FunnelChartAxesSettings,
  RadarChartAxesSettings,
  AreaBumpChartAxesSettings,
} from "./Charts";
import { DiagramTypes } from "../../../../../features/serviceSlices/SpecificWidgets/DiagramWidget/Types";

const getSettings = (
  diagramType: DiagramTypes,
  settings: SettingsType,
  setSettings: (arg: any) => void
) => {
  switch (diagramType) {
    case "Line":
      return (
        <LineChartAxesSettings settings={settings} setSettings={setSettings} />
      );
    case "Bar":
      return (
        <BarChartAxesSettings settings={settings} setSettings={setSettings} />
      );
    case "Radar":
      return (
        <RadarChartAxesSettings settings={settings} setSettings={setSettings} />
      );
    case "Funnel":
      return (
        <FunnelChartAxesSettings
          settings={settings}
          setSettings={setSettings}
        />
      );
    case "AreaBump":
      return (
        <AreaBumpChartAxesSettings
          settings={settings}
          setSettings={setSettings}
        />
      );
    case "HeatMap":
      return (
        <HeatmapChartAxesSettings
          settings={settings}
          setSettings={setSettings}
        />
      );
    case "RadialBar":
      return (
        <RadialChartAxesSettings
          settings={settings}
          setSettings={setSettings}
        />
      );
    default:
      return "";
  }
};

export const AxesSettings = ({
  settings,
  setSettings,
  diagramType,
}: BaseSettingsProps) => {
  return (
    <SettingsContainer title="axes">
      {getSettings(diagramType, settings, setSettings)}
    </SettingsContainer>
  );
};
