import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import { useIntl } from "react-intl";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Stack";

export const PageNotFound = () => {
  const intl = useIntl();
  return (
    <Stack spacing={2}>
      <Alert severity="warning">{intl.messages["pageNotFound"]}</Alert>

      <Box sx={{ display: "block", padding: "0 16px 0" }}>
        <Link to={{ pathname: "/" }} component={RouterLink}>
          {intl.messages["goToMain"]}
        </Link>
      </Box>
    </Stack>
  );
};
