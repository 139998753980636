import React, { useEffect } from "react";
import {
  DataInputProps,
  OptionType,
  GetSelectedFilterType,
  GetValueToSaveType,
} from "../../Types";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "../../../../../../utils/hooks/useSnackbar";
import { Alert, Snackbar, Box } from "@mui/material";
import { createPortal } from "react-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useIntl } from "react-intl";
import { parseJSON } from "../../../../../../utils/helpers/functions";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { CSSAutocompleteDropdownWrapper } from "../../FilterWidget.styles";

export const getSelectedFilter: GetSelectedFilterType = ({
  findObject,
  data,
}) => {
  return data?.value[0]?.data.find(
    (item: { key: string | number }) => item.key === findObject[data.variable]
  );
};

export const getValueToSave: GetValueToSaveType = ({
  value,
}: {
  value: OptionType;
}) => value.key;

export const AutocompleteDropdown: React.FC<
  DataInputProps<OptionType | null>
> = ({ data, selectedValue, handleChange }) => {
  const intl = useIntl();
  const {
    isShowSnackbar,
    snackbarMessage,
    snackbarSeverity,
    blinkSnackbar,
    closeSnackbar,
  } = useSnackbar();

  const onChange = (
    event: React.BaseSyntheticEvent,
    newValue: OptionType | null
  ) => {
    if (newValue) {
      handleChange(newValue);
    }
  };

  const onReset = () => {
    handleChange(null);
  };

  useEffect(() => {
    if (
      selectedValue &&
      !data.value[0].data.find((item) => item.key === selectedValue.key)
    ) {
      blinkSnackbar(
        intl.messages["oldWidgetDataDueToFilterDefaultValue"] as string,
        "error",
        Infinity
      );
    }
  }, [
    blinkSnackbar,
    data.value,
    intl.messages,
    selectedValue,
    selectedValue?.key,
  ]);

  const snackbar = createPortal(
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={isShowSnackbar}
      autoHideDuration={null}
    >
      <Alert elevation={6} severity={snackbarSeverity} sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "start",
            gap: "8px",
          }}
        >
          {snackbarMessage}
          <CloseIcon
            onClick={closeSnackbar}
            style={{
              marginBottom: "-4px",
              position: "relative",
              top: "-1.5px",
              cursor: "pointer",
            }}
          />
        </Box>
      </Alert>
    </Snackbar>,
    document.body
  );

  const parsedDefaultValue = parseJSON(data.defaultValue)?.value;
  const isDefaultValue =
    (!parsedDefaultValue && !selectedValue?.key) ||
    parsedDefaultValue === selectedValue?.key;

  return (
    <>
      <CSSAutocompleteDropdownWrapper>
        <Autocomplete
          fullWidth
          value={selectedValue}
          onChange={onChange}
          options={data.value[0].data.filter(
            (item) => item.key !== null && item.label !== null
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={data.label}
              InputProps={{
                ...params.InputProps,
                endAdornment: !isDefaultValue ? (
                  <InputAdornment position="end">
                    <IconButton onClick={onReset} size="small">
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ) : undefined,
              }}
            />
          )}
        />
      </CSSAutocompleteDropdownWrapper>
      {snackbar}
    </>
  );
};
