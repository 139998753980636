import { MapControl } from "../../../../common";
import styled from "@emotion/styled";
import colors from "../../../../../Variables.module.scss";
import React, { useState } from "react";
import LayersIcon from "@mui/icons-material/Layers";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";
import { useIntl } from "react-intl";
import { TileLayerType } from "../../../../../features/serviceSlices/MapLayer/Types";
import {
  deleteFilter,
  addFilter,
} from "../../../../../features/slices/App/appSlice";
import { useAppDispatch } from "../../../../../utils/reduxHooks";
import { TFilterDefaultValueJson } from "../../../../../features/serviceSlices/SharedTypes";
import { TVariableDefaultValueJson } from "../../../../../features/serviceSlices/Dashboards/Types";
import { parseJSON } from "../../../../../utils/helpers/functions";

const labelStyles = {
  display: "flex",
  alignItems: "flex-start",
  gap: ".25rem",
  marginLeft: 0,
  "& .MuiFormControlLabel-label": {
    fontSize: "14px",
  },
  "span:first-of-type": {
    alignSelf: "center",
  },
};

const CSSOptionsContainer = styled.div`
  height: fit-content;
`;

const CSSTitle = styled.p`
  font-size: 14px;
  font-weight: 600;
`;

const CSSLayersBlock = styled.div<LayersControlContainerType>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
  max-height: 200px;
  overflow-y: auto;
  p {
    position: sticky;
    top: 0;
    background-color: ${colors.light};
    z-index: 1600;
  }
  div {
    gap: 6px;
  }
  label:hover {
    background-color: #e7e9f4;
    border-radius: 4px;
  }
`;

type LayersControlContainerType = {
  open: boolean;
};

const CSSLayersControlContainer = styled.div<LayersControlContainerType>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${(props) => (props.open ? "flex-start" : "center")};
  color: ${colors.darkBlue};
  background: ${colors.light};
  min-height: ${(props) => (props.open ? "fit-content" : "30px")};
  max-height: ${(props) => (props.open ? "30rem" : "30px")};
  width: ${(props) => (props.open ? "13rem" : "30px")};
  border-radius: 2px;
  box-shadow: ${colors.bsMapIconLight};
  padding: ${(props) => (props.open ? ".5rem" : "")};
  cursor: ${(props) => (props.open ? "" : "pointer")};
  gap: 0.5rem;
  position: relative;
  &:hover {
    background: ${(props) => (props.open ? "" : "#f4f4f4")};
  }
`;

type Layer = {
  id: string;
  name: string;
};

export type BaseLayer = Layer & {
  variable?: TVariableDefaultValueJson["key"];
  defaultValue?: TFilterDefaultValueJson;
};

type LayersControlType = {
  baseLayers: BaseLayer[];
  overlayLayers: Layer[];
  baseLayer: string | null;
  overlay: string[];
  setBaseLayer: (arg: string) => void;
  setOverlay: (arg: string[]) => void;
  activeMapLayer: TileLayerType;
  mapLayers: TileLayerType[];
  setActiveMapLayer: (arg: TileLayerType) => void;
};

export const LayersControl = ({
  baseLayers,
  overlayLayers,
  baseLayer,
  setBaseLayer,
  overlay,
  setOverlay,
  activeMapLayer,
  mapLayers,
  setActiveMapLayer,
}: LayersControlType) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const intl = useIntl();
  const openLayersControlHandler = () => setOpen(!open);
  const handleChangeMapLayer = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newLayer = mapLayers.find(
      (mapLayer) => mapLayer.id === event.target.value
    );
    if (newLayer) {
      setActiveMapLayer(newLayer);
    }
  };
  const handleChangeBaseLayer = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const prevBaseLayer = baseLayers.find((layer) => layer.id === baseLayer);
    if (prevBaseLayer && prevBaseLayer.variable) {
      dispatch(deleteFilter(String(prevBaseLayer.variable)));
    }
    const newBaseLayer = baseLayers.find(
      (layer) => layer.id === event.target.value
    );
    const defaultNewBaseLayerFilterValue = parseJSON(
      newBaseLayer?.defaultValue
    )?.value;
    if (newBaseLayer && defaultNewBaseLayerFilterValue) {
      dispatch(
        addFilter({
          [String(newBaseLayer.variable)]: defaultNewBaseLayerFilterValue,
        })
      );
    }
    setBaseLayer(event.target.value);
  };

  const onOverlayChangeHandler = (event: React.BaseSyntheticEvent) => {
    const index = overlay.indexOf(event.target.name);
    if (index === -1 && !event.target.checked) {
      const newOverlay = [...overlay];
      newOverlay.push(event.target.name);
      setOverlay(newOverlay);
    } else if (index !== -1 && event.target.checked) {
      const newOverlay = [...overlay];
      newOverlay.splice(index, 1);
      setOverlay(newOverlay);
    }
  };

  return (
    <MapControl position="topRight">
      <CSSLayersControlContainer open={open}>
        {open ? (
          <>
            <CloseIcon
              onMouseUp={() => setOpen(false)}
              sx={{
                position: "absolute",
                right: 6,
                top: 5,
                cursor: "pointer",
                "&:hover": {
                  color: colors.purple500,
                },
                zIndex: 1601,
              }}
              fontSize="small"
            />
            <CSSLayersBlock open={open}>
              <CSSTitle>{intl.messages["mapLayers"]}</CSSTitle>
              <CSSOptionsContainer>
                <RadioGroup
                  name="radio-buttons-group"
                  value={activeMapLayer.id}
                  onChange={handleChangeMapLayer}
                >
                  {mapLayers.map((mapLayer) => (
                    <FormControlLabel
                      key={mapLayer.id}
                      sx={labelStyles}
                      value={mapLayer.id}
                      control={
                        <Radio
                          size="small"
                          disableRipple
                          sx={{
                            padding: 0,
                            backgroundColor: "transparent",
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                            },
                            span: { color: colors.darkBlue },
                          }}
                        />
                      }
                      label={mapLayer.id}
                    />
                  ))}
                </RadioGroup>
              </CSSOptionsContainer>
            </CSSLayersBlock>
            <CSSLayersBlock open={open}>
              <CSSTitle>{intl.messages["layers"]}</CSSTitle>
              <CSSOptionsContainer>
                <RadioGroup value={baseLayer} onChange={handleChangeBaseLayer}>
                  {baseLayers
                    .concat([{ id: "", name: `${intl.messages["no"]}` }])
                    .map((baseLayer) => (
                      <FormControlLabel
                        key={baseLayer.id}
                        sx={labelStyles}
                        value={baseLayer.id}
                        control={
                          <Radio
                            size="small"
                            disableRipple
                            sx={{
                              padding: 0,
                              backgroundColor: "transparent",
                              "& .MuiSvgIcon-root": {
                                fontSize: 18,
                              },
                              span: { color: colors.darkBlue },
                            }}
                          />
                        }
                        label={baseLayer.name}
                      />
                    ))}
                </RadioGroup>
              </CSSOptionsContainer>
            </CSSLayersBlock>
            <CSSLayersBlock open={open}>
              <CSSTitle>{intl.messages["mapObjects"]}</CSSTitle>
              <FormGroup>
                {overlayLayers.map((overlayLayer) => (
                  <FormControlLabel
                    key={overlayLayer.id}
                    sx={{
                      display: "flex",
                      gap: ".5rem",
                      marginLeft: 0,
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                      },
                    }}
                    control={
                      <Checkbox
                        checked={Boolean(
                          overlay.find((element) => element === overlayLayer.id)
                        )}
                        name={overlayLayer.id}
                        onMouseUp={onOverlayChangeHandler}
                        disableRipple
                        sx={{
                          color: colors.darkBlue,
                          padding: 0,
                          backgroundColor: "transparent",
                          "& .MuiSvgIcon-root": {
                            fontSize: 18,
                          },
                        }}
                      />
                    }
                    label={overlayLayer.name}
                  />
                ))}
              </FormGroup>
            </CSSLayersBlock>
          </>
        ) : (
          <LayersIcon onMouseUp={openLayersControlHandler} />
        )}
      </CSSLayersControlContainer>
    </MapControl>
  );
};
