import { rootApi } from "../serviceRoot";
import { TTimezoneGetAllResponse } from "./Types";

export const timezonesApiSlice = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllTimezones: builder.query<TTimezoneGetAllResponse, void>({
      query: () => `api/TimeZone/GetAvailable`,
    }),
  }),
});

export const { useLazyGetAllTimezonesQuery } = timezonesApiSlice;
