import { useGetDiagramWidgetDataQuery } from "../../../../features/serviceSlices/serviceHooks";
import { useAppSelector } from "../../../../utils/reduxHooks";
import { LoadingScreen, ErrorMessage } from "../../../common";
import {
  DiagramTypes,
  TDiagramWidgetData,
} from "../../../../features/serviceSlices/SpecificWidgets/DiagramWidget/Types";
import {
  LineChart,
  BarChart,
  PieChart,
  RadarChart,
  TimeRangeChart,
  RadialBarChart,
  CalendarChart,
  AreaBumpChart,
  HeatMapChart,
  FunnelChart,
} from "./DiagramTypes";
import { WidgetLayout } from "../../common";
import { useEffect } from "react";
import { WidgetProps } from "../Widgets.types";
import { skipToken } from "@reduxjs/toolkit/query";
import { CSSWrapper } from "../../common/WidgetLayout/WidgetLayout.styles";
import { AuthGuard } from "../../../Guards/AuthGuard/AuthGuard";
import { WidgetMenu } from "../../common/WidgetMenu";
import { FetchBaseQueryErrorType } from "../../../../features/serviceSlices/SharedTypes";

export const getDiagram = (type: DiagramTypes, data: TDiagramWidgetData) => {
  switch (type) {
    case "Line":
      return (
        <LineChart
          data={data?.data ?? []}
          settings={data.widgetSettings.settings.diagram}
          widgetSettings={data.widgetSettings.settings}
        />
      );
    case "Bar":
      return (
        <BarChart
          data={data?.data ?? []}
          settings={data.widgetSettings.settings.diagram}
          widgetSettings={data.widgetSettings.settings}
        />
      );
    case "Pie":
      return (
        <PieChart
          data={data?.data ?? []}
          widgetSettings={data.widgetSettings.settings}
        />
      );
    case "Radar":
      return (
        <RadarChart
          data={data?.data ?? []}
          settings={data.widgetSettings.settings.diagram}
        />
      );
    case "TimeRange":
      return (
        <TimeRangeChart
          data={data?.data ?? []}
          settings={data.widgetSettings.settings.diagram}
          id={data.id}
        />
      );
    case "RadialBar":
      return (
        <RadialBarChart
          data={data?.data ?? []}
          settings={data.widgetSettings.settings.diagram}
        />
      );
    case "Calendar":
      return (
        <CalendarChart
          data={data?.data ?? []}
          settings={data.widgetSettings.settings.diagram}
          id={data.id}
        />
      );
    case "AreaBump":
      return (
        <AreaBumpChart
          data={data?.data ?? []}
          settings={data.widgetSettings.settings.diagram}
        />
      );
    case "HeatMap":
      return (
        <HeatMapChart
          data={data?.data ?? []}
          settings={data.widgetSettings.settings.diagram}
        />
      );
    case "Funnel":
      return (
        <FunnelChart
          data={data?.data ?? []}
          settings={data.widgetSettings.settings.diagram}
        />
      );
    default:
      return <p></p>;
  }
};

export const Diagram = ({
  id,
  setBody,
  setResizeHandlerColor,
}: WidgetProps) => {
  const {
    type,
    startDate,
    endDate,
    role,
    filters,
    editMode,
    authorizationInfo,
  } = useAppSelector((state) => state.app);
  const DiagramWidgetData = useGetDiagramWidgetDataQuery(
    type !== "NotSet" && type
      ? {
          widgetId: id,
          dashboardId: role.id,
          startDate,
          endDate,
          periodType: type,
          filters: JSON.stringify(filters),
        }
      : skipToken
  );

  useEffect(() => {
    if (
      DiagramWidgetData.data &&
      DiagramWidgetData.data.widgetData.widgetSettings?.settings?.widget?.body
    ) {
      setBody(
        DiagramWidgetData.data.widgetData.widgetSettings.settings.widget.body
      );
    }
  }, [DiagramWidgetData.data, setBody]);

  useEffect(() => {
    if (
      DiagramWidgetData.data &&
      DiagramWidgetData.data?.widgetData.widgetSettings?.settings?.widget
        ?.fontColor
    ) {
      setResizeHandlerColor(
        DiagramWidgetData.data.widgetData.widgetSettings.settings.widget
          ?.fontColor
      );
    }
  }, [DiagramWidgetData.data, setResizeHandlerColor]);

  const getErrorMessage = () => {
    return (
      <CSSWrapper>
        <ErrorMessage
          error={DiagramWidgetData.error as FetchBaseQueryErrorType}
          isShowTooltip={!!authorizationInfo?.isAdmin}
        />
        <AuthGuard>
          <WidgetMenu
            dashboardId={role.id}
            widgetID={id}
            type={"Diagram"}
            editMode={editMode}
            isError={true}
          />
        </AuthGuard>
      </CSSWrapper>
    );
  };

  return DiagramWidgetData.isLoading || DiagramWidgetData.isFetching ? (
    <LoadingScreen />
  ) : DiagramWidgetData.isSuccess ? (
    <WidgetLayout
      measure={DiagramWidgetData.data.widgetData.measure}
      settings={
        DiagramWidgetData.data.widgetData.widgetSettings.settings.widget
      }
      label={DiagramWidgetData.data.widgetData.label}
      data={DiagramWidgetData.data.widgetData}
      type="Diagram"
      settingsId={DiagramWidgetData.data.widgetData.widgetSettings.id}
      dashboardId={DiagramWidgetData.data.widgetData.widgetSettings.dashboardID}
      widgetID={DiagramWidgetData.data.widgetData.widgetSettings.widgetID}
    >
      {DiagramWidgetData.data.widgetData.widgetSettings?.settings?.diagram
        ?.diagramType
        ? getDiagram(
            DiagramWidgetData.data.widgetData.widgetSettings.settings.diagram
              .diagramType,
            DiagramWidgetData.data.widgetData
          )
        : "Необходимо выбрать тип диаграммы"}
    </WidgetLayout>
  ) : (
    getErrorMessage()
  );
};
