import { MapContainer, TileLayer, MapConsumer } from "react-leaflet";
import styles from "./Map.module.scss";
import { MapLayer } from "./components/MapLayer";
import { LayersControl } from "./components/LayersControl";
import { useState, useEffect, forwardRef } from "react";
import { MapControl } from "../../../common";
import { useGetMapWidgetDataQuery } from "../../../../features/serviceSlices/serviceHooks";
import MarkerClusterGroup from "react-leaflet-markercluster";
import "react-leaflet-markercluster/dist/styles.min.css";
import { LoadingScreen, ErrorMessage } from "../../../common";
import { PrintControl } from "./components/PrintControl";
import { FilterClearControl } from "./components/FilterClearControl/FilterClearControl";
import { TileLayerType } from "../../../../features/serviceSlices/MapLayer/Types";
import { WidgetBodySettings } from "../../../../features/serviceSlices/WidgetSettings/Types";
import { WidgetLayout } from "../../common";
import { useAppSelector, useAppDispatch } from "../../../../utils/reduxHooks";
import { useBoundsFit } from "./BoundsFit/useBoundsFit";
import { FullscreenControl } from "react-leaflet-fullscreen";
import "react-leaflet-fullscreen/dist/styles.css";
import {
  addFilter,
  filterFilter,
} from "../../../../features/slices/App/appSlice";
import "./leaflet.css";
import { ReactComponent as Logo } from "../../../../assets/icons/yndex_logo_ru.svg";
import styled from "@emotion/styled";
import { CSSWrapper } from "../../common/WidgetLayout/WidgetLayout.styles";
import { AuthGuard } from "../../../Guards/AuthGuard/AuthGuard";
import { WidgetMenu } from "../../common/WidgetMenu";
import { FetchBaseQueryErrorType } from "../../../../features/serviceSlices/SharedTypes";

const WIDGET_TYPE = "Map";
const MAP_MAX_ZOOM = 18;
const MAP_ZOOM_SNAP = 0.1;
const MAP_FIT_BOUNDS_PADDING = 130;
const MAP_FIT_ANIMATION_DURATION = 1;

const CSSLogoContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const GeoMap = forwardRef(
  (
    {
      id,
      setBody,
      setResizeHandlerColor,
    }: {
      id: string;
      setBody?: (arg: WidgetBodySettings) => void;
      setResizeHandlerColor?: (arg: string) => void;
    },
    ref
  ) => {
    const dispatch = useAppDispatch();

    const { role, filters, editMode, authorizationInfo } = useAppSelector(
      (state) => state.app
    );
    const [baseLayer, setBaseLayer] = useState<string | null>(null);
    const [overlay, setOverlay] = useState<string[] | []>([]);
    const [filterValue, setFilterValue] = useState<string | null>(null);
    const [filterKey, setFilterKey] = useState<string | null>(null);

    const [activeMapLayer, setActiveMapLayer] = useState<TileLayerType | null>(
      null
    );

    const widgetData = useGetMapWidgetDataQuery({
      widgetId: id,
      dashboardId: role.id,
    });

    const isDynamicPositioning =
      !!widgetData.data?.widgetData.isPositioningEnable;

    const {
      boundBoxesByLayers,
      boundBoxesByLayersPrevRenderValue,
      updateBoundBoxesByLayerPrevRenderValue,
      onLayerLoad,
      resultBoundBox,
    } = useBoundsFit({ baseLayer, overlay, skip: !isDynamicPositioning });

    useEffect(() => {
      if (widgetData.data && baseLayer) {
        const filter = widgetData.data.widgetData.layers.find(
          (object) => object.id === baseLayer
        );
        if (filter?.actionType === "Filter") {
          setFilterKey(filter.variable);
        }
      }
    }, [baseLayer, widgetData.data]);

    useEffect(() => {
      if (widgetData.data) {
        const defaultLayer = widgetData.data.widgetData.layers.find(
          (layer) => layer.isDefault === true
        );
        const defaultOverlays = widgetData.data.widgetData.objects.filter(
          (object) => object.isDefault === true
        );
        if (defaultLayer) {
          setBaseLayer(defaultLayer.id);
        }
        if (defaultOverlays) {
          const idArray = defaultOverlays.map(
            (defaultOverlay) => defaultOverlay.id
          );
          setOverlay(idArray);
        }
      }
    }, [widgetData.data]);

    useEffect(() => {
      if (activeMapLayer) {
        return;
      }
      if (widgetData.data) {
        const defaultLayer = widgetData.data.widgetData.mapLayers.find(
          (mapLayer) => mapLayer.isMain === true
        );
        if (defaultLayer) {
          setActiveMapLayer(defaultLayer);
        }
      }
    }, [activeMapLayer, widgetData.data]);

    useEffect(() => {
      if (
        widgetData.data &&
        widgetData.data.widgetData.widgetSettings?.settings?.widget?.body &&
        setBody
      ) {
        setBody(widgetData.data.widgetData.widgetSettings.settings.widget.body);
      }
    }, [widgetData.data, setBody]);

    useEffect(() => {
      if (
        widgetData.data &&
        widgetData.data?.widgetData.widgetSettings?.settings?.widget
          ?.fontColor &&
        setResizeHandlerColor
      ) {
        setResizeHandlerColor(
          widgetData.data.widgetData.widgetSettings.settings.widget?.fontColor
        );
      }
    }, [widgetData.data, setResizeHandlerColor]);

    useEffect(() => {
      if (baseLayer !== null) {
        setFilterValue(null);
        dispatch(filterFilter("MapLayer"));
      }
    }, [baseLayer, dispatch]);

    useEffect(() => {
      if (filterKey && filterValue) {
        dispatch(addFilter({ [filterKey]: filterValue, type: "MapLayer" }));
      }
    }, [dispatch, filterKey, filterValue]);

    if (widgetData.isError) {
      return (
        <CSSWrapper>
          <ErrorMessage
            error={widgetData.error as FetchBaseQueryErrorType}
            isShowTooltip={!!authorizationInfo?.isAdmin}
          />
          <AuthGuard>
            <WidgetMenu
              dashboardId={role.id}
              widgetID={id}
              type={"Map"}
              editMode={editMode}
              isError={true}
            />
          </AuthGuard>
        </CSSWrapper>
      );
    }
    if (widgetData.isLoading) {
      return <LoadingScreen />;
    }

    return widgetData.isSuccess && activeMapLayer ? (
      <WidgetLayout
        settings={widgetData.data.widgetData.widgetSettings.settings.widget}
        data={widgetData.data.widgetData}
        label={widgetData.data.widgetData.label}
        type={WIDGET_TYPE}
        settingsId={widgetData.data.widgetData.widgetSettings.id}
        dashboardId={widgetData.data.widgetData.widgetSettings.dashboardID}
        widgetID={widgetData.data.widgetData.widgetSettings.widgetID}
      >
        <MapContainer
          center={
            !isDynamicPositioning
              ? [
                  widgetData.data.widgetData.latitude,
                  widgetData.data.widgetData.longitude,
                ]
              : [0, 0]
          }
          zoom={
            !isDynamicPositioning ? widgetData.data.widgetData.zoomLevel : 0
          }
          maxZoom={MAP_MAX_ZOOM}
          zoomSnap={MAP_ZOOM_SNAP}
          scrollWheelZoom
          className={styles.mapContainer}
        >
          <MarkerClusterGroup
            showCoverageOnHover={true}
            disableClusteringAtZoom={widgetData.data.widgetData.clustering}
            spiderfyOnMaxZoom={false}
            chunkedLoading={true}
          >
            {baseLayer && baseLayer !== "" && (
              <MapLayer
                filterKey={filterKey}
                setFilterValue={setFilterValue}
                id={baseLayer}
                key={baseLayer}
                onLayerLoad={isDynamicPositioning ? onLayerLoad : undefined}
                filters={filters}
                dashboardId={
                  widgetData?.data?.widgetData.widgetSettings.dashboardID
                }
                isShowTooltips={widgetData?.data?.widgetData.isShowTooltips}
              />
            )}
          </MarkerClusterGroup>
          {
            <MarkerClusterGroup
              showCoverageOnHover={false}
              disableClusteringAtZoom={widgetData.data.widgetData.clustering}
              spiderfyOnMaxZoom={false}
              chunkedLoading={true}
            >
              {overlay.map((objectsLayerId) => (
                <MapLayer
                  filterKey={filterKey}
                  setFilterValue={setFilterValue}
                  id={objectsLayerId}
                  key={objectsLayerId}
                  onLayerLoad={isDynamicPositioning ? onLayerLoad : undefined}
                  filters={filters}
                  dashboardId={
                    widgetData?.data?.widgetData.widgetSettings.dashboardID
                  }
                  isShowTooltips={widgetData?.data?.widgetData.isShowTooltips}
                />
              ))}
            </MarkerClusterGroup>
          }

          {activeMapLayer.id === "Yandex" ? (
            <>
              <TileLayer
                url={`${activeMapLayer.params.url}?apikey=${activeMapLayer.params.apikey}&lang=${activeMapLayer.params.lang}&l=${activeMapLayer.params.l}&scale=${activeMapLayer.params.scale}&projection=${activeMapLayer.params.projection}&x=0&y=0&z=${activeMapLayer.params.scale}`}
              />
              <CSSLogoContainer>
                <a href="https://yandex.ru/maps">
                  <Logo />
                </a>
              </CSSLogoContainer>
            </>
          ) : (
            <TileLayer
              attribution={activeMapLayer.params.attribution}
              url={activeMapLayer.params.url}
              subdomains={activeMapLayer.params.subdomains}
            />
          )}
          <LayersControl
            mapLayers={widgetData.data?.widgetData.mapLayers}
            activeMapLayer={activeMapLayer}
            setActiveMapLayer={setActiveMapLayer}
            overlay={overlay}
            setOverlay={setOverlay}
            baseLayer={baseLayer}
            setBaseLayer={setBaseLayer}
            baseLayers={widgetData.data.widgetData.layers}
            overlayLayers={widgetData.data.widgetData.objects}
          />
          <FullscreenControl position="topleft" />
          <MapControl position="bottomLeft">
            <FilterClearControl
              filterKey={filterKey}
              baseLayer={baseLayer}
              baseLayers={widgetData.data.widgetData.layers}
              setFilterValue={setFilterValue}
            />
            <PrintControl ref={ref} />
          </MapControl>
          <MapConsumer>
            {(map) => {
              map.invalidateSize();
              if (
                boundBoxesByLayers !== boundBoxesByLayersPrevRenderValue &&
                resultBoundBox &&
                isDynamicPositioning
              ) {
                map.flyToBounds(resultBoundBox, {
                  padding: [MAP_FIT_BOUNDS_PADDING, MAP_FIT_BOUNDS_PADDING],
                  duration: MAP_FIT_ANIMATION_DURATION,
                });
                updateBoundBoxesByLayerPrevRenderValue(boundBoxesByLayers);
              }
              return null;
            }}
          </MapConsumer>
        </MapContainer>
      </WidgetLayout>
    ) : null;
  }
);
