import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";

type LoadingScreenProps = {
  backdrop?: boolean;
};
export const LoadingScreen = ({ backdrop }: LoadingScreenProps) => {
  return backdrop ? (
    <Backdrop open={backdrop}>
      <CircularProgress sx={{ margin: "auto" }} />
    </Backdrop>
  ) : (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress sx={{ margin: "auto" }} />
    </Box>
  );
};
