import { optionType } from "../../DbaComponents/DbaSimpleSelect/DbaSimpleSelect.types";
import { intl } from "../../utils/locale";

const MapObjectActionTypes: optionType[] = [
  {
    value: "Object",
    label: intl.messages["object"] as string,
  },
  {
    value: "Filter",
    label: intl.messages["filter"] as string,
  },
  {
    value: "Information",
    label: intl.messages["info"] as string,
  },
];

export { MapObjectActionTypes };
