import { WidgetSettingContainer } from "../../common";
import Switch from "@mui/material/Switch";
import type { ChartSettingsProps } from "../Types";
import { DbaSlider } from "../../../../../../../DbaComponents";

export const FunnelChartAxesSettings = ({
  settings,
  setSettings,
}: ChartSettingsProps) => {
  return (
    <>
      <WidgetSettingContainer title="enableBeforeSeparators">
        <Switch
          checked={settings.diagram.enableBeforeSeparators ?? true}
          onChange={(e) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                enableBeforeSeparators: e.target.checked,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="beforeSeparatorLength">
        <DbaSlider
          sx={{ width: 150 }}
          value={settings.diagram.beforeSeparatorLength ?? 0}
          min={0}
          max={100}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                beforeSeparatorLength: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="beforeSeparatorOffset">
        <DbaSlider
          sx={{ width: 150 }}
          value={settings.diagram.beforeSeparatorOffset ?? 0}
          min={0}
          max={100}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                beforeSeparatorOffset: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="enableAfterSeparators">
        <Switch
          checked={settings.diagram.enableAfterSeparators ?? true}
          onChange={(e) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                enableAfterSeparators: e.target.checked,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="afterSeparatorLength">
        <DbaSlider
          sx={{ width: 150 }}
          value={settings.diagram.afterSeparatorLength ?? 16}
          min={0}
          max={100}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                afterSeparatorLength: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="afterSeparatorOffset" lastRow>
        <DbaSlider
          sx={{ width: 150 }}
          value={settings.diagram.afterSeparatorOffset ?? 16}
          min={0}
          max={100}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                afterSeparatorOffset: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>
    </>
  );
};
