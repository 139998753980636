import { WidgetSettingContainer } from "../../common";
import type { ChartSettingsProps } from "../Types";
import {
  DbaColorPaletteSelect,
  DbaSlider,
} from "../../../../../../../DbaComponents";
import { getColorSchemeOptions } from "../../common/ColorSettingsOptions";

export const HeatmapChartStyleSettings = ({
  settings,
  setSettings,
}: ChartSettingsProps) => {
  const colorSchemeOptions = getColorSchemeOptions(false, true, true);
  return (
    <>
      <WidgetSettingContainer title="colorScheme">
        <DbaColorPaletteSelect
          setSelectedValue={(value) => {
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                colors: {
                  ...prevState.diagram.colors,
                  type: "diverging",
                  scheme: value,
                },
              },
            }));
          }}
          selectedValue={
            settings.diagram?.colors?.scheme === undefined
              ? colorSchemeOptions.find((option) => option.value === "spectral")
                  ?.value
              : colorSchemeOptions.find(
                  (option) => option.value === settings.diagram?.colors?.scheme
                )?.value
          }
          options={colorSchemeOptions}
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="labelTextFontSize">
        <DbaSlider
          sx={{ width: 150 }}
          value={settings?.diagram?.labels?.text?.fontSize ?? 10}
          min={1}
          max={72}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                labels: {
                  ...prevState.diagram?.labels,
                  text: {
                    ...prevState.diagram?.labels?.text,
                    fontSize: value,
                  },
                },
              },
            }))
          }
        />
      </WidgetSettingContainer>
    </>
  );
};
