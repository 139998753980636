import styled from "@emotion/styled";
import Popper from "@mui/material/Popper";

const CSSDbaPopper = styled(Popper)(() => ({
  zIndex: 51,
}));

const CSSPaletteContainer = styled.div`
  label: PaletteContainer;
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 250px;
  height: 270px;
  .dbaColorInput {
    height: 20px;
    width: 20px;
    border-radius: 50%;
  }
  input[type="color"]::-webkit-color-swatch {
    border-radius: 50%;
  }
`;

const CSSColorSwatch = styled.div<{ color: string; isSelected: boolean }>`
  width: ${(props) => (props.isSelected ? "30px" : "20px")};
  height: ${(props) => (props.isSelected ? "30px" : "20px")};
  border: ${(props) => (props.isSelected ? "2px solid currentColor" : "none")};
  border-radius: 50%;
  background-color: ${(props) => props.color};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
`;

const CSSPaletteRow = styled.div`
  label: PaletteRow;
  display: flex;
  gap: 8px;
  align-items: center;
  height: 30px;
  p {
    width: 100px;
  }
`;

export { CSSDbaPopper, CSSPaletteContainer, CSSColorSwatch, CSSPaletteRow };
