import { useEffect, useState } from "react";
import { filterData } from "../helpers/helpers";

export function useSearchData<T>(searchText: string, data: T[]): T[] {
  const [searchResultData, setSearchResultData] = useState<T[]>(data);

  useEffect(() => {
    if (!searchText) {
      setSearchResultData(data);
    } else {
      setSearchResultData(filterData(searchText, data));
    }
  }, [data, searchText]);

  return searchResultData;
}
