import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import {
  DbaTransferListProps,
  OptionsType,
  OptionType,
} from "./DbaTransferList.types";
import { CSSListsContainer, CSSListContainer } from "./DbaTransfetList.styles";
import { useIntl } from "react-intl";

function not(a: string[], b: string[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: string[], b: string[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: string[], b: string[]) {
  return [...a, ...not(b, a)];
}

export const DbaTransferList = ({
  options,
  availableColumnTitle,
  chosenColumnTitle,
  selectedOptions,
  setSelectedOptions,
}: DbaTransferListProps) => {
  const intl = useIntl();

  const [checked, setChecked] = useState<string[]>([]);
  const [left, setLeft] = useState<string[]>([]);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, selectedOptions);

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: string[]) =>
    intersection(checked, items).length;

  const handleToggleAll = (items: string[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setSelectedOptions(selectedOptions.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setSelectedOptions(not(selectedOptions, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  useEffect(() => {
    setLeft(
      options
        .map((option: OptionType) => option.id)
        .filter((item) => selectedOptions.indexOf(item) === -1)
    );
  }, [options, selectedOptions]);

  const customList = (
    title: React.ReactNode,
    items: string[],
    data: OptionsType
  ) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": intl.messages["selectAllOptions"] as string,
            }}
          />
        }
        title={title}
        subheader={`${intl.messages["selected"]} ${numberOfChecked(items)}/${
          items.length
        }`}
      />
      <Divider />
      <List
        sx={{
          height: 230,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value, index) => {
          const getPrimaryText = () => {
            const primaryText = data.find((element) => element.id === value);
            if (primaryText) {
              return primaryText.name;
            }
            return value;
          };
          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": getPrimaryText(),
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={getPrimaryText()} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <CSSListsContainer>
      <CSSListContainer>
        {customList(intl.messages[availableColumnTitle], left, options)}
      </CSSListContainer>
      <div>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label={intl.messages["moveToTheSelected"] as string}
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label={intl.messages["moveToTheAvailable"] as string}
          >
            &lt;
          </Button>
        </Grid>
      </div>
      <CSSListContainer>
        {customList(intl.messages[chosenColumnTitle], selectedOptions, options)}
      </CSSListContainer>
    </CSSListsContainer>
  );
};
