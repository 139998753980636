import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  DbaRadioButtonProps,
  LabelType,
  DbaRadioButtonOption,
} from "./DbaRadioGroup.types";
import { OptionType } from "../DbaTransferList/DbaTransferList.types";
import { intl } from "../../utils/locale";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { Typography } from "@mui/material";
import { useContext } from "react";
import { ThemeContext } from "../../utils/ThemeContext";
import colors from "../../Variables.module.scss";

const isOptionType = (value: any): value is OptionType => {
  return value.name !== undefined;
};
const isLabelType = (value: any): value is LabelType => {
  return (
    value.label !== undefined &&
    value.icon !== undefined &&
    typeof value !== "string"
  );
};

const getLabel = (item: DbaRadioButtonOption, index: number) => {
  if (isOptionType(item)) {
    return (
      <FormControlLabel
        key={index}
        control={<Radio />}
        value={item.id}
        label={item.name}
      />
    );
  }
  if (isLabelType(item)) {
    return (
      <FormControlLabel
        key={index}
        control={<Radio />}
        value={item.label}
        label={item.icon}
      />
    );
  }
  return (
    <FormControlLabel
      key={index}
      control={<Radio />}
      value={item}
      label={(intl.messages[item] as string) || item}
    />
  );
};

export const DbaRadioGroup = ({
  labels,
  value,
  setValue,
  row,
  formLabel,
  error,
  errorText,
}: DbaRadioButtonProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const { darkMode } = useContext(ThemeContext);
  const formLabelColor = darkMode ? colors.white : colors.darkBlue;

  return (
    <FormControl>
      {formLabel && (
        <Typography color={error ? "error" : formLabelColor}>
          {intl.messages[formLabel]}
        </Typography>
      )}

      <RadioGroup
        row={row}
        aria-label="controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        {labels.map((item, index) => getLabel(item, index))}
      </RadioGroup>
      {error && (
        <FormHelperText error>
          {errorText
            ? intl.messages[errorText]
            : intl.messages["radioButtonGroupError"]}
        </FormHelperText>
      )}
    </FormControl>
  );
};
