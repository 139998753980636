import styled from "@emotion/styled";

const CSSWidgetRelatedReportsContainer = styled.div`
  label: CSSWidgetRelatedReportsContainer;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
`;
const CSSWidgetRelatedReportsChipContainer = styled.div`
  label: CSSWidgetRelatedReportsChipContainer;
  display: flex;
  gap: 16px;
`;

const CSSModalContent = styled.div`
  label: CSSModalContent;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export {
  CSSWidgetRelatedReportsContainer,
  CSSWidgetRelatedReportsChipContainer,
  CSSModalContent,
};
