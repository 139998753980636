import { BaseSettingsProps } from "./Types";
import { SettingsContainer } from "../../SettingsContainer";
import { WidgetSettingContainer } from "./common/WidgetSettingContainer";
import Switch from "@mui/material/Switch";
import { DbaSlider, DbaSimpleSelect } from "../../../../../DbaComponents";

const pointLabelOptions = [
  {
    value: "yFormatted",
    label: "В соответствии с осью Y",
  },
  {
    value: "y",
    label: "Y",
  },
  {
    value: "x",
    label: "X",
  },
  {
    value: "xFormatted",
    label: "В соответствии с осью Х",
  },
];

export const PointsSettings = ({
  settings,
  setSettings,
}: BaseSettingsProps) => {
  return (
    <SettingsContainer title="points">
      <WidgetSettingContainer title="enablePoints">
        <Switch
          checked={settings.diagram.enablePoints ?? true}
          onChange={(e) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                enablePoints: e.target.checked,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="pointSize">
        <DbaSlider
          fullWidth
          sx={{ width: 300 }}
          value={settings.diagram.pointSize ?? 6}
          min={2}
          max={20}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                pointSize: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="pointBorderWidth">
        <DbaSlider
          fullWidth
          sx={{ width: 300 }}
          value={settings.diagram.pointBorderWidth ?? 1}
          min={1}
          max={20}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                pointBorderWidth: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="enablePointLabel">
        <Switch
          checked={settings.diagram.enablePointLabel ?? false}
          onChange={(e) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                enablePointLabel: e.target.checked,
              },
            }))
          }
        />
        {settings.diagram.enablePointLabel && (
          <WidgetSettingContainer title="enablePointLabel">
            <DbaSimpleSelect
              setSelectedValue={(value) => {
                setSettings((prevState: any) => ({
                  ...prevState,
                  diagram: {
                    ...prevState.diagram,
                    pointLabel: value,
                  },
                }));
              }}
              selectedValue={
                settings.diagram.pointLabel === undefined
                  ? pointLabelOptions.find(
                      (option) => option.value === "yFormatted"
                    )
                  : pointLabelOptions.find(
                      (option) => option.value === settings.diagram.pointLabel
                    )
              }
              options={pointLabelOptions}
            />
          </WidgetSettingContainer>
        )}
      </WidgetSettingContainer>
    </SettingsContainer>
  );
};
