import { WidgetSettingContainer, labelOptions } from "../../common";
import type { ChartSettingsProps } from "../Types";
import Switch from "@mui/material/Switch";
import { DbaSlider, DbaSimpleSelect } from "../../../../../../../DbaComponents";

export const RadialChartLabelSettings = ({
  settings,
  setSettings,
}: ChartSettingsProps) => {
  return (
    <>
      <WidgetSettingContainer title="enableLabel">
        <Switch
          checked={settings.diagram.enableLabels ?? false}
          onChange={(e) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                enableLabels: e.target.checked,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="labelFormat">
        <DbaSimpleSelect
          selectedValue={
            settings.diagram.label === undefined
              ? labelOptions.find((option) => option.value === "formattedValue")
              : labelOptions.find(
                  (option) => option.value === settings.diagram.label
                )
          }
          setSelectedValue={(value) => {
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                label: value,
              },
            }));
          }}
          options={labelOptions}
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="labelsSkipAngle">
        <DbaSlider
          sx={{ width: 120 }}
          min={0}
          max={45}
          value={settings.diagram.labelsSkipAngle ?? 10}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                labelsSkipAngle: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="labelsRadiusOffset">
        <DbaSlider
          sx={{ width: 120 }}
          min={0}
          max={2}
          step={0.05}
          value={settings.diagram.labelsRadiusOffset ?? 0.5}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                labelsRadiusOffset: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>
    </>
  );
};
