import { rootApi } from "../serviceRoot";
import {
  AllMapObjectsResponse,
  GetMapObjectDataRequest,
  MapObjectCRUDRequest,
  setDefaultMapObjectRequest,
  GetMapObjectDataResponse,
  MapObject,
  MapObjectSaveRequest,
  MapObjectTypes,
  MapObjectUpdateRequest,
} from "./Types"

export const mapObjectsApiSlice = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getMapObjectData: builder.query<
      GetMapObjectDataResponse,
      GetMapObjectDataRequest
    >({
      query: (payload) => ({
        url: "/api/MapObject/GetData",
        method: "POST",
        body: payload,
      }),
    }),
    getAllMapObjects: builder.query<AllMapObjectsResponse, void>({
      query: () => "api/mapobject/getall",
      providesTags: ["MapObjects"],
    }),
    getMapObject: builder.query<MapObject, string>({
      query: (id) => `api/mapobject/get?ID=${id}`,
      providesTags: ["MapObject"],
    }),
    createMapObject: builder.mutation<any, MapObjectSaveRequest>({
      query: (CreateMapObjectRequest) => ({
        url: "api/mapobject/create",
        method: "POST",
        body: CreateMapObjectRequest,
      }),
      invalidatesTags: ["MapObjects"],
    }),
    updateMapObject: builder.mutation<any, MapObjectUpdateRequest>({
      query: (UpdateMapObjectRequest) => ({
        url: "api/mapobject/update",
        method: "POST",
        body: UpdateMapObjectRequest,
      }),
      invalidatesTags: ["MapObjects", "MapObject"],
    }),
    deleteMapObject: builder.mutation({
      query: (id) => ({
        url: "api/mapobject/delete",
        method: "POST",
        body: { id },
      }),
      invalidatesTags: ["MapObjects"],
    }),
    getMapObjectTypes: builder.query<MapObjectTypes, void>({
      query: () => "api/mapobject/gettypes",
    }),
    addMapObject: builder.mutation<{}, MapObjectCRUDRequest>({
      query: (payload) => ({
        url: "api/Widget/AddMapObject",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["mapWidget", "MapObjects"],
    }),
    setDefaultMapObject: builder.mutation<boolean, setDefaultMapObjectRequest>({
      query: (payload) => ({
        url: "api/MapObject/SetDefault",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["mapWidget", "MapObjects"],
    }),
    deleteMapObjectFromWidget: builder.mutation<boolean, MapObjectCRUDRequest>({
      query: (payload) => ({
        url: "api/Widget/RemoveMapObject",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["mapWidget"],
    }),
  }),
});

export const {
  useGetAllMapObjectsQuery,
  useCreateMapObjectMutation,
  useLazyGetMapObjectQuery,
  useUpdateMapObjectMutation,
  useDeleteMapObjectMutation,
  useGetMapObjectTypesQuery,
  useAddMapObjectMutation,
  useDeleteMapObjectFromWidgetMutation,
  useSetDefaultMapObjectMutation,
  useLazyGetMapObjectDataQuery,
} = mapObjectsApiSlice;
