import { useEffect, useCallback, useRef, useMemo } from "react";
import { TBoundBoxWidthId } from "../../../../../features/serviceSlices/MapObjects/Types";
import { getResultBoundFromBoundBoxesCollection } from "./helpers";
import { useForceUpdate } from "../../../../../utils/hooks/hooks";
import { LatLngBoundsExpression } from "leaflet";

type TUseBoundsFit = {
  baseLayer: string | null;
  overlay: string[];
  // В случае пропуска хука возвращаемые значения не должны использоваться
  skip?: boolean;
};

type TUseBoundsFitReturn = {
  boundBoxesByLayers: TBoundBoxWidthId[];
  // Значение boundBoxesByLayersPrevRenderValue использовать для того, чтобы понять когда данные действительно изменились и только тогда отрисовывать
  boundBoxesByLayersPrevRenderValue: TBoundBoxWidthId[];
  // updateBoundBoxesByLayerPrevRenderValue используется для обновления boundBoxesByLayersPrevRenderValue
  // новым значением, обновлять после отрисовки новыми данными
  updateBoundBoxesByLayerPrevRenderValue: (value: TBoundBoxWidthId[]) => void;
  onLayerLoad: (data: { boundBox: TBoundBoxWidthId }) => void;
  resultBoundBox: LatLngBoundsExpression | undefined;
};

export function useBoundsFit({
  baseLayer,
  overlay,
  skip,
}: TUseBoundsFit): TUseBoundsFitReturn {
  const forceUpdate = useForceUpdate();

  const boundBoxesByLayers = useRef<TBoundBoxWidthId[]>([]);
  const boundBoxesByLayersPrevRenderValue = useRef<TBoundBoxWidthId[]>([]);

  const onLayerLoad = useCallback(
    (data) => {
      if (!skip) {
        if (
          boundBoxesByLayers.current.find(
            (item) => item.layerID === data.boundBox.layerID
          )
        ) {
          boundBoxesByLayers.current = [
            ...boundBoxesByLayers.current.map((item) =>
              item.layerID === data.boundBox.layerID ? data.boundBox : item
            ),
          ];
        } else {
          boundBoxesByLayers.current = [
            ...boundBoxesByLayers.current,
            data.boundBox,
          ];
        }
        forceUpdate();
      }
    },
    [forceUpdate, skip]
  );

  const updateBoundBoxesByLayerPrevRenderValue = (
    value: TBoundBoxWidthId[]
  ) => {
    if (!skip) {
      boundBoxesByLayersPrevRenderValue.current = value;
    }
  };

  useEffect(() => {
    if (!skip) {
      boundBoxesByLayers.current = [
        ...boundBoxesByLayers.current.filter(
          (boundBox) =>
            overlay.indexOf(boundBox.layerID) > -1 ||
            boundBox.layerID === baseLayer
        ),
      ];
      forceUpdate();
    }
  }, [overlay, baseLayer, forceUpdate, skip]);

  const resultBoundBox = useMemo(() => {
    // Здесь отключаем линтер для одной строки потому что он хочет сделать массив зависимостей без учета ref,
    // а нам нужно чтобы useMemo зависел от boundBoxesByLayers.current
    return !skip
      ? getResultBoundFromBoundBoxesCollection(boundBoxesByLayers.current)
      : undefined;
    // eslint-disable-next-line
  }, [boundBoxesByLayers.current, skip]);

  return {
    boundBoxesByLayers: boundBoxesByLayers.current,
    boundBoxesByLayersPrevRenderValue:
      boundBoxesByLayersPrevRenderValue.current,
    updateBoundBoxesByLayerPrevRenderValue,
    onLayerLoad,
    resultBoundBox,
  };
}
