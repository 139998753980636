import { rootApi } from "../serviceRoot";
import { GetEnumValuesResponse, GetProvidersResponse, Provider } from "./Types";

export const providersApiSlice = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getProviders: builder.query<GetProvidersResponse, void>({
      query: () => ({
        url: "api/Provider/GetProviders",
      }),
      providesTags: ["ProviderInfo"],
    }),
    getProviderInfo: builder.mutation<Provider, { name: string }>({
      query: (payload) => ({
        url: "api/Provider/GetProviderInfo",
        method: "POST",
        body: payload,
      }),
    }),
    getProvidersDataMining: builder.query<GetProvidersResponse, void>({
      query: () => ({
        url: "Provider/GetProviders",
      }),
      providesTags: ["ProviderInfo"],
    }),

    getSelectOptions: builder.query<{ [key: string]: any }[], string>({
      query: (url) => ({
        url,
      }),
      providesTags: ["selectOptions"],
    }),
    getSelectOptionsByEnum: builder.query<GetEnumValuesResponse, string>({
      query: (enumName) => ({
        url: `/api/Types/Get?Name=${enumName}`,
      }),
      providesTags: ["selectOptions"],
    }),
    testConnection: builder.query<
      boolean,
      { name: string; sourceSettings: string }
    >({
      query: (payload) => ({
        url: "/api/provider/CheckConnection",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetProvidersQuery,
  useGetSelectOptionsQuery,
  useLazyGetSelectOptionsQuery,
  useLazyGetSelectOptionsByEnumQuery,
  useLazyTestConnectionQuery,
  useGetProviderInfoMutation,
} = providersApiSlice;
