import { rootApi } from "../serviceRoot";
import { AllMeasuresResponse, MeasureType } from "./Types";

export const measuresApiSlice = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllMeasures: builder.query<AllMeasuresResponse, void>({
      query: () => "api/measure/getall",
      providesTags: ["Measures"],
    }),
    getMeasure: builder.query<MeasureType, string>({
      query: (id) => `api/measure/get?ID=${id}`,
      providesTags: ["Measure"],
    }),
    createMeasure: builder.mutation({
      query: ({ payload }) => ({
        url: "api/measure/create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Measures"],
    }),
    updateMeasure: builder.mutation({
      query: ({ payload }) => ({
        url: "api/measure/update",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Measures", "Measure", "dataWidget", "diagramWidget"],
    }),
    deleteMeasure: builder.mutation({
      query: (id) => ({
        url: "api/measure/delete",
        method: "POST",
        body: { id },
      }),
      invalidatesTags: ["Measures"],
    }),
  }),
});

export const {
  useGetAllMeasuresQuery,
  useCreateMeasureMutation,
  useGetMeasureQuery,
  useLazyGetMeasureQuery,
  useUpdateMeasureMutation,
  useDeleteMeasureMutation,
} = measuresApiSlice;
