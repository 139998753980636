import styled from "@emotion/styled";
import ToggleButton from "@mui/material/ToggleButton";
import colors from "../../../../../Variables.module.scss";

export const CSSPeriodButton = styled(ToggleButton)((theme: any) => {
  return {
    color: theme.theme.palette.mode === "dark" ? "" : colors.darkBlue,
    border:
      theme.theme.palette.mode === "dark"
        ? `1px solid ${colors.purple500}`
        : `1px solid ${colors.darkBlue}`,
    "&.Mui-selected": {
      color: theme.theme.palette.mode === "dark" ? colors.light : "",
      background: theme.theme.palette.mode === "dark" ? colors.purple500 : "",
    },
  };
});

export const CSSDatePickerContainer = styled.div<{ mobileView: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.mobileView ? "column" : "row")};
  padding: ${(props) => (props.mobileView ? "8px 0 0 16px" : null)};
  gap: 1rem;
  width: 730px;
  height: 40px;
`;
