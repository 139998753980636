import { TSidebarItemMetadata } from "./PageTemplate.types";

const menuItems = getMenuItem();
export const getTitle = (link: string | undefined): string => {
  const menuItems = getMenuItem();
  let item = menuItems.find((x) => x.link === link);

  if (!item) {
    item = menuItems
      .filter((x) => link?.toLowerCase().includes(x.singular.toLowerCase()))
      .sort((a, b) => b.singular.length - a.singular.length)
      .at(0);
  }
  return item?.caption ?? "";
};

function getMenuItem(): TSidebarItemMetadata[] {
  return [
    {
      link: "/playlists",
      caption: "playlists",
      singular: "playlist",
      icon: "PlaylistPlay",
      allowRoles: ["User", "Admin"],
    },
    {
      link: "/users",
      caption: "users",
      singular: "user",
      icon: "SupervisedUserCircleSharp",
      allowRoles: ["Admin"],
    },
    {
      link: "/usergroups",
      caption: "userGroups",
      singular: "userGroup",
      icon: "AdminPanelSettings",
      allowRoles: ["Admin"],
    },
    {
      link: "/datasources",
      caption: "datasources",
      singular: "datasource",
      icon: "Storage",
      allowRoles: ["Admin"],
    },
    {
      link: "/datasets",
      caption: "datasets",
      singular: "dataset",
      icon: "LibraryBooks",
      allowRoles: ["Admin"],
    },
    {
      link: "/dashboards",
      caption: "dashboards",
      singular: "dashboard",
      icon: "DashboardCustomize",
      allowRoles: ["Admin"],
    },
    {
      link: "/widgets",
      caption: "widgets",
      singular: "widget",
      icon: "Widgets",
      allowRoles: ["Admin"],
    },
    {
      link: "/reports",
      caption: "reports",
      singular: "report",
      icon: "Summarize",
      allowRoles: ["Admin"],
    },
    {
      link: "/mapobjects",
      caption: "mapObjects",
      singular: "mapObject",
      icon: "MapRounded",
      allowRoles: ["Admin"],
    },
    {
      link: "/references",
      caption: "references",
      singular: "reference",
      icon: "SyncAlt",
      allowRoles: ["Admin"],
    },
    {
      link: "/widget-templates",
      caption: "templates",
      singular: "template",
      icon: "LibraryAdd",
      allowRoles: ["Admin"],
    },
    {
      link: "/measures",
      caption: "measures",
      singular: "measure",
      icon: "Speed",
      allowRoles: ["Admin"],
    },
    {
      link: "/files",
      caption: "files",
      singular: "file",
      icon: "CloudSync",
      allowRoles: ["Admin"],
    },
    {
      link: "/file-categories",
      caption: "fileCategories",
      singular: "file-category",
      icon: "SnippetFolder",
      allowRoles: ["Admin"],
    },
    {
      link: "/mapLayers",
      caption: "mapLayers",
      singular: "mapLayer",
      icon: "Layers",
      allowRoles: ["Admin"],
    },
  ];
}
export default menuItems;
