import { SettingsContainer } from "../../SettingsContainer";
import { CSSMarginSettingsContainer } from "../DiagramWidgetConstructor.styles";
import { DbaNumberInput } from "../../../../../DbaComponents";
import { WidgetSettingContainer } from "./common/WidgetSettingContainer";
import { BaseSettingsProps } from "./Types";
import { SettingsType } from "../../../../../features/serviceSlices/WidgetSettings/Types";
import { LineChartBaseSettings } from "./Charts/LineChartSettings/LineChartBaseSettings";
import {
  PieChartBaseSettings,
  RadarChartBaseSettings,
  FunnelChartBaseSettings,
  AreaBumpChartBaseSettings,
  CalendarChartBaseSettings,
  HeatmapChartBaseSettings,
  RadialChartBaseSettings,
  TimeRangeChartBaseSettings,
  BarChartBaseSettings,
} from "./Charts";
import { DiagramTypes } from "../../../../../features/serviceSlices/SpecificWidgets/DiagramWidget/Types";

const getSettings = (
  diagramType: DiagramTypes,
  settings: SettingsType,
  setSettings: (arg: any) => void,
  dataMappingOptions: any
) => {
  switch (diagramType) {
    case "Line":
      return (
        <LineChartBaseSettings
          settings={settings}
          setSettings={setSettings}
          dataMappingOptions={dataMappingOptions}
        />
      );
    case "Bar":
      return (
        <BarChartBaseSettings
          settings={settings}
          setSettings={setSettings}
          dataMappingOptions={dataMappingOptions}
        />
      );
    case "Pie":
      return (
        <PieChartBaseSettings
          settings={settings}
          setSettings={setSettings}
          dataMappingOptions={dataMappingOptions}
        />
      );
    case "Radar":
      return (
        <RadarChartBaseSettings
          settings={settings}
          setSettings={setSettings}
          dataMappingOptions={dataMappingOptions}
        />
      );
    case "Funnel":
      return (
        <FunnelChartBaseSettings
          settings={settings}
          setSettings={setSettings}
        />
      );
    case "AreaBump":
      return (
        <AreaBumpChartBaseSettings
          settings={settings}
          setSettings={setSettings}
        />
      );
    case "Calendar":
      return (
        <CalendarChartBaseSettings
          settings={settings}
          setSettings={setSettings}
        />
      );
    case "HeatMap":
      return (
        <HeatmapChartBaseSettings
          settings={settings}
          setSettings={setSettings}
        />
      );
    case "RadialBar":
      return (
        <RadialChartBaseSettings
          settings={settings}
          setSettings={setSettings}
          dataMappingOptions={dataMappingOptions}
        />
      );
    case "TimeRange":
      return (
        <TimeRangeChartBaseSettings
          settings={settings}
          setSettings={setSettings}
        />
      );
    default:
      return "";
  }
};

export const BaseSettings = ({
  settings,
  setSettings,
  dataMappingOptions,
  diagramType,
}: BaseSettingsProps) => {
  return (
    <SettingsContainer title="baseSettingsTitle">
      {getSettings(diagramType, settings, setSettings, dataMappingOptions)}
      <WidgetSettingContainer title="margin">
        <CSSMarginSettingsContainer>
          <DbaNumberInput
            label="Верх"
            value={settings.diagram?.margin?.top ?? 0}
            setValue={(value) =>
              setSettings((prevState: any) => ({
                ...prevState,
                diagram: {
                  ...prevState.diagram,
                  margin: { ...prevState.diagram.margin, top: value },
                },
              }))
            }
          />
          <DbaNumberInput
            label="Низ"
            value={settings.diagram?.margin?.bottom ?? 0}
            setValue={(value) =>
              setSettings((prevState: any) => ({
                ...prevState,
                diagram: {
                  ...prevState.diagram,
                  margin: { ...prevState.diagram.margin, bottom: value },
                },
              }))
            }
          />
          <DbaNumberInput
            label="Левая сторона"
            value={settings.diagram?.margin?.left ?? 0}
            setValue={(value) =>
              setSettings((prevState: any) => ({
                ...prevState,
                diagram: {
                  ...prevState.diagram,
                  margin: { ...prevState.diagram.margin, left: value },
                },
              }))
            }
          />
          <DbaNumberInput
            label="Правая сторона"
            value={settings.diagram?.margin?.right ?? 0}
            setValue={(value) =>
              setSettings((prevState: any) => ({
                ...prevState,
                diagram: {
                  ...prevState.diagram,
                  margin: { ...prevState.diagram.margin, right: value },
                },
              }))
            }
          />
        </CSSMarginSettingsContainer>
      </WidgetSettingContainer>
    </SettingsContainer>
  );
};
