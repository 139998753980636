import styled from "@emotion/styled";

export const CSSFileFieldWrapper = styled.div<{ isPointer: boolean }>`
  .MuiTextField-root {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    input {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      cursor: ${(props) => (props.isPointer ? "pointer" : "default")};
    }
  }
`;
