import { useContext } from "react";
import { useIntl } from "react-intl";
import styled from "@emotion/styled";
import { ThemeContext } from "../../utils/ThemeContext";
import colors from "../../Variables.module.scss";

type PageTitleProps = {
  darkMode: boolean;
};
const CSSPageTitle = styled.h2<PageTitleProps>`
  font-size: clamp(1.5rem, 3vw, 2rem);
  color: ${(props) => (props.darkMode ? colors.light : colors.darkBlue)};
  font-weight: 500;
`;

export const Title = ({ text }: { text: string }) => {
  const { darkMode } = useContext(ThemeContext);
  const intl = useIntl();
  return <CSSPageTitle darkMode={darkMode}>{intl.messages[text]}</CSSPageTitle>;
};
