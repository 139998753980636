import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  TPlaylist,
  TPlaylistDashboard,
} from "../../serviceSlices/Playlists/Types";

const DASHBOARD_START_INDEX = 0;

export type TInitialStatePlaylistSlice = {
  isPlaying: boolean;
  data: TPlaylist | null;
  currentPlayingDashboard: TPlaylistDashboard | null;
  currentPlayingDashboardIndex: number | null;
};

const initialState: TInitialStatePlaylistSlice = {
  isPlaying: false,
  data: null,
  currentPlayingDashboard: null,
  currentPlayingDashboardIndex: null,
};

export const playlistSlice = createSlice({
  name: "playlist",
  initialState,
  reducers: {
    start(state, action: PayloadAction<TPlaylist>) {
      state.isPlaying = true;
      state.data = action.payload;
      state.currentPlayingDashboard =
        state.data.dashboards[DASHBOARD_START_INDEX];
      state.currentPlayingDashboardIndex = DASHBOARD_START_INDEX;
    },
    stop: () => {
      return initialState;
    },
    goToNextDashboard(state) {
      const isLastDashboard =
        state.currentPlayingDashboardIndex ===
        state.data!.dashboards.length - 1;
      const nextDashboardIndex = isLastDashboard
        ? DASHBOARD_START_INDEX
        : (state.currentPlayingDashboardIndex as number) + 1;

      state.currentPlayingDashboard =
        state.data!.dashboards[nextDashboardIndex];
      state.currentPlayingDashboardIndex = nextDashboardIndex;
    },
  },
});

export const { start, stop, goToNextDashboard } = playlistSlice.actions;

export default playlistSlice.reducer;
