import { rootApi } from "../serviceRoot";
import {
  GroupDashboardsResponse,
  GroupUsersResponse,
  TGroupPlaylists,
  TPlaylistAddOrRemoveRequest,
  UserGroup,
  UserGroupResponse,
} from "./Types";

export const groupApiSlice = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserGroup: builder.query<UserGroup, string>({
      query: (id) => `api/group/get?id=${id}`,
      providesTags: ["UserGroups"],
    }),
    getUserGroups: builder.query<UserGroupResponse, void>({
      query: () => "api/group/getall",
      providesTags: ["UserGroups"],
    }),
    addUserGroup: builder.mutation({
      query: ({ payload }) => ({
        url: "api/group/create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["UserGroups"],
    }),
    updateUserGroup: builder.mutation<UserGroup, UserGroup>({
      query: (payload) => ({
        url: "api/group/update",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["UserGroups"],
    }),
    deleteUserGroup: builder.mutation({
      query: (id) => ({
        url: "api/group/delete",
        method: "POST",
        body: { id },
      }),
      invalidatesTags: ["UserGroups"],
    }),
    getUserGroupDashboards: builder.query<GroupDashboardsResponse, string>({
      query: (id) => `api/group/dashboards?groupID=${id}`,
      providesTags: ["groupDashboards"],
    }),
    addDashboardToGroup: builder.mutation({
      query: (payload) => ({
        url: "api/group/addDashboard",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["groupDashboards", "roles"],
    }),
    deleteDashboardFromGroup: builder.mutation({
      query: (payload) => ({
        url: "api/group/removeDashboard",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["groupDashboards", "roles"],
    }),
    getGroupUsers: builder.query<GroupUsersResponse, string>({
      query: (id) => ({
        url: `api/group/users?GroupID=${id}`,
      }),
      providesTags: ["GroupUsers"],
    }),
    addUserToGroup: builder.mutation({
      query: (payload) => ({
        url: "api/group/addUser",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["GroupUsers"],
    }),
    deleteUserFromGroup: builder.mutation({
      query: (payload) => ({
        url: "api/group/removeUser",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["GroupUsers"],
    }),
    addPlaylistToGroup: builder.mutation<void, TPlaylistAddOrRemoveRequest>({
      query: (payload) => ({
        url: "api/group/addPlaylist",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Playlists"],
    }),
    removePlaylistFromGroup: builder.mutation<void, TPlaylistAddOrRemoveRequest>(
      {
        query: (payload) => ({
          url: "api/group/removePlaylist",
          method: "POST",
          body: payload,
        }),
        invalidatesTags: ["Playlists"],
      }
    ),
    getGroupPlaylists: builder.query<TGroupPlaylists, string>({
      query: (id) => ({
        url: `api/group/playlists?GroupID=${id}`,
      }),
      providesTags: ["Playlists"],
    }),
  }),
});

export const {
  useGetUserGroupQuery,
  useGetUserGroupsQuery,
  useAddUserGroupMutation,
  useUpdateUserGroupMutation,
  useDeleteUserGroupMutation,
  useGetUserGroupDashboardsQuery,
  useAddDashboardToGroupMutation,
  useDeleteDashboardFromGroupMutation,
  useGetGroupUsersQuery,
  useAddUserToGroupMutation,
  useDeleteUserFromGroupMutation,
  useAddPlaylistToGroupMutation,
  useRemovePlaylistFromGroupMutation,
  useGetGroupPlaylistsQuery,
} = groupApiSlice;
