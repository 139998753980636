import { WidgetLink, WidgetLinks } from "../../../../features/serviceSlices/Widget/Types";

export type WidgetLinksActionTypes =
  | {
      type: "addWidgetLink";
      payload: WidgetLink;
    }
  | {
      type: "changeWidgetLink";
      payload: WidgetLink;
      initialObj: WidgetLink;
    }
  | {
      type: "deleteWidgetLink";
      payload: WidgetLink;
    }
  | {
      type: "loadWidgetLinks";
      payload: WidgetLinks;
    };

export const widgetLinksReducer = (
  state: WidgetLinks | null,
  action: WidgetLinksActionTypes
) => {
  switch (action.type) {
    case "addWidgetLink":
      if (state) {
        const newArr = [...state];
        newArr.push(action.payload);
        return newArr;
      }
      return [action.payload];
    case "changeWidgetLink":
      if (state) {
        const idx = state.findIndex((i) => i === action.initialObj);
        const newArr = [...state];
        newArr.splice(idx, 1, action.payload);
        return newArr;
      }
      return state;
    case "deleteWidgetLink":
      if (state) {
        return state.filter((i) => i !== action.payload);
      }
      return state;
    case "loadWidgetLinks":
      return action.payload;
    default:
      return state;
  }
};

export const initialWidgetLinksState = null;
