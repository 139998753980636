import { rootApi } from "../serviceRoot";
import {
  GetWidgetDashboardsResponse,
  GetAllWidgetsResponse,
  GetWidgetResponse
} from "./Types"

export const widgetApiSlice = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getWidget: builder.query<GetWidgetResponse, string>({
      query: (widgetID) => `api/widget/get?ID=${widgetID}`,
    }),
    getWidgets: builder.query<GetAllWidgetsResponse, void>({
      query: () => "api/widget/getall",
      providesTags: ["Widgets"],
    }),
    getWidgetDashboards: builder.query<GetWidgetDashboardsResponse, string>({
      query: (widgetID) => ({
        url: `api/widget/dashboards?WidgetID=${widgetID}`,
        method: "POST",
      }),
      providesTags: ["WidgetDashboards"],
    }),
    deleteWidget: builder.mutation<any, { id: string }>({
      query: (payload) => ({
        url: "api/widget/delete",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Widgets"],
    }),
  }),
});

export const {
  useGetWidgetsQuery,
  useDeleteWidgetMutation,
  useLazyGetWidgetDashboardsQuery,
  useGetWidgetQuery,
} = widgetApiSlice;
