import React, { useMemo, useState, useEffect, useCallback } from "react";
import { TMLResultTab } from "../../Types";
import { ColumnDef, PaginationState } from "@tanstack/react-table";
import {
  TMLDataSetWithDataQualityInspectedData,
  TMLDataSetWithDataQualityInspectedGetResponse,
} from "../../../../features/serviceSlices/MLDataSet/Types";
import { useLazyGetMLDataSetWithDataQualityInspectedQuery } from "../../../../features/serviceSlices/MLDataSet/mlDataSetService";
import { DbaTable } from "../../../../DbaComponents";
import { TableCell } from "./TableCell";
import { UNIQUE_SYSTEM_ROW_IDENTIFIER_FIELD_NAME } from "../../constants";
import { TSortOptions } from "../../../../DbaComponents/DbaTable/Types";
import { LoadingScreen } from "../../../../components/common";

const RESERVED_ROW_NUM_COLUMN_NAME = "RowNum";
const PAGE_SIZE = 10;

export const MLResultTab: React.FC<TMLResultTab> = ({ mlDataSetSummary }) => {
  const [getResultTabData, resultTabData] =
    useLazyGetMLDataSetWithDataQualityInspectedQuery();

  const [pageIndex, setPageIndex] = useState<PaginationState["pageIndex"]>(0);
  const [sortOptions, setSortOptions] = useState<TSortOptions>();
  const [tableData, setTableData] = useState<
    TMLDataSetWithDataQualityInspectedGetResponse | undefined
  >();

  const onServerPageChange = useCallback(
    (event: React.BaseSyntheticEvent, newPage: number) => {
      setPageIndex(newPage);
    },
    []
  );

  const onSortingChange = useCallback((options: TSortOptions) => {
    setSortOptions(options);
  }, []);

  const columns: ColumnDef<TMLDataSetWithDataQualityInspectedData>[] =
    useMemo(() => {
      if (!resultTabData.data?.data?.length) {
        return [];
      }
      const columnsKeys = Object.keys(resultTabData.data?.data[0]);
      return columnsKeys
        .filter((key) => key !== UNIQUE_SYSTEM_ROW_IDENTIFIER_FIELD_NAME)
        .map((key) => ({
          id: key,
          header: key.toUpperCase(),
          accessorFn: () => key,
          enableSorting: RESERVED_ROW_NUM_COLUMN_NAME !== key,
          cell: (props) => (
            <TableCell
              original={props.row.original}
              allMLFoundErrors={resultTabData.data?.mlData ?? []}
              columnKey={key}
            />
          ),
        }));
    }, [resultTabData.data?.data, resultTabData.data?.mlData]);

  useEffect(() => {
    getResultTabData({
      id: mlDataSetSummary.dataSetId,
      pageSize: PAGE_SIZE,
      pageIndex: pageIndex + 1,
      sortOptions,
    });
  }, [getResultTabData, mlDataSetSummary.dataSetId, pageIndex, sortOptions]);

  useEffect(() => {
    if (resultTabData.data) {
      setTableData(resultTabData.data);
    }
  }, [resultTabData.data]);

  let content = null;
  if (resultTabData.isLoading) {
    content = <LoadingScreen />;
  } else if (columns.length) {
    content = (
      <DbaTable
        columns={columns}
        data={tableData?.data ?? []}
        pagination={{
          enableServerPagination: true,
          serverRowsCount: tableData?.count ?? 0,
          showRowsPerPageSelector: false,
          serverRowsPerPage: PAGE_SIZE,
          onServerPageChange,
          autoResetPagination: false,
        }}
        isLoading={resultTabData.isFetching}
        headToolbar={{ showHeadToolbar: false }}
        sorting={{
          enableServerSorting: !!onSortingChange,
          onSortingChange: onSortingChange,
        }}
        stylesSettings={{
          cellStyles: {
            minWidth: "200px",
            padding: "10px",
            position: "relative",
            cursor: "default",
          },
        }}
        fixControls={{ enableFixControls: false }}
      />
    );
  }

  return content;
};
