import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { DbaPopover } from "../../DbaComponents/DbaPopover";

type TDeleteButton = {
  row: any;
  deleteDataHandler: (data: any) => void;
  disabled?: boolean;
};

export const DeleteButton: React.FC<TDeleteButton> = ({
  row,
  deleteDataHandler,
  disabled,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onDeleteDataClick = (event: React.BaseSyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const closeModal = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={onDeleteDataClick}
        color="error"
        size="small"
        disabled={disabled}
      >
        <DeleteIcon />
      </IconButton>
      <DbaPopover
        // confirmAgain={selectedRow?.original?.isDeleted}
        onCloseModal={closeModal}
        onDeleteItem={() => {
          deleteDataHandler(row.original);
          closeModal();
        }}
        anchorEl={anchorEl}
        setAnchorEl={() => setAnchorEl}
      />
    </>
  );
};
