import React, { memo, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { DbaSimpleSelect } from "../../../../../../../DbaComponents";
import styled from "@emotion/styled";
//@ts-ignores
import { formatSpecifier as parseFormat, FormatSpecifier } from "d3-format";
import {
  DbaTextField,
  DbaNumberInput,
} from "../../../../../../../DbaComponents";
import Switch from "@mui/material/Switch";

type ValueFormatControlProps = {
  value: string;
  setSettings: (arg: any) => void;
  formatLabel: "valueFormat" | "yFormat" | "xFormat";
};

const CSSValueFormatContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 80px auto 80px auto;
  gap: 9px;
  align-items: center;
`;

const typeOptions = [
  {
    value: undefined,
    label: "none",
    description: "Нет",
  },
  {
    value: "e",
    label: "e",
    description: "Экспоненциальная запись",
  },
  {
    value: "f",
    label: "f",
    description: "С фиксированной точкой",
  },
  {
    value: "g",
    label: "g",
    description:
      "Десятичная или экспоненциальная запись, округленная до значащих чисел",
  },
  {
    value: "r",
    label: "r",
    description: "Десятичная запись, округленная до значащих чисел",
  },
  {
    value: "s",
    label: "s",
    description:
      "Десятичная запись с SI префиксом, округленная до значащих чисел",
  },
  {
    value: "%",
    label: "%",
    description: "Десятичная запись, умноженная на 100 со знаком процента",
  },
  {
    value: "p",
    label: "p",
    description:
      "Десятичная запись, умноженная на 100 со знаком процента, округленная до значащих чисел",
  },
  {
    value: "b",
    label: "b",
    description: "Бинарная запись, округленная до целых чисел",
  },
  {
    value: "o",
    label: "8-bit",
    description: "Восьмеричная запись, округленная до целых чисел",
  },
  {
    value: "d",
    label: "10-bit",
    description: "Десятичная запись, округленная до целых чисел",
  },
  {
    value: "x",
    label: "x",
    description:
      "Шестнадцатеричная запись строчными буквами, округленная до целых чисел",
  },
  {
    value: "X",
    label: "X",
    description:
      "Шестнадцатеричная запись заглавными буквами, округленная до целых чисел",
  },
  {
    value: "c",
    label: "c",
    description: "Преобразовывать целые числа в соответсвующие символы юникода",
  },
];
const alignOptions = [
  {
    value: ">",
    label: ">",
    description: "Выравнивание по правой стороне",
  },
  {
    value: "<",
    label: "<",
    description: "Выравнивание по левой стороне",
  },
  {
    value: "^",
    label: "^",
    description: "Выравнивание по центру",
  },
  {
    value: "=",
    label: "=",
    description:
      "Выравнивание по правой стороне, с любыми знаками и символами по левой стороне",
  },
];

const signOptions = [
  {
    value: "-",
    label: "-",
    description: `Положительные числа и ноль без знака, минус для отрицательных чисел`,
  },
  {
    value: "+",
    label: "+",
    description: `Положительные числа и ноль со знаком плюс, минус для отрицательных чисел`,
  },
  {
    value: "(",
    label: "(",
    description:
      "Положительные числа и ноль - без знака, скобки для отрицательных чисел ",
  },
  {
    value: " ",
    label: "(space)",
    description:
      "Пробел для нуля и положительных чисел, минус для отрицательных чисел",
  },
];
const symbolOptions = [
  {
    value: undefined,
    label: "none",
  },
  {
    value: "#",
    label: "#",
  },
  {
    value: "$",
    label: "$",
  },
];

export const ValueFormatControl = memo(
  ({ value, setSettings, formatLabel }: ValueFormatControlProps) => {
    const intl = useIntl();
    const formatSpecifier = useMemo(
      () => (value === "" || !value ? "" : parseFormat(value)),
      [value]
    );

    const updateFormat = useCallback(
      (property, propertyValue) => {
        const updatedFormatSpecifier = new FormatSpecifier({
          ...formatSpecifier,
          [property]: propertyValue,
        });
        setSettings((prevState: any) => ({
          ...prevState,
          diagram: {
            ...prevState.diagram,
            [formatLabel]: updatedFormatSpecifier.toString(),
          },
        }));
      },
      [formatSpecifier, setSettings, formatLabel]
    );

    const handleTypeChange = (option: any) => updateFormat("type", option);
    const handleSignChange = (option: any) => updateFormat("sign", option);
    const handleSymbolChange = (option: any) => updateFormat("symbol", option);
    const handlePrecisionChange = (value: any) =>
      updateFormat("precision", value);
    const handleWidthChange = (value: any) => updateFormat("width", value);
    const handleFillChange = (value: string) => {
      updateFormat("fill", value.slice(1));
    };
    const handleAlignChange = (option: any) => updateFormat("align", option);
    const handleZeroChange = (event: React.ChangeEvent<HTMLInputElement>) =>
      updateFormat("zero", event.target.checked);
    const handleCommaChange = (event: React.ChangeEvent<HTMLInputElement>) =>
      updateFormat("comma", event.target.checked);
    const handleTrimChange = (event: React.ChangeEvent<HTMLInputElement>) =>
      updateFormat("trim", event.target.checked);

    return (
      <CSSValueFormatContainer>
        {intl.messages["type"]}
        <DbaSimpleSelect
          options={typeOptions}
          selectedValue={typeOptions.find(
            (option) => option.value === formatSpecifier.type
          )}
          setSelectedValue={handleTypeChange}
        />
        {intl.messages["sign"]}
        <DbaSimpleSelect
          options={signOptions}
          selectedValue={signOptions.find(
            (option) => option.value === formatSpecifier.sign
          )}
          setSelectedValue={handleSignChange}
        />
        {intl.messages["symbol"]}
        <DbaSimpleSelect
          options={symbolOptions}
          selectedValue={symbolOptions.find(
            (option) => option.value === formatSpecifier.symbol
          )}
          setSelectedValue={handleSymbolChange}
        />
        {intl.messages["accuracy"]}
        <DbaNumberInput
          size="small"
          value={formatSpecifier.precision}
          setValue={handlePrecisionChange}
        />
        {intl.messages["width"]}
        <DbaNumberInput
          size="small"
          value={formatSpecifier.width}
          setValue={handleWidthChange}
        />
        {intl.messages["fill"]}
        <DbaTextField
          label=""
          size="small"
          value={formatSpecifier.fill}
          setValue={handleFillChange}
        />
        {intl.messages["alignText"]}
        <DbaSimpleSelect
          options={alignOptions}
          selectedValue={alignOptions.find(
            (option) => option.value === formatSpecifier.align
          )}
          setSelectedValue={handleAlignChange}
        />
        <Switch checked={formatSpecifier.zero} onChange={handleZeroChange} />
        {intl.messages["withoutIndentation"]}
        <Switch checked={formatSpecifier.comma} onChange={handleCommaChange} />
        {intl.messages["delimiter"]}
        <Switch checked={formatSpecifier.trim} onChange={handleTrimChange} />
        {intl.messages["withoutZeros"]}
      </CSSValueFormatContainer>
    );
  }
);
