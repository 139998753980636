import { WidgetSettingContainer } from "../../common";
import type { ChartSettingsProps } from "../Types";
import {
  DbaColorInput,
  DbaColorPaletteSelect,
  DbaSlider,
} from "../../../../../../../DbaComponents";
import { getColorSchemeOptions } from "../../common/ColorSettingsOptions";

export const RadialChartStyleSettings = ({
  settings,
  setSettings,
}: ChartSettingsProps) => {
  const colorSchemeOptions = getColorSchemeOptions(true, true);
  return (
    <>
      <WidgetSettingContainer title="colorScheme">
        <DbaColorPaletteSelect
          setSelectedValue={(value) => {
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                colors: { scheme: value },
              },
            }));
          }}
          selectedValue={
            settings.diagram?.colors?.scheme === undefined
              ? colorSchemeOptions.find((option) => option.value === "nivo")
                  ?.value
              : colorSchemeOptions.find(
                  (option) => option.value === settings.diagram.colors.scheme
                )?.value
          }
          options={colorSchemeOptions}
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="borderWidth">
        <DbaSlider
          sx={{ width: 150 }}
          value={settings.diagram.borderWidth ?? 2}
          min={0}
          max={20}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                borderWidth: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="labelTextFontSize">
        <DbaSlider
          sx={{ width: 150 }}
          value={settings?.diagram?.labels?.text?.fontSize ?? 10}
          min={1}
          max={72}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                labels: {
                  ...prevState.diagram?.labels,
                  text: {
                    ...prevState.diagram?.labels?.text,
                    fontSize: value,
                  },
                },
              },
            }))
          }
        />
      </WidgetSettingContainer>
      <WidgetSettingContainer title="labelTextFill">
        <DbaColorInput
          value={settings?.diagram?.labels?.text?.fill ?? ""}
          onChange={(event) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                labels: {
                  ...prevState.diagram?.labels,
                  text: {
                    ...prevState.diagram?.labels?.text,
                    fill: event.target.value,
                  },
                },
              },
            }))
          }
        />
      </WidgetSettingContainer>
    </>
  );
};
