import TextField from "@mui/material/TextField";
import { useIntl } from "react-intl";

type DbaTextAreaType = {
  label: string;
  value: string;
  setValue: (value: string) => void;
  width?: number | string;
  size?: "small" | "medium";
  minRows?: number;
  maxRows?: number;
};

export const DbaTextArea = ({
  label,
  value,
  setValue,
  width,
  size,
  minRows,
  maxRows,
}: DbaTextAreaType) => {
  const intl = useIntl();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValue(event.target.value);

  return (
    <TextField
      InputLabelProps={{ sx: { zIndex: 0 } }}
      value={value}
      onChange={handleChange}
      label={intl.messages[label] || label}
      multiline
      size={size}
      sx={{ width }}
      minRows={minRows}
      maxRows={maxRows}
    />
  );
};
