import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router";
import { Box, Tabs, Tab, Stack, Snackbar } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import SaveIcon from "@mui/icons-material/Save";
import { DbaTabPanel, DbaTextField } from "../../DbaComponents";
import { AddButton, ReturnButton } from "../../components/common";
import {
  useGetUserGroupQuery,
  useUpdateUserGroupMutation,
} from "../../features/serviceSlices/serviceHooks";
import { UsersTab } from "./UsersTab";
import { DashboardsTab } from "./DashboardsTab";
import { SnackbarMessage } from "./Types";
import { PlaylistTab } from "./PlaylistTab";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} {...props} />;
});

export const EditUserGroup = () => {
  const [value, setValue] = useState(0);
  const { id } = useParams<Record<string, string | undefined>>();
  const intl = useIntl();
  const group = useGetUserGroupQuery(id!);
  const [updateUserGroup, updateUserGroupResponse] =
    useUpdateUserGroupMutation();
  const [name, setName] = useState<string>("");
  const [error, setError] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<SnackbarMessage>({
    open: false,
    message: "",
    severity: "info",
  });

  const onSubmitCommonDataHandler = () => {
    if (updateUserGroupResponse.status === "pending") {
      return;
    }
    if (!name.trim()) {
      setError(true);
    } else {
      if (id) {
        setError(false);
        updateUserGroup({ id, name: name.trim() });
      }
    }
  };
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) =>
    setValue(newValue);

  useEffect(() => {
    if (group.data?.name) {
      setName(group.data?.name);
    }
  }, [group]);

  const handleSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarMessage({ ...snackbarMessage, open: false });
  };
  const snackbar = (
    <Snackbar
      open={snackbarMessage.open}
      autoHideDuration={3000}
      onClose={handleSnackbarClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert
        elevation={6}
        severity={snackbarMessage.severity}
        sx={{ width: "100%", minWidth: "100%" }}
      >
        {intl.messages[snackbarMessage.message]}
      </Alert>
    </Snackbar>
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Stack direction="row" spacing={2} sx={{ mb: "1rem" }}>
          <ReturnButton color="error" />
          <AddButton
            icon={<SaveIcon />}
            text="updateGroupName"
            onClick={onSubmitCommonDataHandler}
            status={updateUserGroupResponse.status}
          />
        </Stack>
        <Stack direction="column" spacing={2}>
          <DbaTextField
            required
            error={error && !name.trim()}
            value={name}
            setValue={setName}
            size="medium"
            label="label"
            disabled={updateUserGroupResponse.status === "pending"}
            helperText="fieldIsEmptyError"
          />
        </Stack>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          variant="fullWidth"
          value={value}
          onChange={handleTabChange}
          aria-label="user-groups-tabs"
        >
          <Tab label={`${intl.messages["users"]}`} />
          <Tab label={`${intl.messages["dashboards"]}`} />
          <Tab label={`${intl.messages["playlists"]}`} />
        </Tabs>
      </Box>
      <DbaTabPanel value={value} index={0}>
        {
          <UsersTab
            groupId={id}
            setSnackbarMessage={setSnackbarMessage}
            snackbarMessage={snackbarMessage}
          />
        }
      </DbaTabPanel>
      <DbaTabPanel value={value} index={1}>
        {
          <DashboardsTab
            groupId={id}
            setSnackbarMessage={setSnackbarMessage}
            snackbarMessage={snackbarMessage}
          />
        }
      </DbaTabPanel>
      <DbaTabPanel value={value} index={2}>
        {
          <PlaylistTab
            groupId={id}
            setSnackbarMessage={setSnackbarMessage}
            snackbarMessage={snackbarMessage}
          />
        }
      </DbaTabPanel>
      {snackbar}
    </Box>
  );
};
