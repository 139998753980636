import { SettingsContainer } from "../../SettingsContainer";
import { BaseSettingsProps } from "./Types";
import Switch from "@mui/material/Switch";
import { SettingsToggle } from "./common/";
import { WidgetSettingContainer } from "./common/WidgetSettingContainer";
import { DbaSlider } from "../../../../../DbaComponents";

const arcLinkLabelsColorOptions = [
  {
    value: { from: "color", modifiers: [] },
    label: "inherit",
  },
  {
    value: { theme: "labels.text.fill" },
    label: "theme",
  },
];

export const ArcLinkLablelSettings = ({
  settings,
  setSettings,
}: BaseSettingsProps) => {
  return (
    <SettingsContainer title="arcLinkLabelsSettings">
      <WidgetSettingContainer title="enableArcLinkLabels">
        <Switch
          checked={settings.diagram.enableArcLinkLabels}
          onChange={(e) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                enableArcLinkLabels: e.target.checked,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer lastRow title="arcLinkLabelsSkipAngle">
        <DbaSlider
          sx={{ width: 120 }}
          value={settings.diagram.arcLinkLabelsSkipAngle}
          min={0}
          max={45}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                arcLinkLabelsSkipAngle: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="arcLinkLabelsColor">
        <SettingsToggle
          settings={settings}
          setSettings={setSettings}
          property="arcLinkLabelsColor"
          options={arcLinkLabelsColorOptions}
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="arcLinkLabelsTextColor">
        <SettingsToggle
          settings={settings}
          setSettings={setSettings}
          property="arcLinkLabelsTextColor"
          options={arcLinkLabelsColorOptions}
        />
      </WidgetSettingContainer>
    </SettingsContainer>
  );
};
