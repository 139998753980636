import { convertDateToISOUsingExternalTimeZone } from "../../../utils/helpers/dateTime";
import { store } from "../../../utils/store";

export function prepare({
  startDate,
  endDate,
}: {
  startDate?: Date | null;
  endDate?: Date | null;
}) {
  let parsedEndDate = null;
  let parsedStartDate = null;

  if (startDate) {
    parsedStartDate = convertDateToISOUsingExternalTimeZone(
      startDate,
      store.getState().app.timezone.id
    );
  }
  if (endDate) {
    parsedEndDate = convertDateToISOUsingExternalTimeZone(
      endDate,
      store.getState().app.timezone.id
    );
  }
  return {
    payload: {
      startDate: !startDate || !parsedStartDate ? null : parsedStartDate,
      endDate: !endDate || !parsedEndDate ? null : parsedEndDate,
    },
  };
}
