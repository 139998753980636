import { TTimezone } from "../../features/serviceSlices/Timezones/Types";
import { convertSecondsToHoursMinutes } from "../../utils/helpers/dateTime";

export const BROWSER_TIMEZONE_ID =
  Intl.DateTimeFormat().resolvedOptions().timeZone;

const generateBrowserTimezone = (): TTimezone => {
  const timezoneOffset = new Date().getTimezoneOffset();
  const timezoneOffsetAbs = Math.abs(timezoneOffset);
  const offsetSign = timezoneOffset >= 0 ? "-" : "+";

  const timezoneDisplayName = "Определено автоматически";

  return {
    id: BROWSER_TIMEZONE_ID,
    displayName: `(UTC${offsetSign}${convertSecondsToHoursMinutes(
      timezoneOffsetAbs * 60
    )}) ${timezoneDisplayName}`,
  };
};

export const BROWSER_TIMEZONE: TTimezone = generateBrowserTimezone();
