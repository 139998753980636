const directionOptions = [
  {
    value: "horizontal",
    label: "horizontal",
  },
  {
    value: "vertical",
    label: "vertical",
  },
];

const groupModeOptions = [
  {
    value: "stacked",
    label: "column",
  },
  {
    value: "grouped",
    label: "row",
  },
];

const interpolationOptions = [
  {
    value: "smooth",
    label: "smoothInterpolation",
  },
  {
    value: "linear",
    label: "linear",
  },
];
const alignOptions = [
  {
    value: "start",
    label: "Начало",
  },
  {
    value: "middle",
    label: "Середина",
  },
  {
    value: "end",
    label: "Конец",
  },
];
export {
  directionOptions,
  groupModeOptions,
  interpolationOptions,
  alignOptions,
};
