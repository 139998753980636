import React, { useEffect, useMemo, useCallback } from "react";
import { DbaSelect } from "../DbaSelect";
import { useLazyGetAllTimezonesQuery } from "../../features/serviceSlices/Timezones/timezonesService";
import { BROWSER_TIMEZONE } from "../DbaTimezonePicker/constants";
import { TDbaTimezoneSelector } from "./Types";
import { TTimezone } from "../../features/serviceSlices/Timezones/Types";
import { ENUM } from "../../features/serviceSlices/SharedTypes";
import { useIntl } from "react-intl";
import { CSSDbaSelect } from "./DbaTimezoneSelector.styles";

export const DbaTimezoneSelector: React.FC<TDbaTimezoneSelector> = ({
  value,
  onChange,
  required,
  error,
}) => {
  const intl = useIntl();

  const [getOptions, optionsResponse] = useLazyGetAllTimezonesQuery();

  const onChangeValue = (value: ENUM) => {
    onChange(value ? convertFromDbaSelector(value) : undefined);
  };

  const convertToDbaSelector = (option: TTimezone) => ({
    ...option,
    name: option.displayName,
  });

  const convertFromDbaSelector = (option: ENUM) => ({
    ...option,
    displayName: option.name,
  });

  const convertToDbaSelectorAll = useCallback(
    (options: TTimezone[]) => options.map(convertToDbaSelector),
    []
  );

  useEffect(() => {
    getOptions();
  }, [getOptions]);

  const systemDefaultTimezoneOption: TTimezone | undefined = useMemo(() => {
    let defaultOption = optionsResponse.data?.timeZones.find(
      (option) => option.id === optionsResponse.data?.default
    );
    if (defaultOption) {
      defaultOption = {
        ...defaultOption,
        displayName: `${defaultOption.displayName} (время системы по умолчанию)`,
      };
    }
    return defaultOption;
  }, [optionsResponse.data?.default, optionsResponse.data?.timeZones]);

  const options = useMemo(() => {
    let timezones = optionsResponse.data?.timeZones
      ? [...optionsResponse.data?.timeZones]
      : [];
    if (systemDefaultTimezoneOption) {
      timezones = [
        systemDefaultTimezoneOption,
        ...timezones.filter(
          (option) => option.id !== optionsResponse.data?.default
        ),
      ];
    }
    return convertToDbaSelectorAll([BROWSER_TIMEZONE, ...timezones]);
  }, [
    convertToDbaSelectorAll,
    optionsResponse.data?.default,
    optionsResponse.data?.timeZones,
    systemDefaultTimezoneOption,
  ]);

  const valueConverted = useMemo(() => {
    let resultValue = value;

    if (!resultValue) {
      return null;
    }

    if (typeof resultValue === "string") {
      resultValue = optionsResponse.data?.timeZones.find(
        (option) => option.id === resultValue
      ) ?? { id: resultValue, displayName: resultValue };
    }

    return value ? convertToDbaSelector(resultValue) : null;
  }, [optionsResponse.data?.timeZones, value]);

  return (
    <CSSDbaSelect>
      <DbaSelect
        required={required}
        error={error}
        setSelectedValue={onChangeValue}
        selectedValue={valueConverted}
        options={options}
        loadingData={optionsResponse.isLoading}
        isDynamicHeight={true}
        selectOnFocus={false}
        label={intl.messages["timeZone"] as string}
        width="100%"
      />
    </CSSDbaSelect>
  );
};
