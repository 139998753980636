import Slider from "@mui/material/Slider";
import styled from "@emotion/styled";
import { styled as muiStyled } from "@mui/material/styles";
import { AngleSettingsCircle } from "../../Pages/WidgetEditor/Constructors/DiagramWidgetConstructor/Settings/common/AngleSettingsCircle";
import MuiInput from "@mui/material/Input";
import React from "react";
type SliderContainerProps = {
  fullWidth?: boolean;
};

const CSSSmallInput = muiStyled(MuiInput)`
  width: 60px;
`;

const CSSSliderContainer = styled.div<SliderContainerProps>`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
`;
const CSSSliderBox = styled.div<SliderContainerProps>`
  width: calc(100% - 90px);
`;

type DbaSliderProps = {
  value: number | number[] | undefined;
  step?: number;
  min: number;
  max: number;
  onChange: (arg: number | number[]) => void;
  sx?: any;
  showCircle?: boolean;
  smallCircle?: boolean;
} & SliderContainerProps;

export const DbaSlider = ({
  value,
  min,
  max,
  step = 1,
  onChange,
  sx,
  fullWidth,
  showCircle,
  smallCircle = false,
}: DbaSliderProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value: number = Number(e.target.value);
    if (value > max) {
      value = max;
    }
    if (value < min) {
      value = min;
    }
    onChange(value);
  };

  return (
    <CSSSliderContainer fullWidth={fullWidth}>
      <CSSSliderBox>
        <Slider
          step={step}
          sx={sx}
          min={min}
          max={max}
          value={value}
          onChange={(_, value) => onChange(value)}
        />
      </CSSSliderBox>
      {showCircle && (
        <AngleSettingsCircle
          smallCircle={smallCircle}
          angle={value as number}
        />
      )}
      <CSSSmallInput
        value={value}
        size="small"
        onChange={handleChange}
        inputProps={{
          step,
          min,
          max,
          type: "number",
        }}
      />
    </CSSSliderContainer>
  );
};
