import {
  Box,
  Button,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ViewData } from "../../../Datasets/ViewData";
import { tabIndexes, TMLStatusTab, TMLStatusTabRows } from "../../Types";
import { useIntl } from "react-intl";
import {
  MLDataSetProcessingStatusLabels,
  MLDataTypesDetectionStatusLabels,
  MLTaskStatusLabels,
} from "./MLStatusLabels";
import { useRemoveMlTasksMutation } from "../../../../features/serviceSlices/serviceHooks";
import { isFetchBaseQueryErrorTypeGuard } from "../../../../utils/TypeGuards";
import { DbaSnackbar } from "../../../../DbaComponents";
import { InfoOutlined } from "@mui/icons-material";

export const MLStatusTab: React.FC<TMLStatusTab> = ({
  mlDataSetSummary,
  setTabIndex,
  isDataQualityTabDisabled,
  isDataTypesTabDisabled,
}) => {
  const intl = useIntl();
  const [selectedDatasetId, setSelectedDatasetId] = useState<string | null>(
    null
  );
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [removeMlTasks, removeMlTasksResponse] = useRemoveMlTasksMutation();
  const onShowDataSetDataHandler = () => {
    setSelectedDatasetId(mlDataSetSummary.dataSetId);
  };

  const onClickToFindDataQualityHandler = () => {
    if (!isDataQualityTabDisabled()) {
      setTabIndex(tabIndexes.DATA_QUALITY_TAB_INDEX);
    }
  };
  const onClickToDetectTypesHandler = () => {
    if (!isDataTypesTabDisabled()) {
      setTabIndex(tabIndexes.DATA_TYPES_TAB_INDEX);
    }
  };

  const onClickRemoveTasksHandler = () => {
    setSnackbarMessage("");
    removeMlTasks({ dataSetId: mlDataSetSummary.dataSetId });
  };

  useEffect(() => {
    if (removeMlTasksResponse.isError) {
      if (isFetchBaseQueryErrorTypeGuard(removeMlTasksResponse.error)) {
        setSnackbarMessage(removeMlTasksResponse.error.data.message);
      }
    } else if (removeMlTasksResponse.isSuccess) {
      setSnackbarMessage(
        removeMlTasksResponse.data.message ??
          (intl.messages["successMessage"] as string)
      );
    }
  }, [intl.messages, removeMlTasksResponse]);

  const getLabelForTypesDetectionButton = () => {
    if (mlDataSetSummary.typesDetected === "Undefined") {
      return intl.messages["define"] as string;
    }
    if (mlDataSetSummary.typesDetected === "AwaitingСonfirmation") {
      return intl.messages["confirm"] as string;
    }
    return undefined;
  };
  const getStatusRow = (
    hasDivider: boolean | undefined,
    rows: TMLStatusTabRows
  ) => {
    return (
      <>
        {hasDivider && <Divider sx={{}} />}
        <Stack spacing={2}>
          {rows.map(
            (row) =>
              row.rowFirstLabel !== undefined &&
              row.rowSecondLabel !== undefined &&
              row.rowSecondLabel !== null && (
                <Stack
                  direction={"row"}
                  spacing={3}
                  alignItems={"center"}
                  key={`${row.rowFirstLabel}${row.rowSecondLabel}`}
                >
                  <Box sx={{ minWidth: 200 }}>
                    <Stack spacing={1} direction={"row"} alignItems={"center"}>
                      <Typography
                        variant="subtitle1"
                        component="div"
                        fontSize={16}
                      >
                        {`${row.rowFirstLabel}`}
                      </Typography>
                      {!row.showTooltipOnFirstRow && (
                        <Tooltip
                          arrow={true}
                          placement="right-end"
                          title={row.rowFirstTooltip}
                        >
                          <InfoOutlined
                            sx={{ width: 15, height: 15, color: "#888888" }}
                          />
                        </Tooltip>
                      )}
                    </Stack>
                  </Box>
                  {row.showTooltipOnFirstRow ? (
                    <Tooltip
                      arrow={true}
                      placement="right-end"
                      title={row.rowFirstTooltip}
                    >
                      <Typography
                        sx={{ minWidth: 200 }}
                        variant="subtitle2"
                        fontSize={16}
                        component="div"
                      >
                        {row.rowSecondLabel}
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography
                      sx={{ minWidth: 200 }}
                      variant="subtitle2"
                      fontSize={16}
                      component="div"
                      color={row.color ?? ""}
                    >
                      {row.rowSecondLabel}
                    </Typography>
                  )}

                  {row.buttonName && row.buttonHandler && (
                    <Button
                      sx={{ minWidth: 200, width: 250 }}
                      size="small"
                      variant="outlined"
                      onClick={row.buttonHandler}
                      disabled={row.isButtonDisabled}
                    >
                      {row.buttonName}
                    </Button>
                  )}
                </Stack>
              )
          )}
        </Stack>
      </>
    );
  };

  return (
    <Stack spacing={2}>
      {getStatusRow(false, [
        {
          rowFirstLabel: intl.messages["preProcessingStage"] as string,
          rowFirstTooltip: intl.messages["preProcessingStageTooltip"] as string,
          rowSecondLabel: MLDataSetProcessingStatusLabels[
            mlDataSetSummary.stage as keyof typeof MLDataSetProcessingStatusLabels
          ] as string,
          color: mlDataSetSummary.stage === "Error" ? "red" : undefined,
        },
        {
          rowFirstLabel: intl.messages["messageWithError"] as string,
          rowFirstTooltip: mlDataSetSummary.errorDescription
            ?.description as string,
          rowSecondLabel:
            mlDataSetSummary.errorDescription !== null
              ? mlDataSetSummary.errorDescription.shortDescription?.length !== 0
                ? mlDataSetSummary.errorDescription.shortDescription
                : null
              : null,
          showTooltipOnFirstRow: true,
        },
        {
          rowFirstLabel: intl.messages["taskStatus"] as string,
          rowFirstTooltip: intl.messages["taskStatusTooltip"] as string,
          rowSecondLabel:
            mlDataSetSummary.status !== null
              ? (MLTaskStatusLabels[
                  mlDataSetSummary.status as keyof typeof MLTaskStatusLabels
                ] as string)
              : (intl.messages["notDefined"] as string),
          buttonName: intl.messages["removeMlTasks"] as string,
          buttonHandler: onClickRemoveTasksHandler,
          isButtonDisabled:
            removeMlTasksResponse.isLoading || mlDataSetSummary.status === null,
        },
      ])}
      {getStatusRow(true, [
        {
          rowFirstLabel: intl.messages["countOfRows"] as string,
          rowFirstTooltip: intl.messages["countOfRowsTooltip"] as string,
          rowSecondLabel:
            mlDataSetSummary.rowCount !== null
              ? mlDataSetSummary.rowCount
              : (intl.messages["notDefined"] as string),
          buttonName: intl.messages["showDataSetData"] as string,
          buttonHandler: onShowDataSetDataHandler,
        },
        {
          rowFirstLabel: intl.messages["dataTypes"] as string,
          rowFirstTooltip: intl.messages["dataTypesStatusTooltip"] as string,
          rowSecondLabel: MLDataTypesDetectionStatusLabels[
            mlDataSetSummary.typesDetected as keyof typeof MLDataTypesDetectionStatusLabels
          ] as string,
          buttonName: getLabelForTypesDetectionButton(),
          buttonHandler: onClickToDetectTypesHandler,
        },
      ])}
      {getStatusRow(true, [
        {
          rowFirstLabel: intl.messages["duplicates"] as string,
          rowFirstTooltip: intl.messages["duplicatesStatusTooltip"] as string,
          rowSecondLabel:
            mlDataSetSummary.duplicates !== null
              ? mlDataSetSummary.duplicates
              : (intl.messages["notDefined"] as string),
          buttonName:
            mlDataSetSummary.duplicates !== null
              ? undefined
              : (intl.messages["find"] as string),
          buttonHandler: onClickToFindDataQualityHandler,
          isButtonDisabled: isDataQualityTabDisabled(),
        },
        {
          rowFirstLabel: intl.messages["passes"] as string,
          rowFirstTooltip: intl.messages["passesStatusTooltip"] as string,
          rowSecondLabel:
            mlDataSetSummary.passes !== null
              ? mlDataSetSummary.passes
              : (intl.messages["notDefined"] as string),
          buttonName:
            mlDataSetSummary.passes !== null
              ? undefined
              : (intl.messages["find"] as string),
          buttonHandler: onClickToFindDataQualityHandler,
          isButtonDisabled: isDataQualityTabDisabled(),
        },
        {
          rowFirstLabel: intl.messages["typesMismatch"] as string,
          rowFirstTooltip: intl.messages[
            "typesMismatchStatusTooltip"
          ] as string,
          rowSecondLabel:
            mlDataSetSummary.typesMismatch !== null
              ? mlDataSetSummary.typesMismatch
              : (intl.messages["notDefined"] as string),
          buttonName:
            mlDataSetSummary.typesMismatch !== null
              ? undefined
              : (intl.messages["find"] as string),
          buttonHandler: onClickToFindDataQualityHandler,
          isButtonDisabled: isDataQualityTabDisabled(),
        },
        {
          rowFirstLabel: intl.messages["outliers"] as string,
          rowFirstTooltip: intl.messages["outliersStatusTooltip"] as string,
          rowSecondLabel:
            mlDataSetSummary.outliers !== null
              ? mlDataSetSummary.outliers
              : (intl.messages["notDefined"] as string),
          buttonName:
            mlDataSetSummary.outliers !== null
              ? undefined
              : (intl.messages["find"] as string),
          buttonHandler: onClickToFindDataQualityHandler,
          isButtonDisabled: isDataQualityTabDisabled(),
        },
      ])}
      <DbaSnackbar
        error={removeMlTasksResponse.isError}
        success={removeMlTasksResponse.isSuccess}
        successMessage={snackbarMessage as string}
        errorMessage={snackbarMessage as string}
      />
      {/* <Tooltip
          placement="right"
          title={
            "Создание, определение типов данных, проверка качества данных, исправление данных, завершено, ошибка"
          }
        >
          <Button
            size="small"
            variant="outlined"
            startIcon={<QuestionMarkIcon />}
          >
            Возможные этапы предобработки
          </Button>
        </Tooltip> */}
      <ViewData id={selectedDatasetId} setSelectedId={setSelectedDatasetId} />
    </Stack>
  );
};
