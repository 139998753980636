import { WidgetSettingContainer, curveOptions } from "../../common";
import type { ChartSettingsProps } from "../Types";
import {
  DbaColorPaletteSelect,
  DbaSimpleSelect,
  DbaSlider,
} from "../../../../../../../DbaComponents";
import Switch from "@mui/material/Switch";
import { getColorSchemeOptions } from "../../common/ColorSettingsOptions";

export const LineChartStyleSettings = ({
  settings,
  setSettings,
}: ChartSettingsProps) => {
  const colorSchemeOptions = getColorSchemeOptions(true, true);
  return (
    <>
      <WidgetSettingContainer title="colorScheme">
        <DbaColorPaletteSelect
          setSelectedValue={(value) => {
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                colors: { scheme: value },
              },
            }));
          }}
          selectedValue={
            settings.diagram?.colors?.scheme === undefined
              ? colorSchemeOptions.find((option) => option.value === "nivo")
                  ?.value
              : colorSchemeOptions.find(
                  (option) => option.value === settings.diagram.colors.scheme
                )?.value
          }
          options={colorSchemeOptions}
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="curve">
        <DbaSimpleSelect
          selectedValue={
            settings.diagram.curve === undefined
              ? curveOptions.find((option) => option.value === "linear")
              : curveOptions.find(
                  (option) => option.value === settings.diagram.curve
                )
          }
          setSelectedValue={(value) => {
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                curve: value,
              },
            }));
          }}
          options={curveOptions}
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="lineWidth">
        <DbaSlider
          sx={{ width: 150 }}
          value={
            settings.diagram.lineWidth === undefined
              ? 2
              : settings.diagram.lineWidth
          }
          min={0}
          max={20}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                lineWidth: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>
      <WidgetSettingContainer title="enableArea">
        {settings.diagram.enableArea}
        <Switch
          checked={settings.diagram.enableArea ?? false}
          onChange={(e) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                enableArea: e.target.checked,
              },
            }))
          }
        />
        {settings.diagram.enableArea && (
          <>
            <WidgetSettingContainer title="areaBaselineValue">
              <DbaSlider
                sx={{ width: 150 }}
                value={settings.diagram.areaBaselineValue ?? 0}
                min={0}
                max={200}
                onChange={(value) =>
                  setSettings((prevState: any) => ({
                    ...prevState,
                    diagram: {
                      ...prevState.diagram,
                      areaBaselineValue: value,
                    },
                  }))
                }
              />
            </WidgetSettingContainer>

            <WidgetSettingContainer title="opacity">
              <DbaSlider
                sx={{ width: 150 }}
                value={settings.diagram.areaOpacity ?? 0.2}
                min={0}
                max={1}
                step={0.05}
                onChange={(value) =>
                  setSettings((prevState: any) => ({
                    ...prevState,
                    diagram: {
                      ...prevState.diagram,
                      areaOpacity: value,
                    },
                  }))
                }
              />
            </WidgetSettingContainer>
          </>
        )}
      </WidgetSettingContainer>
    </>
  );
};
