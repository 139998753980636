import { WidgetSettingContainer } from "../../common";
import type { ChartSettingsProps } from "../Types";
import { DbaSlider } from "../../../../../../../DbaComponents";
import Switch from "@mui/material/Switch";
import { MotionConfigSettings } from "../../common";

export const PieChartInteractivitySettings = ({
  settings,
  setSettings,
}: ChartSettingsProps) => {
  return (
    <>
      <WidgetSettingContainer title="animate">
        <Switch
          checked={
            settings.diagram.animate === undefined
              ? true
              : settings.diagram.animate
          }
          onChange={(e) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                animate: e.target.checked,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="isInteractive">
        <Switch
          checked={
            settings.diagram.isInteractive === undefined
              ? true
              : settings.diagram.isInteractive
          }
          onChange={(e) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                isInteractive: e.target.checked,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="motionConfig">
        <MotionConfigSettings
          settings={settings}
          setSettings={setSettings}
          defaultValue="default"
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="activeInnerRadiusOffset">
        <DbaSlider
          sx={{ width: 350 }}
          fullWidth
          value={settings.diagram.activeInnerRadiusOffset ?? 0}
          min={0}
          max={50}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                activeInnerRadiusOffset: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer lastRow title="activeOuterRadiusOffset">
        <DbaSlider
          sx={{ width: 350 }}
          fullWidth
          value={settings.diagram.activeOuterRadiusOffset ?? 0}
          min={0}
          max={50}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                activeOuterRadiusOffset: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>
    </>
  );
};
