import React, { useEffect, useState } from "react";
import { TStringFilter } from "../../../../../Types";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { notSelectedConditionType, stringFilterOptions } from "./constants";
import { useIntl } from "react-intl";
import { DbaTextField } from "../../../../../../DbaTextField";
import { DbaButton } from "../../../../../../DbaButton";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";

export const StringFilter: React.FC<TStringFilter> = ({ filter, onChange }) => {
  const intl = useIntl();

  const [filterData, setFilterData] = useState<TStringFilter["filter"]>();

  const onFilterConditionChange = (event: SelectChangeEvent<any>) => {
    setFilterData({
      ...filterData!,
      type:
        event.target.value !== notSelectedConditionType
          ? event.target.value
          : undefined,
      value: undefined,
    });
  };

  const onFilterValueChange = (value: string) => {
    setFilterData({
      ...filterData!,
      value: value,
    });
  };

  const onApplyClick = () => {
    onChange(filterData!);
  };

  const onResetClick = (event: React.BaseSyntheticEvent) => {
    event.preventDefault();
    setFilterData({
      ...filterData!,
      type: undefined,
      value: undefined,
    });
  };

  useEffect(() => {
    setFilterData(filter);
  }, [filter]);

  const isApplyBtnDisabled = !!(filterData?.type && !filterData?.value);

  return (
    <Stack spacing={2}>
      <FormControl>
        <InputLabel>{intl.messages["filterByCondition"]}</InputLabel>
        <Select
          value={filterData?.type ?? notSelectedConditionType}
          label={intl.messages["filterByCondition"]}
          onChange={onFilterConditionChange}
        >
          {stringFilterOptions.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {intl.messages[item.label]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <DbaTextField
          value={filterData?.value ?? ""}
          setValue={onFilterValueChange}
          label="value"
          disabled={!filterData?.type}
          autoComplete="off"
        />
      </FormControl>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Link
          underline="hover"
          component={RouterLink}
          to={{
            pathname: `/`,
          }}
          sx={{ color: "inherit" }}
          onClick={onResetClick}
        >
          {intl.messages["resetInfinitive"]}
        </Link>
        <DbaButton
          color="success"
          text="apply"
          onClick={onApplyClick}
          disabled={isApplyBtnDisabled}
        />
      </Stack>
    </Stack>
  );
};
