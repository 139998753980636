import React, { useMemo, useState, useEffect, useContext } from "react";
import { TMLDataTypesTab } from "../../Types";
import { DbaSelect, DbaTable, DbaButton } from "../../../../DbaComponents";
import { Box, Switch, Tooltip, Snackbar, Alert, Divider } from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";
import { useIntl } from "react-intl";
import { TMLDataSetColumns } from "../../../../features/serviceSlices/MLDataSet/Types";
import { useGetTypesQuery } from "../../../../features/serviceSlices/TypesFeature/typesFeatureService";
import { InfoOutlined } from "@mui/icons-material";
import { Type } from "../../../../features/serviceSlices/TypesFeature/Types";
import {
  useLazyGetMLDataSetSummaryQuery,
  useCreateTaskToDetectDataTypesMutation,
  useConfirmDataTypesMutation,
} from "../../../../features/serviceSlices/serviceHooks";
import { tabIndexes } from "../../Types";
import { DbaPopover } from "../../../../DbaComponents/DbaPopover";
import useMediaQuery from "@mui/material/useMediaQuery";
import { isFetchBaseQueryErrorTypeGuard } from "../../../../utils/TypeGuards";
import colors from "../../../../Variables.module.scss";
import { ThemeContext } from "../../../../utils/ThemeContext";
import { useSnackbar } from "../../../../utils/hooks/useSnackbar";

export const MLDataTypesTab: React.FC<TMLDataTypesTab> = ({
  mlDataSetSummary,
  setTabIndex,
}) => {
  const { darkMode } = useContext(ThemeContext);
  const intl = useIntl();

  const isCreation =
    mlDataSetSummary.stage === "Creation" && !mlDataSetSummary.status;

  const isTaskHandlingByBackend = ["InQueue", "InWork"].includes(
    mlDataSetSummary.status as string
  );

  const isAwaitingDataTypesConfirmation =
    mlDataSetSummary.typesDetected === "AwaitingConfirmation" &&
    !mlDataSetSummary.status;

  const isConfirmed =
    mlDataSetSummary.stage === "DataTypesDetection" &&
    !mlDataSetSummary.status &&
    mlDataSetSummary.typesDetected === "Confirmed";

  const isMobileView = useMediaQuery("(max-width:1100px)");

  const dataTypes = useGetTypesQuery("PandasDataType");
  const [getMlDataSetSummary] = useLazyGetMLDataSetSummaryQuery();
  const [createTaskToDetectDataTypes, createTaskToDetectDataTypesResponse] =
    useCreateTaskToDetectDataTypesMutation();
  const [confirmDataTypes, confirmDataTypesResponse] =
    useConfirmDataTypesMutation();

  const [columnsData, setColumnsData] = useState<TMLDataSetColumns[]>([
    ...mlDataSetSummary.columns.map((item) => ({ ...item })),
  ]);
  const { isShowSnackbar, snackbarMessage, snackbarSeverity, blinkSnackbar } =
    useSnackbar();
  const [
    showStartTypeDetectionConfirmModal,
    setShowStartTypesDetectionConfirmModal,
  ] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(null);

  const onAutomaticDetectDataTypesClick = () => {
    createTaskToDetectDataTypes({
      mlDatasetId: mlDataSetSummary.id,
      taskType: "DataTypesDetection",
      args: JSON.stringify(
        columnsData.map((column) => ({
          name: column.name,
          isUsed: column.isUsed,
        }))
      ),
    })
      .unwrap()
      .then(async () => {
        await getMlDataSetSummary(mlDataSetSummary.dataSetId);
        blinkSnackbar(`${intl.messages["taskIsAddedToQueue"]}`);
      })
      .catch((error) => {
        blinkSnackbar(
          isFetchBaseQueryErrorTypeGuard(error)
            ? error.data.message
            : (intl.messages["errorMessage"] as string),
          "error"
        );
      });
  };

  const onConfirmClick = () => {
    confirmDataTypes({
      mlDataSetId: mlDataSetSummary.id,
      columns: columnsData.map((column) => ({
        name: column.name,
        isUsed: column.isUsed,
        selectedType: column.dataType,
      })),
    })
      .unwrap()
      .then(async () => {
        await getMlDataSetSummary(mlDataSetSummary.dataSetId);
        blinkSnackbar(`${intl.messages["confirmedMessage"]}`);
      })
      .catch((error) => {
        blinkSnackbar(
          isFetchBaseQueryErrorTypeGuard(error)
            ? error.data.message
            : (intl.messages["errorMessage"] as string),
          "error"
        );
      });
  };

  const onIsUsedChange =
    (columnData: TMLDataSetColumns) => (event: React.BaseSyntheticEvent) => {
      setColumnsData((currentColumnsData) => [
        ...currentColumnsData.map((item) =>
          item.name === columnData.name
            ? { ...item, isUsed: event.target.checked }
            : { ...item }
        ),
      ]);
    };

  const onDataTypeChange =
    (columnData: TMLDataSetColumns) => (selectedDataType: Type) => {
      setColumnsData((currentColumnsData) => [
        ...currentColumnsData.map((item) =>
          item.name === columnData.name
            ? { ...item, dataType: selectedDataType?.id ?? "Undefined" }
            : { ...item }
        ),
      ]);
    };

  const closeStartDataDetectionConfirmModal = () => {
    setShowStartTypesDetectionConfirmModal(null);
  };
  const closeConfirmModal = () => {
    setShowConfirmModal(null);
  };

  useEffect(() => {
    if (isAwaitingDataTypesConfirmation) {
      setColumnsData((currentColumnsData) => [
        ...currentColumnsData.map((item) =>
          !item.dataType && item.possibleDataTypes?.length
            ? { ...item, dataType: item.possibleDataTypes[0] }
            : { ...item }
        ),
      ]);
    }
  }, [isAwaitingDataTypesConfirmation]);

  const dataTypesOptions = useMemo(
    () =>
      dataTypes.data
        ? [
            { id: "Undefined", name: intl.messages["undefined2"] },
            ...dataTypes.data,
          ]
        : [],
    [dataTypes.data, intl.messages]
  );

  const columns: ColumnDef<TMLDataSetColumns>[] = useMemo(
    () => [
      {
        id: intl.messages["name"] as string,
        accessorFn: (row) => row.name,
      },
      {
        id: intl.messages["isUsed"] as string,
        header: () => (
          <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
            {intl.messages["isUsed"]}
            <Tooltip
              placement="bottom"
              arrow={true}
              title={intl.messages["hintIfTheFieldIsUsedInPreprocessing"]}
            >
              <InfoOutlined sx={{ width: 15, height: 15, color: "#888888" }} />
            </Tooltip>
          </Box>
        ),
        cell: (props) => (
          <Switch
            disabled={
              isTaskHandlingByBackend ||
              isAwaitingDataTypesConfirmation ||
              isConfirmed ||
              createTaskToDetectDataTypesResponse.isLoading ||
              confirmDataTypesResponse.isLoading
            }
            checked={props.row.original.isUsed}
            onChange={onIsUsedChange(props.row.original)}
          />
        ),
      },
      {
        id: intl.messages["dataType"] as string,
        header: () => (
          <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
            {intl.messages["dataType"]}
            <Tooltip
              placement="bottom"
              arrow={true}
              title={intl.messages["hintDataTypeInPreprocessing"]}
            >
              <InfoOutlined sx={{ width: 15, height: 15, color: "#888888" }} />
            </Tooltip>
          </Box>
        ),
        cell: (props) => {
          let options =
            isAwaitingDataTypesConfirmation &&
            props.row.original.possibleDataTypes?.length
              ? dataTypesOptions.filter((option) =>
                  props.row.original.possibleDataTypes.includes(option.id)
                )
              : dataTypesOptions;
          if (props.row.original.dataType) {
            const currentSetDataType = dataTypesOptions.find(
              (option) => option.id === props.row.original.dataType
            );
            const isCurrentSetDatatypeAlreadyInList = !!options.find(
              (option) => option.id === props.row.original.dataType
            );
            if (currentSetDataType && !isCurrentSetDatatypeAlreadyInList) {
              options.push(currentSetDataType);
            }
          }
          return (
            <Box sx={{ maxWidth: 200, padding: "8px 0" }}>
              <DbaSelect
                disabled={
                  isTaskHandlingByBackend ||
                  isCreation ||
                  isConfirmed ||
                  createTaskToDetectDataTypesResponse.isLoading ||
                  confirmDataTypesResponse.isLoading
                }
                selectedValue={
                  props.row.original.dataType
                    ? dataTypesOptions.find(
                        (item) => item.id === props.row.original.dataType
                      )
                    : options[0]
                }
                setSelectedValue={onDataTypeChange(props.row.original)}
                options={options}
              />
            </Box>
          );
        },
      },
    ],
    [
      confirmDataTypesResponse.isLoading,
      createTaskToDetectDataTypesResponse.isLoading,
      dataTypesOptions,
      intl.messages,
      isAwaitingDataTypesConfirmation,
      isConfirmed,
      isCreation,
      isTaskHandlingByBackend,
    ]
  );

  const getSnackbar = (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={isShowSnackbar}
      autoHideDuration={null}
    >
      <Alert elevation={6} severity={snackbarSeverity} sx={{ width: "100%" }}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );

  const isTaskHandlingByBackendMessage = isTaskHandlingByBackend ? (
    <Box sx={{ marginBottom: "16px" }}>
      <Alert severity="info">
        <Box>{intl.messages["taskIsWhileHandlingByBackendPleaseWait"]}</Box>
        <DbaButton
          size="small"
          variant="outlined"
          sx={{ marginTop: "8px" }}
          text="learnProcessingStatusFully"
          onClick={() => setTabIndex(tabIndexes.STATUS_TAB_INDEX)}
        />
      </Alert>
    </Box>
  ) : null;

  const isTypesConfirmedMessage = isConfirmed ? (
    <Box sx={{ marginBottom: "16px" }}>
      <Alert severity="info">
        <Box>{intl.messages["dataTypesAreConfirmedInfoMessage"]}</Box>
        <DbaButton
          size="small"
          variant="outlined"
          sx={{ marginTop: "8px", marginRight: "16px" }}
          text="learnProcessingStatusFully"
          onClick={() => setTabIndex(tabIndexes.STATUS_TAB_INDEX)}
        />
        <DbaButton
          size="small"
          variant="outlined"
          sx={{ marginTop: "8px" }}
          text="continuePreprocessing"
          onClick={() => setTabIndex(tabIndexes.DATA_QUALITY_TAB_INDEX)}
        />
      </Alert>
    </Box>
  ) : null;

  return (
    <>
      {isTaskHandlingByBackendMessage}
      {isTypesConfirmedMessage}
      <Box sx={{ position: "sticky", top: "-16px", zIndex: 30 }}>
        <Box
          sx={{
            background: darkMode ? colors.dark : colors.light,
            display: "flex",
            gap: "20px",
            paddingBottom: "16px",
          }}
        >
          <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <DbaButton
              text="detectDataTypesAutomatically"
              onClick={(event) =>
                setShowStartTypesDetectionConfirmModal(event.currentTarget)
              }
              disabled={
                isTaskHandlingByBackend ||
                !isCreation ||
                createTaskToDetectDataTypesResponse.isLoading ||
                confirmDataTypesResponse.isLoading
              }
              isLoading={createTaskToDetectDataTypesResponse.isLoading}
            />
            <Tooltip
              placement="bottom"
              arrow={true}
              title={intl.messages["hintAutomaticDataTypeDetection"]}
            >
              <InfoOutlined sx={{ width: 15, height: 15, color: "#888888" }} />
            </Tooltip>
          </Box>
          <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <DbaButton
              text="confirm"
              onClick={(event) => setShowConfirmModal(event.currentTarget)}
              disabled={
                isTaskHandlingByBackend ||
                !isAwaitingDataTypesConfirmation ||
                createTaskToDetectDataTypesResponse.isLoading ||
                confirmDataTypesResponse.isLoading
              }
              isLoading={confirmDataTypesResponse.isLoading}
            />
            <Tooltip
              placement="bottom"
              arrow={true}
              title={intl.messages["hintConfirmDataTypeDetection"]}
            >
              <InfoOutlined sx={{ width: 15, height: 15, color: "#888888" }} />
            </Tooltip>
          </Box>
        </Box>
        <Divider sx={{ marginBottom: "16px" }} />
      </Box>
      <Box sx={{ display: "flex", gap: "3%" }}>
        <Box sx={isMobileView ? { width: "100%" } : { width: "60%" }}>
          <DbaTable
            data={columnsData}
            columns={columns}
            headToolbar={{ showHeadToolbar: false }}
            pagination={{ showPagination: false }}
            sorting={{ enableSorting: false }}
            fixControls={{ enableFixControls: false }}
          />
        </Box>
      </Box>
      <DbaPopover
        // confirmAgain={selectedRow?.original?.isDeleted}
        styles={{ margin: "10px 0 0 0" }}
        customConfirmMessage="startAutoTypesDetection"
        adviceMessage="areYouSureYouWantStartDataTypeDetectionMessage"
        actionMessage="start"
        onCloseModal={closeStartDataDetectionConfirmModal}
        onDeleteItem={() => {
          onAutomaticDetectDataTypesClick();
          closeStartDataDetectionConfirmModal();
        }}
        anchorEl={showStartTypeDetectionConfirmModal}
        setAnchorEl={() => setShowStartTypesDetectionConfirmModal}
      />
      <DbaPopover
        // confirmAgain={selectedRow?.original?.isDeleted}
        styles={{ margin: "10px 0 0 0" }}
        customConfirmMessage="confirmTypesMessage"
        adviceMessage="areYouSureYouWantConfirmTypesMessage"
        actionMessage="confirm"
        onCloseModal={closeConfirmModal}
        onDeleteItem={() => {
          onConfirmClick();
          closeConfirmModal();
        }}
        anchorEl={showConfirmModal}
        setAnchorEl={() => setShowConfirmModal}
      />
      {getSnackbar}
    </>
  );
};
