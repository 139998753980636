import { WidgetSettingContainer } from "../../common";
import type { ChartSettingsProps } from "../Types";
import Switch from "@mui/material/Switch";
import { MotionConfigSettings } from "../../common";

export const BarChartInteractivitySettings = ({
  settings,
  setSettings,
}: ChartSettingsProps) => {
  return (
    <>
      <WidgetSettingContainer title="animate">
        <Switch
          checked={
            settings.diagram.animate === undefined
              ? true
              : settings.diagram.animate
          }
          onChange={(e) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                animate: e.target.checked,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="isInteractive">
        <Switch
          checked={
            settings.diagram.isInteractive === undefined
              ? true
              : settings.diagram.isInteractive
          }
          onChange={(e) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                isInteractive: e.target.checked,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer lastRow title="motionConfig">
        <MotionConfigSettings
          settings={settings}
          setSettings={setSettings}
          defaultValue="default"
        />
      </WidgetSettingContainer>
    </>
  );
};
