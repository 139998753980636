import styled from "@emotion/styled";
import ListItemIcon from "@mui/material/ListItemIcon";
import colors from "../../../../../../Variables.module.scss";

export const CSSListItem = styled(ListItemIcon)`
  cursor: pointer;
  padding: 7px 0 7px 16px;
  &:hover {
    background-color: ${({ theme }: any) =>
      theme.palette.mode === "dark" ? colors.purple200 : colors.grayLight};
  }
  svg {
    color: ${({ theme }: any) =>
      theme.palette.mode === "dark" ? colors.grayLight : colors.darkBlue};
  }
`;

export const CSSDrawerHeader = styled("div")(({ theme }: any) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  svg: {
    width: "40px",
    zIndex: 9999,
  },
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
