import {
  CSSConstructorContainer,
  CSSEditableWidgetContainer,
} from "./DiagramWidgetConstructor.styles";
import { WidgetConstructorToolbar } from "../WidgetConstructorToolbar";
import {
  DiagramWidgetConstructorProps,
  WidgetElementTypes,
} from "./DiagramWidgetConstructor.types";
import { useContext, useEffect, useState } from "react";
import {
  WidgetLayout,
  WidgetContainer,
} from "../../../../components/Widgets/common";
import { ThemeContext } from "../../../../utils/ThemeContext";
import {
  LegendSettings,
  BaseSettings,
  StyleSettings,
  LabelSettings,
  ArcLinkLablelSettings,
  InteractivitySettings,
  AxesSettings,
} from "./Settings";
import { PointsSettings } from "./Settings/PointsSettings";
import { DotsSettings } from "./Settings/DotsSettings";
import { DbaErrorBoundary } from "../../../../DbaComponents";
import {
  PieChart,
  BarChart,
  LineChart,
  RadarChart,
  TimeRangeChart,
  RadialBarChart,
  CalendarChart,
  AreaBumpChart,
  HeatMapChart,
  FunnelChart,
} from "../../../../components/Widgets/Widgets/Diagram/DiagramTypes";
import { Thresholds } from "../SettingsContainer/Thresholds/Thresholds";
import { SettingsType } from "./Settings/Types";
import DataMerge from "./utils";
import {
  DiagramTypes,
  DiagramWidgetResponse,
} from "../../../../features/serviceSlices/SpecificWidgets/DiagramWidget/Types";
import { useIntl } from "react-intl";

export const getDiagram = (
  type: DiagramTypes,
  settings: any,
  widgetData: DiagramWidgetResponse & { data: any },
  widgetSettings: SettingsType
) => {
  const data = widgetData.data;
  const id = widgetData.id;
  switch (type) {
    case "Line":
      return (
        <LineChart
          data={data}
          settings={settings}
          widgetSettings={widgetSettings}
        />
      );
    case "Bar":
      return (
        <BarChart
          data={data}
          settings={settings}
          widgetSettings={widgetSettings}
        />
      );
    case "Pie":
      return <PieChart data={data} widgetSettings={widgetSettings} />;
    case "Radar":
      return <RadarChart data={data} settings={settings} />;
    case "TimeRange":
      return <TimeRangeChart data={data} settings={settings} id={id} />;
    case "RadialBar":
      return <RadialBarChart data={data} settings={settings} />;
    case "Calendar":
      return <CalendarChart data={data} settings={settings} id={id} />;
    case "AreaBump":
      return <AreaBumpChart data={data} settings={settings} />;
    case "HeatMap":
      return <HeatMapChart data={data} settings={settings} />;
    case "Funnel":
      return <FunnelChart data={data} settings={settings} />;
    default:
      return <p></p>;
  }
};

export const DiagramWidgetConstructor = ({
  settings,
  setSettings,
  widgetData,
}: DiagramWidgetConstructorProps) => {
  const { darkMode } = useContext(ThemeContext);
  const [selectedElement, setSelectedElement] =
    useState<WidgetElementTypes | null>(null);
  const [dataMappingOptions, setDataMappingOptions] = useState<string[] | []>(
    []
  );
  const widgetEditorLink = `/widget/${(widgetData as DiagramWidgetResponse).widgetSettings?.widgetID}?widgetType=Diagram`;
  const intl = useIntl();
  useEffect(() => {
    setDataMappingOptions(DataMerge(widgetData.data));
  }, [widgetData.data]);

  return (
    <CSSConstructorContainer>
      <DbaErrorBoundary>
        <WidgetConstructorToolbar
          widgetType="Diagram"
          settings={settings}
          setSettings={setSettings}
          selectedElement={selectedElement}
          setSelectedElement={setSelectedElement}
        />
        <CSSEditableWidgetContainer>
          {settings.diagram?.diagramType ? (
            selectedElement === "legends" ? (
              <LegendSettings setSettings={setSettings} settings={settings} />
            ) : selectedElement === "baseSettings" ? (
              <DbaErrorBoundary>
                <BaseSettings
                  diagramType={settings.diagram.diagramType}
                  setSettings={setSettings}
                  settings={settings}
                  dataMappingOptions={dataMappingOptions}
                />
              </DbaErrorBoundary>
            ) : selectedElement === "styles" ? (
              <>
                <StyleSettings
                  setSettings={setSettings}
                  settings={settings}
                  diagramType={settings.diagram.diagramType}
                />
              </>
            ) : selectedElement === "thresholds" ? (
              <Thresholds
                setSettings={setSettings}
                settings={settings}
                diagram
              />
            ) : selectedElement === "labels" ? (
              <LabelSettings
                setSettings={setSettings}
                settings={settings}
                diagramType={settings.diagram.diagramType}
              />
            ) : selectedElement === "arcLinkLabels" ? (
              <ArcLinkLablelSettings
                setSettings={setSettings}
                settings={settings}
                diagramType={settings.diagram.diagramType}
              />
            ) : selectedElement === "interactivity" ? (
              <InteractivitySettings
                setSettings={setSettings}
                settings={settings}
                diagramType={settings.diagram.diagramType}
              />
            ) : selectedElement === "axes" ? (
              <AxesSettings
                setSettings={setSettings}
                settings={settings}
                diagramType={settings.diagram.diagramType}
              />
            ) : selectedElement === "points" &&
              settings.diagram.diagramType === "Line" ? (
              <PointsSettings
                setSettings={setSettings}
                settings={settings}
                diagramType={settings.diagram.diagramType}
              />
            ) : selectedElement === "points" &&
              settings.diagram.diagramType === "Radar" ? (
              <DotsSettings
                setSettings={setSettings}
                settings={settings}
                diagramType={settings.diagram.diagramType}
              />
            ) : (
              ""
            )
          ) : (
            ""
          )}

          <DbaErrorBoundary>
            <WidgetContainer
              type="Diagram"
              darkMode={darkMode}
              body={settings.widget.body}
            >
              <WidgetLayout
                measure={widgetData.measure}
                settings={settings.widget}
                label={widgetData.label}
                type="Diagram"
                editMode={true}
                setSelectedElement={setSelectedElement}
                selectedElement={selectedElement}
                dashboardId="12"
                widgetID="12"
              >
                <DbaErrorBoundary
                  resetKeys={[settings.diagram.diagramType]}
                  customMessage={intl.messages["visualEditorError"] as string}
                  isShowErrorMessage={false}
                  link={widgetEditorLink}
                >
                  {settings.diagram &&
                  settings.diagram.diagramType &&
                  widgetData.data.length > 0
                    ? getDiagram(
                        settings.diagram.diagramType,
                        settings.diagram,
                        widgetData,
                        settings
                      )
                    : ""}
                </DbaErrorBoundary>
              </WidgetLayout>
            </WidgetContainer>
          </DbaErrorBoundary>
        </CSSEditableWidgetContainer>
      </DbaErrorBoundary>
    </CSSConstructorContainer>
  );
};
