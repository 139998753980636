import { DbaTextField, DbaNumberInput } from "../../../DbaComponents";
import { useGetTypesQuery } from "../../../features/serviceSlices/serviceHooks";
import styled from "@emotion/styled";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { LoadingScreen, ErrorMessage } from "../../../components/common";
import { FormHelperText } from "@mui/material";
import { useIntl } from "react-intl";
import { GetHeadersDescriptionResponse } from "../../../features/serviceSlices/ReferenceHeader/Types";

const CSSFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

type ReferenceInputGeneratorProps = {
  fields: GetHeadersDescriptionResponse;
  localState: any;
  setLocalState: (value: any) => void;
  error: boolean;
};

export const ReferenceInputGenerator = ({
  fields,
  localState,
  setLocalState,
  error,
}: ReferenceInputGeneratorProps) => {
  const tableFields = useGetTypesQuery("TableFieldType");
  const intl = useIntl();

  if (tableFields.isLoading) {
    return <LoadingScreen />;
  }

  if (tableFields.isError) {
    return <ErrorMessage />;
  }

  return tableFields.isSuccess ? (
    <CSSFieldsContainer>
      {fields.map((field) => {
        switch (field.type) {
          case "String":
            return (
              <DbaTextField
                key={field.label}
                value={localState[field.name] || ""}
                setValue={(value) =>
                  setLocalState({ ...localState, [field.name]: value })
                }
                label={field.label}
                error={
                  error &&
                  (localState?.[field.name] === undefined ||
                    localState?.[field.name].trim() === "")
                }
                helperText="fieldIsEmptyError"
                required={field.isRequired}
              />
            );
          case "Int32":
            return (
              <DbaNumberInput
                key={field.label}
                value={localState[field.name] || 0}
                setValue={(value) =>
                  setLocalState({ ...localState, [field.name]: value })
                }
                label={field.label}
                required={field.isRequired}
              />
            );
          default:
            return (
              <>
                <FormControl
                  error={error && !localState[field.name]}
                  required={field.isRequired}
                  fullWidth
                  key={field.label}
                >
                  <InputLabel id={field.label}>{field.label}</InputLabel>
                  <Select
                    required={field.isRequired}
                    value={localState[field.name]}
                    label={field.label}
                    onChange={(event) =>
                      setLocalState({
                        ...localState,
                        [field.name]: event.target.value,
                      })
                    }
                  >
                    {tableFields.data.map((option) => {
                      return (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {error && !localState[field.name] && (
                    <FormHelperText>
                      {intl.messages["requiredField"]}
                    </FormHelperText>
                  )}
                </FormControl>
              </>
            );
        }
      })}
    </CSSFieldsContainer>
  ) : null;
};
