import React, { ChangeEvent } from "react";
import { TDbaFileUploader } from "./Types";
import { TFile } from "../../../features/serviceSlices/Files/Types";
import { Box } from "@mui/material";
import { DbaButton } from "../../DbaButton";
import { TextField } from "@mui/material";
import { useIntl } from "react-intl";

const isMetaDataGuard = (data: any): data is TFile =>
  data && data.extension && data.size;

export const DbaFileUploader: React.FC<TDbaFileUploader> = ({
  file,
  onChange,
  maxSize,
  isOuterError,
  outerErrorText,
  isLoading,
}) => {
  const intl = useIntl();

  // "newFileProvided" - именно когда выбран новый файл, независимо от режима Create/Edit
  let mode: "newFileProvided" | "metaDataProvided" = isMetaDataGuard(file)
    ? "metaDataProvided"
    : "newFileProvided";

  const getError = (file: File): string | undefined => {
    if (isOuterError) {
      return outerErrorText;
    }
    return validateInnerRules(file);
  };

  const validateInnerRules = (file: File): string | undefined => {
    if (maxSize && (file?.size ?? 0) > maxSize) {
      return `${intl.messages["maxFileSizeMustNotExceed"]} ${
        maxSize / 1024 / 1024
      } MB`;
    }
    if (file?.name) {
      const splitFileName = (file?.name || "").split(".");
      if (splitFileName.length < 2) {
        return intl.messages["fileMustHaveExtension"] as string;
      }
    }
    return;
  };

  const onFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(
        event,
        event?.target?.files && event?.target?.files[0]
          ? validateInnerRules(event.target.files[0])
          : undefined
      );
    }
  };

  const fileName =
    mode === "newFileProvided"
      ? file?.name
      : `${file?.name}.${(file as TFile)?.extension}`;

  const uploadButtonLabel = !file ? "chooseFile" : "chooseAnotherFile";

  const error = mode === "newFileProvided" ? getError(file as File) : null;

  return (
    <Box sx={{ display: "flex", gap: "10px", alignItems: "start" }}>
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
          display: "flex",
        }}
      >
        <DbaButton
          text={uploadButtonLabel}
          onClick={() => {}}
          color="success"
          sx={{ paddingLeft: "25px", paddingRight: "25px", height: "56px" }}
          disabled={isLoading}
        />
        <TextField
          sx={{
            opacity: 0,
            position: "absolute",
            left: 0,
            right: 0,
            width: "100%",
            height: "100%",
          }}
          type="file"
          onChange={onFileInputChange}
          disabled={isLoading}
        />
      </Box>
      <Box sx={{ display: "flex", flex: 1 }}>
        <TextField
          disabled={true}
          value={fileName ?? " "}
          fullWidth={true}
          error={!!error}
          helperText={error}
        />
      </Box>
    </Box>
  );
};
