import { createSlice, PayloadAction, createAction } from "@reduxjs/toolkit";
import {
  DashboardResponse,
  TAvailableDashboards,
  TVariableDefaultValueFromJson,
} from "../../serviceSlices/Dashboards/Types";
import { TimePeriods, TISODateString } from "../../serviceSlices/SharedTypes";
import { dashboardApiSlice } from "../../serviceSlices/Dashboards/dashboardsService";
import { TGroupingFunction } from "../../serviceSlices/Widget/Types";
import { AuthorizationInfo } from "../../serviceSlices/Authentication/Types";
import { BROWSER_TIMEZONE } from "../../../DbaComponents/DbaTimezonePicker/constants";
import { TTimezone } from "../../serviceSlices/Timezones/Types";
import { prepare } from "./helpers";
import {
  convertDateToISOUsingExternalTimeZone,
  createNewDateOnPeriodEnd,
  createNewDateOnPeriodStart,
  restoreISODateUsingTimeZoneAndConvertToISODateWithTargetTZ,
} from "../../../utils/helpers/dateTime";
import { parseJSON } from "../../../utils/helpers/functions";

type TInitialState = {
  role: Omit<DashboardResponse, "variableDefaultValues"> & {
    variableDefaultValues: TVariableDefaultValueFromJson[];
  };
  roles: TAvailableDashboards;
  startDate: TISODateString;
  endDate: TISODateString;
  type: TimePeriods;
  timezone: TTimezone;
  editMode: boolean;
  layouts: string;
  groupingFunction: TGroupingFunction;
  filters: any[];
  authorizationInfo: null | AuthorizationInfo;
};

const initialState: TInitialState = {
  role: {
    description: "",
    id: "",
    layout: "",
    name: "",
    value: "",
    label: "",
    role: "",
    widgets: [],
    availablePeriods: [],
    defaultPeriod: "",
    variableDefaultValues: [],
  },
  roles: [],
  startDate: convertDateToISOUsingExternalTimeZone(
    createNewDateOnPeriodStart("Day")!,
    BROWSER_TIMEZONE.id
  ),
  endDate: convertDateToISOUsingExternalTimeZone(
    createNewDateOnPeriodEnd("Day")!,
    BROWSER_TIMEZONE.id
  ),
  type: "NotSet",
  timezone: BROWSER_TIMEZONE,
  groupingFunction: "Year",
  editMode: false,
  layouts: "",
  filters: [],
  authorizationInfo: null,
};

const dateChange = createAction("dias/dateChange", prepare);
export { dateChange };

export const appSlice = createSlice({
  name: "dias",
  initialState,
  reducers: {
    diasRoleChange: (
      state,
      action: PayloadAction<DashboardResponse | null>
    ) => {
      if (action.payload) {
        const resultFilters: TInitialState["filters"] = [];
        state.role = {
          ...action.payload,
          variableDefaultValues: action.payload.variableDefaultValues.map(
            (defaultFilter) => {
              const parsed = parseJSON(defaultFilter.value);
              const isAddressFilter = parsed?.id;
              const value = isAddressFilter ? parsed?.id : parsed?.value;
              if (value) {
                resultFilters.push({ [defaultFilter.key]: value });
              }
              return {
                ...defaultFilter,
                value,
                query: isAddressFilter ? parsed.value : undefined,
              };
            }
          ),
        };
        state.filters = resultFilters;
      } else {
        return state;
      }
    },
    periodChange: (state, action: PayloadAction<TimePeriods>) => {
      if (action.payload && action.payload !== "NotSet") {
        state.startDate = convertDateToISOUsingExternalTimeZone(
          createNewDateOnPeriodStart(action.payload)!,
          state.timezone.id
        );
        state.endDate = convertDateToISOUsingExternalTimeZone(
          createNewDateOnPeriodEnd(action.payload)!,
          state.timezone.id
        );
      }
      state.type = action.payload;
    },
    timezoneChange: (state, action: PayloadAction<TTimezone>) => {
      if (state.startDate) {
        state.startDate =
          restoreISODateUsingTimeZoneAndConvertToISODateWithTargetTZ({
            date: state.startDate,
            dateTZ: state.timezone.id,
            targetTZ: action.payload.id,
          });
      }
      if (state.endDate) {
        state.endDate =
          restoreISODateUsingTimeZoneAndConvertToISODateWithTargetTZ({
            date: state.endDate,
            dateTZ: state.timezone.id,
            targetTZ: action.payload.id,
          });
      }
      state.timezone = action.payload;
    },
    timezoneReset: (state) => {
      state.timezone = BROWSER_TIMEZONE;
    },
    editModeChange: (state, action: PayloadAction<boolean>) => {
      state.editMode = action.payload;
    },
    layoutChange: (state, action: PayloadAction<string>) => {
      state.layouts = action.payload;
    },
    addFilter: (state, action: PayloadAction<{}>) => {
      const filterToAddIndex = state.filters.findIndex((filter) =>
        Object.keys(filter).includes(Object.keys(action.payload)[0])
      );
      if (filterToAddIndex >= 0) {
        const newArrayOfFilters = [...state.filters];
        newArrayOfFilters.splice(filterToAddIndex, 1, action.payload);
        return { ...state, filters: newArrayOfFilters };
      }
      state.filters = [...state.filters, action.payload];
    },
    deleteFilter: (state, action: PayloadAction<string>) => {
      const filterToDeleteIndex = state.filters.findIndex((filter) =>
        Object.keys(filter).includes(action.payload)
      );
      if (filterToDeleteIndex >= 0) {
        const newArrayOfFilters = [...state.filters];
        newArrayOfFilters.splice(filterToDeleteIndex, 1);
        return { ...state, filters: newArrayOfFilters };
      }
      return state;
    },
    filterFilter: (state, action: PayloadAction<string>) => {
      state.filters = state.filters.filter(
        (filter) => filter.type !== action.payload
      );
    },
    cleanFilters: (state) => {
      state.filters = [];
    },
    changeAuth: (state, action: PayloadAction<AuthorizationInfo | null>) => {
      state.authorizationInfo = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(dateChange, (state, action) => {
        if (!action.payload.endDate && action.payload.startDate) {
          state.startDate = action.payload.startDate;
        } else {
          state.endDate = action.payload.endDate!;
        }
      })
      .addCase("playlist/stop", (state) => {
        state.startDate = initialState.startDate;
        state.endDate = initialState.endDate;
        state.type = initialState.type;
      })
      .addMatcher(
        dashboardApiSlice.endpoints.getAvailable.matchFulfilled,
        (state, { payload }) => {
          state.roles = payload.map((i) => ({
            ...i,
            value: i.name,
            label: i.name,
          }));
        }
      )
      .addMatcher(
        dashboardApiSlice.endpoints.getPublicDashboard.matchFulfilled,
        (state, { payload }) => {
          state.roles = [
            {
              ...payload,
              value: payload.name,
              label: payload.name,
            },
          ];
        }
      );
  },
});

export const {
  diasRoleChange,
  periodChange,
  timezoneChange,
  timezoneReset,
  editModeChange,
  layoutChange,
  addFilter,
  deleteFilter,
  filterFilter,
  cleanFilters,
  changeAuth,
  reset,
} = appSlice.actions;

export default appSlice.reducer;
