import { DbaHeatMapChart } from '../../../../common/Diagrams';

export const HeatMapChart = ({ data, settings }) => {
  function objectsWithSameId(arr) {
    return arr.every(e => e.id === arr[0].id);
  }
  const fixedData =
    (
      Array.isArray(data)
      && data.length !== 0
      && objectsWithSameId(data)
    ) ?
      data.map((obj, index) => {
        return { ...obj, id: obj.id + index }
      }) : data;

  return (
    <DbaHeatMapChart
      data={fixedData}
      settings={settings}
    />
  )
}