import { DialogProps } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { DbaIconButton } from "../DbaIconButton";
import { useIntl } from "react-intl";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";

type DbaModalProps = DialogProps & {
  setOpen: (arg: boolean) => void;
  title: string;
  children: React.ReactNode;
  onCloseHandler?: any;
  toolbar?: React.ReactNode;
};

export const DbaModal = ({
  open,
  setOpen,
  title,
  fullWidth,
  children,
  onCloseHandler,
  toolbar,
  ...rest
}: DbaModalProps) => {
  const intl = useIntl();
  const handleClose = () => {
    onCloseHandler && onCloseHandler();
    setOpen(false);
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          minWidth: 350,
        },
      }}
      open={open}
      maxWidth="xl"
      fullWidth={fullWidth}
      onClose={handleClose}
      scroll="paper"
      {...rest}
    >
      <DialogTitle sx={{ display: "flex", gap: "16px", minHeight: "64px" }}>
        {intl.messages[title] || title}
        {toolbar && toolbar}
        <DbaIconButton
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
          }}
          icon={<CloseIcon />}
          onClick={handleClose}
        />
      </DialogTitle>
      <DialogContent dividers={true}>{children}</DialogContent>
    </Dialog>
  );
};
