import { Link as RouterLink, To } from "react-router-dom";
import Link from "@mui/material/Link";

type TIntlMessageCombiner = {
  text: string;
  links: string[];
};

export const IntlMessageCombiner = ({ text, links }: TIntlMessageCombiner) => {
  const textArray = text.split("#split");
  let unusedLinks = links;
  const messageWithLinks = textArray.map((str) => {
    if (str.includes("#link")) {
      return (
        <Link
          component={RouterLink}
          to={unusedLinks.shift() as To}
        >
          {str.replace("#link", "")}
        </Link>
      );
    }
    return str;
  });
  return <>{messageWithLinks}</>;
};

export default IntlMessageCombiner;
