import styled from "@emotion/styled";
import {
  DbaDataFetchSelect,
  DbaSnackbar,
  DbaTable,
} from "../../../DbaComponents";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  AddButton,
  ErrorMessage,
  LoadingScreen,
} from "../../../components/common";
import {
  useAddWidgetToDashboardMutation,
  useLazyGetWidgetDashboardsQuery,
  useDeleteWidgetFromDashboardMutation,
} from "../../../features/serviceSlices/serviceHooks";
import { useIntl } from "react-intl";
import { DashboardResponse } from "../../../features/serviceSlices/Dashboards/Types";
import { WidgetDashboardObject } from "../../../features/serviceSlices/Widget/Types";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import { ColumnDef } from "@tanstack/react-table";
import { DeleteButton } from "../../../components/TableHelpers/DeleteButton";

type AddWidgetToDashboardProps = {
  widgetID: string;
};

export const CSSAddWidgetToDashboardRow = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  & > div {
    width: 100%;
  }
  & > button {
    min-width: 133px;
  }
`;
const CSSAddWidgetToDashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const AddWidgetToDashboard = ({
  widgetID,
}: AddWidgetToDashboardProps) => {
  const intl = useIntl();
  const [selectedDashboard, setSelectedDashboard] =
    useState<DashboardResponse | null>(null);
  const [error, setError] = useState(false);
  const [addWidget, addWidgetResponse] = useAddWidgetToDashboardMutation();
  const [getWidgetDashboards, widgetDashboardsResponse] =
    useLazyGetWidgetDashboardsQuery();
  const [deleteWidget] = useDeleteWidgetFromDashboardMutation();

  const onAddWidgetHandler = () => {
    if (!selectedDashboard || selectedDashboard.id === undefined) {
      setError(true);
    } else {
      setError(false);
      addWidget({ widgetID, dashboardID: selectedDashboard.id });
      setSelectedDashboard(null);
    }
  };

  const onDeleteDashboardHandler = useCallback(
    (dashboard: WidgetDashboardObject) =>
      deleteWidget({ widgetID, dashboardID: dashboard.dashboardID }),
    [deleteWidget, widgetID]
  );

  useEffect(() => {
    if (widgetID) {
      getWidgetDashboards(widgetID);
    }
  }, [getWidgetDashboards, widgetID]);

  const widgetDashboardsColumns: ColumnDef<WidgetDashboardObject>[] = useMemo(
    () => [
      {
        id: intl.messages["label"] as string,
        accessorFn: (row) => row.name,
        cell: (props) => {
          return (
            <Link
              component={RouterLink}
              to={`/dashboard/${props.row.original.dashboardID}`}
            >
              {props.row.original.name}
            </Link>
          );
        },
        size: Number.MAX_SAFE_INTEGER,
      },
      {
        id: "deletion",
        header: "",
        cell: (props) => (
          <DeleteButton
            row={props.row}
            deleteDataHandler={onDeleteDashboardHandler}
          />
        ),
      },
    ],
    [intl.messages, onDeleteDashboardHandler]
  );

  return (
    <CSSAddWidgetToDashboardContainer>
      <CSSAddWidgetToDashboardRow>
        <DbaDataFetchSelect
          size="small"
          error={error && !selectedDashboard}
          selectedValue={selectedDashboard}
          setSelectedValue={setSelectedDashboard}
          label="chooseDashboard"
          url="api/dashboard/getavailable"
        />
        <AddButton
          status={addWidgetResponse.status}
          onClick={onAddWidgetHandler}
        />
      </CSSAddWidgetToDashboardRow>
      {widgetDashboardsResponse.isLoading ? (
        <LoadingScreen />
      ) : widgetDashboardsResponse.isSuccess ? (
        <DbaTable
          data={widgetDashboardsResponse.data}
          columns={widgetDashboardsColumns}
          stylesSettings={{ autoColumnsWidth: false }}
          fixControls={{ enableFixControls: false }}
        />
      ) : (
        <ErrorMessage />
      )}
      <DbaSnackbar
        error={addWidgetResponse.isError}
        success={addWidgetResponse.isSuccess}
        successMessage="addWidgetSuccessMessage"
        errorMessage="addWidgetErrorMessage"
      />
    </CSSAddWidgetToDashboardContainer>
  );
};
