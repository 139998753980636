import React, { useState, useMemo, useCallback } from "react";
import {
  DbaDataFetchSelect,
  DbaSnackbar,
  DbaTable,
} from "../../../../DbaComponents";
import { CSSAddWidgetToDashboardRow } from "../../components/AddWidgetToDashboard";
import {
  useAddMapObjectMutation,
  useGetMapWidgetQuery,
  useDeleteMapObjectFromWidgetMutation,
  useSetDefaultMapObjectMutation,
} from "../../../../features/serviceSlices/serviceHooks";
import {
  AddButton,
  ErrorMessage,
  LoadingScreen,
} from "../../../../components/common";
import type { MapObject } from "../../../../features/serviceSlices/MapObjects/Types";
import { useIntl } from "react-intl";
import Checkbox from "@mui/material/Checkbox";
import { ColumnDef } from "@tanstack/react-table";
import { DeleteButton } from "../../../../components/TableHelpers/DeleteButton";

export const MapWidgetSecondStep = ({ widgetID }: { widgetID: string }) => {
  const mapWidget = useGetMapWidgetQuery({ widgetID });
  const [addMapObject, addMapObjectResponse] = useAddMapObjectMutation();
  const [deleteMapObject] = useDeleteMapObjectFromWidgetMutation();
  const [setDefaultMapObject, setDefaultMapObjectResponse] =
    useSetDefaultMapObjectMutation();
  const [mapObject, setMapObject] = useState<MapObject | null>(null);
  const intl = useIntl();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
      setDefaultMapObject({
        mapObjectID: id,
        widgetID,
        isDefault: event.target.checked,
      });
    },
    [setDefaultMapObject, widgetID]
  );

  const onAddMapObjectsHandler = () => {
    if (mapObject?.id) {
      addMapObject({ widgetID, mapObjectID: mapObject.id });
      setMapObject(null);
    }
  };

  const onDeleteMapObjectHandler = useCallback(
    (MapObject: MapObject) => {
      if (MapObject?.id) {
        deleteMapObject({ widgetID, mapObjectID: MapObject.id });
      }
      //TO DO: add snackbar for handling errors
    },
    [deleteMapObject, widgetID]
  );

  const columns: ColumnDef<MapObject>[] = useMemo(
    () => [
      {
        id: intl.messages["label"] as string,
        accessorFn: (row) => row.name,
      },
      {
        id: intl.messages["type"] as string,
        accessorFn: (row) => row.type,
        cell: (props) => {
          switch (props.row.original.type) {
            case "Layer":
              return intl.messages["layer"];
            case "Object":
              return intl.messages["object"];
            default:
              return props.row.original;
          }
        },
      },
      {
        id: intl.messages["showByDefault"] as string,
        accessorFn: (row) => row.isDefault,
        cell: (props) => {
          return (
            <Checkbox
              checked={props.row.original.isDefault}
              onChange={(e) => handleChange(e, props.row.original.id)}
              disabled={setDefaultMapObjectResponse.isLoading}
            />
          );
        },
      },
      {
        id: "deletion",
        header: "",
        cell: (props) => (
          <DeleteButton
            row={props.row}
            deleteDataHandler={onDeleteMapObjectHandler}
          />
        ),
      },
    ],
    [
      handleChange,
      intl.messages,
      onDeleteMapObjectHandler,
      setDefaultMapObjectResponse.isLoading,
    ]
  );

  if (mapWidget.isLoading) {
    return <LoadingScreen />;
  }
  if (mapWidget.isError) {
    return <ErrorMessage />;
  }

  return (
    <>
      <CSSAddWidgetToDashboardRow>
        <DbaDataFetchSelect
          required
          disableClearable
          size="small"
          url="api/MapObject/GetAll"
          label="mapObjects"
          selectedValue={mapObject}
          setSelectedValue={setMapObject}
          filterOptions={
            mapWidget.data?.mapObjects ? mapWidget.data?.mapObjects : null
          }
        />
        <AddButton
          status={addMapObjectResponse.status}
          onClick={onAddMapObjectsHandler}
          disabled={!mapObject?.id}
        />
      </CSSAddWidgetToDashboardRow>
      {mapWidget.data ? (
        <DbaTable
          data={mapWidget.data.mapObjects}
          columns={columns}
          fixControls={{ enableFixControls: false }}
        />
      ) : null}
      <DbaSnackbar
        error={addMapObjectResponse.isError}
        success={addMapObjectResponse.isSuccess}
        successMessage="addWidgetSuccessMessage"
        errorMessage="addWidgetErrorMessage"
      />
      <DbaSnackbar
        error={setDefaultMapObjectResponse.isError}
        errorMessage="errorSettingDefaultMapObject"
      />
    </>
  );
};
