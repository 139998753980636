import { useState, useCallback } from "react";
import { AlertColor } from "@mui/material";

const SHOW_SNACKBAR_PERIOD = 3000;
const MAX_SHOW_SNACKBAR_PERIOD = 2147483647;
let showingSnackbarTimeout: any;

type TUseSnackbar = {
  isShowSnackbar: boolean;
  snackbarMessage: string;
  snackbarSeverity: AlertColor;
  blinkSnackbar: (
    message: string,
    severity?: AlertColor,
    snackbarPeriod?: number
  ) => void;
  closeSnackbar: () => void;
};

export const useSnackbar = (): TUseSnackbar => {
  const [isShowSnackbar, setIsShowSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<AlertColor>("success");

  const blinkSnackbar = useCallback(
    (
      message: string,
      severity: AlertColor = "success",
      snackbarPeriod?: number
    ) => {
      setIsShowSnackbar(true);
      setSnackbarMessage(message);
      setSnackbarSeverity(severity);
      clearTimeout(showingSnackbarTimeout);
      showingSnackbarTimeout = setTimeout(() => {
        setIsShowSnackbar(false);
      }, (snackbarPeriod === Infinity ? MAX_SHOW_SNACKBAR_PERIOD : snackbarPeriod) ?? SHOW_SNACKBAR_PERIOD);
    },
    []
  );

  const closeSnackbar = useCallback(() => {
    clearTimeout(showingSnackbarTimeout);
    setIsShowSnackbar(false);
  }, []);

  return {
    isShowSnackbar,
    snackbarMessage,
    snackbarSeverity,
    blinkSnackbar,
    closeSnackbar,
  };
};
