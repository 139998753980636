import { useIntl } from "react-intl";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { CSSWrapper, CSSNumberInput } from "./DbaNumberInput.styles";
import type { DbaNumberInputProps } from "./DbaNumberInput.types";

export const DbaNumberInput = ({
  value,
  setValue,
  label,
  required,
  size,
  error = false,
  helperText = "",
  sx,
  min,
  max,
  step = 1,
  round,
}: DbaNumberInputProps) => {
  const intl = useIntl();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    round
      ? setValue(Math.round(parseFloat(event.target.value) * round) / round)
      : setValue(parseFloat(event.target.value));

  const onIncreaseHandler = () => {
    round
      ? setValue(Math.round((value + step) * round) / round)
      : setValue(
          Math.round((value + step) * Math.pow(10, 2)) / Math.pow(10, 2)
        );
  };

  const onDecreaseHandler = () =>
    round
      ? setValue(Math.round((value - step) * round) / round)
      : setValue(
          Math.round((value - step) * Math.pow(10, 2)) / Math.pow(10, 2)
        );

  return (
    <CSSWrapper className="DbaNumberInput">
      <ArrowDropUpIcon
        onClick={onIncreaseHandler}
        sx={{
          position: "absolute",
          top: size && size === "small" ? 0 : "6px",
          right: "5px",
          zIndex: 1,
          cursor: "pointer",
        }}
      />
      <CSSNumberInput
        type="number"
        sx={sx}
        value={value}
        onChange={handleChange}
        required={required}
        label={(label && intl.messages[label]) || label}
        error={error}
        helperText={
          error
            ? required && helperText === ""
              ? intl.messages["requiredField"]
              : intl.messages[helperText]
            : intl.messages[helperText]
        }
        size={size}
        fullWidth
        inputProps={{
          inputMode: "decimal",
          min,
          max,
          step: step ? step : "1",
        }}
      />
      <ArrowDropDownIcon
        onClick={onDecreaseHandler}
        sx={{
          position: "absolute",
          bottom:
            size && size === "small"
              ? error
                ? "24px"
                : 0
              : error
              ? "29px"
              : "6px",
          right: "5px",
          zIndex: 1,
          cursor: "pointer",
        }}
      />
    </CSSWrapper>
  );
};
