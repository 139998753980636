import { rootApi } from "../serviceRoot";
import {
  WidgetsInDashboard,
  AddWidgetToDashboardRequest,
  DeleteWidgetFromDashboardRequest,
  CreateDashboardRequest,
  UpdateDashboardRequest,
  GetAllDashboardsResponse,
  SetDefaultDashboardRequest,
  TGetAvailableDashboardsResponse,
  Dashboard,
  DashboardResponse,
} from "./Types";

export const dashboardApiSlice = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getBackendVersion: builder.query<string, void>({
      query: () => ({
        url: "api/Dashboard/Version",
      }),
    }),
    getAvailable: builder.query<TGetAvailableDashboardsResponse, void>({
      query: () => "api/dashboard/getavailable",
      providesTags: ["roles"],
    }),
    getDashboardById: builder.query<DashboardResponse, string>({
      query: (id) => `api/dashboard/getdashboard?DashboardID=${id}`,
      providesTags: ["dashboardWidgets", "layouts"],
    }),
    getPublicDashboard: builder.query<DashboardResponse, string>({
      query: (id) => `api/dashboard/getpublicdashboard?DashboardID=${id}`,
      // providesTags: ["dashboardWidgets", "layouts", "roles"],
    }),
    setDefaultDashboard: builder.mutation<boolean, SetDefaultDashboardRequest>({
      query: (payload) => ({
        url: "api/dashboard/SetDefault",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["dashboardWidgets", "groupDashboards"],
    }),
    getDashboard: builder.query<Dashboard, string>({
      query: (id) => `api/dashboard/get?ID=${id}`,
      providesTags: ["Dashboards"],
    }),
    getDashboards: builder.query<GetAllDashboardsResponse, void>({
      query: () => "api/dashboard/getall",
      providesTags: ["Dashboards"],
    }),
    createDashboard: builder.mutation<any, CreateDashboardRequest>({
      query: (CreateDashboardRequest) => ({
        url: "api/dashboard/create",
        method: "POST",
        body: CreateDashboardRequest,
      }),
      invalidatesTags: ["Dashboards", "dashboardWidgets", "layouts", "roles"],
    }),
    updateDashboard: builder.mutation<any, UpdateDashboardRequest>({
      query: (UpdateDashboardRequest) => ({
        url: "api/dashboard/update",
        method: "POST",
        body: UpdateDashboardRequest,
      }),
      invalidatesTags: ["Dashboards", "dashboardWidgets", "layouts", "roles"],
    }),
    deleteDashboard: builder.mutation({
      query: (id) => ({
        url: "api/dashboard/delete",
        method: "POST",
        body: { id },
      }),
      invalidatesTags: ["Dashboards", "Playlists", "groupDashboards"],
    }),
    getDashboardWidgets: builder.query<WidgetsInDashboard, string>({
      query: (id) => `api/dashboard/Widgets?DashboardID=${id}`,
      providesTags: ["WidgetsInDashboards"],
    }),
    addWidgetToDashboard: builder.mutation<any, AddWidgetToDashboardRequest>({
      query: (payload) => ({
        url: "api/dashboard/AddWidget",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [
        "WidgetsInDashboards",
        "dashboardWidgets",
        "dataWidget",
        "diagramWidget",
        "tableWidget",
        "WidgetDashboards",
      ],
    }),
    deleteWidgetFromDashboard: builder.mutation<
      any,
      DeleteWidgetFromDashboardRequest
    >({
      query: (payload) => ({
        url: "api/dashboard/RemoveWidget",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [
        "WidgetsInDashboards",
        "dashboardWidgets",
        "dataWidget",
        "diagramWidget",
        "tableWidget",
        "WidgetDashboards",
        "Widgets",
      ],
    }),
  }),
});

export const {
  useGetDashboardQuery,
  useLazyGetDashboardQuery,
  useGetDashboardsQuery,
  useCreateDashboardMutation,
  useUpdateDashboardMutation,
  useDeleteDashboardMutation,
  useGetDashboardWidgetsQuery,
  useAddWidgetToDashboardMutation,
  useDeleteWidgetFromDashboardMutation,
  useSetDefaultDashboardMutation,
  useGetAvailableQuery,
  useGetDashboardByIdQuery,
  useLazyGetDashboardByIdQuery,
  useGetPublicDashboardQuery,
  useGetBackendVersionQuery,
} = dashboardApiSlice;
