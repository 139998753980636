import styled from "@emotion/styled";

const CSSPopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
  gap: 0.75rem;
  font-size: 14px;
  max-width: 270px;
`;
const CSSButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export { CSSPopoverContainer, CSSButtonContainer };
