import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { LoadingScreen, ErrorMessage } from "../../components/common";
import {
  DbaDataFetchSelect,
  DbaIconButton,
  DbaTable,
} from "../../DbaComponents";
import { DbaSnackbar } from "../../DbaComponents";
import styled from "@emotion/styled";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import { UserResponse } from "../../features/serviceSlices/Authentication/Types";
import {
  ENUM,
  FetchBaseQueryErrorType,
} from "../../features/serviceSlices/SharedTypes";
import { LockReset, PersonOff } from "@mui/icons-material";
import { ResetPassword } from "./ResetPassword";
import {
  useAssignRoleToUserMutation,
  useDeleteUserMutation,
  useLazyGetAllUsersQuery,
  useRevokeTokenMutation,
} from "../../features/serviceSlices/serviceHooks";
import { ColumnDef } from "@tanstack/react-table";
import { EditButton } from "../../components/TableHelpers/EditButton";
import { DeleteButton } from "../../components/TableHelpers/DeleteButton";

const CSSUsersContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const Users = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [deleteUser, deleteResponse] = useDeleteUserMutation();
  const [getUsers, users] = useLazyGetAllUsersQuery();
  const [assignRole, assignRoleResponse] = useAssignRoleToUserMutation();
  const [showDeletedUsers, setShowDeletedUsers] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const deleteHandler = useCallback(
    (obj: UserResponse) => deleteUser({ id: obj.id }),
    [deleteUser]
  );
  const [selectedResetUserId, setSelectedResetUserId] = useState<number | null>(
    null
  );
  const [revoke, revokeResponse] = useRevokeTokenMutation();

  const onAddButtonClick = useCallback(() => {
    navigate("/user");
  }, [navigate]);

  const changeShowDeletedUsersHandler = (event: React.BaseSyntheticEvent) =>
    setShowDeletedUsers(event.target.checked);

  useEffect(() => {
    getUsers(showDeletedUsers);
  }, [showDeletedUsers, getUsers]);

  const handleChangeRole = useCallback(
    (userId: number, newRole: ENUM) => {
      if (newRole && userId !== undefined) {
        assignRole({ id: userId, role: newRole.id });
      }
    },
    [assignRole]
  );

  const isFetchBaseQueryErrorType = (
    error: any
  ): error is FetchBaseQueryErrorType => "status" in error;

  useEffect(() => {
    if (assignRoleResponse.isError) {
      if (isFetchBaseQueryErrorType(assignRoleResponse.error)) {
        setError(true);
        setSnackbarMessage(assignRoleResponse.error.data.message);
      }
    }
  }, [assignRoleResponse]);

  useEffect(() => {
    if (deleteResponse.isError) {
      if (isFetchBaseQueryErrorType(deleteResponse.error)) {
        setError(true);
        setSnackbarMessage(deleteResponse.error.data.message);
      }
    }
  }, [deleteResponse]);

  const handleRevokeToken = useCallback(
    (id: number) => {
      if (id !== undefined) {
        revoke({ userId: id });
      }
    },
    [revoke]
  );

  useEffect(() => {
    if (revokeResponse.isError) {
      if (isFetchBaseQueryErrorType(revokeResponse.error)) {
        setError(true);
        setSnackbarMessage(
          revokeResponse.error.data?.message !== undefined
            ? revokeResponse.error.data?.message
            : (intl.messages["error"] as string)
        );
      }
    } else if (revokeResponse.isSuccess) {
      setSnackbarMessage("Токен отозван");
    }
  }, [intl.messages, revokeResponse]);

  const tableData = useMemo(() => users.data, [users.data]);

  const columns: ColumnDef<UserResponse>[] = useMemo(
    () => [
      {
        id: intl.messages["fullName"] as string,
        accessorFn: (row) => row.fullName,
        cell: (props) => {
          return (
            <Link
              underline="hover"
              component={RouterLink}
              to={{
                pathname: `/user/${props.row.original.id}`,
              }}
              sx={{ color: "inherit" }}
            >
              {props.row.original.fullName}
            </Link>
          );
        },
      },
      {
        id: intl.messages["userLogin"] as string,
        accessorFn: (row) => row.userName,
        cell: (props) => {
          return <span>{props.row.original.userName}</span>;
        },
      },
      {
        id: intl.messages["email"] as string,
        accessorFn: (row) => row.email,
        cell: (props) => {
          return <span>{props.row.original.email}</span>;
        },
      },
      {
        id: intl.messages["role"] as string,
        accessorFn: (row) => row.role,
        cell: (props) => {
          return (
            <DbaDataFetchSelect
              required
              url="api/Types/Get?Name=UserRole"
              optionLabel="name"
              selectedIndex={props.row.original.role.toString()}
              setSelectedValue={(value: any) =>
                handleChangeRole(props.row.original.id, value)
              }
              size="small"
              disableClearable
              labelIndex="id"
              keyIndex="name"
              width="180px"
              minWidth="100px"
            />
          );
        },
      },
      {
        id: intl.messages["revokeRefreshToken"] as string,
        cell: (props) => {
          return (
            <DbaIconButton
              size="small"
              onClick={() => handleRevokeToken(props.row.original.id)}
              icon={<PersonOff sx={{ cursor: "pointer" }} />}
            />
          );
        },
      },
      {
        id: intl.messages["resetPassword"] as string,
        cell: (props) => {
          return (
            <DbaIconButton
              size="small"
              onClick={() => setSelectedResetUserId(props.row.original.id)}
              icon={<LockReset sx={{ cursor: "pointer" }} />}
            />
          );
        },
      },
      {
        id: intl.messages["status"] as string,
        accessorFn: (row) => row.isDeleted,
        cell: (props) => {
          return props.row.original.isDeleted
            ? intl.messages["deleted"]
            : intl.messages["active"];
        },
      },
      {
        id: "edition",
        header: "",
        cell: (props) => <EditButton editLink="/user/" row={props.row} />,
      },
      {
        id: "deletion",
        header: "",
        cell: (props) => (
          <DeleteButton row={props.row} deleteDataHandler={deleteHandler} />
        ),
      },
    ],
    [deleteHandler, handleChangeRole, handleRevokeToken, intl.messages]
  );

  if (users.isError) {
    return <ErrorMessage />;
  }
  if (users.isLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      {tableData !== undefined ? (
        <CSSUsersContainer>
          {revokeResponse.isLoading ? (
            <LoadingScreen />
          ) : (
            <DbaTable
              id="users-form-table"
              columns={columns}
              data={tableData}
              headToolbar={{
                showDeletedSwitch: true,
                deletedSwitchValue: showDeletedUsers,
                onDeletedSwitchChange: changeShowDeletedUsersHandler,
                onAddButtonClick,
              }}
            />
          )}
          {selectedResetUserId !== null ? (
            <ResetPassword
              id={selectedResetUserId}
              setOpen={setSelectedResetUserId}
            />
          ) : (
            ""
          )}
        </CSSUsersContainer>
      ) : (
        <LoadingScreen />
      )}
      <DbaSnackbar
        error={
          (deleteResponse.isError ||
            assignRoleResponse.isError ||
            revokeResponse.isError) &&
          error
        }
        errorMessage={snackbarMessage as string}
        success={revokeResponse.isSuccess || deleteResponse.isSuccess}
        successMessage={snackbarMessage as string}
      />
    </>
  );
};
