import { useEffect } from "react";
import {
  diasRoleChange,
  periodChange,
} from "../../../features/slices/App/appSlice";
import { useAppSelector, useAppDispatch } from "../../../utils/reduxHooks";
import { useParams } from "react-router-dom";
import { useGetPublicDashboardQuery } from "../../../features/serviceSlices/serviceHooks";
import { DashboardResponse } from "../../../features/serviceSlices/Dashboards/Types";
import { skipToken } from "@reduxjs/toolkit/query";
import { TUseModeReturn } from "../Types";

type TUsePublicMode = {
  skip: boolean;
};

export const usePublicMode = (options: TUsePublicMode): TUseModeReturn => {
  const dispatch = useAppDispatch();
  const params = useParams<Record<string, string | undefined>>();

  const dashboard = useGetPublicDashboardQuery(
    options.skip ? skipToken : params.dashboardID!
  );

  const { role, roles } = useAppSelector((state) => state.app);

  useEffect(() => {
    if (!options.skip) {
      let selectedDashboard = roles.find(
        (role) => role.id === params.dashboardID
      )!;
      if (selectedDashboard) {
        dispatch(diasRoleChange(selectedDashboard as DashboardResponse));
        dispatch(periodChange(role.defaultPeriod ?? "NotSet"));
      }
    }
    // roles изменяется при получении дашборда из useGetPublicDashboardQuery
  }, [dispatch, options.skip, params.dashboardID, role.defaultPeriod, roles]);

  return {
    isLoading: !options.skip && dashboard.isLoading,
    isSuccess: !options.skip && dashboard.isSuccess,
    error: !options.skip && dashboard.error,
  };
};
