import { messagesInRussian } from "../../../../../../utils/locale";
import { ColorSchemeOption, ColorSchemeType } from "./ColorSchemeOptionTypes";

export const getColorSchemeOptions = (
  includeCategorical: boolean = false,
  includeDiverging: boolean = false,
  includeSequential: boolean = false
) => {
  let requiredTypes: ColorSchemeType[] = [];

  if (includeCategorical) requiredTypes.push("CATEGORICAL");
  if (includeDiverging) requiredTypes.push("DIVERGING");
  if (includeSequential) requiredTypes.push("SEQUENTIAL");

  return colorSchemeOptions.filter((value) =>
    requiredTypes.includes(value.type)
  );
};

const colorSchemeOptions: ColorSchemeOption[] = [
  // CATEGORICAL COLORS
  {
    value: "nivo",
    type: "CATEGORICAL",
    label: messagesInRussian.nivoColorScheme,
    colors: ["#e8c1a0", "#f47560", "#f1e15b", "#e8a838", "#61cdbb", "#97e3d5"],
  },
  {
    value: "category10",
    type: "CATEGORICAL",
    label: messagesInRussian.category10,
    colors: [
      "#1f77b4",
      "#ff7f0e",
      "#2ca02c",
      "#d62728",
      "#9467bd",
      "#8c564b",
      "#e377c2",
      "#7f7f7f",
      "#bcbd22",
      "#17becf",
    ],
  },
  {
    value: "accent",
    type: "CATEGORICAL",
    label: messagesInRussian.accent,
    colors: [
      "#7fc97f",
      "#beaed4",
      "#fdc086",
      "#ffff99",
      "#386cb0",
      "#f0027f",
      "#bf5b17",
      "#666666",
    ],
  },
  {
    value: "dark2",
    type: "CATEGORICAL",
    label: messagesInRussian.dark2,
    colors: [
      "#1b9e77",
      "#d95f02",
      "#7570b3",
      "#e7298a",
      "#66a61e",
      "#e6ab02",
      "#a6761d",
      "#666666",
    ],
  },
  {
    value: "paired",
    type: "CATEGORICAL",
    label: messagesInRussian.paired,
    colors: [
      "#a6cee3",
      "#1f78b4",
      "#b2df8a",
      "#33a02c",
      "#fb9a99",
      "#e31a1c",
      "#fdbf6f",
      "#ff7f00",
      "#cab2d6",
      "#6a3d9a",
      "#ffff99",
      "#b15928",
    ],
  },
  {
    value: "pastel1",
    type: "CATEGORICAL",
    label: messagesInRussian.pastel1,
    colors: [
      "#fbb4ae",
      "#b3cde3",
      "#ccebc5",
      "#decbe4",
      "#fed9a6",
      "#ffffcc",
      "#e5d8bd",
      "#fddaec",
      "#f2f2f2",
    ],
  },
  {
    value: "pastel2",
    type: "CATEGORICAL",
    label: messagesInRussian.pastel2,
    colors: [
      "#b3e2cd",
      "#fdcdac",
      "#cbd5e8",
      "#f4cae4",
      "#e6f5c9",
      "#fff2ae",
      "#f1e2cc",
      "#cccccc",
    ],
  },
  {
    value: "set1",
    type: "CATEGORICAL",
    label: messagesInRussian.set1,
    colors: [
      "#e41a1c",
      "#377eb8",
      "#4daf4a",
      "#984ea3",
      "#ff7f00",
      "#ffff33",
      "#a65628",
      "#f781bf",
      "#999999",
    ],
  },
  {
    value: "set2",
    type: "CATEGORICAL",
    label: messagesInRussian.set2,
    colors: [
      "#66c2a5",
      "#fc8d62",
      "#8da0cb",
      "#e78ac3",
      "#a6d854",
      "#ffd92f",
      "#e5c494",
      "#b3b3b3",
    ],
  },
  {
    value: "set3",
    type: "CATEGORICAL",
    label: messagesInRussian.set3,
    colors: [
      "#8dd3c7",
      "#ffffb3",
      "#bebada",
      "#fb8072",
      "#80b1d3",
      "#fdb462",
      "#b3de69",
      "#fccde5",
      "#d9d9d9",
      "#bc80bd",
      "#ccebc5",
      "#ffed6f",
    ],
  },

  //DIVERGING COLORS
  {
    value: "brown_blueGreen",
    type: "DIVERGING",
    label: messagesInRussian.brown_blueGreen,
    colors: [
      "#543005",
      "#8C510A",
      "#BF812D",
      "#DFC27D",
      "#F6E8C3",
      "#F5F5F5",
      "#C7EAE5",
      "#80CDC1",
      "#35978F",
      "#01665E",
      "#003C30",
    ],
  },
  {
    value: "purpleRed_green",
    type: "DIVERGING",
    label: messagesInRussian.purpleRed_green,
    colors: [
      "#40004B",
      "#762A83",
      "#9970AB",
      "#C2A5CF",
      "#E7D4E8",
      "#F7F7F7",
      "#D9F0D3",
      "#A6DBA0",
      "#5AAE61",
      "#1B7837",
      "#00441B",
    ],
  },
  {
    value: "pink_yellowGreen",
    type: "DIVERGING",
    label: messagesInRussian.pink_yellowGreen,
    colors: [
      "#8E0152",
      "#C51B7D",
      "#DE77AE",
      "#F1B6DA",
      "#FDE0EF",
      "#F7F7F7",
      "#E6F5D0",
      "#B8E186",
      "#7FBC41",
      "#4D9221",
      "#276419",
    ],
  },
  {
    value: "purple_orange",
    type: "DIVERGING",
    label: messagesInRussian.purple_orange,
    colors: [
      "#2D004B",
      "#542788",
      "#8073AC",
      "#B2ABD2",
      "#D8DAEB",
      "#F7F7F7",
      "#FEE0B6",
      "#FDB863",
      "#E08214",
      "#B35806",
      "#7F3B08",
    ],
  },
  {
    value: "red_blue",
    type: "DIVERGING",
    label: messagesInRussian.red_blue,
    colors: [
      "#67001F",
      "#B2182B",
      "#D6604D",
      "#F4A582",
      "#FDDBC7",
      "#F7F7F7",
      "#D1E5F0",
      "#92C5DE",
      "#4393C3",
      "#2166AC",
      "#053061",
    ],
  },
  {
    value: "red_grey",
    type: "DIVERGING",
    label: messagesInRussian.red_grey,
    colors: [
      "#67001F",
      "#B2182B",
      "#D6604D",
      "#F4A582",
      "#FDDBC7",
      "#F7F7F7",
      "#E0E0E0",
      "#BABABA",
      "#878787",
      "#4D4D4D",
      "#1A1A1A",
    ],
  },
  {
    value: "red_yellow_blue",
    type: "DIVERGING",
    label: messagesInRussian.red_yellow_blue,
    colors: [
      "#A50026",
      "#D73027",
      "#F46D43",
      "#FDAE61",
      "#FEE090",
      "#FFFFBF",
      "#E0F3F8",
      "#ABD9E9",
      "#74ADD1",
      "#4575B4",
      "#313695",
    ],
  },
  {
    value: "red_yellow_green",
    type: "DIVERGING",
    label: messagesInRussian.red_yellow_green,
    colors: [
      "#A50026",
      "#D73027",
      "#F46D43",
      "#FDAE61",
      "#FEE090",
      "#FFFFBF",
      "#D9EF8B",
      "#A6D96A",
      "#66BD63",
      "#1A9850",
      "#006837",
    ],
  },
  {
    value: "spectral",
    type: "DIVERGING",
    label: messagesInRussian.spectral,
    colors: [
      "#A50026",
      "#D73027",
      "#F46D43",
      "#FDAE61",
      "#FEE090",
      "#FFFFBF",
      "#E6F598",
      "#ABDDA4",
      "#66C2A5",
      "#3288BD",
      "#5E4FA2",
    ],
  },

  //SEQUENTIAL COLORS
  {
    value: "blues",
    type: "SEQUENTIAL",
    label: messagesInRussian.blues,
    colors: [
      "#E3EEF9",
      "#CFE1F2",
      "#93C3DF",
      "#6DAED5",
      "#4B97C9",
      "#1864AA",
      "#0A4A90",
      "#08306B",
    ],
  },
  {
    value: "greens",
    type: "SEQUENTIAL",
    label: messagesInRussian.greens,
    colors: [
      "#E5F5E0",
      "#C7E9C0",
      "#A1D99B",
      "#74C476",
      "#41AB5D",
      "#238B45",
      "#006D2C",
      "#00441B",
    ],
  },
  {
    value: "greys",
    type: "SEQUENTIAL",
    label: messagesInRussian.greys,
    colors: [
      "#f2f2f2",
      "#e2e2e2",
      "#b4b4b4",
      "#979797",
      "#7a7a7a",
      "#404040",
      "#1e1e1e",
      "#000000",
    ],
  },
  {
    value: "oranges",
    type: "SEQUENTIAL",
    label: messagesInRussian.oranges,
    colors: [
      "#fee6ce",
      "#fdd0a2",
      "#fdae6b",
      "#fd8d3c",
      "#f16913",
      "#d94801",
      "#a63603",
      "#7f2704",
    ],
  },
  {
    value: "purples",
    type: "SEQUENTIAL",
    label: messagesInRussian.purples,
    colors: [
      "#efedf5",
      "#dadaeb",
      "#bcbddc",
      "#9e9ac8",
      "#807dba",
      "#6a51a3",
      "#54278f",
      "#3f007d",
    ],
  },
  {
    value: "reds",
    type: "SEQUENTIAL",
    label: messagesInRussian.reds,
    colors: [
      "#fee0d2",
      "#fcbba1",
      "#fc9272",
      "#fb6a4a",
      "#ef3b2c",
      "#cb181d",
      "#a50f15",
      "#67000d",
    ],
  },
  {
    value: "blue_green",
    type: "SEQUENTIAL",
    label: messagesInRussian.blue_green,
    colors: [
      "#F7FCFD",
      "#E5F5F9",
      "#CCECE6",
      "#99D8C9",
      "#66C2A4",
      "#41AE76",
      "#006D2C",
      "#00441B",
    ],
  },
  {
    value: "blue_purple",
    type: "SEQUENTIAL",
    label: messagesInRussian.blue_purple,
    colors: [
      "#F7FCFD",
      "#E0ECF4",
      "#BFD3E6",
      "#9EBCDA",
      "#8C96C6",
      "#8C6BB1",
      "#88419D",
      "#810F7C",
    ],
  },
  {
    value: "green_blue",
    type: "SEQUENTIAL",
    label: messagesInRussian.green_blue,
    colors: [
      "#F7FCF0",
      "#E0F3DB",
      "#CCEBC5",
      "#A8DDB5",
      "#7BCCC4",
      "#4EB3D3",
      "#2B8CBE",
      "#0868AC",
    ],
  },
  {
    value: "orange_red",
    type: "SEQUENTIAL",
    label: messagesInRussian.orange_red,
    colors: [
      "#FFF7EC",
      "#FEE8C8",
      "#FDD49E",
      "#FDBB84",
      "#FC8D59",
      "#EF6548",
      "#D7301F",
      "#B30000",
    ],
  },
  {
    value: "purple_blue_green",
    type: "SEQUENTIAL",
    label: messagesInRussian.purple_blue_green,
    colors: [
      "#FFF7FB",
      "#ECE2F0",
      "#D0D1E6",
      "#A6BDDB",
      "#67A9CF",
      "#3690C0",
      "#02818A",
      "#016C59",
    ],
  },
  {
    value: "purple_blue",
    type: "SEQUENTIAL",
    label: messagesInRussian.purple_blue,
    colors: [
      "#FFF7FB",
      "#ECE2F0",
      "#D0D1E6",
      "#A6BDDB",
      "#67A9CF",
      "#3690C0",
      "#0570B0",
      "#045A8D",
    ],
  },
  {
    value: "purple_red",
    type: "SEQUENTIAL",
    label: messagesInRussian.purple_red,
    colors: [
      "#F7F4F9",
      "#E7E1EF",
      "#D4B9DA",
      "#C994C7",
      "#DF65B0",
      "#E7298A",
      "#CE1256",
      "#980043",
    ],
  },
  {
    value: "red_purple",
    type: "SEQUENTIAL",
    label: messagesInRussian.red_purple,
    colors: [
      "#FFF7F3",
      "#FDE0DD",
      "#FCC5C0",
      "#FA9FB5",
      "#F768A1",
      "#DD3497",
      "#AE017E",
      "#7A0177",
    ],
  },
  {
    value: "yellow_green_blue",
    type: "SEQUENTIAL",
    label: messagesInRussian.yellow_green_blue,
    colors: [
      "#FFFFD9",
      "#EDF8B1",
      "#C7E9B4",
      "#7FCDBB",
      "#41B6C4",
      "#1D91C0",
      "#225EA8",
      "#253494",
    ],
  },
  {
    value: "yellow_green",
    type: "SEQUENTIAL",
    label: messagesInRussian.yellow_green,
    colors: [
      "#FFFFE5",
      "#F7FCB9",
      "#D9F0A3",
      "#ADDD8E",
      "#78C679",
      "#41AB5D",
      "#238443",
      "#006837",
    ],
  },
  {
    value: "yellow_orange_brown",
    type: "SEQUENTIAL",
    label: messagesInRussian.yellow_orange_brown,
    colors: [
      "#FFFFE5",
      "#FFF7BC",
      "#FEE391",
      "#FEC44F",
      "#FE9929",
      "#EC7014",
      "#CC4C02",
      "#993404",
    ],
  },
  {
    value: "yellow_orange_red",
    type: "SEQUENTIAL",
    label: messagesInRussian.yellow_orange_red,
    colors: [
      "#FFFFCC",
      "#FFEDA0",
      "#FED976",
      "#FEB24C",
      "#FD8D3C",
      "#FC4E2A",
      "#E31A1C",
      "#BD0026",
    ],
  },
];
