import { ResponsiveRadialBar } from "@nivo/radial-bar";
import { GetTheme } from "../Theme";
import { useRef } from "react";
import { DbaPortalWrapper } from "../../../../DbaComponents/DbaPortalWrapper";
import { DbaDiagramTooltip } from "./DbaDiagramTooltip";

export const DbaRadialBarChart = ({ data, settings }) => {
  const tooltipContainerRef = useRef(null);
  return (
    <ResponsiveRadialBar
      data={data}
      maxValue={settings.maxValue}
      valueFormat={settings.valueFormat}
      label={
        settings.label === "formattedValue"
          ? (d) => d.formattedValue.replaceAll(",", " ")
          : settings.label
      }
      margin={settings.margin}
      startAngle={settings.startAngle}
      endAngle={settings.endAngle}
      innerRadius={settings.innerRadius}
      padding={settings.padding}
      padAngle={settings.padAngle}
      cornerRadius={settings.cornerRadius}
      colors={settings.colors}
      borderWidth={settings.borderWidth}
      borderColor={settings.borderColor}
      enableTracks={settings.enableTracks}
      tooltip={(props) => {
        return (
          <div ref={tooltipContainerRef}>
            <DbaPortalWrapper
              left={tooltipContainerRef.current?.getBoundingClientRect().left}
              top={tooltipContainerRef.current?.getBoundingClientRect().top}
            >
              <DbaDiagramTooltip
                markerColor={props.bar.color}
                label={props.bar.category}
                value={props.bar.formattedValue}
              />
            </DbaPortalWrapper>
          </div>
        );
      }}
      tracksColor={settings.tracksColor}
      enableRadialGrid={settings.enableRadialGrid}
      enableCircularGrid={settings.enableCircularGrid}
      radialAxisStart={settings.radialAxisStart}
      radialAxisEnd={settings.radialAxisEnd}
      circularAxisInner={settings.circularAxisInner}
      circularAxisOuter={settings.circularAxisOuter}
      enableLabels={settings.enableLabels}
      labelsSkipAngle={settings.labelsSkipAngle}
      labelsRadiusOffset={settings.labelsRadiusOffset}
      labelsTextColor={settings.labelsTextColor}
      legends={settings.legends}
      isInteractive={settings.isInteractive}
      animate={settings.animate}
      motionConfig={settings.motionConfig}
      theme={GetTheme(settings)}
    />
  );
};
