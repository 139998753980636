import styled from "@emotion/styled";

const CSSMapObjectsContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const CSSErrorMessagesContainer = styled.div`
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const CSSFieldsContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

const CSSInputBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const CSSLabel = styled.div`
  font-size: 1.25rem;
`;

const CSSSelectReferenceContainer = styled.div`
  display: flex;
  gap: 8px;
  div {
    width: 100%;
  }
`;

const CSSColorInput = styled.div`
  margin-bottom: 10px;
`;

const CSSSliderContainer = styled.div<{ widthClamp: string }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  min-width: 280px;

  & > p {
    width: ${({ widthClamp }) => `clamp(${widthClamp})`};
  }
`;

const CSSSliderInput = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  margin-right: 10px;
`;

const CSSSlider = styled.div`
  flex: 1;
  min-width: 200px;
  margin-right: 10px;
`;

export {
  CSSMapObjectsContainer,
  CSSErrorMessagesContainer,
  CSSFieldsContainer,
  CSSLabel,
  CSSInputBlock,
  CSSSelectReferenceContainer,
  CSSColorInput,
  CSSSliderContainer,
  CSSSlider,
  CSSSliderInput,
};
