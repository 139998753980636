import { useState, useEffect } from "react";
import { ResponsiveLine } from "@nivo/line";
import { GetTheme } from "../Theme";
import styled from "@emotion/styled";
import { clampBuilder } from "../../../../utils/helpers/functions";
import colors from "../../../../Variables.module.scss";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getLegendOrientation } from "./utils";
import { DbaPortalWrapper } from "../../../../DbaComponents/DbaPortalWrapper";
import { useRef } from "react";

const CSSTooltipContainer = styled.div`
  background: ${({ theme }) =>
    theme.palette.mode === "dark" ? colors.purple900 : colors.white};
  display: flex;
  width: max-content;
  max-width: 400px;
  flex-direction: column;
  gap: 2px;
  padding: 8px;
  border-radius: 4px;
`;

const CSSTooltipTitle = styled.span`
  display: flex;
  gap: 8px;
  align-items: center;
`;
const CSSTooltipLegend = styled.span`
  min-width: 16px;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: ${(props) => props.color};
`;

const CSSTooltipData = styled.span`
  padding-left: 24px;
`;

const CSSTooltipDataContainer = styled.span`
  display: flex;
  flex-direction: column;
`;

export const DbaLineChart = ({ settings, data, widgetSettings }) => {
  const thresholds = widgetSettings.widget?.thresholds;
  const thresholdSettings = settings.thresholdSettings;
  const mobileView = useMediaQuery("(max-width:1000px)");
  const clampBuilderFunction = mobileView
    ? clampBuilder(1, 1000, thresholdSettings?.fontSize)
    : clampBuilder(1001, 1920, thresholdSettings?.fontSize);

  // Монтирование и размонтирование компонента при изменении данных,
  // необходимо, т.к без этого диаграмма может обновиться неправильно.
  // При частом обновлении данных точки могут не совпасть с отрезками.
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(false);
    setTimeout(() => setIsMounted(true), 0);
  }, [data]);

  const tooltipContainerRef = useRef(null);

  if (!isMounted) {
    return null;
  }

  return (
    <ResponsiveLine
      data={data}
      xScale={settings.xScale}
      xFormat={settings.xFormat}
      yScale={settings.yScale}
      yFormat={settings.yFormat}
      margin={settings.margin}
      curve={settings.curve}
      colors={settings.colors}
      lineWidth={settings.lineWidth}
      enableArea={settings.enableArea}
      areaBaselineValue={settings.areaBaselineValue}
      areaOpacity={settings.areaOpacity}
      areaBlendMode={settings.areaBlendMode}
      defs={settings.defs}
      fill={settings.fill}
      enablePoints={settings.enablePoints}
      pointSize={settings.pointSize}
      pointColor={settings.pointColor}
      pointBorderWidth={settings.pointBorderWidth}
      pointBorderColor={settings.pointBorderColor}
      enablePointLabel={settings.enablePointLabel}
      pointLabel={settings.pointLabel}
      pointLabelYOffset={settings.pointLabelYOffset}
      enableGridX={settings.enableGridX}
      enableGridY={settings.enableGridY}
      axisTop={settings.axisTop}
      axisRight={settings.axisRight}
      axisBottom={settings.axisBottom}
      axisLeft={settings.axisLeft}
      isInteractive={settings.isInteractive}
      useMesh={settings.useMesh}
      enableSlices={settings.enableSlices}
      enableCrosshair={settings.enableCrosshair}
      crosshairType={settings.crosshairType}
      legends={settings.legends}
      theme={GetTheme(settings)}
      animate={settings.animate}
      tooltip={(a) => {
        return (
          <div ref={tooltipContainerRef}>
            <DbaPortalWrapper
              left={tooltipContainerRef.current?.getBoundingClientRect().left}
              top={tooltipContainerRef.current?.getBoundingClientRect().top}
            >
              <CSSTooltipContainer>
                <CSSTooltipTitle>
                  <CSSTooltipLegend
                    color={a.point.serieColor}
                  ></CSSTooltipLegend>
                  <span>{a.point.serieId}: </span>
                </CSSTooltipTitle>
                <CSSTooltipDataContainer>
                  <CSSTooltipData>
                    {settings.axisBottom.legend}: {a.point.data.xFormatted}
                  </CSSTooltipData>
                  <CSSTooltipData>
                    {settings.axisLeft.legend}: {a.point.data.yFormatted}
                  </CSSTooltipData>
                </CSSTooltipDataContainer>
              </CSSTooltipContainer>
            </DbaPortalWrapper>
          </div>
        );
      }}
      markers={
        thresholds && settings.showThresholds
          ? thresholds.map((item) => ({
              axis: thresholdSettings?.axe ?? "y",
              value: item.value,
              legend: item.value,
              legendOrientation: getLegendOrientation(thresholdSettings?.axe),
              textStyle: {
                fill: item.color,
                fontSize: thresholdSettings?.fontSize
                  ? clampBuilderFunction
                  : 16,
              },
              lineStyle: {
                stroke: item.color,
                strokeWidth: settings?.thresholdSettings?.width ?? 2,
                strokeDasharray: Boolean(thresholdSettings?.dashedLine)
                  ? 5
                  : null,
              },
            }))
          : null
      }
    />
  );
};
