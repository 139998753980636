import styled from "@emotion/styled";

export const CSSDataSetFields = styled.div`
  margin-bottom: 20px;
`;

export const CSSDateRangePicker = styled.div`
  margin-bottom: 30px;
`;

export const CSSButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;
