const POSITION_CLASSES = {
  bottomLeft: "leaflet-bottom leaflet-left",
  bottomRight: "leaflet-bottom leaflet-right",
  topLeft: "leaflet-top leaflet-left",
  topRight: "leaflet-top leaflet-right",
};

type MapControlType = {
  children: React.ReactNode;
  position: "bottomLeft" | "bottomRight" | "topLeft" | "topRight";
};

export const MapControl = ({ position, children }: MapControlType) => {
  return (
    <div className={POSITION_CLASSES[position]}>
      <div className="leaflet-control">{children}</div>
    </div>
  );
};
