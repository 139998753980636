import React, { useEffect, useState } from "react";
import { TBooleanFilter } from "../../../../../Types";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { notSelectedConditionType, booleanFilterOptions } from "./constants";
import { useIntl } from "react-intl";
import { DbaButton } from "../../../../../../DbaButton";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";

export const notSelectedValue = "notSelected";
const options = [
  {
    label: "notSelected",
    value: notSelectedValue,
  },
  {
    label: "yes",
    value: "yes",
  },
  {
    label: "no",
    value: "no",
  },
];

export const BooleanFilter: React.FC<TBooleanFilter> = ({
  filter,
  onChange,
}) => {
  const intl = useIntl();

  const [filterData, setFilterData] = useState<TBooleanFilter["filter"]>();

  const onFilterConditionChange = (event: SelectChangeEvent<any>) => {
    setFilterData({
      ...filterData!,
      type:
        event.target.value !== notSelectedConditionType
          ? event.target.value
          : undefined,
      value: undefined,
    });
  };

  const onFilterValueChange = (event: SelectChangeEvent<any>) => {
    setFilterData({
      ...filterData!,
      value: event.target.value,
    });
  };

  const onApplyClick = () => {
    onChange(filterData!);
  };

  const onResetClick = (event: React.BaseSyntheticEvent) => {
    event.preventDefault();
    setFilterData({
      ...filterData!,
      type: undefined,
      value: undefined,
    });
  };

  useEffect(() => {
    setFilterData(filter);
  }, [filter]);

  const isApplyBtnDisabled = !!(
    filterData?.type &&
    (!filterData?.value || filterData?.value === notSelectedValue)
  );

  return (
    <Stack spacing={2}>
      <FormControl>
        <InputLabel>{intl.messages["filterByCondition"]}</InputLabel>
        <Select
          value={filterData?.type ?? notSelectedConditionType}
          label={intl.messages["filterByCondition"]}
          onChange={onFilterConditionChange}
        >
          {booleanFilterOptions.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {intl.messages[item.label]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel>{intl.messages["value"]}</InputLabel>
        <Select
          value={filterData?.value ?? notSelectedConditionType}
          label={intl.messages["value"]}
          onChange={onFilterValueChange}
          disabled={!filterData?.type}
        >
          {options.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {intl.messages[item.label]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Link
          underline="hover"
          component={RouterLink}
          to={{
            pathname: `/`,
          }}
          sx={{ color: "inherit" }}
          onClick={onResetClick}
        >
          {intl.messages["resetInfinitive"]}
        </Link>
        <DbaButton
          color="success"
          text="apply"
          onClick={onApplyClick}
          disabled={isApplyBtnDisabled}
        />
      </Stack>
    </Stack>
  );
};
