import React from "react";
import { TTableBody, TData } from "../../Types";
import { CSSTableCell, CSSTableBodyRow } from "../../DbaTable.styles";
import { flexRender } from "@tanstack/react-table";

export const TableBody: React.FC<TTableBody> = ({
  table,
  darkMode = false,
  backgroundColorDarkThemeOverride,
  enableSelectionMode,
  onRowClick,
  cellStyles,
  showPagination,
}) => {
  const onRowClickHandler =
    (row: { original: TData; [key: string]: any }) =>
    (event: React.BaseSyntheticEvent) => {
      if (onRowClick) {
        onRowClick(row.original);
      }
    };

  return (
    <tbody>
      {table.getRowModel().rows.map((row) => (
        <CSSTableBodyRow
          key={row.id}
          style={enableSelectionMode ? { cursor: "pointer" } : {}}
          onClick={onRowClickHandler(row)}
        >
          {row.getVisibleCells().map((cell) => (
            <CSSTableCell
              key={cell.id}
              darkMode={darkMode}
              backgroundColorDarkThemeOverride={
                backgroundColorDarkThemeOverride
              }
              style={cellStyles}
              showPagination={showPagination}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </CSSTableCell>
          ))}
        </CSSTableBodyRow>
      ))}
    </tbody>
  );
};
