import React from "react";
import { CSSString } from "../../../../../utils/helpers/functions";

type TCreateMarkerParams = {
  marker?: string;
  color: string;
  size: number;
  type?: "HTML" | "JSX";
};

type TCreateMarker = (params: TCreateMarkerParams) => React.ReactNode | string;

type TCreateMarkerHTML = (
  params: Omit<TCreateMarkerParams, "type">
) => React.ReactNode | string;
type TCreateMarkerJSX = TCreateMarkerHTML;

type TCreateSpecificMarker = (params: { color: string; size: number }) => {
  styles: string;
  after?: string;
};

type TCreateSpecificMarkerTool = Record<string, TCreateSpecificMarker>;

const createMarker: TCreateMarker = ({
  marker = "default",
  color,
  size,
  type = "HTML",
}) => {
  const generatedStyles = createSpecificMarkerTool[marker]({ color, size });
  let styles = generatedStyles.styles;
  let afterStyles = generatedStyles.after ? generatedStyles.after : "";

  // styles - пробелы, даже в значениях свойств могут привести к проблеме
  // при которой стили могут неверно парсится лифлетом (для лифлета HTML).
  // При возникновении проблем со стилем маркера стоит обратить на них внимание.
  return type === "HTML" ? (
    `<div class="leaflet-custom-marker ${marker}" style=${pureString(
      styles
    )}><div style=${pureString(afterStyles)}></div></div>`
  ) : (
    <div
      className={`leaflet-custom-marker ${marker}`}
      style={CSSString(styles)}
    >
      <div style={afterStyles ? CSSString(afterStyles) : {}} />
    </div>
  );
};

export const createMarkerHTML: TCreateMarkerHTML = ({
  marker = "default",
  color,
  size,
}) => {
  return createMarker({ marker, color, size, type: "HTML" });
};

export const createMarkerJSX: TCreateMarkerJSX = ({
  marker = "default",
  color,
  size,
}) => {
  return createMarker({ marker, color, size, type: "JSX" });
};

const pureString = (string: string): string =>
  string.replaceAll(" ", "").replaceAll("\n", "");

const createSpecificMarkerTool: TCreateSpecificMarkerTool = {
  // Пробелы в значениях свойств могут привести к тому что стили неверно парсятся лифлетом.
  // При возникновении проблем со стилем маркера стоит обратить на них внимание.
  pin1: ({ color, size }) => ({
    styles: `
      position: relative;
      left: -1px;
      top: -4px;
      border-radius: 50%;
      border-width: 7px;
      border-style: solid;
      border-color: ${color};
      width: 22px;
      height: 22px;
      transform: scale(${size});
      -webkit-transform-origin-y: 153%;
    `,
    after: `
      position: absolute;
      width: 0px;
      height: 0px;
      bottom: -${size < 1.4 ? 28.7 : 28.2}px;
      left: -6px;
      border-width: 10px;
      border-style: solid;
      border-color: transparent;
      border-top-width: 17.55px;
      border-top-style: solid;
      border-top-color: ${color};
    `,
  }),

  pin2: ({ color, size }) => {
    // Более точное позиционирование маркера взависимости от размера
    // Так как верстка маркера завязана на rotate, приходится делать так
    const markUpTable = new Map();
    markUpTable.set(0.8, 2);
    markUpTable.set(0.7, 4);
    markUpTable.set(0.6, 8);
    markUpTable.set(0.5, 12);
    markUpTable.set(0.4, 18);
    markUpTable.set(0.3, 27);
    markUpTable.set(1.1, 2);
    markUpTable.set(1.2, 2);
    markUpTable.set(1.3, 3);

    let translateSizeX = 0;
    let translateSizeY = 0;
    if (size <= 0.8) {
      translateSizeX = -markUpTable.get(size);
      translateSizeY = markUpTable.get(size);
    } else if (size >= 1.1 && size <= 1.3) {
      translateSizeX = markUpTable.get(size);
      translateSizeY = -markUpTable.get(size);
    } else if (size >= 1.4 && size <= 1.6) {
      translateSizeX = size + 3;
      translateSizeY = -(size + 3);
    } else if (size >= 1.7 && size <= 2.5) {
      translateSizeX = size * 1.5 + 4;
      translateSizeY = -(size * 1.5 + 4);
    } else if (size >= 2.6) {
      translateSizeX = size + 6;
      translateSizeY = -(size + 6);
    }

    return {
      styles: `
      position: relative;
      left: -3px;
      top: -1px;
      border-top-left-radius: 50%;
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
      bottom-bottom-left-radius: 0;
      border-width: 4px;
      border-style: solid;
      border-color: ${color};
      width: 26px;
      height: 26px;
      transform: rotate(-45deg)scale(${size})translate(${translateSizeX}px, ${translateSizeY}px);
    `,
      after: `
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      margin-left: -5px;
      margin-top: -5px;
      background-color: ${color};
    `,
    };
  },

  square: ({ color, size }) => ({
    styles: `
      width: 20px;
      height: 20px;
      background: ${color};
      transform: scale(${size});
    `,
  }),

  circle: ({ color, size }) => ({
    styles: `
      width: 20px;
      height: 20px;
      background: ${color};
      border-radius: 50%;
      transform: scale(${size});
    `,
  }),

  triangleUp: ({ color, size }) => ({
    styles: `
      width: 0;
      height: 0;
      border-left-width: 10px;
      border-left-style: solid;
      border-left-color: transparent;
      border-right-width: 10px;
      border-right-style: solid;
      border-right-color: transparent;
      border-bottom-width: 20px;
      border-bottom-style: solid;
      border-bottom-color: ${color};
      transform: scale(${size});
    `,
  }),

  triangleDown: ({ color, size }) => ({
    styles: `
      width: 0;
      height: 0;
      border-left-width: 10px;
      border-left-style: solid;
      border-left-color: transparent;
      border-right-width: 10px;
      border-right-style: solid;
      border-right-color: transparent;
      border-top-width: 20px;
      border-top-style: solid;
      border-top-color: ${color};
      transform: scale(${size});
    `,
  }),

  rhombus: ({ color, size }) => ({
    styles: `
      width: 0;
      height: 0;
      border-width: 10px;
      border-style: solid;
      border-color: transparent;
      border-bottom-width: 14px;
      border-bottom-color: ${color};
      position: relative;
      top: -10px;
      -webkit-transform-origin-y: 100%;
      transform: scale(${size});
    `,
    after: `
      position: absolute;
      left: -10px;
      top: 14px;
      width: 0;
      height: 0;
      border-width: 10px;
      border-style: solid;
      border-color: transparent;
      border-top-width: 14px;
      border-top-style: solid;
      border-top-color: ${color};
    `,
  }),

  cross: ({ color, size }) => ({
    styles: `
      background: ${color};
      height: 20px;
      position: relative;
      width: 4px;
      left: 8px;
      transform: scale(${size});
    `,
    after: `
      background: ${color};
      height: 4px;
      left: -8px;
      position: absolute;
      top: 8px;
      width: 20px;
    `,
  }),

  default: ({ color, size }) => ({
    styles: `
      background-color: ${color};
      width: 100%;
      height: 100%;
      transform: scale(${size});
    `,
  }),
};
