import { ChartSettingsPropsWithMapping } from "../Types";
import { DbaSlider, DbaSimpleSelect } from "../../../../../../../DbaComponents";
import { ValueFormatSettings, WidgetSettingContainer } from "../../common";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Switch from "@mui/material/Switch";
import { useIntl } from "react-intl";
import styled from "@emotion/styled";

const CSSHintContainer = styled.div`
  width: 100%;
  font-size: 14px;
`;

export const PieChartBaseSettings = ({
  settings,
  setSettings,
  dataMappingOptions,
}: ChartSettingsPropsWithMapping) => {
  const intl = useIntl();

  return (
    <>
      <WidgetSettingContainer title="idWidgetSettings">
        <DbaSimpleSelect
          options={dataMappingOptions}
          selectedValue={settings.diagram.indexBy ?? "x"}
          setSelectedValue={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                indexBy: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="keyWidgetSettings">
        <DbaSimpleSelect
          options={dataMappingOptions}
          selectedValue={settings.diagram.value}
          setSelectedValue={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="displayedValues">
        <ToggleButtonGroup
          color="primary"
          value={settings.diagram?.displayedValues ?? "absolute"}
          exclusive
          onChange={(
            event: React.MouseEvent<HTMLElement>,
            newAlignment: string
          ) => {
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                displayedValues: newAlignment,
              },
            }));
          }}
        >
          <ToggleButton value="absolute">
            {intl.messages["absolute"]}
          </ToggleButton>
          <ToggleButton value="percentage">
            {intl.messages["percentage"]}
          </ToggleButton>
          <ToggleButton value="percentageabsolute">
            {intl.messages["percentageabsolute"]}
          </ToggleButton>
        </ToggleButtonGroup>
      </WidgetSettingContainer>

      <WidgetSettingContainer title="displayOverallValue">
        <ToggleButtonGroup
          color="primary"
          value={settings.diagram.displayOverallValue ?? false}
          exclusive
          onChange={(event: React.MouseEvent<HTMLElement>, value: Boolean) => {
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                displayOverallValue: value,
              },
            }));
          }}
        >
          <ToggleButton value={true}>{intl.messages["yes"]}</ToggleButton>
          <ToggleButton value={false}>{intl.messages["no"]}</ToggleButton>
        </ToggleButtonGroup>
      </WidgetSettingContainer>

      <ValueFormatSettings
        setSettings={setSettings}
        settings={settings}
        property="valueFormat"
      />

      <WidgetSettingContainer title="startAngle">
        <DbaSlider
          sx={{ width: 170 }}
          value={settings.diagram.startAngle ?? 0}
          min={-180}
          max={360}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                startAngle: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="endAngle">
        <DbaSlider
          sx={{ width: 170 }}
          value={settings.diagram.endAngle ?? 360}
          min={-360}
          max={360}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                endAngle: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="fillFreeSpace">
        <Switch
          checked={settings.diagram.fit}
          onChange={(e) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                fit: e.target.checked,
              },
            }))
          }
        />
        <CSSHintContainer>
          ({intl.messages["fillFreeSpaceHint"]})
        </CSSHintContainer>
      </WidgetSettingContainer>

      <WidgetSettingContainer title="innerRadius">
        <DbaSlider
          sx={{ width: 170 }}
          value={settings.diagram.innerRadius ?? 0}
          step={0.05}
          min={0}
          max={0.95}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                innerRadius: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="padAngle">
        <DbaSlider
          sx={{ width: 130 }}
          value={settings.diagram.padAngle ?? 0}
          min={0}
          max={45}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                padAngle: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="cornerRadius">
        <DbaSlider
          sx={{ width: 170 }}
          value={settings.diagram.cornerRadius ?? 0}
          min={0}
          max={45}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                cornerRadius: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="sortByValue">
        <Switch
          checked={settings.diagram.sortByValue}
          onChange={(e) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                sortByValue: e.target.checked,
              },
            }))
          }
        />
      </WidgetSettingContainer>
    </>
  );
};
