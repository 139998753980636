import { rootApi } from "../serviceRoot";

import {
  ColumnTypes,
  DatasetsTable,
  DatasetType,
  GenerateColumnsRequest,
  GenerateColumnsResponse,
  DeleteDataFromDataSetRequestType,
  TGetDatasetTableDataRequest,
  TGetDatasetTableDataResponse,
} from "./Types";

export const dataSetApiSlice = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getDatasets: builder.query<DatasetsTable, void>({
      query: () => ({
        url: "api/DataSet/GetAll",
      }),
      providesTags: ["datasets"],
    }),
    getDataset: builder.mutation<DatasetType, string>({
      query: (id) => ({
        url: `api/DataSet/Get?ID=${id}`,
      }),
    }),
    getColumnTypes: builder.query<ColumnTypes, void>({
      query: () => ({
        url: "api/DataSet/GetColumnTypes",
      }),
    }),
    generateColumns: builder.mutation<
      GenerateColumnsResponse,
      GenerateColumnsRequest
    >({
      query: (payload) => ({
        url: "api/DataSet/GenerateColumns",
        method: "POST",
        body: payload,
      }),
    }),
    createSettings: builder.mutation<any, DatasetType>({
      query: (payload) => ({
        url: "api/DataSet/Create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["datasets"],
    }),
    deleteSettings: builder.mutation<any, string>({
      query: (id) => ({
        url: "api/DataSet/Delete",
        method: "POST",
        body: { id },
      }),
      invalidatesTags: ["datasets"],
    }),
    updateSettings: builder.mutation<any, DatasetType>({
      query: (payload) => ({
        url: "api/DataSet/Update",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["datasets"],
    }),
    reloadDataset: builder.mutation<boolean, string>({
      query: (id) => ({
        url: "api/dataset/run",
        method: "POST",
        body: { dataSetID: id },
      }),
      invalidatesTags: ["datasets", "MLDateSetSummary"],
    }),
    getDatasetTableData: builder.query<
      TGetDatasetTableDataResponse,
      TGetDatasetTableDataRequest
    >({
      query: ({ id, pageSize, pageIndex, sortOptions, filterOptions }) => {
        return {
          url: `api/dataset/GetTableData`,
          params: {
            dataSetId: id,
            PageSize: pageSize,
            Page: pageIndex,
            "SortOptions.ColumnName": sortOptions?.columnName,
            "SortOptions.OrderType": sortOptions?.orderType,
            filterOptions: filterOptions,
          },
        };
      },
    }),
    truncateDatasetTable: builder.mutation<boolean, { dataSetId: string }>({
      query: (request) => ({
        url: "api/dataset/TruncateTable",
        method: "POST",
        body: request,
      }),
    }),
    dropDatasetTable: builder.mutation<boolean, { dataSetId: string }>({
      query: (request) => ({
        url: "api/dataset/DropTable",
        method: "POST",
        body: request,
      }),
    }),
    deleteDataFromDataSet: builder.mutation<
      number,
      DeleteDataFromDataSetRequestType
    >({
      query: (request) => ({
        url: "api/DataSet/DeleteData",
        method: "POST",
        body: request,
      }),
      invalidatesTags: ["datasets"],
    }),
  }),
});

export const {
  useGetDatasetsQuery,
  useGetDatasetMutation,
  useGetColumnTypesQuery,
  useCreateSettingsMutation,
  useGenerateColumnsMutation,
  useDeleteSettingsMutation,
  useUpdateSettingsMutation,
  useReloadDatasetMutation,
  useLazyGetDatasetTableDataQuery,
  useTruncateDatasetTableMutation,
  useDropDatasetTableMutation,
  useDeleteDataFromDataSetMutation,
} = dataSetApiSlice;
