import React from "react";
import { CSSDesktopDrawer, CSSMobileDrawer } from "./NavMenu.style";
import { TPropsNavMenu } from "./NavMenu.types";
import { DbaNavMenuItems } from "./components/NavMenuItems/NavMenuItems";

export const NavMenu: React.FC<TPropsNavMenu> = ({
  menuState,
  openSidebar,
  setOpenSidebar,
}: TPropsNavMenu) => {
  const mobileContent = (
    <CSSMobileDrawer
      open={openSidebar}
      onClose={() => setOpenSidebar(false)}
      variant="temporary"
      ModalProps={{
        keepMounted: true,
      }}
    >
      <DbaNavMenuItems menuState={menuState} />
    </CSSMobileDrawer>
  );

  const desktopContent = (
    <CSSDesktopDrawer variant="permanent">
      <DbaNavMenuItems menuState={menuState} />
    </CSSDesktopDrawer>
  );

  return menuState.isMobile
    ? mobileContent
    : !menuState.isPlaylistMode
    ? desktopContent
    : null;
};
