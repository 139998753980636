import { rootApi } from "../serviceRoot";
import {
  TFileGetAllResponse,
  TFileGetResponse,
  TFileUploadResponse,
  TFileUploadRequest,
  TFileReUploadResponse,
  TFileReUploadRequest,
  // TFileDownloadResponse,
  // TFileDownloadRequest,
  TFileUpdateResponse,
  TFileUpdateRequest,
  TFileDeleteResponse,
  TFileDeleteRequest,
  TFileGetByExtensionsResponse,
} from "./Types";

export const filesApiSlice = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllFiles: builder.query<TFileGetAllResponse, void>({
      query: () => "api/file/getall",
      providesTags: ["Files"],
    }),
    getFilesByExtensions: builder.query<TFileGetByExtensionsResponse, string[]>(
      {
        query: (attributes: string[]) =>
          `api/file/getbyextension?extensions=${attributes.join(
            "&extensions="
          )}`,
        providesTags: ["Files"],
      }
    ),
    getFile: builder.query<TFileGetResponse, string>({
      query: (id) => `api/file/get?ID=${id}`,
      providesTags: ["Files"],
    }),
    uploadFile: builder.mutation<TFileUploadResponse, TFileUploadRequest>({
      query: (payload: TFileUploadRequest) => {
        const formData = new FormData();
        formData.append("file", payload.file);
        if (payload.categoryId) {
          formData.append("categoryId", payload.categoryId);
        }
        formData.append("name", payload.name);
        return {
          url: "api/file/upload",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["Files"],
    }),
    reUploadFile: builder.mutation<TFileReUploadResponse, TFileReUploadRequest>(
      {
        query: (payload: TFileReUploadRequest) => {
          const formData = new FormData();
          formData.append("file", payload.file);
          formData.append("id", payload.id);
          return {
            url: "api/file/reupload",
            method: "POST",
            body: formData,
          };
        },
        invalidatesTags: ["Files"],
      }
    ),
    // downloadFile: builder.query<TFileDownloadResponse, TFileDownloadRequest>({
    //   query: (id) => `api/file/download?ID=${id}`,
    //   providesTags: ["Files"],
    // }),
    updateFile: builder.mutation<TFileUpdateResponse, TFileUpdateRequest>({
      query: (payload: TFileUpdateRequest) => {
        const formData = new FormData();
        formData.append("id", payload.id);
        if (payload.file) {
          formData.append("file", payload.file);
        }
        if (payload.categoryId) {
          formData.append("categoryId", payload.categoryId);
        }
        formData.append("name", payload.name);
        return {
          url: "api/file/update",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["Files"],
    }),
    deleteFile: builder.mutation<TFileDeleteResponse, TFileDeleteRequest>({
      query: (id) => ({
        url: "api/file/delete",
        method: "POST",
        body: { id },
      }),
      invalidatesTags: ["Files"],
    }),
  }),
});

export const {
  useGetAllFilesQuery,
  useGetFilesByExtensionsQuery,
  useUploadFileMutation,
  useReUploadFileMutation,
  // useDownloadFileQuery,
  useGetFileQuery,
  useLazyGetFileQuery,
  useUpdateFileMutation,
  useDeleteFileMutation,
} = filesApiSlice;
