import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { LoadingScreen, ErrorMessage } from "../../components/common";
import { useGetAllMapObjectsQuery } from "../../features/serviceSlices/serviceHooks";
import { useDeleteMapObjectMutation } from "../../features/serviceSlices/serviceHooks";
import { DbaTable } from "../../DbaComponents";
import { DbaSnackbar } from "../../DbaComponents";
import { CSSMapObjectsContainer } from "./MapObjects.styles";
import { MapObject } from "../../features/serviceSlices/MapObjects/Types";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import { ColumnDef } from "@tanstack/react-table";
import { EditButton } from "../../components/TableHelpers/EditButton";
import { DeleteButton } from "../../components/TableHelpers/DeleteButton";

export const MapObjects = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [deleteMapObject, deleteResponse] = useDeleteMapObjectMutation();
  const mapObjects = useGetAllMapObjectsQuery();

  const onAddButtonClick = useCallback(() => {
    navigate("/mapobject");
  }, [navigate]);

  const deleteHandler = useCallback(
    (data: MapObject) => deleteMapObject(data.id),
    [deleteMapObject]
  );

  const tableData = mapObjects.data;

  const columns: ColumnDef<MapObject>[] = useMemo(
    () => [
      {
        id: intl.messages["name"] as string,
        accessorFn: (row) => row.name,
        cell: (props) => {
          return (
            <Link
              underline="hover"
              component={RouterLink}
              to={{
                pathname: `/mapobject/${props.row.original.id}`,
              }}
              sx={{ color: "inherit" }}
            >
              {props.row.original.name}
            </Link>
          );
        },
        size: Number.MAX_SAFE_INTEGER,
      },
      {
        id: "edition",
        header: "",
        cell: (props) => <EditButton editLink="/mapobject/" row={props.row} />,
      },
      {
        id: "deletion",
        header: "",
        cell: (props) => (
          <DeleteButton row={props.row} deleteDataHandler={deleteHandler} />
        ),
      },
    ],
    [deleteHandler, intl.messages]
  );

  if (mapObjects.isError) {
    return <ErrorMessage />;
  }
  if (mapObjects.isLoading) {
    return <LoadingScreen />;
  }
  return (
    <CSSMapObjectsContainer>
      <DbaTable
        id="map-objects-form-table"
        columns={columns}
        data={tableData ?? []}
        headToolbar={{ onAddButtonClick }}
        stylesSettings={{ autoColumnsWidth: false }}
      />
      <DbaSnackbar
        error={deleteResponse.isError}
        success={deleteResponse.isSuccess}
      />
    </CSSMapObjectsContainer>
  );
};
