import Box from "@mui/material/Box";
import { DbaButton } from "../../../DbaComponents";
import Typography from "@mui/material/Typography";
import {
  CSSExpressionModal,
  style,
  CSSButtonBlockModal,
} from "../QueryConstructor.styles";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import Dialog from "@mui/material/Dialog";

export const QueryConstructorModal = ({
  open,
  title,
  saveHandler,
  deleteHandler,
  children,
  fullWidth,
}: {
  open: boolean;
  title: string;
  saveHandler: () => void;
  deleteHandler: () => void;
  children: React.ReactNode;
  fullWidth?: boolean;
}) => {
  return (
    <Dialog fullWidth={fullWidth} maxWidth="xl" open={open}>
      <Box
        sx={
          fullWidth
            ? {
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                height: "100vh",
                maxHeight: "100vh",
                overflow: "auto",
                transition: "all 0.3 ease",
              }
            : style
        }
      >
        <CSSExpressionModal>
          <Typography variant="h6" gutterBottom component="div">
            {title}
          </Typography>
          {children}
          <CSSButtonBlockModal>
            <DbaButton
              startIcon={<DeleteIcon />}
              color="error"
              text="delete"
              onClick={deleteHandler}
            />
            <DbaButton
              startIcon={<SaveIcon />}
              text="save"
              onClick={saveHandler}
            />
          </CSSButtonBlockModal>
        </CSSExpressionModal>
      </Box>
    </Dialog>
  );
};
