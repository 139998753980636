const constants = getConstants();

function getConstants() {
  return {
    changepasswordURL: window.__env__.REACT_APP_CHANGE_PASSWORD_URL,
    forgotpasswordURL: window.__env__.REACT_APP_FORGOT_PASSWORD_URL,
    authURL: window.__env__.REACT_APP_AUTH_URL,
    mediaUrl: window.__env__.REACT_APP_MEDIA_SERVICE_URL,
    ovbUrl: window.__env__.REACT_APP_OVB_URL,
    baseurl: window.__env__.REACT_APP_BASE_URL,
    referencesUrl: window.__env__.REACT_APP_REFERENCES_URL,
    authClientUrl: window.__env__.REACT_APP_AUTH_CLIENT_URL,
    seqUrl: window.__env__.REACT_APP_SEQ_URL,
    locale: window.__env__.REACT_APP_LOCALE,
    mapDefaultClustering: window.__env__.REACT_APP_DEFAULT_MAP_CLUSTERING,
    mapDefaultZoomLevel: window.__env__.REACT_APP_DEFAULT_MAP_ZOOM_LEVEL,
    mapDefaultLatitude: window.__env__.REACT_APP_DEFAULT_MAP_LATITUDE,
    mapDefaultLongitude: window.__env__.REACT_APP_DEFAULT_MAP_LONGITUDE,
    mapDefaultBounds: window.__env__.REACT_APP_DEFAULT_MAP_BOUNDS,
    daDataApiKey: "a139a61dd255da90b2f747d12185efee020fad78",
    maxFileSize: 209715200, // equal to 200 mb
    experimentalPublicDashboards:
      window.__env__.REACT_APP_EXPERIMENTAL_PUBLIC_DASHBOARDS,
    isMLEnabled: window.__env__.IS_ML_ENABLED,
  };
}

export default constants;
export const initialDataURL = "api/data/GetInitialData";
export const divisionURL = "api/Data/GetGEOMapData";
export const localObjectURL = "api/Data/GetLocalObjectList";

/*

  switch (client) {
    case 'altke': {
      return {
        changepasswordURL: 'https://auth.sk-altke.ru/changepassword',
        forgotpasswordURL: 'https://auth.sk-altke.ru/forget',
        authURL: 'https://authapi.sk-altke.ru/',
        mediaUrl: 'https://media.sk-altke.ru/file/',
        ovbUrl: 'https://ake-dashboard.ake.ru:40001/',
        baseurl: 'https://diasapi.sk-altke.ru/',
        referencesUrl: 'https://references.sk-altke.ru/',
        authClientUrl: 'https://auth.sk-altke.ru/',
        seqUrl: 'https://seq.sk-altke.ru/',
      };
    }
    case 'dba': {
      return {
        changepasswordURL: 'https://auth.dba.ooo/changepassword',
        forgotpasswordURL: 'https://auth.dba.ooo/forget',
        authURL: 'https://authapi.dba.ooo/',
        mediaUrl: 'https://media.dba.ooo/file/',
        ovbUrl: 'https://ovb.dba.ooo/',
        baseurl: 'https://dias.dba.ooo/',
        referencesUrl: 'https://references.dba.ooo/',
        authClientUrl: 'https://auth.dba.ooo/',
        seqUrl: 'https://seq.dba.ooo/',
      };
    }
    case 'mincifra': {
      return {
        changepasswordURL: 'https://digital-sc.alregn.ru/auth/changepassword',
        forgotpasswordURL: 'https://digital-sc.alregn.ru/auth/forget',
        authURL: 'https://digital-sc.alregn.ru/auth_service/',
        mediaUrl: 'https://digital-sc.alregn.ru/mediaservice/file/',
        baseurl: 'https://digital-sc.alregn.ru/dias/',
        referencesUrl: 'https://digital-sc.alregn.ru/referenceclient',
        authClientUrl: 'https://digital-sc.alregn.ru/auth',
        seqUrl: 'https://digital-sc.alregn.ru/seq',
      };
    }
    case 'dev': {
      return {
        changepasswordURL: 'https://auth.sk-altke.ru/changepassword',
        forgotpasswordURL: 'https://auth.sk-altke.ru/forget',
        authURL: 'https://authapi.sk-altke.ru/',
        mediaUrl: 'https://media.sk-altke.ru/file/',
        baseurl: 'http://localhost:5000/',
        ovbUrl: 'https://ake-dashboard.ake.ru:40001/',
        referencesUrl: 'https://references.sk-altke.ru/',
        authClientUrl: 'https://auth.sk-altke.ru/',
        seqUrl: 'https://seq_test.sk-altke.ru/',
      };
    }
    case 'demo': {
      return {
        changepasswordURL: 'https://auth.sk-altke.ru/changepassword',
        forgotpasswordURL: 'https://auth.sk-altke.ru/forget',
        authURL: 'https://authapi.sk-altke.ru/',
        mediaUrl: 'https://media.sk-altke.ru/file/',
        baseurl: 'https://diasdemo.sk-altke.ru/',
        referencesUrl: 'https://references.sk-altke.ru/',
        authClientUrl: 'https://auth.sk-altke.ru/',
        seqUrl: 'https://seq.sk-altke.ru/',
      };
    }
    case 'altke-test': {
      return {
        changepasswordURL: 'https://auth.sk-altke.ru/changepassword',
        forgotpasswordURL: 'https://auth.sk-altke.ru/forget',
        authURL: 'https://authapi.sk-altke.ru/',
        mediaUrl: 'https://media.sk-altke.ru/file/',
        baseurl: 'https://diasapi-test.sk-altke.ru/',
        ovbUrl: 'https://ake-dashboard.ake.ru:40001/',
        referencesUrl: 'https://references.sk-altke.ru/',
        authClientUrl: 'https://auth.sk-altke.ru/',
        seqUrl: 'https://seq-test.sk-altke.ru/',
      };
    }
    case 'test': {
      return {
        changepasswordURL: 'https://auth.sk-altke.ru/changepassword',
        forgotpasswordURL: 'https://auth.sk-altke.ru/forget',
        authURL: 'https://authapi.sk-altke.ru/',
        mediaUrl: 'https://media.sk-altke.ru/file/',
        ovbUrl: 'https://ake-dashboard.ake.ru:40001/',
        baseurl: 'https://diasapi-test.sk-altke.ru/',
        referencesUrl: 'https://references.sk-altke.ru/',
        authClientUrl: 'https://auth.sk-altke.ru/',
        seqUrl: 'https://seq_test.sk-altke.ru/',
      };
    }
    default: {
      return {};
    }
  }
}


*/
