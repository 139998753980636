import { useContext } from 'react';
import { ThemeContext } from '../../../utils/ThemeContext';
import colors from "../../../Variables.module.scss"

export function GetTheme(settings) {
  const { darkMode } = useContext(ThemeContext)
  return {
    textColor: darkMode ? colors.white : colors.darkBlue,
    labels: {
      text: {
        fill: settings?.labels?.text?.fill ? settings?.labels?.text?.fill : darkMode ? colors.white : colors.darkBlue,
        fontSize: settings?.labels?.text?.fontSize
      }
    },
    dots: {
      text: {
        fill: settings?.labels?.text?.fill ? settings?.labels?.text?.fill : darkMode ? colors.white : colors.darkBlue,
        fontSize: settings?.labels?.text?.fontSize
      },
    },
    tooltip: {
      container:
      {
        color: colors.darkBlue
      }
    },
    legends:
    {
      text:
      {
        fill: darkMode ? colors.white : colors.darkBlue
      }
    },
    axis: {
      ticks: {
        line: {
          stroke: settings?.axis?.domain?.line?.stroke ? settings?.axis?.domain?.line?.stroke : darkMode ? colors.white : colors.darkBlue
        },
        text: {
          fill: settings?.axis?.ticks?.text?.fill ? settings?.axis?.ticks?.text?.fill : darkMode ? colors.white : colors.darkBlue,
          fontSize: settings?.axis?.ticks?.text?.fontSize,
        }
      },
      legend: {
        text: {
          fill: settings?.axis?.ticks?.text?.fill ? settings?.axis?.legend?.text?.fill : darkMode ? colors.white : colors.darkBlue,
          fontSize: settings?.axis?.legend?.text?.fontSize,
        }
      },
      domain: {
        line: {
          stroke: settings?.axis?.domain?.line?.stroke ? settings?.axis?.domain?.line?.stroke : darkMode ? colors.white : colors.darkBlue,
          strokeWidth: settings?.axis?.domain?.line?.strokeWidth
        }
      },
    }
  };
}