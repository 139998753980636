import styled from "@emotion/styled";

const CSSConstructorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-height: 75vh;
  width: 100%;
`;
const CSSEditableWidgetContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
`;

const CSSSettingRow = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem 1rem 0;
  flex-wrap: wrap;
`;

const CSSSettingRowColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 1rem 1rem 0;
`;
const CSSMarginSettingsContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 8px 8px;
`;

export {
  CSSConstructorContainer,
  CSSEditableWidgetContainer,
  CSSSettingRow,
  CSSSettingRowColumn,
  CSSMarginSettingsContainer,
};
