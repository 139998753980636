import styled from "@emotion/styled";
import colors from "../../Variables.module.scss";

const CSSDataContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const CSSRequestFieldsContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

const CSSSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 1rem;
`;

const CSSStyledInput = styled.input`
  color: ${({ theme }: any) =>
    theme.palette.mode === "dark" ? colors.white : colors.darkBlue};
  appearance: none !important;
  background: inherit;
  border: none;
  font-family: inherit;
  font-weight: inherit;
  &::placeholder {
    font-size: 1rem;
    font-weight: 400;
  }
`;

const CSSTableContainer = styled.div`
  width: 100%;
`;
const CSSTableTitle = styled.p`
  font-weight: 600;
  padding-bottom: 0.5rem;
  font-size: 1.25rem;
`;
export {
  CSSRequestFieldsContainer,
  CSSSettingsContainer,
  CSSStyledInput,
  CSSDataContainer,
  CSSTableContainer,
  CSSTableTitle,
};
