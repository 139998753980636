import { rootApi } from "../serviceRoot";
import {
  TReportsResponse,
  TCreateReportRequest,
  TCreateReportResponse,
  TGetReportDataResponse,
  TGetReportRequest,
  TReport,
  TReportDeleteRequest,
  TReportDeleteResponse,
  TUpdateReportRequest,
  TUpdateReportResponse,
  TGetReportDataRequest,
} from "./Types";

export const reportsApiSlice = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getReport: builder.query<TReport, TGetReportRequest>({
      query: (payload) => ({
        url: "api/Report/Get",
        method: "POST",
        body: payload,
      }),
      providesTags: ["Reports"],
    }),
    getReportData: builder.query<TGetReportDataResponse, TGetReportDataRequest>(
      {
        query: (payload) => ({
          url: "api/Report/GetData",
          method: "POST",
          body: payload,
        }),
        providesTags: ["Reports"],
      }
    ),
    getAllReports: builder.query<TReportsResponse, boolean>({
      query: (payload) => `api/Report/GetAll?IsShowDeleted=${payload}`,
      providesTags: ["Reports"],
    }),
    createReport: builder.mutation<TCreateReportResponse, TCreateReportRequest>(
      {
        query: (payload) => ({
          url: "api/Report/Create",
          method: "POST",
          body: payload,
        }),
        invalidatesTags: ["Reports"],
      }
    ),
    updateReport: builder.mutation<TUpdateReportResponse, TUpdateReportRequest>(
      {
        query: (payload) => ({
          url: "api/Report/Update",
          method: "POST",
          body: payload,
        }),
        invalidatesTags: ["Reports"],
      }
    ),
    deleteReport: builder.mutation<TReportDeleteResponse, TReportDeleteRequest>(
      {
        query: (id) => ({
          url: "api/Report/Delete",
          method: "POST",
          body: { id },
        }),
        invalidatesTags: ["Reports"],
      }
    ),
  }),
});

export const {
  useGetReportDataQuery,
  useGetAllReportsQuery,
  useCreateReportMutation,
  useUpdateReportMutation,
  useDeleteReportMutation,
  useLazyGetReportQuery,
} = reportsApiSlice;
