import { Button, TextField } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";
import { CSSForm } from "./AuthStyles";

interface ForgetFormProps {
  handleForget: (event: React.SyntheticEvent) => void;
  error: boolean;
  usernameOrEmail: string;
  onForgetInputChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setOpenForgetForm: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ForgetForm: React.FC<ForgetFormProps> = (props) => {
  const intl = useIntl();
  return (
    <CSSForm autoComplete="off" onSubmit={props.handleForget}>
      <TextField
        required
        error={
          props.error && props.usernameOrEmail.length === 0
        }
        helperText={
          props.error && props.usernameOrEmail.length === 0
            ? intl.messages["requiredField"]
            : ""
        }
        fullWidth
        value={props.usernameOrEmail}
        onChange={props.onForgetInputChangeHandler}
        label="Логин или почта"
        variant="outlined"
        inputProps={{ maxLength: 256 }}
      />
      <Button type="submit" variant="contained">
        {intl.messages["recovery"]}
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => props.setOpenForgetForm(false)}
      >
        {intl.messages["cancel"]}
      </Button>
    </CSSForm>
  );
};
