import React, { useContext } from "react";
import Box from "@mui/material/Box";
import * as MuiIcons from "@mui/icons-material";
import List from "@mui/material/List";
import Tooltip from "@mui/material/Tooltip";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Brightness3Icon from "@mui/icons-material/Brightness3";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useGetBackendVersionQuery } from "../../../../../../features/serviceSlices/serviceHooks";
import { Logout } from "../../../../../../authorization/AuthService";
import { ThemeContext } from "../../../../../../utils/ThemeContext";
import { useAppSelector } from "../../../../../../utils/reduxHooks";
import {
  DbaPeriodSelector,
  DbaTimezonePicker,
} from "../../../../../../DbaComponents";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ReactLogo } from "../../../../../../assets/icons/logo.svg";
import generatedGitInfo from "../../../../../../generatedGitInfo.json";
import { AuthGuard } from "../../../../../Guards/AuthGuard/AuthGuard";
import { RoleGuard } from "../../../../../Guards/RoleGuard/RoleGuard";
import menuItems from "../../../../menuConstant";
import { CSSDrawerHeader, CSSListItem } from "./NavMenuItems.styles";
import { NavMenuItem } from "./components/NavMenuItem";
import { TPropsNavMenuItems } from "./NavMenuItems.types";

const TOOLTIP_DELAY = 3000;

export const DbaNavMenuItems: React.FC<TPropsNavMenuItems> = ({
  menuState,
}: TPropsNavMenuItems) => {
  const backendVersion = useGetBackendVersionQuery();
  const { darkMode, setDarkMode } = useContext(ThemeContext);

  const navigate = useNavigate();

  const { role, authorizationInfo } = useAppSelector((state) => state.app);

  const getIcon = (iconName: string) => {
    const Icon = MuiIcons[iconName as keyof typeof MuiIcons];
    return <Icon />;
  };

  const leftMenuItems = (
    <List>
      {menuItems.map((item) => {
        return (
          <RoleGuard
            key={item.caption}
            allowedRoles={item.allowRoles.map((role) => role)}
          >
            <NavMenuItem
              link={item.link}
              text={item.caption}
              icon={getIcon(item.icon)}
            />
          </RoleGuard>
        );
      })}
    </List>
  );

  const menuFooter = (
    <List
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        marginTop: authorizationInfo?.isAdmin ? 0 : "auto",
      }}
    >
      <CSSListItem onClick={() => setDarkMode(!darkMode)}>
        {darkMode ? <Brightness7Icon /> : <Brightness3Icon />}
      </CSSListItem>
      <AuthGuard>
        <NavMenuItem
          onClick={() => {
            navigate("/");
            Logout();
          }}
          link="/login"
          text="exit"
          icon={<ExitToAppIcon />}
        />
      </AuthGuard>
    </List>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <>
        <div>
          {!menuState.isMobile && (
            <CSSDrawerHeader>
              <Tooltip
                title={`
              ${
                generatedGitInfo.gitLatestTag
                  ? generatedGitInfo.gitLatestTag
                  : "0.0.0"
              }/${backendVersion.data}`}
                placement="right-end"
                arrow
                enterDelay={TOOLTIP_DELAY}
              >
                <ReactLogo />
              </Tooltip>
            </CSSDrawerHeader>
          )}
          {menuState.isMobile && (
            <>
              <DbaPeriodSelector />
              <DbaTimezonePicker />
            </>
          )}
          <AuthGuard>
            <NavMenuItem
              link={role && role.id ? `/${role.id}` : "/"}
              text={"dashboard"}
              icon={<DashboardIcon />}
            />
          </AuthGuard>
          {leftMenuItems}
        </div>
        {menuFooter}
      </>
    </Box>
  );
};
