import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useIntl } from "react-intl";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Snackbar from "@mui/material/Snackbar";
import { useLocation, To } from "react-router-dom";

import { Alert, Box, Stack } from "@mui/material";
import {
  CSSForm,
  CSSFormBox,
  CSSLoginFormContainer,
  CSSLogoBox,
  CSSLogoContainer,
  CSSPasswordContainer,
  CSSWelcomeText,
} from "./AuthStyles";
import useLoginFormLogic from "./useLoginFormLogic";
import { ForgetForm } from "./ForgetForm";

export const LoginForm = () => {
  const intl = useIntl();
  const location: any = useLocation();
  const from: To =
    `${location.state?.from?.pathname ?? ""}${
      location.state?.from?.search ?? ""
    }` || "/";
  const loginFormStore = useLoginFormLogic({ from });

  return (
    <CSSLoginFormContainer>
      <CSSFormBox>
        <CSSLogoContainer>
          <CSSLogoBox>
            <Logo />
          </CSSLogoBox>
          {loginFormStore.openForgetForm ? (
            <CSSWelcomeText>{intl.messages["recoveryPassword"]}</CSSWelcomeText>
          ) : (
            <CSSWelcomeText>{intl.messages["welcome"]}</CSSWelcomeText>
          )}
        </CSSLogoContainer>
        {loginFormStore.openForgetForm ? (
          <ForgetForm
            error={loginFormStore.error}
            handleForget={loginFormStore.handleForget}
            onForgetInputChangeHandler={
              loginFormStore.onForgetInputChangeHandler
            }
            setOpenForgetForm={loginFormStore.setOpenForgetForm}
            usernameOrEmail={loginFormStore.usernameOrEmail}
          />
        ) : (
          <CSSForm autoComplete="off" onSubmit={loginFormStore.handleSubmit}>
            {/* 2 поля снизу необходимы для отключения автозаполнения тестовых инпутов в хроме */}
            <input
              style={{
                position: "absolute",
                bottom: 0,
                opacity: 0,
                left: 0,
                pointerEvents: "none",
              }}
              type="text"
              name="fakeusernameremembered"
            />
            <input
              style={{
                position: "absolute",
                bottom: 0,
                opacity: 0,
                left: 0,
                pointerEvents: "none",
              }}
              type="password"
              name="fakepasswordremembered"
            />
            <TextField
              required
              error={
                (loginFormStore.error && loginFormStore.login.length === 0) ||
                (loginFormStore.error &&
                  loginFormStore.errorMessage.includes("логин"))
              }
              helperText={
                loginFormStore.error && loginFormStore.login.length === 0
                  ? intl.messages["requiredField"]
                  : loginFormStore.errorMessage !== "" &&
                    loginFormStore.errorMessage.includes("логин")
                  ? loginFormStore.errorMessage
                  : ""
              }
              fullWidth
              value={loginFormStore.login}
              onChange={(event) =>
                loginFormStore.onFieldChangeHandler(
                  event.target.value,
                  loginFormStore.setLogin
                )
              }
              label="Логин"
              variant="outlined"
              inputProps={{ maxLength: 256 }}
            />
            <CSSPasswordContainer>
              <TextField
                error={
                  (loginFormStore.error &&
                    loginFormStore.password.length === 0) ||
                  (loginFormStore.error &&
                    loginFormStore.errorMessage.includes("пароль"))
                }
                helperText={
                  loginFormStore.error && loginFormStore.password.length === 0
                    ? intl.messages["requiredField"]
                    : loginFormStore.errorMessage !== "" &&
                      loginFormStore.errorMessage.includes("пароль")
                    ? loginFormStore.errorMessage
                    : ""
                }
                required
                type={loginFormStore.showPassword ? "text" : "password"}
                inputProps={{ maxLength: 256 }}
                InputProps={{
                  endAdornment:
                    loginFormStore.password.length > 0 ? (
                      <InputAdornment
                        onClick={() =>
                          loginFormStore.setShowPassword(
                            !loginFormStore.showPassword
                          )
                        }
                        position="end"
                      >
                        {loginFormStore.showPassword ? (
                          <VisibilityOff sx={{ cursor: "pointer" }} />
                        ) : (
                          <Visibility sx={{ cursor: "pointer" }} />
                        )}
                      </InputAdornment>
                    ) : (
                      ""
                    ),
                }}
                fullWidth
                value={loginFormStore.password}
                onChange={(event) =>
                  loginFormStore.onFieldChangeHandler(
                    event.target.value,
                    loginFormStore.setPassword
                  )
                }
                label="Пароль"
                variant="outlined"
              />
            </CSSPasswordContainer>
            <Box sx={{ margin: "0 auto" }}>
              <Stack direction="row" spacing={8} justifyContent="space-evenly">
                {/* <Button
                  size="small"
                  style={{ textTransform: "none", padding: "0px" }}
                  onClick={() => navigate("/registration")}
                >
                  {intl.messages["registration"]}
                </Button> */}
                <Button
                  size="small"
                  style={{
                    textTransform: "none",
                    padding: "0px",
                  }}
                  onClick={() => loginFormStore.setOpenForgetForm(true)}
                >
                  {intl.messages["forgotPassword"]}?
                </Button>
              </Stack>
            </Box>
            <Button type="submit" variant="contained">
              {intl.messages["login"]}
            </Button>
          </CSSForm>
        )}
      </CSSFormBox>
      <Snackbar
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={loginFormStore.openSnackbar}
        onClose={() => loginFormStore.setOpenSnackbar(false)}
      >
        <Alert
          severity={
            loginFormStore.openForgetForm
              ? loginFormStore.forgetError
                ? "error"
                : "success"
              : loginFormStore.authenticateError
              ? "error"
              : "success"
          }
        >
          {loginFormStore.snackbarMessage.length !== 0
            ? loginFormStore.snackbarMessage
            : intl.messages["success"]}
        </Alert>
      </Snackbar>
    </CSSLoginFormContainer>
  );
};
