import { Typography } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";
import IntlMessageCombiner from "../../components/common/IntlMessageCombiner";
import ErrorIcon from "@mui/icons-material/Error";
import { CSSWidgetErrorMessageContainer } from "./DbaErrorBoundary.styles";

type DbaErrorBoundaryProps = {
  customMessage?: string;
  link?: string;
  resetKeys?: unknown[] | undefined;
  isShowErrorMessage?: boolean;
  children: React.ReactNode;
};

function ErrorFallback({
  customMessage,
  link,
  isShowErrorMessage,
  error,
  resetErrorBoundary,
}: any) {
  return (
    <>
      {customMessage ? (
        <CSSWidgetErrorMessageContainer>
          <ErrorIcon color="error" />
          <Typography variant="body1">
            {IntlMessageCombiner({
              links: [link],
              text: customMessage,
            })}
          </Typography>
        </CSSWidgetErrorMessageContainer>
      ) : (
        <p>Что-то пошло не так:</p>
      )}
      {isShowErrorMessage ? <p>{error.message}</p> : null}
      {/* <button onClick={resetErrorBoundary}>Повторить</button> */}
    </>
  );
}

export const DbaErrorBoundary = ({
  customMessage,
  link,
  resetKeys,
  isShowErrorMessage = true,
  children,
}: DbaErrorBoundaryProps) => {
  return (
    <ErrorBoundary
      resetKeys={resetKeys}
      FallbackComponent={(props) =>
        ErrorFallback({ customMessage, link, isShowErrorMessage, ...props })
      }
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      {children}
    </ErrorBoundary>
  );
};
