import { useState } from "react";
import Chip from "@mui/material/Chip";
import { QueryConstructorModal } from "../../../components/QueryConstructor/components/QueryConstructorModal";
import { useIntl } from "react-intl";
import { ReferenceInputGenerator } from "./ReferenceInputGenerator";
import { GetHeadersDescriptionResponse } from "../../../features/serviceSlices/ReferenceHeader/Types";

type ReferenceChipProps = {
  fields: GetHeadersDescriptionResponse;
  data: any;
  onDeleteHandler: () => void;
  onSaveChangesHandler: (payload: {}, initialObject: {}) => void;
};

export const ReferenceChip = ({
  data,
  onDeleteHandler,
  fields,
  onSaveChangesHandler,
}: ReferenceChipProps) => {
  const intl = useIntl();
  const [open, setOpen] = useState(data.field === undefined);
  const [reference, setReference] = useState(data);
  const [error, setError] = useState(false);

  const handleOpen = () => setOpen(true);

  const onSaveHandler = () => {
    if (
      !reference.field ||
      reference.field.trim() === "" ||
      !reference.type ||
      !reference.headerName ||
      reference.headerName.trim() === ""
    ) {
      setError(true);
      return;
    }
    onSaveChangesHandler(reference, data);
    setOpen(false);
  };

  const deleteHandler = () => {
    onDeleteHandler();
    setOpen(false);
  };

  return (
    <>
      <Chip
        onClick={handleOpen}
        label={
          data.headerName && data.field
            ? `${data.headerName} (${data.field}) `
            : ""
        }
        onDelete={deleteHandler}
      />
      <QueryConstructorModal
        open={open}
        title={
          (data.field === undefined
            ? intl.messages["addingNewReferenceHeader"]
            : intl.messages["changeReferenceHeader"]) as string
        }
        saveHandler={onSaveHandler}
        deleteHandler={deleteHandler}
      >
        <ReferenceInputGenerator
          fields={fields}
          localState={reference}
          setLocalState={setReference}
          error={error}
        />
      </QueryConstructorModal>
    </>
  );
};
