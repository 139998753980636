import { DbaSlider } from "../../../../../../../DbaComponents";
import { WidgetSettingContainer } from "../../common";
import type { ChartSettingsProps } from "../Types";
import Switch from "@mui/material/Switch";

export const BarChartLabelSettings = ({
  settings,
  setSettings,
}: ChartSettingsProps) => {
  return (
    <>
      <WidgetSettingContainer title="enableLabel">
        <Switch
          checked={settings.diagram.enableLabel ?? true}
          onChange={(e) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                enableLabel: e.target.checked,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="labelSkipWidth">
        <DbaSlider
          sx={{ width: 120 }}
          min={0}
          max={36}
          value={settings.diagram.labelSkipWidth}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                labelSkipWidth: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="labelSkipHeight">
        <DbaSlider
          sx={{ width: 120 }}
          min={0}
          max={36}
          value={settings.diagram.labelSkipHeight}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                labelSkipHeight: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>
    </>
  );
};
