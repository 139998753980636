import { DbaDiagramTooltipProps } from "./Types"
import colors from "../../../../Variables.module.scss"
import React from "react";

export const DbaDiagramTooltip: React.FC<DbaDiagramTooltipProps> = ({
  markerColor,
  label,
  value
}) => {
  return (
    <div
      style={{
        background: colors.white,
        fontSize: 13,
        borderRadius: 2,
        boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 2px",
        padding: "5px 9px" }}>
      <div
        style={{
          whiteSpace: "pre",
          display: "flex",
          alignItems: "center",
          color: colors.dark}}>
        <span
          style={{
            display: "block",
            width: 12,
            height: 12,
            background: markerColor,
            marginRight: 7}}>
        </span>
        <span>{label}: <strong>{value}</strong></span>
      </div>
    </div>
  )
}