import Button from "@mui/material/Button";
import { useNavigate } from "react-router";
import CircularProgress from "@mui/material/CircularProgress";
import SaveIcon from "@mui/icons-material/Save";
import CheckIcon from "@mui/icons-material/Check";
import { useEffect, useState } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { TRequestStatus } from "../../features/serviceSlices/SharedTypes";

type SaveButtonType = {
  status: TRequestStatus;
  redirectUrl?: string;
  onClick?: () => void;
  disabled?: boolean;
  type?: "submit" | "button" | "reset" | undefined;
};

export const SaveButton = ({
  status,
  redirectUrl,
  onClick,
  disabled,
  type,
  ...rest
}: SaveButtonType) => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);

  if (status === "fulfilled" && redirectUrl) {
    setTimeout(() => {
      navigate(redirectUrl);
    }, 300);
  }

  useEffect(() => {
    if (status === "rejected") {
      setError(true);
    } else {
      setError(false);
    }
  }, [status]);

  if (error) {
    setTimeout(() => {
      setError(false);
    }, 1500);
  }
  return (
    <Button
      type={type}
      startIcon={
        status === "uninitialized" ? (
          <SaveIcon />
        ) : status === "pending" ? (
          <CircularProgress size={20} sx={{ color: "white" }} />
        ) : error ? (
          <ErrorOutlineIcon />
        ) : (
          <CheckIcon />
        )
      }
      onClick={onClick}
      color={status === "fulfilled" ? "success" : error ? "error" : "primary"}
      sx={{ width: "fit-content" }}
      disabled={disabled}
      variant="contained"
    >
      {status === "fulfilled" ? "Сохранено" : error ? "Ошибка" : "Сохранить"}
    </Button>
  );
};
