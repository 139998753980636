import { useIntl } from "react-intl";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

type SettingsToggleProps = {
  settings?: any;
  setSettings?: (arg: any) => void;
  defaultValue?: string;
  options: { value: string | any; label: string }[];
  property?: string;
  onChange?: (arg: any, arg2: any) => void;
  value?: any;
};
//передавать всегда options +  value+onChange или settings+setSettings+defaultValue+property
export const SettingsToggle = ({
  settings,
  setSettings,
  defaultValue,
  property,
  options,
  onChange,
  value,
}: SettingsToggleProps) => {
  const intl = useIntl();
  return (
    <ToggleButtonGroup
      size="small"
      value={property ? settings.diagram[property] ?? defaultValue : value}
      exclusive
      onChange={
        setSettings && property
          ? (event, newValue) => {
              setSettings((prevState: any) => ({
                ...prevState,
                diagram: {
                  ...prevState.diagram,
                  [property]: newValue === null ? defaultValue : newValue,
                },
              }));
            }
          : onChange
      }
    >
      {options.map((option) => (
        <ToggleButton
          key={option.value}
          value={option.value}
          aria-label={intl.messages[option.label] as string}
        >
          {intl.messages[option.label]}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
