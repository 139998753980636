import React, { useContext, useState, memo } from "react";
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  getPaginationRowModel,
  SortingState,
  PaginationState,
  ColumnOrderState,
} from "@tanstack/react-table";
import { TColumnId, TDbaTable, TTableHeadToolbar } from "./Types";
import { ThemeContext } from "../../utils/ThemeContext";
import {
  CSSTableLayout,
  CSSTableWrapper,
  CSSTable,
  CSSLoaderContainer,
} from "./DbaTable.styles";
import { TableHead } from "./components/TableHead/TableHead";
import { TableBody } from "./components/TableBody/TableBody";
import { TableFootToolbar } from "./components/TableFootToolbar/TableFootToolbar";
import { TableHeadToolbar } from "./components/TableHeadToolbar/TableHeadToolbar";
import { useSearchData } from "./hooks/useSearchData";
import { LoadingScreen } from "../../components/common";
import { useFixControlsMode } from "./hooks/useFixControlsMode";

export const DbaTable: React.FC<TDbaTable> = memo(
  ({
    id,
    data,
    columns,
    stylesSettings = {},
    pagination = {},
    sorting = {},
    columnSorting = {},
    headToolbar = {},
    selectionMode = {},
    filters = {},
    fixControls = {},
    isLoading = false,
  }) => {
    const { darkMode } = useContext(ThemeContext);

    const {
      backgroundColorDarkThemeOverride,
      autoColumnsWidth = true,
      cellStyles = {},
      heightReducer = "0px",
    } = stylesSettings;
    const {
      showPagination = true,
      showRowsPerPageSelector = true,
      rowsPerPageSelector,
      autoResetPagination = true,
      enableServerPagination = false,
      serverRowsCount = 0,
      serverRowsPerPage = 10,
      serverPageIndex,
      onServerPageChange,
    } = pagination;
    const {
      enableSorting = true,
      enableServerSorting = false,
      onSortingChange,
    } = sorting;
    const { enableColumnSorting = true, onColumnSortingChange } = columnSorting;
    const {
      showHeadToolbar = true,
      showHeadToolbarSearchField = true,
      onAddButtonClick,
      showDeletedSwitch = false,
      deletedSwitchValue = false,
      onDeletedSwitchChange,
    } = headToolbar;
    const { enableSelectionMode = false, onRowClick } = selectionMode;
    const {
      enableFilters = false,
      filtersList = [],
      onFiltersListChange,
    } = filters;
    const { enableFixControls = true } = fixControls;

    const [headToolbarSearchText, setHeadToolbarSearchText] =
      useState<TTableHeadToolbar["headToolbarSearchText"]>("");
    const searchResultData = useSearchData(
      headToolbarSearchText as string,
      data
    );
    const [sortingState, setSortingState] = React.useState<SortingState>([]);
    const [columnOrder, setColumnOrder] = React.useState<ColumnOrderState>(
      columns.map((column) => column.id as TColumnId)
    );
    const [serverPaginationState, setServerPaginationState] =
      React.useState<PaginationState>({
        pageIndex: serverPageIndex ?? 0,
        pageSize: serverRowsPerPage,
      });

    const table = useReactTable({
      data: searchResultData,
      columns,
      getCoreRowModel: getCoreRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      state: {
        sorting: enableSorting ? sortingState : undefined,
        columnOrder: enableColumnSorting ? columnOrder : undefined,
      },
      autoResetPageIndex: autoResetPagination,
      onSortingChange: setSortingState,
      getSortedRowModel: !enableServerSorting ? getSortedRowModel() : undefined,
      manualSorting: enableServerSorting,
      onColumnOrderChange: setColumnOrder,
    });

    useFixControlsMode(id, !enableFixControls);

    const footerToolbarContent = (
      <TableFootToolbar
        data={searchResultData}
        table={table}
        darkMode={darkMode}
        backgroundColorDarkThemeOverride={backgroundColorDarkThemeOverride}
        showPagination={showPagination}
        showRowsPerPageSelector={showRowsPerPageSelector}
        rowsPerPageSelector={rowsPerPageSelector}
        enableServerPagination={enableServerPagination}
        serverRowsCount={serverRowsCount}
        serverPageIndex={serverPageIndex}
        onServerPageChange={onServerPageChange}
        serverPaginationState={serverPaginationState}
        setServerPaginationState={setServerPaginationState}
        enableFixControls={enableFixControls}
        isLoading={isLoading}
      />
    );

    return (
      <CSSTableLayout
        id={id}
        showPagination={showPagination}
        heightReducer={heightReducer}
      >
        <CSSTableWrapper className="dba-table-wrapper">
          <TableHeadToolbar
            darkMode={darkMode}
            backgroundColorDarkThemeOverride={backgroundColorDarkThemeOverride}
            showHeadToolbar={showHeadToolbar}
            showHeadToolbarSearchField={showHeadToolbarSearchField}
            onAddButtonClick={onAddButtonClick}
            headToolbarSearchText={headToolbarSearchText}
            setHeadToolbarSearchText={(text) => setHeadToolbarSearchText(text)}
            showDeletedSwitch={showDeletedSwitch}
            deletedSwitchValue={deletedSwitchValue}
            onDeletedSwitchChange={onDeletedSwitchChange}
            enableFixControls={enableFixControls}
          />
          <CSSTable
            className="dba-table"
            darkMode={darkMode}
            backgroundColorDarkThemeOverride={backgroundColorDarkThemeOverride}
            cellPadding="0"
            cellSpacing="0"
          >
            <TableHead
              table={table}
              darkMode={darkMode}
              backgroundColorDarkThemeOverride={
                backgroundColorDarkThemeOverride
              }
              autoColumnsWidth={autoColumnsWidth}
              enableSorting={enableSorting}
              onSortingChange={onSortingChange}
              sortingState={sortingState}
              enableColumnSorting={enableColumnSorting}
              onColumnSortingChange={onColumnSortingChange}
              enableFilters={enableFilters}
              filtersList={filtersList}
              onFiltersListChange={onFiltersListChange}
              showHeadToolbar={showHeadToolbar}
              enableFixControls={enableFixControls}
            />
            <TableBody
              table={table}
              darkMode={darkMode}
              backgroundColorDarkThemeOverride={
                backgroundColorDarkThemeOverride
              }
              enableSelectionMode={enableSelectionMode}
              onRowClick={onRowClick}
              cellStyles={cellStyles}
              showPagination={showPagination}
            />
          </CSSTable>
          {!enableFixControls ? footerToolbarContent : null}
        </CSSTableWrapper>
        {enableFixControls ? footerToolbarContent : null}
        {isLoading ? (
          <CSSLoaderContainer>
            <LoadingScreen />
          </CSSLoaderContainer>
        ) : null}
      </CSSTableLayout>
    );
  }
);
