import styled from "@emotion/styled";
import Switch from "@mui/material/Switch";
import { DbaNumberInput } from "../../../../../../DbaComponents";
import { SettingsType } from "../../../../../../features/serviceSlices/WidgetSettings/Types";

type MinMaxValueSettingsProps = {
  settings: SettingsType;
  setSettings: (arg: any) => void;
  value: number;
  property: "minValue" | "maxValue";
};

const CSSMinMaxValueContainer = styled.div`
  display: flex;
`;

export const MinMaxValueSettings = ({
  settings,
  setSettings,
  value,
  property,
}: MinMaxValueSettingsProps) => {
  return (
    <CSSMinMaxValueContainer>
      {settings.diagram[property] !== "auto" &&
        settings.diagram[property] !== undefined && (
          <>
            <DbaNumberInput
              size="small"
              sx={{ maxWidth: 120 }}
              value={value}
              setValue={(value) =>
                setSettings((prevState: any) => ({
                  ...prevState,
                  diagram: {
                    ...prevState.diagram,
                    [property]: value,
                  },
                }))
              }
            />
          </>
        )}
      <Switch
        checked={
          settings.diagram[property] !== "auto" &&
          settings.diagram[property] !== undefined
        }
        onChange={(e) =>
          setSettings((prevState: any) => ({
            ...prevState,
            diagram: {
              ...prevState.diagram,
              [property]:
                e.target.checked === true
                  ? property === "minValue"
                    ? -1000
                    : 1000
                  : "auto",
            },
          }))
        }
      />
    </CSSMinMaxValueContainer>
  );
};
