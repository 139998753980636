import React, { useContext } from "react";
import { useIntl } from "react-intl";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { ThemeContext } from "../../../../../../utils/ThemeContext";
import {
  useAppSelector,
  useAppDispatch,
} from "../../../../../../utils/reduxHooks";
import { cleanFilters } from "../../../../../../features/slices/App/appSlice";
import {
  DbaIconButton,
  DbaPeriodSelector,
  DbaTimezonePicker,
} from "../../../../../../DbaComponents";
import { AuthGuard } from "../../../../../Guards/AuthGuard/AuthGuard";
import { DashboardEditorToolbar } from "./components/DashboardEditToolbar";
import { FullScreenWidgetNavMenu } from "../../../FullScreenWidgetNavMenu/FullScreenWidgetNavMenu";
import { TPropsDashboardHeader } from "./DashboardHeader.types";
import {
  CSSPlaylistStopButton,
  CSSSelectContainer,
} from "./DashboardHeader.styles";
import { createCustomStyles } from "./createCustomStyles";

export const DashboardHeader: React.FC<TPropsDashboardHeader> = ({
  menuState,
}: TPropsDashboardHeader) => {
  const intl = useIntl();
  const { role, roles, type } = useAppSelector((state) => state.app);
  const { darkMode } = useContext(ThemeContext);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const timezonePickerDisabled = !type || type === "NotSet";

  const isShowDatePickerControls = (): boolean => {
    return !menuState.isMobile && !menuState.isPlaylistMode;
  };

  return (
    <Stack
      sx={{ width: "100%" }}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <AuthGuard
        placeholder={
          <Box
            sx={{
              width: "100%",
            }}
          />
        }
      >
        <>
          {menuState.isPlaylistMode ? (
            <CSSPlaylistStopButton
              onClick={() => navigate("/playlists", { replace: true })}
            >
              <DbaIconButton icon={<StopCircleIcon />} />
              <Box>{intl.messages["stopPlaylist"]}</Box>
            </CSSPlaylistStopButton>
          ) : !menuState.isFullscreenMode ? (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Select
                styles={createCustomStyles({
                  isDarkMode: darkMode,
                  isMobile: menuState.isMobile,
                })}
                onChange={(value) => {
                  dispatch(cleanFilters());
                  navigate(value?.id!);
                }}
                value={
                  roles.find((item) => item.id === menuState.dashboardID) ??
                  roles.find((item) => item.id === role.id)
                }
                options={roles}
                noOptionsMessage={() => intl.messages["noDashboards"]}
                placeholder={intl.messages["chooseDashboard"]}
                blurInputOnSelect={true}
              />
              <DashboardEditorToolbar menuState={menuState} />
            </Stack>
          ) : null}
        </>
      </AuthGuard>
      {menuState.isFullscreenMode ? <FullScreenWidgetNavMenu /> : null}
      <CSSSelectContainer>
        {isShowDatePickerControls() ? (
          <>
            <DbaPeriodSelector />
            <DbaTimezonePicker disabled={timezonePickerDisabled} />
          </>
        ) : null}
      </CSSSelectContainer>
    </Stack>
  );
};
