import { useCallback } from "react";
import { WidgetRelatedReportModalProps } from "./Types";
import { DbaModal } from "../../../../DbaComponents";
import { WidgetRelatedReport } from "./WidgetRelatedReport";

export const WidgetRelatedReportModal = ({
  isOpen,
  setIsOpen,
  report,
  dashboardId,
}: WidgetRelatedReportModalProps) => {
  const closeHandler = useCallback(
    (isOpen: boolean) => {
      if (!isOpen) {
        setIsOpen(false);
      }
    },
    [setIsOpen]
  );

  return report ? (
    <DbaModal
      title={report.name}
      open={isOpen}
      setOpen={closeHandler}
      fullWidth={true}
      fullScreen={true}
    >
      <WidgetRelatedReport report={report} dashboardId={dashboardId} />
    </DbaModal>
  ) : null;
};
