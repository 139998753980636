import { ResponsiveRadar } from '@nivo/radar';
import { GetTheme } from '../Theme';

export const DbaRadarChart = ({ data, settings }) => {
  return (
    <ResponsiveRadar
      data={data}
      indexBy={settings.indexBy}
      keys={settings.keys ?? []}
      maxValue={settings.maxValue}
      valueFormat={settings.valueFormat}
      curve={settings.curve}
      margin={settings.margin}
      colors={settings.colors}
      fillOpacity={settings.fillOpacity}
      blendMode={settings.blendMode}
      borderWidth={settings.borderWidth}
      borderColor={settings.borderColor}
      gridLevels={settings.gridLevels}
      gridShape={settings.gridShape}
      gridLabelOffset={settings.gridLabelOffset}
      enableDots={settings.enableDots}
      dotSize={settings.dotSize}
      dotColor={settings.dotColor}
      dotBorderWidth={settings.dotBorderWidth}
      dotBorderColor={settings.dotBorderColor}
      enableDotLabel={settings.enableDotLabel}
      dotLabel={(d) => d.formattedValue.replaceAll(',', ' ')}
      dotLabelYOffset={settings.dotLabelYOffset}
      isInteractive={settings.isInteractive}
      animate={settings.animate}
      motionConfig={settings.motionConfig}
      legends={settings.legends}
      theme={GetTheme(settings)}
    />
  )
}

