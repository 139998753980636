import React from "react";
import { DBAMarkerTypeInputProps, OptionType } from "./Types";
import {
  Autocomplete,
  InputAdornment,
  AutocompleteRenderInputParams,
  Box,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useIntl } from "react-intl";
import markersOptions from "./markersOptions";
import { createMarkerJSX } from "../../components/Widgets/Widgets/Map/MarkersStyles/MarkersStyles";

export const DBAMarkerTypeInput: React.FC<DBAMarkerTypeInputProps> = ({
  value,
  handleChange,
  required,
}) => {
  const intl = useIntl();

  const selectedOption: OptionType | undefined =
    markersOptions.find((item) => item.value === value) ?? undefined;

  const markerFigure = (marker: string | undefined, scale?: number) => {
    return (
      <div style={{ width: "30px" }}>
        {createMarkerJSX({ marker, size: scale ?? 1, color: "#a0a8e4" })}
      </div>
    );
  };

  const onChange = (
    event: React.BaseSyntheticEvent,
    newValue: OptionType | null
  ) => handleChange(newValue);

  const renderInput = (params: AutocompleteRenderInputParams) => {
    const startAdornment = (
      <>
        <InputAdornment position="start">
          <div style={{ marginLeft: "10px" }}>
            {markerFigure(selectedOption?.value)}
          </div>
        </InputAdornment>
      </>
    );
    return (
      <TextField
        {...params}
        label={`${intl.messages["marker"]}`}
        InputProps={{
          ...params.InputProps,
          startAdornment: startAdornment,
        }}
        required={required ?? false}
        inputProps={{
          ...params.inputProps,
          value: selectedOption?.label
            ? intl.messages[selectedOption?.label]
            : "",
          onChange: (e) => false,
        }}
      />
    );
  };

  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: OptionType
  ) => (
    <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
      {markerFigure(option.value, 0.7)}
      {intl.messages[option.label]}
    </Box>
  );

  return (
    <Autocomplete
      fullWidth
      value={selectedOption}
      onChange={onChange}
      options={markersOptions}
      size="medium"
      renderOption={renderOption}
      renderInput={renderInput}
      disableClearable={true}
    />
  );
};
