import { WidgetSettingContainer } from ".";
import Switch from "@mui/material/Switch";
import { AxisSetting } from "./AxisSetting";
import { BaseSettingsProps } from "../Types";
import { DbaSlider } from "../../../../../../DbaComponents/DbaSlider";
import { DbaColorInput } from "../../../../../../DbaComponents/DbaColorInput";

export const ChartGridSettings = ({
  settings,
  setSettings,
  diagramType,
}: BaseSettingsProps) => {
  return (
    <>
      <WidgetSettingContainer title="enableGridX">
        <Switch
          checked={
            settings.diagram.enableGridX === undefined
              ? diagramType === "Line"
                ? true
                : false
              : settings.diagram.enableGridX
          }
          onChange={(e) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                enableGridX: e.target.checked,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="enableGridY">
        <Switch
          checked={
            settings.diagram.enableGridY === undefined
              ? diagramType === "HeatMap"
                ? false
                : true
              : settings.diagram.enableGridY
          }
          onChange={(e) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                enableGridY: e.target.checked,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="axisTicksFontSize">
        <DbaSlider
          sx={{ width: 150 }}
          value={settings.diagram.axis?.ticks?.text?.fontSize ?? 11}
          min={1}
          max={72}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                axis: {
                  ...prevState.diagram?.axis,
                  ticks: {
                    ...prevState.diagram?.axis?.ticks,
                    text: {
                      ...prevState.diagram?.axis?.ticks?.text,
                      fontSize: value
                    }
                  }
                }                
              },
            }))
          }
        />
      </WidgetSettingContainer>
      <WidgetSettingContainer title="axisTicksFill">
      <DbaColorInput
                    value={settings?.diagram?.axis?.ticks?.text?.fill ?? ""}
                    onChange={(event) =>
                      setSettings((prevState: any) => ({
                        ...prevState,
                        diagram: {
                          ...prevState.diagram,
                          axis: {
                            ...prevState.diagram?.axis,
                            ticks: {
                              ...prevState.diagram?.axis?.ticks,
                              text: {
                                ...prevState.diagram?.axis?.ticks?.text,
                                fill: event.target.value
                              }
                            }
                          }                
                        },
                      }))
                    }
                  />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="axisLegendFontSize">
        <DbaSlider
          sx={{ width: 150 }}
          value={settings.diagram.axis?.legend?.text?.fontSize ?? 11}
          min={1}
          max={72}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                axis: {
                  ...prevState.diagram?.axis,
                  legend: {
                    ...prevState.diagram?.axis?.legend,
                    text: {
                      ...prevState.diagram?.axis?.legend?.text,
                      fontSize: value
                    }
                  }
                }                
              },
            }))
          }
        />
      </WidgetSettingContainer>
      <WidgetSettingContainer title="axisLegendFill">
      <DbaColorInput
                    value={settings?.diagram?.axis?.legend?.text?.fill ?? ""}
                    onChange={(event) =>
                      setSettings((prevState: any) => ({
                        ...prevState,
                        diagram: {
                          ...prevState.diagram,
                          axis: {
                            ...prevState.diagram?.axis,
                            legend: {
                              ...prevState.diagram?.axis?.legend,
                              text: {
                                ...prevState.diagram?.axis?.legend?.text,
                                fill: event.target.value
                              }
                            }
                          }                
                        },
                      }))
                    }
                  />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="axisLineStrokeWidth">
        <DbaSlider
          sx={{ width: 150 }}
          value={settings.diagram.axis?.domain?.line?.strokeWidth ?? 1}
          min={0}
          max={10}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                axis: {
                  ...prevState.diagram?.axis,
                  domain: {
                    ...prevState.diagram?.axis?.domain,
                    line: {
                      ...prevState.diagram?.axis?.domain?.line,
                      strokeWidth: value
                    }
                  }
                }                
              },
            }))
          }
        />
      </WidgetSettingContainer>
      <WidgetSettingContainer title="axisLineStroke">
      <DbaColorInput
                    value={settings?.diagram?.axis?.domain?.line?.stroke ?? ""}
                    onChange={(event) =>
                      setSettings((prevState: any) => ({
                        ...prevState,
                        diagram: {
                          ...prevState.diagram,
                          axis: {
                            ...prevState.diagram?.axis,
                            domain: {
                              ...prevState.diagram?.axis?.domain,
                              line: {
                                ...prevState.diagram?.axis?.domain?.line,
                                stroke: event.target.value
                              }
                            }
                          }                
                        },
                      }))
                    }
                  />
      </WidgetSettingContainer>     

      <WidgetSettingContainer title="axisTop">
        <Switch
          checked={Boolean(settings.diagram.axisTop)}
          onChange={(e) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                axisTop: e.target.checked === false ? null : {},
              },
            }))
          }
        />
        {settings.diagram.axisTop && (
          <AxisSetting
            axisLabel="axisTop"
            settings={settings}
            setSettings={setSettings}
          />
        )}
      </WidgetSettingContainer>

      <WidgetSettingContainer title="axisRight">
        <Switch
          checked={Boolean(settings.diagram.axisRight)}
          onChange={(e) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                axisRight: e.target.checked === false ? null : {},
              },
            }))
          }
        />
        {settings.diagram.axisRight && (
          <AxisSetting
            axisLabel="axisRight"
            settings={settings}
            setSettings={setSettings}
          />
        )}
      </WidgetSettingContainer>

      <WidgetSettingContainer title="axisBottom">
        <Switch
          checked={Boolean(settings.diagram.axisBottom)}
          onChange={(e) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                axisBottom: e.target.checked === false ? null : {},
              },
            }))
          }
        />
        {settings.diagram.axisBottom && (
          <AxisSetting
            axisLabel="axisBottom"
            settings={settings}
            setSettings={setSettings}
          />
        )}
      </WidgetSettingContainer>

      <WidgetSettingContainer title="axisLeft" lastRow>
        <Switch
          checked={Boolean(settings.diagram.axisLeft)}
          onChange={(e) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                axisLeft: e.target.checked === false ? null : {},
              },
            }))
          }
        />
        {settings.diagram.axisLeft && (
          <AxisSetting
            axisLabel="axisLeft"
            settings={settings}
            setSettings={setSettings}
          />
        )}
      </WidgetSettingContainer>
    </>
  );
};
