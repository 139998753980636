import { TDbaTable } from "../Types";
import { useLayoutEffect } from "react";
import { useScrollbarWidth } from "../../../utils/hooks/useScrollbarWidth";

export function useFixControlsMode(id: TDbaTable["id"], skipToken: boolean) {
  const OSScrollBarWidth = useScrollbarWidth();

  if (!skipToken && !id) {
    throw new Error('"id" is required while "enableFixControls = true"');
  }

  useLayoutEffect(() => {
    if (skipToken) {
      return;
    }

    const dbaTableLayout = document.getElementById(id!);
    const dbaTableWrapper: HTMLElement | null | undefined =
      dbaTableLayout?.querySelector(".dba-table-wrapper");
    const dbaTable: HTMLElement | null | undefined =
      dbaTableLayout?.querySelector(".dba-table");
    const dbaTableWrapperHeight = dbaTable?.offsetHeight;

    const toolbar: HTMLElement | null | undefined =
      dbaTableLayout?.querySelector(".dba-table-toolbar");
    const toolbarHeight = toolbar?.offsetHeight ?? 0;

    if (dbaTableWrapper && dbaTableWrapperHeight) {
      const hasScroll =
        dbaTableWrapper.scrollWidth > dbaTableWrapper.offsetWidth;
      const scrollBarWidth = hasScroll ? OSScrollBarWidth : 0;

      const outerRules = {
        maxHeight:
          dbaTableWrapperHeight + scrollBarWidth + toolbarHeight + "px",
      };
      Object.assign(dbaTableWrapper.style, outerRules);
    }
  });
}
