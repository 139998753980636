import "./App.css";
import { Route, Routes } from "react-router";
import {
  UserGroups,
  UserGroup,
  Dashboard,
  LoginForm,
  EditUserGroup,
  Measures,
  Measure,
  FileCategories,
  FileCategory,
  Dataset,
  Datasets,
  DashboardsForm,
  DashboardForm,
  DataSource,
  DataSources,
  Widgets,
  Widget,
  Reports,
  Report,
  Files,
  File,
  MapObjects,
  MapObject,
  WidgetEditor,
  FullScreenWidget,
  PageNotFound,
  MapLayers,
  MapLayer,
  Reference,
  References,
  Playlist,
  Playlists,
  WidgetTemplates,
  Users,
  User,
  RecoveryForm,
  RegistrationForm,
  MLDataSetHandler,
} from "./Pages/";
import { PageTemplate } from "./components/PageTemplate/PageTemplate";
import AuthRoute from "./authorization/AuthRoute";
import { PublicRoute } from "./authorization/PublicRoute";
import constants from "./utils/constants";

function App() {
  return (
    <Routes>
      <Route
        path="login"
        element={
          <PublicRoute>
            <LoginForm />
          </PublicRoute>
        }
      />
      <Route path="recovery/:id" element={<RecoveryForm />} />
      <Route path="registration" element={<RegistrationForm />} />
      {constants.experimentalPublicDashboards ? (
        <Route path="/" element={<PageTemplate />}>
          <Route
            path="public-dashboard/:dashboardID"
            element={
              <AuthRoute preventRedirect={true}>
                <Dashboard isPublic={true} />
              </AuthRoute>
            }
          />
        </Route>
      ) : null}
      <Route
        path="/"
        element={
          <AuthRoute>
            <PageTemplate />
          </AuthRoute>
        }
      >
        <Route
          index
          element={
            <AuthRoute>
              <Dashboard />
            </AuthRoute>
          }
        />
        <Route
          path="/:dashboardID"
          element={
            <AuthRoute>
              <Dashboard />
            </AuthRoute>
          }
        />

        <Route path="/:dashboardID/widget">
          <Route
            index
            element={
              <AuthRoute>
                <FullScreenWidget />
              </AuthRoute>
            }
          />
        </Route>

        <Route
          path="userGroups"
          element={
            <AuthRoute isAdminRequired>
              <UserGroups />
            </AuthRoute>
          }
        />
        <Route
          path="userGroup"
          element={
            <AuthRoute isAdminRequired>
              <UserGroup />
            </AuthRoute>
          }
        />
        <Route
          path="edit-userGroup/:id"
          element={
            <AuthRoute isAdminRequired>
              <EditUserGroup />
            </AuthRoute>
          }
        />

        <Route
          path="measures"
          element={
            <AuthRoute isAdminRequired>
              <Measures />
            </AuthRoute>
          }
        />
        <Route
          path="measure"
          element={
            <AuthRoute isAdminRequired>
              <Measure />
            </AuthRoute>
          }
        />
        <Route
          path="edit-measure/:id"
          element={
            <AuthRoute isAdminRequired>
              <Measure />
            </AuthRoute>
          }
        />

        <Route
          path="file-categories"
          element={
            <AuthRoute isAdminRequired>
              <FileCategories />
            </AuthRoute>
          }
        />
        <Route
          path="file-category"
          element={
            <AuthRoute isAdminRequired>
              <FileCategory />
            </AuthRoute>
          }
        />
        <Route
          path="edit-file-category/:id"
          element={
            <AuthRoute isAdminRequired>
              <FileCategory />
            </AuthRoute>
          }
        />

        <Route
          path="dataset"
          element={
            <AuthRoute isAdminRequired>
              <Dataset />
            </AuthRoute>
          }
        />
        <Route
          path="dataset/:id"
          element={
            <AuthRoute isAdminRequired>
              <Dataset />
            </AuthRoute>
          }
        />
        <Route
          path="datasets"
          element={
            <AuthRoute isAdminRequired>
              <Datasets />
            </AuthRoute>
          }
        />

        <Route
          path="dashboards"
          element={
            <AuthRoute isAdminRequired>
              <DashboardsForm />
            </AuthRoute>
          }
        />
        <Route
          path="dashboard"
          element={
            <AuthRoute isAdminRequired>
              <DashboardForm />
            </AuthRoute>
          }
        />
        <Route
          path="dashboard/:id"
          element={
            <AuthRoute isAdminRequired>
              <DashboardForm />
            </AuthRoute>
          }
        />

        <Route
          path="datasources"
          element={
            <AuthRoute isAdminRequired>
              <DataSources />
            </AuthRoute>
          }
        />
        <Route
          path="datasource"
          element={
            <AuthRoute isAdminRequired>
              <DataSource />
            </AuthRoute>
          }
        />
        <Route
          path="edit-datasource/:id"
          element={
            <AuthRoute isAdminRequired>
              <DataSource />
            </AuthRoute>
          }
        />

        <Route
          path="widgets"
          element={
            <AuthRoute isAdminRequired>
              <Widgets />
            </AuthRoute>
          }
        />
        <Route
          path="widget"
          element={
            <AuthRoute isAdminRequired>
              <Widget />
            </AuthRoute>
          }
        />
        <Route
          path="widget/:id"
          element={
            <AuthRoute isAdminRequired>
              <Widget />
            </AuthRoute>
          }
        />

        <Route
          path="reports"
          element={
            <AuthRoute isAdminRequired>
              <Reports />
            </AuthRoute>
          }
        />
        <Route
          path="report"
          element={
            <AuthRoute isAdminRequired>
              <Report />
            </AuthRoute>
          }
        />
        <Route
          path="report/:id"
          element={
            <AuthRoute isAdminRequired>
              <Report />
            </AuthRoute>
          }
        />

        <Route
          path="files"
          element={
            <AuthRoute isAdminRequired>
              <Files />
            </AuthRoute>
          }
        />
        <Route
          path="file"
          element={
            <AuthRoute isAdminRequired>
              <File />
            </AuthRoute>
          }
        />
        <Route
          path="file/:id"
          element={
            <AuthRoute isAdminRequired>
              <File />
            </AuthRoute>
          }
        />

        <Route
          path="mapobjects"
          element={
            <AuthRoute isAdminRequired>
              <MapObjects />
            </AuthRoute>
          }
        />
        <Route
          path="mapobject"
          element={
            <AuthRoute isAdminRequired>
              <MapObject />
            </AuthRoute>
          }
        />
        <Route
          path="mapobject/:id"
          element={
            <AuthRoute isAdminRequired>
              <MapObject />
            </AuthRoute>
          }
        />

        <Route
          path="mapLayers"
          element={
            <AuthRoute isAdminRequired>
              <MapLayers />
            </AuthRoute>
          }
        />
        <Route
          path="mapLayer"
          element={
            <AuthRoute isAdminRequired>
              <MapLayer />
            </AuthRoute>
          }
        />
        <Route
          path="mapLayer/:id"
          element={
            <AuthRoute isAdminRequired>
              <MapLayer />
            </AuthRoute>
          }
        />

        <Route
          path="/widget-templates"
          element={
            <AuthRoute isAdminRequired>
              <WidgetTemplates />
            </AuthRoute>
          }
        />

        <Route
          path="references"
          element={
            <AuthRoute isAdminRequired>
              <References />
            </AuthRoute>
          }
        />
        <Route
          path="reference"
          element={
            <AuthRoute isAdminRequired>
              <Reference />
            </AuthRoute>
          }
        />
        <Route
          path="reference/:id"
          element={
            <AuthRoute isAdminRequired>
              <Reference />
            </AuthRoute>
          }
        />

        <Route
          path="playlists"
          element={
            <AuthRoute>
              <Playlists />
            </AuthRoute>
          }
        />
        <Route
          path="playlist"
          element={
            <AuthRoute isAdminRequired>
              <Playlist />
            </AuthRoute>
          }
        />
        <Route
          path="playlist/:id"
          element={
            <AuthRoute isAdminRequired>
              <Playlist />
            </AuthRoute>
          }
        />

        <Route
          path="widgetEditor/:id"
          element={
            <AuthRoute isAdminRequired>
              <WidgetEditor />
            </AuthRoute>
          }
        />

        <Route
          path="users"
          element={
            <AuthRoute isAdminRequired>
              <Users />
            </AuthRoute>
          }
        />
        <Route
          path="user"
          element={
            <AuthRoute isAdminRequired>
              <User />
            </AuthRoute>
          }
        />
        <Route
          path="user/:id"
          element={
            <AuthRoute isAdminRequired>
              <User />
            </AuthRoute>
          }
        />

        <Route
          path="ml-dataset/:id"
          element={
            <AuthRoute isAdminRequired>
              <MLDataSetHandler />
            </AuthRoute>
          }
        />

        <Route
          path="*"
          element={
            <AuthRoute>
              <PageNotFound />
            </AuthRoute>
          }
        />
      </Route>
    </Routes>
  );
}

export default App;
