import {
  FetchBaseQueryErrorType,
  ENUM,
} from "../features/serviceSlices/SharedTypes";

export const isFetchBaseQueryErrorTypeGuard = (
  error: any
): error is FetchBaseQueryErrorType =>
  error && "status" in error && "data" in error;

export const isEnumGuard = (item: any): item is ENUM =>
  item !== null && typeof item === "object" && "id" in item && "name" in item;
