import { DbaRadialBarChart } from "../../../../common/Diagrams";

export const RadialBarChart = ({ data, settings }) => {
  const diagramData = data.map((dataObj) => {
    const indexes = [].concat.apply(
      [],
      dataObj.data.map((dataObj) => {
        return dataObj[settings.indexBy];
      })
    );

    const objs = indexes.map((item) => {
      let obj = { x: item };
      const objects = dataObj.data.filter((x) => x[settings.indexBy] === item);
      objects.map((item) => {
        return (obj = { ...obj, y: item[settings.value] });
      });
      return obj;
    });

    return { id: dataObj.id, data: objs };
  });

  return <DbaRadialBarChart data={diagramData} settings={settings} />;
};
