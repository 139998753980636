import { SettingsContainer } from "../../SettingsContainer";
import { BaseSettingsProps } from "./Types";
import { SettingsType } from "../../../../../features/serviceSlices/WidgetSettings/Types"
import {
  LineChartInteractivitySettings,
  BarChartInteractivitySettings,
  RadarChartInteractivitySettings,
  AreaBumpChartInteractivitySettings,
  HeatmapChartInteractivitySettings,
  CalendarChartInteractivitySettings,
  TimeRangeChartInteractivitySettings,
  RadialChartInteractivitySettings,
  PieChartInteractivitySettings,
  FunnelChartInteractivitySettings,
} from "./Charts";
import { DiagramTypes } from "../../../../../features/serviceSlices/SpecificWidgets/DiagramWidget/Types";

const getSettings = (
  diagramType: DiagramTypes,
  settings: SettingsType,
  setSettings: (arg: any) => void
) => {
  switch (diagramType) {
    case "Line":
      return (
        <LineChartInteractivitySettings
          settings={settings}
          setSettings={setSettings}
        />
      );
    case "Bar":
      return (
        <BarChartInteractivitySettings
          settings={settings}
          setSettings={setSettings}
        />
      );
    case "Pie":
      return (
        <PieChartInteractivitySettings
          settings={settings}
          setSettings={setSettings}
        />
      );
    case "Radar":
      return (
        <RadarChartInteractivitySettings
          settings={settings}
          setSettings={setSettings}
        />
      );
    case "Funnel":
      return (
        <FunnelChartInteractivitySettings
          settings={settings}
          setSettings={setSettings}
        />
      );
    case "AreaBump":
      return (
        <AreaBumpChartInteractivitySettings
          settings={settings}
          setSettings={setSettings}
        />
      );
    case "Calendar":
      return (
        <CalendarChartInteractivitySettings
          settings={settings}
          setSettings={setSettings}
        />
      );
    case "HeatMap":
      return (
        <HeatmapChartInteractivitySettings
          settings={settings}
          setSettings={setSettings}
        />
      );
    case "RadialBar":
      return (
        <RadialChartInteractivitySettings
          settings={settings}
          setSettings={setSettings}
        />
      );
    case "TimeRange":
      return (
        <TimeRangeChartInteractivitySettings
          settings={settings}
          setSettings={setSettings}
        />
      );
    default:
      return "";
  }
};

export const InteractivitySettings = ({
  settings,
  setSettings,
  diagramType,
}: BaseSettingsProps) => {
  return (
    <SettingsContainer title="interactivitySettings">
      {getSettings(diagramType, settings, setSettings)}
    </SettingsContainer>
  );
};
