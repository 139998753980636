import styled from "@emotion/styled";
import colors from "../../../../../Variables.module.scss";

type ColorBoxProps = {
  color: string;
};

const CSSColorBox = styled.div<ColorBoxProps>`
  height: 40px;
  width: 40px;
  background: ${(props) => props.color};
  border-radius: 4px 0 0 4px;
  cursor: pointer;
`;

const CSSThresholdContainer = styled.div`
  label: ThresholdContainer;
  display: flex;
  align-items: center;
  border-radius: 4px;
  .DbaNumberInput {
    width: calc(100% - 80px);
  }
  .dbaColorInput {
    height: 40px;
    width: 40px;
  }
  .DbaTextField {
    width: calc(100% - 40px);
  }
  .DbaTextField .MuiOutlinedInput-root {
    border-radius: 0 4px 4px 0;
  }
  .MuiOutlinedInput-root {
    border-radius: 0;
  }
  input[type="color"] {
    border: none;
  }
  input[type="color"]::-webkit-color-swatch {
    border-radius: 4px 0 0 4px;
  }
  button {
    border: 1px solid
      ${({ theme }: any) =>
        theme.palette.mode === "dark" ? colors.purple200 : colors.gray};
    border-left: none;
    height: 40px;
  }
`;

const CSSThresholdsContainer = styled.div`
  label: ThresholdsContainer;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .MuiButton-root {
    width: 100%;
  }
`;

const CSSDiagramThresholdsSetting = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CSSThresholdSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 16px 0;
  gap: 16px;
`;

export {
  CSSThresholdContainer,
  CSSThresholdsContainer,
  CSSColorBox,
  CSSDiagramThresholdsSetting,
  CSSThresholdSettingsContainer,
};
