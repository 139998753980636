import { WidgetSettingContainer } from "../WidgetSettingContainer";
import Switch from "@mui/material/Switch";
import { ValueFormatControl } from "./ValueFormatControl";

import { SettingsType } from "../../../../../../../features/serviceSlices/WidgetSettings/Types";

type ValueFormatSettingsProps = {
  property: "valueFormat" | "yFormat" | "xFormat";
  settings: SettingsType;
  setSettings: (arg: any) => void;
};

export const ValueFormatSettings = ({
  settings,
  property,
  setSettings,
}: ValueFormatSettingsProps) => {
  return (
    <WidgetSettingContainer title={property}>
      {settings.diagram[property]}
      <Switch
        checked={
          settings.diagram[property] !== "" &&
          settings.diagram[property] !== undefined
        }
        onChange={(e) =>
          setSettings((prevState: any) => ({
            ...prevState,
            diagram: {
              ...prevState.diagram,
              [property]: e.target.checked === false ? "" : " >-",
            },
          }))
        }
      />
      {settings.diagram[property] !== "" &&
        settings.diagram[property] !== undefined && (
          <ValueFormatControl
            formatLabel={property}
            setSettings={setSettings}
            value={settings.diagram[property]}
          />
        )}
    </WidgetSettingContainer>
  );
};
