import { WidgetSettingContainer } from "../../common";
import type { ChartSettingsProps } from "../Types";
import { DbaColorInput, DbaSlider } from "../../../../../../../DbaComponents";

export const CalendarChartStyleSettings = ({
  settings,
  setSettings,
}: ChartSettingsProps) => {
  return (
    <>
      <WidgetSettingContainer title="emptyCellsColor">
        <DbaColorInput
          value={settings.diagram.emptyColor}
          onChange={(event) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                emptyColor: event.target.value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="paddingHeatMap">
        <DbaSlider
          sx={{ width: 120 }}
          value={settings.diagram.daySpacing ?? 0}
          min={0}
          max={20}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                daySpacing: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="cellBorderWidth">
        <DbaSlider
          sx={{ width: 150 }}
          value={settings.diagram.dayBorderWidth ?? 1}
          min={0}
          max={20}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                dayBorderWidth: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="dayBorderColor">
        <DbaColorInput
          value={settings.diagram.dayBorderColor}
          onChange={(event) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                dayBorderColor: event.target.value,
              },
            }))
          }
        />
      </WidgetSettingContainer>
    </>
  );
};
