import { DbaSaveTemplateModalType, DbaTemplateType } from "./Types";
import Modal from "@mui/material/Modal";
import {
  DbaButton,
  DbaDataFetchSelect,
  DbaTextField,
} from "../../../../../DbaComponents";
import { AddButton, Title } from "../../../../../components/common";
import { useEffect, useState } from "react";
import { DbaTextArea } from "../../../../../DbaComponents/DbaTextArea";
import {
  useAddTemplateWidgetMutation,
  useGetCurrentTemplateMutation,
} from "../../../../../features/serviceSlices/serviceHooks";
import {
  CSSModalContainer,
  CSSTextFieldContainer,
  CSSBtnContainer,
} from "./StyledComponents";

export const SaveTemplateModal = ({
  widgetType,
  setSettings,
  settings,
}: DbaSaveTemplateModalType) => {
  const [addTemplateWidget, respAddTemplateWidget] =
    useAddTemplateWidgetMutation();
  const [getCurrentTemplate, resGetCurrentTemplate] =
    useGetCurrentTemplateMutation();

  const [templateName, setTemplateName] = useState("");
  const [templateDescription, setTemplateDescription] = useState("");
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [templateId, setTemplateId] = useState<DbaTemplateType | null>(null);

  const toggleStateModal = () => {
    setOpen(!open);
  };

  const handleAddTemplateWidget = () => {
    if (templateName.length === 0) {
      setError(true);
    } else {
      setError(false);
      addTemplateWidget({
        name: templateName,
        description: templateDescription,
        widgetType,
        settings,
      });
    }
  };

  useEffect(() => {
    if (templateId && templateId.id) {
      getCurrentTemplate(templateId.id);
    }
  }, [getCurrentTemplate, templateId]);

  useEffect(() => {
    if (resGetCurrentTemplate.status === "fulfilled") {
      setSettings(resGetCurrentTemplate.data.settings);
    }
  }, [
    resGetCurrentTemplate.status,
    resGetCurrentTemplate.data?.settings,
    setSettings,
  ]);

  return (
    <>
      <DbaButton onClick={toggleStateModal} text="addTemplateBtn" />
      <DbaDataFetchSelect
        required
        url={`api/SettingsTemplate/Get?WidgetType=${widgetType}`}
        label="template"
        selectedValue={templateId}
        setSelectedValue={setTemplateId}
        size="small"
        minWidth={'300px'}
        isDynamicHeight={true}
      />
      <Modal open={open}>
        <CSSModalContainer>
          <CSSTextFieldContainer>
            <Title text="addTemplateTitle" />
            <DbaTextField
              size="small"
              label="nameTemplate"
              value={templateName}
              setValue={setTemplateName}
              required
              error={error && templateName === ""}
            />
            <DbaTextArea
              label="description"
              value={templateDescription}
              setValue={setTemplateDescription}
              minRows={1}
              maxRows={6}
            />
          </CSSTextFieldContainer>
          <CSSBtnContainer>
            <DbaButton onClick={toggleStateModal} text="cancel" color="error" />
            <AddButton
              fulfilledHandler={toggleStateModal}
              status={respAddTemplateWidget.status}
              onClick={handleAddTemplateWidget}
            />
          </CSSBtnContainer>
        </CSSModalContainer>
      </Modal>
    </>
  );
};
