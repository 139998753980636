import type { ChartSettingsProps } from "../Types";
import {
  interpolationOptions,
  SettingsToggle,
  WidgetSettingContainer,
  alignOptions,
} from "../../common";
import { DbaSlider, DbaSimpleSelect } from "../../../../../../../DbaComponents";

export const AreaBumpChartBaseSettings = ({
  settings,
  setSettings,
}: ChartSettingsProps) => {
  return (
    <>
      <WidgetSettingContainer title="align">
        <DbaSimpleSelect
          setSelectedValue={(value) => {
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                align: value,
              },
            }));
          }}
          selectedValue={
            settings.diagram.align === undefined
              ? alignOptions.find((option) => option.value === "middle")
              : alignOptions.find(
                  (option) => option.value === settings.diagram.align
                )
          }
          options={alignOptions}
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="interpolation">
        <SettingsToggle
          settings={settings}
          setSettings={setSettings}
          property="interpolation"
          defaultValue="smooth"
          options={interpolationOptions}
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="xPadding">
        <DbaSlider
          sx={{ width: 120 }}
          value={settings.diagram.xPadding ?? 0.6}
          min={0}
          step={0.05}
          max={1}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                xPadding: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="spacing">
        <DbaSlider
          sx={{ width: 150 }}
          value={settings.diagram.spacing ?? 0}
          min={0}
          max={30}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                spacing: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>
    </>
  );
};
