export const notSelectedConditionType = "notSelected";

export const numberFilterOptions = [
  {
    label: "notSelected",
    value: notSelectedConditionType,
  },
  {
    label: "more",
    value: "More",
  },
  {
    label: "equalsOrMore",
    value: "EqualsOrMore",
  },
  {
    label: "less",
    value: "Less",
  },
  {
    label: "equalsOrLess",
    value: "EqualsOrLess",
  },
  {
    label: "equals",
    value: "Equals",
  },
  {
    label: "notEquals",
    value: "NotEquals",
  },
  {
    label: "between",
    value: "Between",
  },
  {
    label: "notBetween",
    value: "NotBetween",
  },
];
