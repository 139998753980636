import { useMemo } from "react";

export const useScrollbarWidth = (): number => {
  return useMemo(getScrollbarWidth, []);
};

const getScrollbarWidth = (): number => {
  const outer = document.createElement("div");
  const outerRules = {
    visibility: "hidden",
    overflow: "scroll",
    msOverflowStyle: "scrollbar", // needed for WinJS apps
  };
  Object.assign(outer.style, outerRules);
  document.body.appendChild(outer);

  const inner = document.createElement("div");
  outer.appendChild(inner);

  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  if (outer.parentNode) {
    outer.parentNode.removeChild(outer);
  }

  return scrollbarWidth;
};
