import { useContext, useState } from "react";
import { ThemeContext } from "../../../../utils/ThemeContext";
import { WidgetLayoutProps } from "./WidgetLayout.types";
import { WidgetRelatedReportType } from "../../../../features/serviceSlices/Widget/Types";
import {
  CSSTitle,
  CSSDiagramContainer,
  CSSWrapper,
  CSSCardHeader,
  CSSLoaderContainer,
} from "./WidgetLayout.styles";
import { WidgetMenu } from "../WidgetMenu";
import { LoadingScreen } from "../../../common";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../../../utils/reduxHooks";
import { DbaWidgetInfoModal } from "../../../../DbaComponents/DbaWidgetInfoModal";
import { WidgetRelatedReportModal } from "../WidgetRelatedReports/WidgetRelatedReportModal";
import { HandleSetActiveRelatedReportType } from "../WidgetRelatedReports/Types";
import { AuthGuard } from "../../../Guards/AuthGuard/AuthGuard";

export const WidgetLayout = ({
  measure,
  children,
  label,
  data,
  type,
  settings,
  setSelectedElement,
  selectedElement,
  settingsId,
  dashboardId,
  widgetID,
  fetching,
  fontColor,
  thresholdFontColor,
}: WidgetLayoutProps) => {
  const { editMode } = useAppSelector((state) => state.app);
  const { darkMode } = useContext(ThemeContext);
  const location = useLocation();
  const widgetEditorMode = location.pathname
    .toLowerCase()
    .includes("widgeteditor");
  const [openWidgetInfoModal, setOpenWidgetInfoModal] = useState(false);
  const [activeRelatedReport, setActiveRelatedReport] =
    useState<WidgetRelatedReportType | null>(null);
  const handleWidgetInfoOpen = () => setOpenWidgetInfoModal(true);
  const handleWidgetInfoClose = () => setOpenWidgetInfoModal(false);
  const handleSetActiveRelatedReport: HandleSetActiveRelatedReportType = (
    reportOrFalse: WidgetRelatedReportType | false
  ) => {
    // Для показа модалки с отчетом нужно передать отчет, для скрытия - null.
    // Благодаря этому нам не нужно в цикле генерить список модалок - по модалке на каждый отчет.
    // Достаточно одной модалки.
    reportOrFalse
      ? setActiveRelatedReport(reportOrFalse)
      : setActiveRelatedReport(null);
  };

  return (
    <>
      <CSSWrapper type={type}>
        {!location.search.includes("id") && (
          <CSSCardHeader
            onClick={() =>
              !editMode && widgetEditorMode !== true && handleWidgetInfoOpen()
            }
            className={editMode ? "grid-item" : ""}
            darkMode={darkMode}
            editMode={editMode}
            widgetEditorMode={widgetEditorMode}
            visible={settings?.header?.visible}
          >
            <CSSTitle
              onClick={
                setSelectedElement
                  ? () => setSelectedElement("header")
                  : () => null
              }
              selected={selectedElement === "header"}
              editMode={editMode}
              widgetEditorMode={widgetEditorMode}
              visible={settings?.header?.visible}
              fontWeight={settings?.header?.fontWeight}
              fontSize={settings?.header?.fontSize}
              fontColor={
                fontColor && thresholdFontColor
                  ? fontColor
                  : settings?.fontColor
              }
              gradientColor={
                thresholdFontColor ? null : settings?.gradientColor
              }
              darkMode={darkMode}
              textAlign={settings?.header?.textAlign}
            >
              {measure ? `${label}, ${measure}` : label}
            </CSSTitle>
          </CSSCardHeader>
        )}
        <AuthGuard>
          <WidgetMenu
            dashboardId={dashboardId}
            widgetID={widgetID}
            settingsId={settingsId!}
            type={type}
            openWidgetInfoModal={handleWidgetInfoOpen}
            editMode={editMode}
            widgetLinks={data?.widgetLinks ? data.widgetLinks : null}
            widgetRelatedReports={
              data?.widgetReports?.length ? data.widgetReports : null
            }
            handleSetActiveRelatedReport={handleSetActiveRelatedReport}
          />
        </AuthGuard>
        <CSSDiagramContainer
          visible={settings?.header?.visible}
          fontSize={settings?.header?.fontSize}
          type={type}
        >
          {children}
        </CSSDiagramContainer>
        {fetching && (
          <CSSLoaderContainer>
            <LoadingScreen />
          </CSSLoaderContainer>
        )}
      </CSSWrapper>
      <DbaWidgetInfoModal
        isOpen={openWidgetInfoModal}
        onClose={handleWidgetInfoClose}
        data={data!}
      />
      <WidgetRelatedReportModal
        isOpen={!!activeRelatedReport}
        setIsOpen={handleSetActiveRelatedReport}
        report={activeRelatedReport}
        dashboardId={dashboardId}
      />
    </>
  );
};
