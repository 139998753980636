export const notSelectedConditionType = "notSelected";

export const dateFilterOptions = [
  {
    label: "notSelected",
    value: notSelectedConditionType,
  },
  {
    label: "dateExact",
    value: "DateExact",
  },
  {
    label: "dateAfter",
    value: "DateAfter",
  },
  {
    label: "dateBefore",
    value: "DateBefore",
  },
];
