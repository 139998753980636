import React, { useContext, useMemo } from "react";
import Widget from "../../components/Widgets/Widget";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import { useAppSelector, useAppDispatch } from "../../utils/reduxHooks";
import { LoadingScreen } from "../../components/common";
import { layoutChange } from "../../features/slices/App/appSlice";
import { Navigate, useParams, useSearchParams } from "react-router-dom";
import { TDashboard } from "./Types";
import { usePlaylistMode } from "./hooks/usePlaylistMode";
import { useDefaultMode } from "./hooks/useDefaultMode";
import { usePublicMode } from "./hooks/usePublicMode";
import { ErrorMessageByCode } from "../../components/common/ErrorMessageByCode";
import { EErrorCodes } from "../../features/serviceSlices/SharedTypes";
import { CSSErrorMessagesContainer } from "./Dashboard.styles";
import { ThemeContext } from "../../utils/ThemeContext";

const ResponsiveGridLayout = WidthProvider(Responsive);

export const Dashboard: React.FC<TDashboard> = ({ isPublic = false }) => {
  const { darkMode } = useContext(ThemeContext);

  const dispatch = useAppDispatch();
  const params = useParams<Record<string, string | undefined>>();
  const [searchParams] = useSearchParams();

  const { role, editMode, authorizationInfo } = useAppSelector(
    (state) => state.app
  );

  const isPlaylistPlayingMode = !!searchParams.get("playlist");

  const defaultMode = useDefaultMode({
    skip: isPublic || isPlaylistPlayingMode,
  });
  const publicMode = usePublicMode({ skip: !isPublic || !!authorizationInfo });
  const playlistMode = usePlaylistMode({
    skip: isPublic || !isPlaylistPlayingMode,
  });

  const isLoading =
    defaultMode.isLoading || playlistMode.isLoading || publicMode.isLoading;
  const isSuccess =
    defaultMode.isSuccess || playlistMode.isSuccess || publicMode.isSuccess;

  const onLayoutChange = (layout: unknown, layouts: unknown): void => {
    dispatch(layoutChange(JSON.stringify(layouts)));
  };

  const layoutObj = useMemo(
    () => (role.layout !== "" ? JSON.parse(role.layout) : []),
    [role.layout]
  );

  if (isPublic && authorizationInfo) {
    return <Navigate to={`/${params.dashboardID}`} />;
  }
  if (isLoading) {
    return <LoadingScreen />;
  }

  const error =
    (!isPlaylistPlayingMode && defaultMode.error) ||
    (isPlaylistPlayingMode && playlistMode.error);
  if (error) {
    return (
      <CSSErrorMessagesContainer darkMode={darkMode}>
        <ErrorMessageByCode code={error?.status} />
      </CSSErrorMessagesContainer>
    );
  }
  if (publicMode.error) {
    return (
      <CSSErrorMessagesContainer darkMode={darkMode}>
        <ErrorMessageByCode code={EErrorCodes.NOT_FOUND} />
      </CSSErrorMessagesContainer>
    );
  }

  return isSuccess && role.layout ? (
    <ResponsiveGridLayout
      breakpoints={{ md: 996, sm: 768 }}
      layouts={layoutObj}
      cols={{ md: 24, sm: 6 }}
      rowHeight={16}
      isDraggable={editMode}
      isResizable={editMode}
      draggableHandle=".grid-item"
      onLayoutChange={(current: unknown, all: unknown) =>
        onLayoutChange(current, all)
      }
      compactType="horizontal"
    >
      {role.widgets.map((item) => (
        <Widget
          // Требует перевода на ts файла src/components/Widgets/Widget.jsx
          // @ts-ignore
          widgetType={item.widgetType}
          item={item}
          key={item.id}
          id={item.id}
          layout={layoutObj}
        />
      ))}
    </ResponsiveGridLayout>
  ) : null;
};
