import { DbaSlider } from "../../../../../../../DbaComponents";
import { WidgetSettingContainer } from "../../common";
import type { ChartSettingsProps } from "../Types";
import Switch from "@mui/material/Switch";

export const PieChartLabelSettings = ({
  settings,
  setSettings,
}: ChartSettingsProps) => {
  return (
    <>
      <WidgetSettingContainer title="enableLabel">
        <Switch
          checked={settings.diagram.enableArcLabels ?? true}
          onChange={(e) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                enableArcLabels: e.target.checked,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="labelsSkipAngle">
        <DbaSlider
          sx={{ width: 120 }}
          min={0}
          max={45}
          value={settings.diagram.arcLabelsSkipAngle ?? 0}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                arcLabelsSkipAngle: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>
    </>
  );
};
