import styled from "@emotion/styled";

import { WIDGET_CONTAINER_MARGIN } from "../WidgetContainer/WidgetContainer";

export const CSSScrollContainer = styled.div`
  overflow: auto;
  height: calc(100% - 0.9rem);
  margin: 0 -${WIDGET_CONTAINER_MARGIN};
  padding: 0 ${WIDGET_CONTAINER_MARGIN};
`;
