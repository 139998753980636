import { rootApi } from "../serviceRoot";
import {
  TMLDataSetSummaryGetResponse,
  TSendMLTaskResponse,
  TSendMLTaskRequest,
  TCreateTaskToDetectDataTypesResponse,
  TCreateTaskToDetectDataTypesRequest,
  TConfirmDataTypesResponse,
  TConfirmDataTypesRequest,
  TRemoveMLTasksRequest,
  TRemoveMLTasksResponse,
  TMLDataSetWithDataQualityInspectedGetRequest,
  TMLDataSetWithDataQualityInspectedGetResponse,
} from "./Types";

export const mlDataSetApiSlice = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getMLDataSetSummary: builder.query<TMLDataSetSummaryGetResponse, string>({
      query: (id) => `api/AutoML/GetDataSet?DataSetId=${id}`,
      providesTags: ["MLDateSetSummary"],
    }),
    createTaskToDetectDataTypes: builder.mutation<
      TCreateTaskToDetectDataTypesResponse,
      TCreateTaskToDetectDataTypesRequest
    >({
      query: (payload) => ({
        url: "api/MLTaskQueue/SendTask",
        method: "POST",
        body: payload,
      }),
    }),
    confirmDataTypes: builder.mutation<
      TConfirmDataTypesResponse,
      TConfirmDataTypesRequest
    >({
      query: (payload) => ({
        url: "api/AutoML/UpdateDataTypesDescription",
        method: "POST",
        body: payload,
      }),
    }),
    sendMlTask: builder.mutation<TSendMLTaskResponse, TSendMLTaskRequest>({
      query: (payload) => ({
        url: "api/MLTaskQueue/SendTask",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["MLDateSetSummary"],
    }),
    removeMlTasks: builder.mutation<
      TRemoveMLTasksResponse,
      TRemoveMLTasksRequest
    >({
      query: (payload) => ({
        url: "api/MLTaskQueue/RemoveTasks",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["MLDateSetSummary"],
    }),
    getMLDataSetWithDataQualityInspected: builder.query<
      TMLDataSetWithDataQualityInspectedGetResponse,
      TMLDataSetWithDataQualityInspectedGetRequest
    >({
      query: ({ id, pageSize, pageIndex, sortOptions }) => {
        return {
          url: `api/AutoML/GetResult`,
          params: {
            DataSetId: id,
            PageSize: pageSize,
            Page: pageIndex,
            "SortOptions.ColumnName": sortOptions?.columnName,
            "SortOptions.OrderType": sortOptions?.orderType,
          },
        };
      },
    }),
  }),
});

export const {
  useCreateTaskToDetectDataTypesMutation,
  useConfirmDataTypesMutation,
  useLazyGetMLDataSetSummaryQuery,
  useGetMLDataSetSummaryQuery,
  useSendMlTaskMutation,
  useRemoveMlTasksMutation,
  useLazyGetMLDataSetWithDataQualityInspectedQuery,
} = mlDataSetApiSlice;
