import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useIntl } from "react-intl";
import { LoadingScreen, ErrorMessage } from "../../components/common";
import { useLazyGetWidgetTemplateQuery } from "../../features/serviceSlices/serviceHooks";
import { useDeleteWidgetTemplateMutation } from "../../features/serviceSlices/serviceHooks";
import { DbaTable } from "../../DbaComponents";
import { DbaSnackbar } from "../../DbaComponents";
import styled from "@emotion/styled";
import { WidgetTemplatesResponse } from "../../features/serviceSlices/WidgetSettings/Types";
import { ColumnDef } from "@tanstack/react-table";
import { DeleteButton } from "../../components/TableHelpers/DeleteButton";
import { Box } from "@mui/material";

const CSSWidgetTemplatesContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const WidgetTemplates = () => {
  const intl = useIntl();

  const [IsShowDeleted, setShowDeleted] = useState(false);
  const [deleteSettingTemplate, deleteResponse] =
    useDeleteWidgetTemplateMutation();
  const [getSettingsTemplate, getSettingsTemplateResponse] =
    useLazyGetWidgetTemplateQuery();

  useEffect(() => {
    getSettingsTemplate(IsShowDeleted);
  }, [IsShowDeleted, getSettingsTemplate]);

  const deleteHandler = useCallback(
    (obj: WidgetTemplatesResponse) => deleteSettingTemplate(obj.id),
    [deleteSettingTemplate]
  );

  const changeIsDeletedHandler = useCallback(
    (event: React.BaseSyntheticEvent) => setShowDeleted(event.target.checked),
    []
  );

  const tableData: any = getSettingsTemplateResponse.data;

  const columns: ColumnDef<WidgetTemplatesResponse>[] = useMemo(
    () => [
      {
        id: intl.messages["name"] as string,
        accessorFn: (row) => row.name,
      },
      {
        id: intl.messages["widgetType"] as string,
        accessorFn: (row) => row.widgetType,
      },
      {
        id: intl.messages["creator"] as string,
        accessorFn: (row) => row.creator,
      },
      {
        id: intl.messages["status"] as string,
        accessorFn: (row) =>
          row.isDeleted ? intl.messages["deleted"] : intl.messages["active"],
      },
      {
        id: "deletion",
        header: "",
        cell: (props) => (
          <Box sx={{ textAlign: "right" }}>
            <DeleteButton row={props.row} deleteDataHandler={deleteHandler} />
          </Box>
        ),
      },
    ],
    [deleteHandler, intl.messages]
  );

  if (getSettingsTemplateResponse.isError) {
    return <ErrorMessage />;
  }
  if (getSettingsTemplateResponse.isSuccess) {
    return (
      <CSSWidgetTemplatesContainer>
        <DbaTable
          id="widget-templates-form-table"
          columns={columns}
          data={tableData}
          headToolbar={{
            showDeletedSwitch: true,
            deletedSwitchValue: IsShowDeleted,
            onDeletedSwitchChange: changeIsDeletedHandler,
          }}
        />
        <DbaSnackbar
          error={deleteResponse.isError}
          success={deleteResponse.isSuccess}
        />
      </CSSWidgetTemplatesContainer>
    );
  } else {
    return <LoadingScreen />;
  }
};
