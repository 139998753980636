import styled from "@emotion/styled";
import colors from "../../Variables.module.scss";
const { darkBlue, white, light } = colors;

const CSSLoginFormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: ${colors.dark};
  background: ${({ theme }: any) =>
    theme.palette.mode === "dark"
      ? `${colors.dark} linear-gradient(110.73deg, #8676FF 0%, rgba(134, 118, 255, 0) 73.4%) `
      : `linear-gradient(283.79deg, rgba(123, 97, 255, 0.82) 0%, rgba(246, 247, 251, 0) 98.12%)`};
`;

const CSSFormBox = styled.div`
  color: ${({ theme }: any) =>
    theme.palette.mode === "dark" ? light : darkBlue};
  width: calc(100% - 2rem);
  max-width: 30rem;
  min-height: 25rem;
  background: ${({ theme }: any) =>
    theme.palette.mode === "dark" ? darkBlue : white};
  border-radius: 0.5rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;
const CSSLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const CSSLogoBox = styled.div`
  height: 120px;
`;
const CSSWelcomeText = styled.p`
  font-size: clamp(1.125rem, 2.5vw, 1.75rem);
  font-weight: 400;
`;
const CSSForm = styled.form`
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
`;
const CSSPasswordContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
`;

export {
  CSSForm,
  CSSFormBox,
  CSSLoginFormContainer,
  CSSLogoBox,
  CSSLogoContainer,
  CSSPasswordContainer,
  CSSWelcomeText,
};
