import styled from "@emotion/styled";

const CSSFilesContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const CSSFieldsContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

const CSSInputBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const CSSLabel = styled.div`
  font-size: 1.25rem;
`;

const CSSSelectCategoryContainer = styled.div`
  display: flex;
  gap: 8px;
  div {
    width: 100%;
  }
`;

export {
  CSSFilesContainer,
  CSSFieldsContainer,
  CSSLabel,
  CSSInputBlock,
  CSSSelectCategoryContainer,
};
