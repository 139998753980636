import React, { useMemo, useEffect, useState, useCallback } from "react";
import {
  DbaModal,
  DbaAlert,
  DbaSnackbar,
  DbaButton,
  DbaTable,
} from "../../DbaComponents";
import { useIntl } from "react-intl";
import styled from "@emotion/styled";
import {
  useTruncateDatasetTableMutation,
  useDropDatasetTableMutation,
  useLazyGetDatasetTableDataQuery,
} from "../../features/serviceSlices/serviceHooks";
import { locale } from "../../utils/locale";
import {
  Delete as DeleteIcon,
  CleaningServices as CleaningServicesIcon,
  AutoDelete as AutoDeleteIcon,
} from "@mui/icons-material";
import { DbaPopover } from "../../DbaComponents/DbaPopover";
import { DeleteByPeriodModal } from "./DeleteByPeriodModal/DeleteByPeriodModal";
import { ColumnDef, PaginationState } from "@tanstack/react-table";
import { TFilterData, TSortOptions } from "../../DbaComponents/DbaTable/Types";
import { LoadingScreen } from "../../components/common";
import { dataSetToFilterTypeMapper } from "../../utils/helpers/mappers";

const PAGE_SIZE = 10;

const CSSButtonBlock = styled.div`
  display: flex;
  gap: 8px;
`;

const CSSDataContainer = styled.footer`
  height: 500px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

type TViewDataProps = {
  id: string | null;
  setSelectedId: (arg: any) => void;
};

export const ViewData = ({ id, setSelectedId }: TViewDataProps) => {
  const intl = useIntl();

  const [getDatasetTableData, datasetTableData] =
    useLazyGetDatasetTableDataQuery();

  const [open, setOpen] = useState<boolean>(true);

  const [truncateTable, truncateTableResponse] =
    useTruncateDatasetTableMutation();
  const [dropTable, dropTableResponse] = useDropDatasetTableMutation();

  const [truncateIconElement, setTruncateIconElement] = useState<any>(null);
  const [deleteIconElement, setDeleteIconElement] = useState<any>(null);

  const [pageIndex, setPageIndex] = useState<PaginationState["pageIndex"]>(0);
  const [tableFilters, setTableFilters] = useState<TFilterData[]>([]);
  const [sortOptions, setSortOptions] = useState<TSortOptions>();

  const [isDeleteByPeriodModalOpen, setIsDeleteByPeriodModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (id) {
      getDatasetTableData({
        id: id,
        pageSize: PAGE_SIZE,
        pageIndex: pageIndex + 1,
        sortOptions,
        filterOptions: JSON.stringify(tableFilters ?? []),
      });
    }
  }, [getDatasetTableData, id, pageIndex, sortOptions, tableFilters]);

  const columns: ColumnDef<any>[] = useMemo(() => {
    return (
      datasetTableData.data?.columns
        ?.map((column) => ({
          id: column.name,
          header: column.name,
          accessorFn: (row: any) => row[column.name],
          order: column.orderBy ?? Infinity,
          meta: {
            dataType: dataSetToFilterTypeMapper[column.type] ?? "String",
          },
        }))
        .sort((a, b) => a.order - b.order) ?? []
    );
  }, [datasetTableData.data?.columns]);

  const onServerPageChange = useCallback(
    (event: React.BaseSyntheticEvent, newPage: number) => {
      setPageIndex(newPage);
    },
    []
  );

  const onTableFiltersChange = useCallback((data: TFilterData[]) => {
    setPageIndex(0);
    setTableFilters(data);
  }, []);

  const onSortingChange = useCallback((options: TSortOptions) => {
    setSortOptions(options);
  }, []);

  const onTruncateHandler = () => {
    if (id) {
      truncateTable({ dataSetId: id });
      setTruncateIconElement(null);
    }
  };
  const onCancelTruncateHandler = () => {
    setTruncateIconElement(null);
  };

  const onCancelDeleteHandler = () => {
    setDeleteIconElement(null);
  };
  const onDeleteHandler = () => {
    if (id) {
      dropTable({ dataSetId: id });
      setDeleteIconElement(null);
    }
  };

  const onDeleteByPeriodClick = () => setIsDeleteByPeriodModalOpen(true);
  const onDeleteByPeriodSuccess = useCallback(() => {
    if (id) {
      getDatasetTableData({
        id: id,
        pageSize: PAGE_SIZE,
        pageIndex: pageIndex + 1,
        sortOptions,
      });
      setIsDeleteByPeriodModalOpen(false);
    }
  }, [pageIndex, getDatasetTableData, id, sortOptions]);

  const getErrorMessage = (error: any) => {
    if ("status" in error && error.data) {
      return error?.data?.message;
    } else {
      return intl.messages["checkDataRequest"];
    }
  };

  const getTableName = () =>
    datasetTableData?.data ? datasetTableData.data.tableName : "";

  const getUpdateDate = () =>
    datasetTableData?.data
      ? datasetTableData.data.lastUpdate == null
        ? ""
        : `${new Date(datasetTableData.data.lastUpdate).toLocaleDateString(
            locale
          )} ${new Date(datasetTableData.data.lastUpdate).toLocaleTimeString(
            locale
          )}`
      : "";

  const getModalTitle = () =>
    `${getTableName()} (${
      intl.messages["lastUpdateDate"]
    }: ${getUpdateDate()}) `;

  const getModalContent = () => {
    let content = null;
    if (datasetTableData.isLoading) {
      content = <LoadingScreen />;
    } else if (datasetTableData.isError) {
      content = (
        <DbaAlert
          severity="error"
          text={getErrorMessage(datasetTableData.error)}
        />
      );
    } else if (id && columns.length) {
      content = (
        <DbaTable
          id={`${id}-view-data-table`}
          data={datasetTableData?.data?.data ?? []}
          columns={columns}
          headToolbar={{
            showHeadToolbar: false,
          }}
          pagination={{
            enableServerPagination: true,
            serverRowsCount: datasetTableData?.data?.count ?? 0,
            showRowsPerPageSelector: false,
            serverRowsPerPage: PAGE_SIZE,
            serverPageIndex: pageIndex,
            onServerPageChange,
          }}
          sorting={{
            enableServerSorting: !!onSortingChange,
            onSortingChange: onSortingChange,
          }}
          filters={{
            enableFilters: true,
            filtersList: tableFilters,
            onFiltersListChange: onTableFiltersChange,
          }}
          stylesSettings={{
            heightReducer: "38px", // Высота блока кнопок управления сверху с отступом
          }}
          isLoading={datasetTableData.isFetching}
        />
      );
    }
    return content;
  };

  return (
    <DbaModal
      onCloseHandler={() => {
        setPageIndex(0);
        setSelectedId(null);
      }}
      fullWidth
      title={getModalTitle()}
      open={open}
      setOpen={setOpen}
    >
      <CSSDataContainer>
        <CSSButtonBlock>
          {!(datasetTableData.isError || datasetTableData.isLoading) ? (
            <DbaButton
              text="truncate"
              onClick={(e) => setTruncateIconElement(e.currentTarget)}
              endIcon={<CleaningServicesIcon />}
              size="small"
            />
          ) : null}
          <DbaPopover
            styles={{ margin: "10px 0 0 0" }}
            onCloseModal={onCancelTruncateHandler}
            onDeleteItem={onTruncateHandler}
            anchorEl={truncateIconElement}
            setAnchorEl={setTruncateIconElement}
            customConfirmMessage="confirmTruncateMessage"
            actionMessage="truncate"
          />
          {!datasetTableData.isLoading ? (
            <DbaButton
              color="error"
              text="delete"
              onClick={(e) => setDeleteIconElement(e.currentTarget)}
              endIcon={<DeleteIcon />}
              size="small"
            />
          ) : null}
          <DbaPopover
            styles={{ margin: "10px 0 0 0" }}
            onCloseModal={onCancelDeleteHandler}
            onDeleteItem={onDeleteHandler}
            anchorEl={deleteIconElement}
            setAnchorEl={setDeleteIconElement}
            adviceMessage={"deleteTableAdvice"}
            customConfirmMessage={"deleteTableActionMessage"}
          />
          {!(datasetTableData.isError || datasetTableData.isLoading) ? (
            <DbaButton
              color="warning"
              text="deleteDataByPeriod"
              onClick={onDeleteByPeriodClick}
              endIcon={<AutoDeleteIcon />}
              size="small"
            />
          ) : null}
        </CSSButtonBlock>
        {getModalContent()}
      </CSSDataContainer>
      <DbaSnackbar
        error={truncateTableResponse.isError || dropTableResponse.isError}
        success={false}
      />
      {id ? (
        <DeleteByPeriodModal
          key={String(isDeleteByPeriodModalOpen)}
          dataSetId={id}
          isOpen={isDeleteByPeriodModalOpen}
          setIsOpen={setIsDeleteByPeriodModalOpen}
          onSuccess={onDeleteByPeriodSuccess}
        />
      ) : null}
    </DbaModal>
  );
};
