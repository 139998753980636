import { DbaSimpleSelect } from "../../../../../../DbaComponents";
import { MotionConfigOptions } from "./MotionConfigOptions";
import { SettingsType } from "../../../../../../features/serviceSlices/WidgetSettings/Types";

const MotionConfigSettings = ({
  settings,
  setSettings,
  defaultValue,
}: {
  settings: SettingsType;
  setSettings: (arg: any) => void;
  defaultValue: string;
}) => {
  return (
    <DbaSimpleSelect
      setSelectedValue={(value) => {
        setSettings((prevState: any) => ({
          ...prevState,
          diagram: {
            ...prevState.diagram,
            motionConfig: value,
          },
        }));
      }}
      selectedValue={
        settings.diagram.motionConfig === undefined
          ? MotionConfigOptions.find((option) => option.value === defaultValue)
          : MotionConfigOptions.find(
              (option) => option.value === settings.diagram.motionConfig
            )
      }
      options={MotionConfigOptions}
    />
  );
};

export { MotionConfigSettings };
