const slicesOptions = [
  {
    value: false,
    label: "Нет",
  },
  {
    value: "x",
    label: "По оси X",
  },
  {
    value: "y",
    label: "По оси Y",
  },
];

const crosshairTypeOptions = [
  {
    value: "x",
    label: "X",
  },
  {
    value: "y",
    label: "Y",
  },
  {
    value: "top-left",
    label: "Вверх-влево",
  },
  {
    value: "top",
    label: "Вверх",
  },
  {
    value: "top-right",
    label: "Вверх-вправо",
  },
  {
    value: "right",
    label: "Вправо",
  },
  {
    value: "bottom-right",
    label: "Вниз-вправо",
  },
  {
    value: "bottom",
    label: "Вниз",
  },
  {
    value: "bottom-left",
    label: "Вниз-влево",
  },
  {
    value: "left",
    label: "Влево",
  },
  {
    value: "cross",
    label: "Перекрестие",
  },
];

const transitionModeOptions = [
  {
    value: "startAngle",
    label: "startAngle",
  },
  {
    value: "middleAngle",
    label: "middleAngle",
  },
  {
    value: "endAngle",
    label: "endAngle",
  },
  {
    value: "inerRadius",
    label: "inerRadius",
  },
  {
    value: "centerRadius",
    label: "centerRadius",
  },
  {
    value: "outerRadius",
    label: "outerRadius",
  },
  {
    value: "pushIn",
    label: "pushIn",
  },
  {
    value: "pushOut",
    label: "pushOut",
  },
];

export { slicesOptions, crosshairTypeOptions, transitionModeOptions };
