import { Russian_Locale } from "./Russian_Locale";
import "./CronGenerator.css";
import { DbaCronGeneratorProps } from "./Types";
import Cron, { CronError } from "react-js-cron";
import { useState, useEffect } from "react";
import styles from "./DbaCronGenerator.module.scss";
import { DbaButton } from "../DbaButton/DbaButton";
import { DbaTextField } from "../DbaTextField";

export const DbaCronGenerator = ({
  value,
  setValue,
  setError,
}: DbaCronGeneratorProps) => {
  const [error, onError] = useState<CronError>();

  useEffect(() => {
    setError(Boolean(error));
  }, [error, setError]);

  return (
    <div className={styles.cronGeneratorContainer}>
      <div className={styles.cronGeneratorRow}>
        <DbaTextField
          size="small"
          label="cronExpression"
          value={value}
          setValue={setValue}
        />
        <DbaButton text="clearInput" onClick={() => setValue("* * * * *")} />
      </div>
      <Cron
        clearButton={false}
        locale={Russian_Locale}
        onError={onError}
        value={value}
        setValue={(value: string) => {
          setValue(value);
        }}
      ></Cron>
      <p className={styles.error}>{error && error.description}</p>
    </div>
  );
};
