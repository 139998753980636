import { SettingsContainer } from "../../SettingsContainer";
import { BaseSettingsProps } from "./Types";
import { SettingsType } from "../../../../../features/serviceSlices/WidgetSettings/Types"
import {
  AreaBumpChartLabelSettings,
  BarChartLabelSettings,
  CalendarChartLabelSettings,
  FunnelChartLabelSettings,
  PieChartLabelSettings,
  TimeRangeChartLabelSettings,
  HeatmapChartLabelSettings,
  RadialChartLabelSettings,
} from "./Charts";
import { DiagramTypes } from "../../../../../features/serviceSlices/SpecificWidgets/DiagramWidget/Types";

const getSettings = (
  diagramType: DiagramTypes,
  settings: SettingsType,
  setSettings: (arg: any) => void
) => {
  switch (diagramType) {
    case "Bar":
      return (
        <BarChartLabelSettings settings={settings} setSettings={setSettings} />
      );
    case "Pie":
      return (
        <PieChartLabelSettings settings={settings} setSettings={setSettings} />
      );
    case "Funnel":
      return (
        <FunnelChartLabelSettings
          settings={settings}
          setSettings={setSettings}
        />
      );
    case "AreaBump":
      return (
        <AreaBumpChartLabelSettings
          settings={settings}
          setSettings={setSettings}
        />
      );
    case "Calendar":
      return (
        <CalendarChartLabelSettings
          settings={settings}
          setSettings={setSettings}
        />
      );
    case "HeatMap":
      return (
        <HeatmapChartLabelSettings
          settings={settings}
          setSettings={setSettings}
        />
      );
    case "RadialBar":
      return (
        <RadialChartLabelSettings
          settings={settings}
          setSettings={setSettings}
        />
      );
    case "TimeRange":
      return (
        <TimeRangeChartLabelSettings
          settings={settings}
          setSettings={setSettings}
        />
      );
    default:
      return "";
  }
};

export const LabelSettings = ({
  settings,
  setSettings,
  diagramType,
}: BaseSettingsProps) => {
  return (
    <SettingsContainer title="arcLabelsSettings">
      {getSettings(diagramType, settings, setSettings)}
    </SettingsContainer>
  );
};
