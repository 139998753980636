import React, { useMemo, useCallback } from "react";
import {
  useGetDashboardsQuery,
  useDeleteDashboardMutation,
} from "../../features/serviceSlices/serviceHooks";
import { DbaTable, DbaSnackbar } from "../../DbaComponents";
import styled from "@emotion/styled";
import { LoadingScreen } from "../../components/common";
import { Dashboard } from "../../features/serviceSlices/Dashboards/Types";
import { useIntl } from "react-intl";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import { ColumnDef } from "@tanstack/react-table";
import { EditButton } from "../../components/TableHelpers/EditButton";
import { DeleteButton } from "../../components/TableHelpers/DeleteButton";

const BIG_SIZE_TO_PUSH_EDIT_COLUMN = 2000;
const ACTION_COLUMN_SIZE = 44;

const CSSDashboardContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const DashboardsForm = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [deleteDashboard, deleteResponse] = useDeleteDashboardMutation();
  const response = useGetDashboardsQuery();

  const onAddButtonClick = useCallback(() => {
    navigate("/dashboard");
  }, [navigate]);

  const deleteHandler = useCallback(
    (data: Dashboard) => deleteDashboard(data.id),
    [deleteDashboard]
  );

  const tableData = response.data;

  const columns: ColumnDef<Dashboard>[] = useMemo(
    () => [
      {
        id: intl.messages["name"] as string,
        accessorFn: (row) => row.name,
        cell: (props) => {
          return (
            <Link
              underline="hover"
              component={RouterLink}
              to={{
                pathname: `/dashboard/${props.row.original.id}`,
              }}
              sx={{ color: "inherit" }}
            >
              {props.row.original.name}
            </Link>
          );
        },
        size: BIG_SIZE_TO_PUSH_EDIT_COLUMN,
      },
      {
        id: intl.messages["description"] as string,
        accessorFn: (row) => row.description,
        size: BIG_SIZE_TO_PUSH_EDIT_COLUMN,
      },
      {
        id: intl.messages["assignedGroups"] as string,
        accessorFn: (row) => row.usedByGroups,
        size: BIG_SIZE_TO_PUSH_EDIT_COLUMN,
      },
      {
        id: "edition",
        header: "",
        cell: (props) => <EditButton editLink="/dashboard/" row={props.row} />,
        size: ACTION_COLUMN_SIZE,
      },
      {
        id: "deletion",
        header: "",
        cell: (props) => (
          <DeleteButton row={props.row} deleteDataHandler={deleteHandler} />
        ),
        size: ACTION_COLUMN_SIZE,
      },
    ],
    [deleteHandler, intl.messages]
  );

  return response.isLoading ? (
    <LoadingScreen />
  ) : (
    <CSSDashboardContainer>
      <DbaTable
        id="dashboards-form-table"
        columns={columns}
        data={tableData ?? []}
        headToolbar={{ onAddButtonClick }}
        stylesSettings={{ autoColumnsWidth: false }}
      />
      <DbaSnackbar
        error={deleteResponse.isError}
        success={deleteResponse.isSuccess}
      />
    </CSSDashboardContainer>
  );
};
