import { styled as muiStyled } from "@mui/material/styles";
import styled from "@emotion/styled";
import MuiInput from "@mui/material/Input";
// import OutlinedInput from "@mui/material/OutlinedInput";

const CSSSmallInput = muiStyled(MuiInput)`
  width: 60px;
`;

const CSSSliderContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const CSSLabelContainer = styled.span`
  margin-right: 0.5rem;
`;

export { CSSSmallInput, CSSSliderContainer, CSSLabelContainer };
