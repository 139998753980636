import React, { useEffect, useState } from "react";
import { TNumberFilter } from "../../../../../Types";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { notSelectedConditionType, numberFilterOptions } from "./constants";
import { useIntl } from "react-intl";
import { DbaTextField } from "../../../../../../DbaTextField";
import { DbaButton } from "../../../../../../DbaButton";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";

type TValue = "Single" | "Start" | "End";

export const NumberFilter: React.FC<TNumberFilter> = ({ filter, onChange }) => {
  const intl = useIntl();

  const [filterData, setFilterData] = useState<TNumberFilter["filter"]>();

  const isDoubleField = !!(
    filterData?.type && ["Between", "NotBetween"].includes(filterData?.type)
  );

  const onFilterConditionChange = (event: SelectChangeEvent<any>) => {
    setFilterData({
      ...filterData!,
      type:
        event.target.value !== notSelectedConditionType
          ? event.target.value
          : undefined,
      value: undefined,
    });
  };

  const onFilterValueChange = (type: TValue) => (value: string) => {
    if (type === "Single") {
      setFilterData({
        ...filterData!,
        value: value,
      });
    } else if (type === "Start") {
      setFilterData({
        ...filterData!,
        value: [
          value,
          (Array.isArray(filterData?.value) && filterData?.value[1]) || "",
        ],
      });
    } else if (type === "End") {
      setFilterData({
        ...filterData!,
        value: [
          (Array.isArray(filterData?.value) && filterData?.value[0]) || "",
          value,
        ],
      });
    }
  };

  const onApplyClick = () => {
    onChange(filterData!);
  };

  const onResetClick = (event: React.BaseSyntheticEvent) => {
    event.preventDefault();
    setFilterData({
      ...filterData!,
      type: undefined,
      value: undefined,
    });
  };

  useEffect(() => {
    setFilterData(filter);
  }, [filter]);

  const isApplyBtnDisabled =
    !!(filterData?.type && !filterData?.value) ||
    (isDoubleField &&
      Array.isArray(filterData?.value) &&
      Number(filterData?.value[0]) >= Number(filterData?.value[1]));

  return (
    <Stack spacing={2}>
      <FormControl>
        <InputLabel>{intl.messages["filterByCondition"]}</InputLabel>
        <Select
          value={filterData?.type ?? notSelectedConditionType}
          label={intl.messages["filterByCondition"]}
          onChange={onFilterConditionChange}
        >
          {numberFilterOptions.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {intl.messages[item.label]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        {!isDoubleField ? (
          <DbaTextField
            value={filterData?.value ?? ""}
            setValue={onFilterValueChange("Single")}
            label="value"
            disabled={!filterData?.type}
            type="number"
            autoComplete="off"
          />
        ) : (
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <DbaTextField
              value={
                (Array.isArray(filterData?.value) && filterData?.value[0]) || ""
              }
              setValue={onFilterValueChange("Start")}
              label="from"
              disabled={!filterData?.type}
              type="number"
              autoComplete="off"
            />
            <DbaTextField
              value={
                (Array.isArray(filterData?.value) && filterData?.value[1]) || ""
              }
              setValue={onFilterValueChange("End")}
              label="to"
              disabled={!filterData?.type}
              type="number"
              autoComplete="off"
            />
          </Stack>
        )}
      </FormControl>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Link
          underline="hover"
          component={RouterLink}
          to={{
            pathname: `/`,
          }}
          sx={{ color: "inherit" }}
          onClick={onResetClick}
        >
          {intl.messages["resetInfinitive"]}
        </Link>
        <DbaButton
          color="success"
          text="apply"
          onClick={onApplyClick}
          disabled={isApplyBtnDisabled}
        />
      </Stack>
    </Stack>
  );
};
