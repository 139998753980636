import { rootApi } from "../../serviceRoot";
import {
  CreateMapWidgetRequest,
  TMapWidgetResponse,
  TMapWidgetData,
  TMapWidgetDataResponse,
  UpdateMapWidgetRequest,
} from "./Types";

export const mapWidgetApiSlice = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getMapWidgetData: builder.query<
      TMapWidgetDataResponse,
      { widgetId: string; dashboardId: string }
    >({
      query: ({ widgetId, dashboardId }) => ({
        url: "api/Widget/GetData",
        method: "GET",
        params: {
          widgetId: widgetId,
          dashboardId: dashboardId,
        },
      }),
      providesTags: (result, error, arg) => {
        if (result) {
          return [{ type: "mapWidget", id: result.widgetData?.id }];
        }
        return ["mapWidget"];
      },
    }),
    getMapWidget: builder.query<TMapWidgetResponse, { widgetID: string }>({
      query: ({ widgetID }) => ({
        url: `api/MapWidget/Get?WidgetID=${widgetID}`,
      }),
      providesTags: (result, error, arg) => {
        if (result) {
          return [{ type: "mapWidget", id: result.id }];
        }
        return ["mapWidget"];
      },
    }),
    createMapWidget: builder.mutation<
      TMapWidgetData,
      CreateMapWidgetRequest
    >({
      query: (payload) => ({
        url: "api/MapWidget/Create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Widgets"],
    }),
    updateMapWidget: builder.mutation<
      TMapWidgetData,
      UpdateMapWidgetRequest
    >({
      query: (payload) => ({
        url: "api/MapWidget/Update",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: (result, error, arg) => {
        if (result) {
          return [{ type: "mapWidget", id: result.id }, "Widgets"];
        }
        return ["mapWidget", "Widgets"];
      },
    }),
  }),
});

export const {
  useCreateMapWidgetMutation,
  useUpdateMapWidgetMutation,
  useGetMapWidgetDataQuery,
  useGetMapWidgetQuery,
  useLazyGetMapWidgetDataQuery,
  useLazyGetMapWidgetQuery,
} = mapWidgetApiSlice;
