import { ChartSettingsPropsWithMapping } from "../Types";
import { DbaSimpleSelect } from "../../../../../../../DbaComponents";
import {
  ValueFormatSettings,
  WidgetSettingContainer,
  MinMaxValueSettings,
} from "../../common";

export const RadarChartBaseSettings = ({
  settings,
  setSettings,
  dataMappingOptions,
}: ChartSettingsPropsWithMapping) => {
  return (
    <>
      <WidgetSettingContainer title="idWidgetSettings">
        <DbaSimpleSelect
          options={dataMappingOptions}
          selectedValue={settings.diagram.indexBy}
          setSelectedValue={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                indexBy: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>
      <WidgetSettingContainer title="keysWidgetSettings">
        <DbaSimpleSelect
          options={dataMappingOptions}
          selectedValue={dataMappingOptions.filter((option) =>
            settings.diagram.keys?.includes(option)
          )}
          multiple
          setSelectedValue={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                keys: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <ValueFormatSettings
        setSettings={setSettings}
        settings={settings}
        property="valueFormat"
      />

      <WidgetSettingContainer title="max">
        <MinMaxValueSettings
          settings={settings}
          setSettings={setSettings}
          value={settings.diagram.maxValue}
          property="maxValue"
        />
      </WidgetSettingContainer>
    </>
  );
};
