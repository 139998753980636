import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useIntl } from "react-intl";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import styled from "@emotion/styled";
import {
  useGetAllMapLayersQuery,
  useDeleteMapLayerMutation,
} from "../../features/serviceSlices/serviceHooks";
import { DbaTable, DbaSnackbar } from "../../DbaComponents";
import { LoadingScreen, ErrorMessage } from "../../components/common";
import { MapLayerType } from "../../features/serviceSlices/MapLayer/Types";
import { FetchBaseQueryErrorType } from "../../features/serviceSlices/SharedTypes";
import { ColumnDef } from "@tanstack/react-table";
import { EditButton } from "../../components/TableHelpers/EditButton";
import { DeleteButton } from "../../components/TableHelpers/DeleteButton";

const BIG_SIZE_TO_PUSH_EDIT_COLUMN = 2000;
const ACTION_COLUMN_SIZE = 44;

const CSSMapLayerContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const MapLayers = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [error, setError] = useState("");

  const [deleteMapLayer, deleteResponse] = useDeleteMapLayerMutation();
  const mapLayers = useGetAllMapLayersQuery();

  const onAddButtonClick = useCallback(() => {
    navigate("/mapLayer");
  }, [navigate]);

  const deleteHandler = useCallback(
    (obj: MapLayerType) => {
      deleteMapLayer({ id: obj.id });
    },
    [deleteMapLayer]
  );

  const tableData = mapLayers.data;

  const columns: ColumnDef<MapLayerType>[] = useMemo(
    () => [
      {
        id: intl.messages["name"] as string,
        accessorFn: (row) => row.name,
        cell: (props) => {
          return (
            <Link
              underline="hover"
              component={RouterLink}
              to={{
                pathname: `/mapLayer/${props.row.original.id}`,
              }}
              sx={{ color: "inherit" }}
            >
              {props.row.original.name}
            </Link>
          );
        },
        size: BIG_SIZE_TO_PUSH_EDIT_COLUMN,
      },
      {
        id: "edition",
        header: "",
        cell: (props) => <EditButton editLink="/mapLayer/" row={props.row} />,
        size: ACTION_COLUMN_SIZE,
      },
      {
        id: "deletion",
        header: "",
        cell: (props) => (
          <DeleteButton row={props.row} deleteDataHandler={deleteHandler} />
        ),
        size: ACTION_COLUMN_SIZE,
      },
    ],
    [deleteHandler, intl.messages]
  );

  const isFetchBaseQueryErrorType = (
    error: any
  ): error is FetchBaseQueryErrorType => "status" in error;

  useEffect(() => {
    if (deleteResponse.error) {
      if (isFetchBaseQueryErrorType(deleteResponse.error)) {
        setError(deleteResponse.error.data.message);
      }
    }
  }, [deleteResponse.error]);

  if (mapLayers.isError) {
    return <ErrorMessage />;
  }
  if (mapLayers.isLoading) {
    return <LoadingScreen />;
  }
  return (
    <CSSMapLayerContainer>
      <DbaTable
        id="map-layers-form-table"
        columns={columns}
        data={tableData ?? []}
        headToolbar={{ onAddButtonClick }}
        stylesSettings={{ autoColumnsWidth: false }}
      />
      <DbaSnackbar
        duration={10000}
        errorMessage={error}
        error={deleteResponse.isError}
        success={deleteResponse.isSuccess}
      />
    </CSSMapLayerContainer>
  );
};
