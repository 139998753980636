import styled from "@emotion/styled";
import colors from "../../Variables.module.scss";
import { DbaContainerProps } from "./DbaQueryCommand.types";

const CSSInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const CSSFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const CSSButtonsBlock = styled.div<DbaContainerProps>`
  display: flex;
  gap: 1rem;
`;

const CSSQueryCommandContainer = styled.div<DbaContainerProps>`
  position: relative;
  background: ${({ darkMode }) => (darkMode ? colors.dark : colors.light)};
  min-height: 100vh;
  padding: 1rem;
  width: 100%;
  margin: auto;
  border: ${({ darkMode }) =>
    darkMode ? `1px solid ${colors.purple900}` : "none"};
  border-radius: 16px;
  box-shadow: ${({ darkMode }) => (darkMode ? "none" : colors.bsCardLight)};
`;

const CSSEditorContainer = styled.div`
  margin-top: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const CSSGetTableDataContainer = styled.div`
  label: GetTableDataContainer;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const CSSGetTableDataRow = styled.div`
  label: GetTableDataRow;
  display: flex;
  gap: 1rem;
`;

export {
  CSSInputsContainer,
  CSSButtonsBlock,
  CSSQueryCommandContainer,
  CSSEditorContainer,
  CSSFieldsContainer,
  CSSGetTableDataContainer,
  CSSGetTableDataRow,
};
