import { ResponsiveBar } from "@nivo/bar";
import { clampBuilder } from "../../../../utils/helpers/functions";
import { useMediaQuery } from "@mui/material";
import { getLegendOrientation } from "./utils";
import { GetTheme } from "../Theme";
import { DbaPortalWrapper } from "../../../../DbaComponents/DbaPortalWrapper";
import { DbaDiagramTooltip } from "./DbaDiagramTooltip";
import { useRef } from "react";

export const DbaResponsiveBar = ({ settings, data, widgetSettings }) => {
  const thresholds = widgetSettings.widget?.thresholds;
  const thresholdSettings = settings.thresholdSettings;
  const mobileView = useMediaQuery("(max-width:1000px)");
  const clampBuilderFunction = mobileView
    ? clampBuilder(1, 1000, thresholdSettings?.fontSize)
    : clampBuilder(1001, 1920, thresholdSettings?.fontSize);

  const tooltipContainerRef = useRef(null);

  return (
    <ResponsiveBar
      data={data}
      keys={settings.keys}
      indexBy={settings.indexBy}
      groupMode={settings.groupMode}
      layout={settings.layout}
      valueScale={settings.valueScale}
      reverse={settings.reverse}
      minValue={settings.minValue}
      maxValue={settings.maxValue}
      valueFormat={settings.valueFormat}
      padding={settings.padding}
      innerPadding={settings.innerPadding}
      pixelRatio={settings.pixelRatio}
      margin={settings.margin}
      colors={settings.colors}
      tooltip={(props) => {
        return (
          <div ref={tooltipContainerRef}>
            <DbaPortalWrapper
              left={tooltipContainerRef.current?.getBoundingClientRect().left}
              top={tooltipContainerRef.current?.getBoundingClientRect().top}
            >
              <DbaDiagramTooltip
                markerColor={props.color}
                label={props.label}
                value={props.formattedValue}
              />
            </DbaPortalWrapper>
          </div>
        );
      }}
      colorBy={settings.colorBy}
      borderRadius={settings.borderRadius}
      borderWidth={settings.borderWidth}
      borderColor={
        settings.borderColor ?? {
          from: "color",
          modifiers: [["darker", 1.6]],
        }
      }
      defs={settings.defs}
      fill={settings.fill}
      enableLabel={settings.enableLabel}
      label={(d) => d.formattedValue.replaceAll(",", " ")}
      labelSkipWidth={settings.labelSkipWidth}
      labelSkipHeight={settings.labelSkipHeight}
      enableGridX={settings.enableGridX}
      enableGridY={settings.enableGridY}
      axisTop={settings.axisTop}
      axisRight={settings.axisRight}
      axisBottom={settings.axisBottom}
      axisLeft={settings.axisLeft}
      isInteractive={settings.isInteractive}
      animate={settings.animate}
      motionConfig={settings.motionConfig}
      isFocusable={settings.isFocusable}
      legends={settings.legends}
      theme={GetTheme(settings)}
      markers={
        thresholds && settings.showThresholds
          ? thresholds.map((item) => ({
              axis: thresholdSettings?.axe ?? "y",
              value: item.value,
              legend: item.value,
              legendOrientation: getLegendOrientation(thresholdSettings?.axe),
              textStyle: {
                fill: item.color,
                fontSize: thresholdSettings?.fontSize
                  ? clampBuilderFunction
                  : 16,
              },
              lineStyle: {
                stroke: item.color,
                strokeWidth: settings?.thresholdSettings?.width ?? 2,
                strokeDasharray: Boolean(thresholdSettings?.dashedLine)
                  ? 5
                  : null,
              },
            }))
          : null
      }
    />
  );
};
