type GroupType = string[];

type ExpressionType = {
  field: string;
  condition: string;
  values: string;
};

type ExpressionsType = ExpressionType[];

type OrderType = {
  column: string;
  sort: string;
};

type OrdersType = OrderType[];

type ExtendedPart = {
  type: "Field" | "Operand" | "Const";
  operand: null | string;
  column: null | string;
  function: null | string;
  id: string;
};

type ExtendedParts = ExtendedPart[];

type SelectType = {
  alias: string;
  parts: ExtendedParts;
  isExtended: boolean;
};

type SelectsType = SelectType[];

type MacrosType = {
  macrosType: string;
};

type MacrosesType = MacrosType[];

//Reducer types
type ExpressionActionType =
  | { type: "addExpression" }
  | {
      type: "changeExpression";
      payload: ExpressionType;
      initialObj: ExpressionType;
    }
  | { type: "deleteExpression"; payload: ExpressionType }
  | {
      type: "loadExpressions";
      payload: ExpressionsType;
    };

type MacrosActionType =
  | { type: "addMacros" }
  | {
      type: "changeMacros";
      payload: MacrosType;
      initialObj: MacrosType;
    }
  | { type: "deleteMacros"; payload: MacrosType }
  | {
      type: "loadMacroses";
      payload: MacrosesType;
    };

type GroupActionType =
  | { type: "addGroup" }
  | {
      type: "changeGroup";
      payload: string;
      idx: number;
    }
  | { type: "deleteGroup"; payload: string }
  | {
      type: "loadGroup";
      payload: GroupType;
    };

type OrderActionType =
  | { type: "addOrder" }
  | {
      type: "changeOrder";
      payload: OrderType;
      initialObj: OrderType;
    }
  | { type: "deleteOrder"; payload: OrderType }
  | { type: "loadOrder"; payload: OrdersType };

type SelectActionType =
  | {
      type: "addSelect";
      widgetType?: "Filter" | "Default" | "Diagram" | "Table";
    }
  | {
      type: "changeSelect";
      payload: SelectType;
      initialObj: SelectType;
    }
  | { type: "deleteSelect"; payload: SelectType }
  | { type: "loadSelect"; payload: SelectsType };

type QueryObjectType = {
  group: null | string[];
  order: null | { column: string; sort: string }[];
  select: SelectsType;
  table: string;
  time: string;
  where: {
    expression: null | ExpressionsType;
    macroses: null | MacrosesType;
  };
};

type QueryConstructorProps = {
  getQuery: (arg: { queryObject: string }) => void;
  getQueryResponse: any;
  setParsedData: (value: any, key: string) => void;
  data: QueryObjectType | null;
  widgetType: "Diagram" | "Default" | "Filter" | "Table";
  dataSourceId: string | undefined;
  queryCommand: string;
};

export enum EAliasesDefaults {
  label = "label",
  key = "key",
  value = "value",
}

export type {
  GroupType,
  ExpressionType,
  ExpressionsType,
  OrdersType,
  OrderType,
  SelectType,
  SelectsType,
  MacrosType,
  MacrosesType,
  MacrosActionType,
  ExpressionActionType,
  GroupActionType,
  OrderActionType,
  SelectActionType,
  QueryObjectType,
  QueryConstructorProps,
  ExtendedPart,
  ExtendedParts,
};
