import styled from "@emotion/styled";
import colors from "../../../../../Variables.module.scss";
import { forwardRef } from "react";
import PrintIcon from "@mui/icons-material/Print";
import { useReactToPrint } from "react-to-print";
import { Tooltip } from "@mui/material";
import { useIntl } from "react-intl";

const CSSPrintControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${colors.darkBlue};
  background: ${colors.white};
  width: 30px;
  height: 30px;
  border-radius: 2px;
  box-shadow: ${colors.bsMapIconLight};
  /* padding: ".5rem"; */
  cursor: pointer;

  &:hover {
    background: #f4f4f4;
  }
`;
export const PrintControl = forwardRef((props, ref: any) => {
  const intl = useIntl();

  const handlePrint = useReactToPrint({
    content: () => ref.ref.current,
  });

  return (
    <Tooltip title={intl.messages["print"]} placement="top" arrow>
      <CSSPrintControlContainer>
        <PrintIcon onClick={handlePrint} />
      </CSSPrintControlContainer>
    </Tooltip>
  );
});
