import { useContext, useState } from "react";
import { MapWidgetConstructorProps } from "./MapWidgetConstructor.types";
import {
  CSSConstructorContainer,
  CSSEditableWidgetContainer,
} from "./MapWidgetConstructor.styles";
import { WidgetConstructorToolbar } from "../WidgetConstructorToolbar";
import {
  WidgetContainer,
  WidgetLayout,
} from "../../../../components/Widgets/common";
import { DbaErrorBoundary } from "../../../../DbaComponents";
import { ThemeContext } from "../../../../utils/ThemeContext";
import { WidgetElementTypes } from "../DataWidgetConstructor/DataWidgetConstructor.types";
import { MapContainer, TileLayer } from "react-leaflet";
import styles from "./Map.module.scss";

export const MapWidgetConstructor = ({
  settings,
  setSettings,
  widgetData,
}: MapWidgetConstructorProps) => {
  const { darkMode } = useContext(ThemeContext);
  const [selectedElement, setSelectedElement] =
    useState<WidgetElementTypes | null>("body");
  return (
    <CSSConstructorContainer>
      <WidgetConstructorToolbar
        widgetType="Map"
        settings={settings}
        setSettings={setSettings}
        selectedElement={selectedElement}
        setSelectedElement={setSelectedElement}
      />
      <CSSEditableWidgetContainer>
        <DbaErrorBoundary>
          <WidgetContainer
            type="Map"
            darkMode={darkMode}
            body={settings.widget.body}
          >
            <WidgetLayout
              settings={settings.widget}
              label={widgetData.label}
              type="Map"
              editMode={true}
              setSelectedElement={setSelectedElement}
              selectedElement={selectedElement}
              dashboardId="12"
              widgetID="12"
            >
              <MapContainer
                center={[61.79, 90.83]}
                zoom={3}
                maxZoom={20}
                scrollWheelZoom={false}
                className={styles.mapContainer}
              >
                <TileLayer
                  attribution={
                    '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  }
                  url={"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"}
                  subdomains={["a", "b", "c"]}
                />
              </MapContainer>
            </WidgetLayout>
          </WidgetContainer>
        </DbaErrorBoundary>
      </CSSEditableWidgetContainer>
    </CSSConstructorContainer>
  );
};
