import { ColumnOrderState } from "@tanstack/react-table";
import { TColumnId } from "../Types";

export const filterData = <T>(searchText: string, data: T[]): T[] => {
  return data.filter((item) => {
    const mappedData = Object.values(item).filter((column) => {
      if (typeof column === "string" || typeof column === "number") {
        if (typeof column === "string") {
          return column.toLowerCase().includes(searchText.toLowerCase());
        }
        return column
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase());
      }
      return false;
    });
    return mappedData.length > 0;
  });
};

export const reorderColumn = (
  draggedColumnId: TColumnId,
  targetColumnId: TColumnId,
  columnOrder: ColumnOrderState
): ColumnOrderState => {
  const newColumnOrder = [...columnOrder];
  newColumnOrder.splice(
    newColumnOrder.indexOf(targetColumnId),
    0,
    newColumnOrder.splice(
      newColumnOrder.indexOf(draggedColumnId),
      1
    )[0] as string
  );
  return [...newColumnOrder];
};
