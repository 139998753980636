import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useGetTableWidgetDataQuery } from "../../../../features/serviceSlices/serviceHooks";
import { useAppSelector } from "../../../../utils/reduxHooks";
import { LoadingScreen, ErrorMessage } from "../../../common";
import type { WidgetProps } from "../Widgets.types";
import { TableWidget } from "../../common";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  TFilterData,
  TSortOptions,
} from "../../../../DbaComponents/DbaTable/Types";
import { ColumnOrderState } from "@tanstack/react-table";
import { convertFilterTableDatesToISOUsingExternalTimeZone } from "../../../../utils/helpers/dateTime";
import { store } from "../../../../utils/store";
import { CSSWrapper } from "../../common/WidgetLayout/WidgetLayout.styles";
import { AuthGuard } from "../../../Guards/AuthGuard/AuthGuard";
import { WidgetMenu } from "../../common/WidgetMenu";
import { FetchBaseQueryErrorType } from "../../../../features/serviceSlices/SharedTypes";

export const Table = ({ id, setBody, setResizeHandlerColor }: WidgetProps) => {
  const {
    type,
    startDate,
    endDate,
    role,
    filters,
    timezone,
    editMode,
    authorizationInfo,
  } = useAppSelector((state) => state.app);
  const [idGenerated] = useState<number>(() => Math.random());
  const [tableFilters, setTableFilters] = useState<TFilterData[]>([]);
  const [sortOptions, setSortOptions] = useState<TSortOptions>();
  const [columnOrder, setColumnOrder] = React.useState<ColumnOrderState>([]);

  const tableWidgetData = useGetTableWidgetDataQuery(
    type !== "NotSet" && type
      ? {
          widgetId: id,
          dashboardId: role.id,
          startDate,
          endDate,
          periodType: type,
          filters: JSON.stringify(filters),
          sortOptions,
          filterOptions: JSON.stringify(
            convertFilterTableDatesToISOUsingExternalTimeZone(
              tableFilters,
              store.getState().app.timezone.id
            ) ?? []
          ),
        }
      : skipToken
  );

  const onTableFiltersChange = useCallback((data: TFilterData[]) => {
    setTableFilters(data);
  }, []);

  const onSortingChange = useCallback((options: TSortOptions) => {
    setSortOptions(options);
  }, []);

  const onColumnSortingChange = useCallback((order: ColumnOrderState) => {
    setColumnOrder(order);
  }, []);

  const tableData = useMemo(() => {
    if (!columnOrder.length) {
      return tableWidgetData.data?.widgetData!;
    }
    const headers = tableWidgetData.data?.widgetData.headers!;
    const reorderedHeaders = columnOrder.map((key, index) => ({
      ...headers[index],
      order: columnOrder.indexOf(headers[index].field),
    }));
    return {
      ...tableWidgetData.data?.widgetData!,
      headers: reorderedHeaders,
    };
  }, [columnOrder, tableWidgetData.data?.widgetData]);

  useEffect(() => {
    if (
      tableWidgetData.data &&
      tableWidgetData.data?.widgetData.widgetSettings?.settings?.widget?.body
    ) {
      setBody(
        tableWidgetData.data.widgetData.widgetSettings.settings.widget.body
      );
    }
  }, [tableWidgetData.data, setBody]);

  useEffect(() => {
    if (
      tableWidgetData.data &&
      tableWidgetData.data?.widgetData.widgetSettings?.settings?.widget
        ?.fontColor
    ) {
      setResizeHandlerColor(
        tableWidgetData.data.widgetData.widgetSettings.settings.widget
          ?.fontColor
      );
    }
  }, [tableWidgetData.data, setResizeHandlerColor]);

  const getErrorMessage = () => {
    return (
      <CSSWrapper>
        <ErrorMessage
          error={tableWidgetData.error as FetchBaseQueryErrorType}
          isShowTooltip={!!authorizationInfo?.isAdmin}
        />
        <AuthGuard>
          <WidgetMenu
            dashboardId={role.id}
            widgetID={id}
            type={"Table"}
            editMode={editMode}
            isError={true}
          />
        </AuthGuard>
      </CSSWrapper>
    );
  };

  return tableWidgetData.isLoading ? (
    <LoadingScreen />
  ) : tableWidgetData.data ? (
    <TableWidget
      id={`${id}-${idGenerated}-form-table`}
      data={tableData}
      appTimezone={timezone.id}
      onSortingChange={onSortingChange}
      showModalLoadingIndicator={tableWidgetData.isFetching}
      onColumnSortingChange={onColumnSortingChange}
      tableFilters={tableFilters}
      onTableFiltersChange={onTableFiltersChange}
    />
  ) : (
    getErrorMessage()
  );
};
