import { LineChartScaleConfiguration } from "./LineChartScaleConfiguration";
import { WidgetSettingAccordion } from "../../common/WidgetSettingAccordion";
import { ValueFormatSettings, WidgetSettingContainer } from "../../common";
import type { ChartSettingsPropsWithMapping } from "../Types";
import { DbaSimpleSelect } from "../../../../../../../DbaComponents";

export const LineChartBaseSettings = ({
  settings,
  setSettings,
  dataMappingOptions,
}: ChartSettingsPropsWithMapping) => {
  return (
    <>
      <WidgetSettingContainer title="idWidgetSettings">
        <DbaSimpleSelect
          options={dataMappingOptions}
          selectedValue={settings.diagram.indexBy ?? "x"}
          setSelectedValue={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                indexBy: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="keyWidgetSettings">
        <DbaSimpleSelect
          options={dataMappingOptions}
          selectedValue={settings.diagram.value}
          setSelectedValue={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <ValueFormatSettings
        setSettings={setSettings}
        settings={settings}
        property="xFormat"
      />
      <WidgetSettingAccordion title="xScaleConf">
        <LineChartScaleConfiguration
          settings={settings.diagram}
          setSettings={setSettings}
          label="xScale"
        />
      </WidgetSettingAccordion>
      <ValueFormatSettings
        setSettings={setSettings}
        settings={settings}
        property="yFormat"
      />
      <WidgetSettingAccordion title="yScaleConf">
        <LineChartScaleConfiguration
          settings={settings.diagram}
          setSettings={setSettings}
          label="yScale"
        />
      </WidgetSettingAccordion>
    </>
  );
};
