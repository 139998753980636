import React from "react";
import { DbaTable } from "../../../../../DbaComponents";
import { ColumnDef } from "@tanstack/react-table";
import { TPlaylistDashboardTableUsage } from "../../Types";
import { PlaylistPeriodSelector } from "../PlaylistPeriodSelector/PlaylistPeriodSelector";
import { DeleteButton } from "../../../../../components/TableHelpers/DeleteButton";
import { useIntl } from "react-intl";
import {
  TDashboardPeriodType,
  TPlaylistDashboard,
} from "../../../../../features/serviceSlices/Playlists/Types";
import { TDashboardTable } from "./Types";
import { OrderCell } from "./components/OrderCell/OrderCell";
import set from "date-fns/set";

const BIG_SIZE_COLUMN = 2000;
const ACTION_COLUMN_SIZE = 44;

export const DashboardsTable: React.FC<TDashboardTable> = ({
  playlistDashboards,
  setPlaylistDashboards,
  isUpdating,
}) => {
  const intl = useIntl();

  const defaultDate = set(new Date(), {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

  const moveDashboardUpOrDownInsideTable = (
    row: TPlaylistDashboardTableUsage,
    direction: "up" | "down"
  ) => {
    const indexDifference = direction === "up" ? -1 : 1;
    const currentIndex = playlistDashboards.findIndex(
      (dashboard) => dashboard.dashboardIdForTable === row.dashboardIdForTable
    );
    let updatedPlaylistDashboards = [...playlistDashboards];
    if (currentIndex || currentIndex === 0) {
      updatedPlaylistDashboards = updatedPlaylistDashboards.filter(
        (dashboard) => dashboard.dashboardIdForTable !== row.dashboardIdForTable
      );
      updatedPlaylistDashboards.splice(currentIndex + indexDifference, 0, row);
    }
    setPlaylistDashboards(updatedPlaylistDashboards);
  };

  const onUpDashboardClick = (row: TPlaylistDashboardTableUsage) => () => {
    moveDashboardUpOrDownInsideTable(row, "up");
  };

  const onDownDashboardClick = (row: TPlaylistDashboardTableUsage) => () => {
    moveDashboardUpOrDownInsideTable(row, "down");
  };

  const onChangePeriodType =
    (row: TPlaylistDashboardTableUsage) =>
    (event: React.BaseSyntheticEvent, periodType: string) => {
      if (!periodType) {
        return;
      }
      setPlaylistDashboards(
        playlistDashboards.map((dashboard) =>
          dashboard.dashboardIdForTable === row.dashboardIdForTable
            ? {
                ...dashboard,
                periodType: periodType as TDashboardPeriodType,
              }
            : dashboard
        )
      );
    };

  const onChangePeriod =
    (row: TPlaylistDashboardTableUsage) =>
    (period: TPlaylistDashboard["period"]) => {
      setPlaylistDashboards(
        playlistDashboards.map((dashboard) =>
          dashboard.dashboardIdForTable === row.dashboardIdForTable
            ? {
                ...dashboard,
                period,
              }
            : dashboard
        )
      );
    };

  const onChangeIsCurrent =
    (row: TPlaylistDashboardTableUsage) =>
    (event: React.BaseSyntheticEvent) => {
      setPlaylistDashboards(
        playlistDashboards.map((dashboard) =>
          dashboard.dashboardIdForTable === row.dashboardIdForTable
            ? {
                ...dashboard,
                isCurrent: event.target.checked,
                period: event.target.checked ? null : defaultDate,
              }
            : dashboard
        )
      );
    };

  const onDashboardDeleteClick = (row: TPlaylistDashboardTableUsage) => {
    setPlaylistDashboards(
      playlistDashboards.filter(
        (dashboard) => dashboard.dashboardIdForTable !== row.dashboardIdForTable
      )
    );
  };

  const columns: ColumnDef<TPlaylistDashboardTableUsage>[] = [
    {
      id: "orderBy",
      header: intl.messages["orderBy"] as string,
      cell: (props) => (
        <OrderCell
          row={props.row.original}
          playlistDashboards={playlistDashboards}
          onUpDashboardClick={onUpDashboardClick}
          onDownDashboardClick={onDownDashboardClick}
          isUpdating={isUpdating}
        />
      ),
      size: ACTION_COLUMN_SIZE,
    },
    {
      id: intl.messages["name"] as string,
      accessorFn: (row) => row.name,
      size: BIG_SIZE_COLUMN,
      enableSorting: false,
    },
    {
      id: "periods",
      header: intl.messages["periods"] as string,
      cell: (props) => {
        return (
          <PlaylistPeriodSelector
            disabled={isUpdating}
            onStartDateChange={onChangePeriod(props.row.original)}
            onPeriodChange={onChangePeriodType(props.row.original)}
            startDate={(props.row.original.period ?? defaultDate) as Date}
            type={props.row.original.periodType}
            isCurrent={props.row.original.isCurrent}
            onIsCurrentChange={onChangeIsCurrent(props.row.original)}
          />
        );
      },
      size: BIG_SIZE_COLUMN,
    },
    {
      id: "deletion",
      header: "",
      cell: (props) => (
        <DeleteButton
          disabled={isUpdating}
          row={props.row}
          deleteDataHandler={onDashboardDeleteClick}
        />
      ),
      size: ACTION_COLUMN_SIZE,
    },
  ];

  return playlistDashboards.length ? (
    <DbaTable
      columns={columns}
      data={playlistDashboards ?? []}
      pagination={{ showPagination: false }}
      stylesSettings={{ autoColumnsWidth: false }}
      fixControls={{ enableFixControls: false }}
    />
  ) : null;
};
