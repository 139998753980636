import { DbaFunnelChart } from "../../../../common/Diagrams";

export const FunnelChart = ({ data, settings }) => {

  let merged = [].concat.apply([], data.map((item) => {
    return item.data.map((item) => {
      return item
    })
  }));

  if (settings.defaultValueSorting)
    merged = merged.sort((a, b) => (
      settings.sortDirection === "asc" ? a.value > b.value ? 1 : -1 : a.value < b.value ? 1 : -1))


  return (
    <DbaFunnelChart
      data={merged}
      settings={settings}
    />
  )
}

