import { useEffect, useLayoutEffect } from "react";
import {
  diasRoleChange,
  periodChange,
  editModeChange,
} from "../../../features/slices/App/appSlice";
import { useAppSelector, useAppDispatch } from "../../../utils/reduxHooks";
import { useLocation, useParams } from "react-router-dom";
import {
  useGetAvailableQuery,
  useLazyGetDashboardByIdQuery,
} from "../../../features/serviceSlices/serviceHooks";
import { skipToken } from "@reduxjs/toolkit/query";
import { TUseModeReturn } from "../Types";

type TUseDefaultMode = {
  skip: boolean;
};

export const useDefaultMode = (options: TUseDefaultMode): TUseModeReturn => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const params = useParams<Record<string, string | undefined>>();

  const responseGetAvailable = useGetAvailableQuery(
    options.skip ? skipToken : undefined
  );
  const [getDashboard, dashboard] = useLazyGetDashboardByIdQuery();

  const { role, roles, editMode, type } = useAppSelector((state) => state.app);
  const dashboardIdToLoad = (params.dashboardID as string) ?? roles[0]?.id;

  const getIsLoading = () => {
    if (options.skip) {
      return false;
    }
    // В режиме редактирования никогда не показываем loading indicator во время загрузки дашборда
    if (editMode) {
      return false;
    }
    if (dashboard.isFetching && type === "NotSet") {
      return true;
    }
    if (dashboard.isFetching && role.id !== params.dashboardID) {
      return true;
    }
    return responseGetAvailable.isLoading || dashboard.isLoading;
  };

  useEffect(() => {
    if (!options.skip) {
      if (!dashboardIdToLoad) {
        return;
      }
      dispatch(periodChange("NotSet"));
      getDashboard(dashboardIdToLoad)
        .unwrap()
        .then((dashboard) => {
          dispatch(diasRoleChange(dashboard));
          dispatch(periodChange(dashboard.defaultPeriod ?? "NotSet"));
        });
    }
  }, [dashboardIdToLoad, dispatch, getDashboard, options.skip]);

  useLayoutEffect(() => {
    // Во избежании различных труднообрабатываемых явлений при смене url
    // всегда выключаем режим редактирования
    dispatch(editModeChange(false));
    return () => {
      dispatch(editModeChange(false));
    };
  }, [dispatch, location]);

  return {
    isLoading: getIsLoading(),
    isSuccess:
      !options.skip && responseGetAvailable.isSuccess && dashboard.isSuccess,
    error: !options.skip && (responseGetAvailable.error || dashboard.error),
  };
};
