import { rootApi } from "../serviceRoot";

import { TGetAvailableDashboardsResponse } from "../Dashboards/Types"
import { 
  CreateReferenceHeaderRequest, 
  GetHeadersDescriptionResponse, 
  getReferenceHeaderResponse 
} from "./Types";

export const referenceHeaderApiSlice = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllReferenceHeaders: builder.query<TGetAvailableDashboardsResponse, void>(
      {
        query: () => "api/ReferenceHeader/getAll",
        providesTags: ["referenceHeaders"],
      }
    ),
    getHeadersDescription: builder.query<GetHeadersDescriptionResponse, void>({
      query: () => "api/ReferenceHeader/GetHeadersDescription",
    }),
    createReferenceHeader: builder.mutation<
      CreateReferenceHeaderRequest,
      CreateReferenceHeaderRequest
    >({
      query: (payload) => ({
        url: "api/ReferenceHeader/Create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["referenceHeaders"],
    }),
    updateReferenceHeader: builder.mutation<
      CreateReferenceHeaderRequest,
      getReferenceHeaderResponse
    >({
      query: (payload) => ({
        url: "api/ReferenceHeader/Update",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["referenceHeaders", "referenceHeader"],
    }),
    getReferenceHeader: builder.query<getReferenceHeaderResponse, string>({
      query: (id) => ({
        url: `api/ReferenceHeader/Get?ID=${id}`,
      }),
      providesTags: ["referenceHeader"],
    }),
    deleteReferenceHeader: builder.mutation<
      getReferenceHeaderResponse,
      { id: string }
    >({
      query: (payload) => ({
        url: "api/ReferenceHeader/Delete",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["referenceHeaders"],
    }),
  }),
});

export const {
  useGetAllReferenceHeadersQuery,
  useGetHeadersDescriptionQuery,
  useCreateReferenceHeaderMutation,
  useLazyGetReferenceHeaderQuery,
  useUpdateReferenceHeaderMutation,
  useDeleteReferenceHeaderMutation,
} = referenceHeaderApiSlice;
