import { DbaSlider } from "../../../../../../../DbaComponents";
import {
  WidgetSettingContainer,
  SettingsToggle,
  legendPositionOptions,
} from "../../common";
import type { ChartSettingsProps } from "../Types";

export const TimeRangeChartLabelSettings = ({
  settings,
  setSettings,
}: ChartSettingsProps) => {
  return (
    <>
      <WidgetSettingContainer title="monthLegendPosition">
        <SettingsToggle
          settings={settings}
          setSettings={setSettings}
          property="monthLegendPosition"
          defaultValue="before"
          options={legendPositionOptions}
        />
      </WidgetSettingContainer>
      <WidgetSettingContainer title="monthLegendOffset">
        <DbaSlider
          sx={{ width: 120 }}
          min={0}
          max={36}
          value={settings.diagram.monthLegendOffset ?? 10}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                monthLegendOffset: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>
      <WidgetSettingContainer title="weekdayLegendOffset">
        <DbaSlider
          sx={{ width: 100 }}
          min={0}
          max={100}
          value={settings.diagram.weekdayLegendOffset ?? 75}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                weekdayLegendOffset: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>
    </>
  );
};
