import styled from "@emotion/styled";
import colors from "../../../../../../Variables.module.scss";

const CSSTableImage = styled.img`
  max-height: 250px;
  width: auto;
`;

const CSSInfoTable = styled.table`
  border: 1px solid ${colors.lavander500};
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.5;
  td {
    border-bottom: 1px solid ${colors.lavander500};
    padding: 16px;
  }
  & td:first-of-type {
    border-right: 1px solid ${colors.lavander500};
  }
  & td:last-of-type {
    border-bottom: 1px solid ${colors.lavander500};
  }
  & tr:last-of-type {
    td {
      border-bottom: none;
    }
  }
  overflow: auto;
`;

const CSSWrongDataContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export { CSSTableImage, CSSInfoTable, CSSWrongDataContainer };
