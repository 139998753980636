import React, { useState } from "react";
import { CSSTimezonePickerButton } from "./DbaTimezonePicker.styles";
import { useAppSelector, useAppDispatch } from "../../utils/reduxHooks";
import { Popover, Paper, useMediaQuery } from "@mui/material";
import { DbaTimezoneSelector } from "../DbaTimezoneSelector/DbaTimezoneSelector";
import { timezoneChange } from "../../features/slices/App/appSlice";
import { TTimezone } from "../../features/serviceSlices/Timezones/Types";
import { Box } from "@mui/material";
import { DbaTimezonePickerProps } from "./Types";

export const DbaTimezonePicker: React.FC<DbaTimezonePickerProps> = ({
  disabled,
}) => {
  const dispatch = useAppDispatch();

  const isMobileView = useMediaQuery("(max-width:1000px)");

  const { role } = useAppSelector((state) => state.app);
  const { timezone } = useAppSelector((state) => state.app);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isOpen = !!anchorEl;

  const handleOpen = (event: React.BaseSyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const handleChange = (timezone: TTimezone | undefined) => {
    if (timezone) {
      dispatch(timezoneChange(timezone));
      handleClose();
    }
  };

  const timezoneOffsetExtracted = timezone.displayName
    .split(")")[0]
    .replace("(", "");

  return role?.availablePeriods && role?.availablePeriods?.length > 0 ? (
    <Box sx={isMobileView ? { padding: "1rem" } : {}}>
      <CSSTimezonePickerButton
        value={timezone}
        onClick={handleOpen}
        size="small"
        disabled={disabled}
      >
        <Box sx={{ whiteSpace: "nowrap" }}>{timezoneOffsetExtracted}</Box>
      </CSSTimezonePickerButton>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 40,
          horizontal: isMobileView ? "left" : "right",
        }}
      >
        <Paper
          sx={{ padding: "16px", width: isMobileView ? "300px" : "451px" }}
        >
          <DbaTimezoneSelector value={timezone} onChange={handleChange} />
        </Paper>
      </Popover>
    </Box>
  ) : null;
};
