import { createContext } from "react";
import { PaletteMode } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import colors from "../Variables.module.scss";
import { useStickyState } from "./hooks/hooks";

const getDesignTokens = (mode: PaletteMode) => ({
  mixins: {
    toolbar: {
      height: 56,
    },
  },
  palette: {
    mode,
    primary: {
      main: colors.purple500,
    },
    background: {
      ...(mode === "light"
        ? {
            default: colors.light,
            paper: colors.white,
          }
        : {
            default: colors.darkBlue,
            paper: colors.darkBlue,
          }),
    },
    text: {
      ...(mode === "light"
        ? {
            primary: colors.darkBlue,
            secondary: colors.darkBlue,
          }
        : {
            primary: colors.white,
          }),
    },
  },
});

type ThemeType = {
  darkMode: boolean;
  setDarkMode: (prevState: boolean) => void;
};
const defaultValue: ThemeType = {
  darkMode: false,
  setDarkMode: () => true,
};

type ProviderType = {
  children: React.ReactNode;
  forceTheme?: PaletteMode;
};
const ThemeContext = createContext<ThemeType>(defaultValue);
const { Provider } = ThemeContext;

const ThemeProviderr = ({ children, forceTheme }: ProviderType) => {
  const [darkMode, setDarkMode] = useStickyState(
    forceTheme === "dark",
    forceTheme === "dark" ? "darkMode" : "lightMode"
  );
  const darkModeTheme = createTheme(
    getDesignTokens(darkMode ? "dark" : "light")
  );
  return (
    <Provider value={{ darkMode, setDarkMode }}>
      <ThemeProvider theme={darkModeTheme}>{children}</ThemeProvider>
    </Provider>
  );
};

export { ThemeProviderr, ThemeContext };
