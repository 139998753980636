import { WidgetSettingContainer, transitionModeOptions } from "../../common";
import type { ChartSettingsProps } from "../Types";
import { DbaSimpleSelect } from "../../../../../../../DbaComponents";
import Switch from "@mui/material/Switch";
import { MotionConfigSettings } from "../../common";

export const RadialChartInteractivitySettings = ({
  settings,
  setSettings,
}: ChartSettingsProps) => {
  return (
    <>
      <WidgetSettingContainer title="animate">
        <Switch
          checked={
            settings.diagram.animate === undefined
              ? true
              : settings.diagram.animate
          }
          onChange={(e) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                animate: e.target.checked,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="isInteractive">
        <Switch
          checked={
            settings.diagram.isInteractive === undefined
              ? true
              : settings.diagram.isInteractive
          }
          onChange={(e) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                isInteractive: e.target.checked,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="transitionMode">
        <DbaSimpleSelect
          selectedValue={
            settings.diagram.transitionMode === undefined
              ? transitionModeOptions.find(
                  (option) => option.value === "centerRadius"
                )
              : transitionModeOptions.find(
                  (option) => option.value === settings.diagram.transitionMode
                )
          }
          setSelectedValue={(value) => {
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                transitionMode: value,
              },
            }));
          }}
          options={transitionModeOptions}
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer lastRow title="motionConfig">
        <MotionConfigSettings
          settings={settings}
          setSettings={setSettings}
          defaultValue="default"
        />
      </WidgetSettingContainer>
    </>
  );
};
