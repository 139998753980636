import styled from "@emotion/styled";
import colors from "../../Variables.module.scss";
import { useContext } from "react";
import { ThemeContext } from "../../utils/ThemeContext";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";

type TrendIconProps = TrendIconType & { darkMode: boolean };

type TrendIconType = {
  positiveTrend: boolean | null;
  customBackgroundColor: boolean;
  transparentBackground: boolean;
};

const CSSIconContainer = styled.span<TrendIconProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  background: ${(props) => {
    if (props.transparentBackground) {
      return "transparent";
    }
    return props.positiveTrend ? colors.green100 : colors.red100;
  }};
  box-shadow: ${(props) => {
    if (
      props.transparentBackground ||
      props.customBackgroundColor ||
      props.darkMode
    ) {
      return "";
    }
    return colors.bsIconLight;
  }};
  color: ${(props) => (props.positiveTrend ? colors.green500 : colors.red500)};
  width: 1.5rem;
  height: 1.5rem;
`;
export const TrendIcon = ({
  positiveTrend = true,
  customBackgroundColor,
  transparentBackground,
}: TrendIconType) => {
  const { darkMode } = useContext(ThemeContext);

  if (positiveTrend === null) {
    return null;
  }
  return (
    <CSSIconContainer
      darkMode={darkMode}
      positiveTrend={positiveTrend}
      customBackgroundColor={customBackgroundColor}
      transparentBackground={transparentBackground}
    >
      {positiveTrend ? (
        <TrendingUpIcon fontSize="small" />
      ) : (
        <TrendingDownIcon fontSize="small" />
      )}
    </CSSIconContainer>
  );
};
