import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";

const CSSWrapper = styled.div`
  position: relative;
`;

const CSSNumberInput = styled(TextField)`
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    display: none;
  }
`;

export { CSSWrapper, CSSNumberInput };
