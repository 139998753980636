import { ResponsivePie } from "@nivo/pie";
import { GetTheme } from "../Theme";
import { animated } from "@react-spring/web";
import { CSSTotalValue } from "./DbaPieChart.styles";
import React, { useEffect, useRef, useState } from "react";
import { DbaPortalWrapper } from "../../../../DbaComponents/DbaPortalWrapper";
import { PieChartTooltip } from "./PieChartTooltip";

const getDisplayedData = (data, settings) => {
  let overall = 0;
  data.forEach((element) => {
    overall += element[settings.value];
  });

  const newArr = data.map((element) => {
    let result = { ...element };
    result["formattedPercentage"] = `${(
      (element[settings.value] / overall) *
      100
    ).toFixed(2)}%`;
    return result;
  });
  return newArr;
};

const createCenteredMetric = (widgetsetting) => {
  return ({ dataWithArc, centerX, centerY }) => {
    let total = 0;
    dataWithArc.forEach((datum) => {
      total += datum.value;
    });
    return (
      <svg>
        <defs>
          <linearGradient
            id={`${widgetsetting.fontColor}${
              widgetsetting.gradientColor ?? ""
            }`}
          >
            <stop offset="0%" stopColor={widgetsetting.fontColor} />
            {widgetsetting.gradientColor ? (
              <stop offset="100%" stopColor={widgetsetting.gradientColor} />
            ) : (
              ""
            )}
          </linearGradient>
        </defs>
        <CSSTotalValue
          x={centerX}
          y={centerY}
          textAnchor="middle"
          dominantBaseline="central"
          fontSize={widgetsetting.header?.fontSize}
          fontWeight={widgetsetting.header?.fontWeight}
          gradientId={`${widgetsetting.fontColor}${
            widgetsetting.gradientColor ?? ""
          }`}
        >
          {total.toLocaleString(window.__env__.REACT_APP_LOCALE)}
        </CSSTotalValue>
      </svg>
    );
  };
};

export const DbaPieChart = ({ widgetSettings, data }) => {
  const settings = widgetSettings.diagram;
  const theme = GetTheme(settings);
  let layers = ["arcs", "arcLabels", "arcLinkLabels", "legends"];
  if (settings.displayOverallValue) {
    layers = [...layers, createCenteredMetric(widgetSettings.widget)];
  }

  // Монтирование и размонтирование компонента при смене
  // типа отображаемых значений, необходимо, т.к без этого
  // при смене типа отображаемых значений в конструкторе
  // не меняется тултип.
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(false);
    setTimeout(() => setIsMounted(true), 0);
  }, [settings.displayedValues]);

  const tooltipContainerRef = useRef(null);

  if (!isMounted) {
    return null;
  }

  return (
    <ResponsivePie
      data={getDisplayedData(data, settings)}
      id={settings.indexBy}
      value={settings.value}
      valueFormat={settings.valueFormat}
      pixelRatio={settings.pixelRatio}
      startAngle={settings.startAngle}
      endAngle={settings.endAngle}
      fit={settings.fit}
      innerRadius={settings.innerRadius}
      padAngle={settings.padAngle}
      cornerRadius={settings.cornerRadius}
      sortByValue={settings.sortByValue}
      margin={settings.margin}
      colors={settings.colors}
      defs={settings.defs}
      fill={settings.fill}
      borderWidth={settings.borderWidth}
      borderColor={settings.borderColor}
      enableArcLabels={settings.enableArcLabels}
      tooltip={({ datum }) => {
        return (
          <div ref={tooltipContainerRef}>
            <DbaPortalWrapper
              left={tooltipContainerRef.current?.getBoundingClientRect().left}
              top={tooltipContainerRef.current?.getBoundingClientRect().top}
            >
              <PieChartTooltip
                datum={datum}
                displayedValues={settings.displayedValues}
              />
            </DbaPortalWrapper>
          </div>
        );
      }}
      arcLabel=""
      arcLabelsComponent={({ datum, label, style }) => {
        const res = datum.formattedValue.replaceAll(",", " ");
        let displayedValue = "";

        switch (settings.displayedValues) {
          case "absolute":
            displayedValue = res;
            break;
          case "percentage":
            displayedValue = datum.data.formattedPercentage;
            break;
          case "percentageabsolute":
            displayedValue = `${res}|(${datum.data.formattedPercentage})`;
            break;
          default:
            displayedValue = res;
        }

        return (
          <animated.g
            transform={style.transform}
            style={{ pointerEvents: "none" }}
          >
            <text
              textAnchor="middle"
              dominantBaseline="central"
              fill={style.textColor}
              style={{
                fontSize: 10,
                fontWeight: 800,
              }}
            >
              {settings.displayedValues === "percentageabsolute" ? (
                displayedValue.split("|").map((item, key) => {
                  return (
                    <tspan key={key} x="0" dy={`${-0.5 + key * 1.5}em`}>
                      {item}
                    </tspan>
                  );
                })
              ) : (
                <tspan x="0" dy="0">
                  {displayedValue}
                </tspan>
              )}
            </text>
          </animated.g>
        );
      }}
      arcLabelsRadiusOffset={settings.arcLabelsRadiusOffset}
      arcLabelsSkipAngle={settings.arcLabelsSkipAngle}
      arcLabelsTextColor={{ theme: "labels.text.fill" }}
      enableArcLinkLabels={settings.enableArcLinkLabels}
      arcLinkLabel={settings.arcLinkLabel}
      arcLinkLabelsSkipAngle={settings.arcLinkLabelsSkipAngle}
      arcLinkLabelsOffset={settings.arcLinkLabelsOffset}
      arcLinkLabelsDiagonalLength={settings.arcLinkLabelsDiagonalLength}
      arcLinkLabelsStraightLength={settings.arcLinkLabelsStraightLength}
      arcLinkLabelsTextOffset={settings.arcLinkLabelsTextOffset}
      arcLinkLabelsThickness={settings.arcLinkLabelsThickness}
      arcLinkLabelsTextColor={settings.arcLinkLabelsTextColor}
      arcLinkLabelsColor={settings.arcLinkLabelsColor}
      isInteractive={settings.isInteractive}
      activeInnerRadiusOffset={settings.activeInnerRadiusOffset}
      activeOuterRadiusOffset={settings.activeOuterRadiusOffset}
      animate={settings.animate}
      motionConfig={settings.motionConfig}
      transitionMode={settings.transitionMode}
      legends={settings.legends}
      layers={layers}
      theme={theme}
    />
  );
};
