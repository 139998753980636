import { useEffect } from "react";
import { useLazyGetSelectOptionsQuery } from "../../features/serviceSlices/serviceHooks";
import Autocomplete from "@mui/material/Autocomplete";
import { useIntl } from "react-intl";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { InputIcon } from "./InputIcon";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { DbaIconButton } from "../DbaIconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import Tooltip from "@mui/material/Tooltip";

type FetchSelectProps = {
  url: string;
  selectedValue: any;
  setSelectedValue: (arg: any) => void;
  optionLabel: string;
  optionValue: string;
  label: string;
  size?: "small" | "medium";
  disableClearable?: boolean;
  required?: boolean;
  error?: boolean;
};

export const FetchSelect = ({
  url,
  selectedValue,
  setSelectedValue,
  optionLabel,
  optionValue,
  label,
  size,
  required,
  disableClearable,
  error,
}: FetchSelectProps) => {
  const intl = useIntl();
  const [getOptions, optionsResponse] = useLazyGetSelectOptionsQuery();

  const getSelectedValue = () => {
    if (typeof selectedValue === "string" && optionsResponse.data) {
      if (selectedValue === "") {
        return null;
      }
      return optionsResponse.data.find(
        (item) => item[optionValue] === selectedValue);
    }
    else {
      return selectedValue;
    }
  };

  useEffect(() => {
    getOptions(url);
  }, [getOptions, url]);

  return (
    <Autocomplete
      size={size}
      renderInput={(params) => (
        <TextField
          {...params}
          label={intl.messages[label]}
          required={required}
          error={error}
          helperText={error && required && intl.messages["requiredField"]}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {optionsResponse.isLoading ? (
                  <CircularProgress size={20} />
                ) : null}
                {optionsResponse.isError ? (
                  <>
                    <InputIcon
                      icon={<ErrorOutlineIcon color="error" />}
                      text="error"
                    />
                    <Tooltip title={intl.messages["repeatRequest"]}>
                      <span>
                        <DbaIconButton
                          size="small"
                          onClick={() => getOptions(url)}
                          icon={<RefreshIcon />}
                        />
                      </span>
                    </Tooltip>
                  </>
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      options={optionsResponse.data ?? []}
      getOptionLabel={(option) =>
        typeof option === "object" ? option[optionLabel] : option
      }
      onChange={(e, value) => setSelectedValue(value)}
      value={getSelectedValue()}
      disableClearable={disableClearable}
    />
  );
};
