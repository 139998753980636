import styled from "@emotion/styled";
import Skeleton from "@mui/material/Skeleton";

const CSSSkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const WidgetStepperSkeleton = () => {
  return (
    <CSSSkeletonContainer>
      {[...Array(6)].map((element, i) => (
        <Skeleton
          key={i}
          sx={{ borderRadius: "4px" }}
          variant="rectangular"
          width={"100%"}
          height={56}
        />
      ))}
    </CSSSkeletonContainer>
  );
};

const CSSDataContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const CSSStepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-top: 16px;
`;

export { CSSDataContainer, CSSStepsContainer, WidgetStepperSkeleton };
