import { DbaSlider, DbaColorInput } from "../../../../../../../DbaComponents";
import {
  WidgetSettingContainer,
  SettingsToggle,
  legendPositionOptions,
} from "../../common";
import type { ChartSettingsProps } from "../Types";
import { WidgetSettingAccordion } from "../../common/WidgetSettingAccordion";

export const CalendarChartLabelSettings = ({
  settings,
  setSettings,
}: ChartSettingsProps) => {
  return (
    <>
      <WidgetSettingAccordion title="years">
        <WidgetSettingContainer lastRow title="yearSpacing">
          <DbaSlider
            sx={{ width: 120 }}
            min={0}
            max={160}
            value={settings.diagram.yearSpacing ?? 30}
            onChange={(value) =>
              setSettings((prevState: any) => ({
                ...prevState,
                diagram: {
                  ...prevState.diagram,
                  yearSpacing: value,
                },
              }))
            }
          />
        </WidgetSettingContainer>
        <WidgetSettingContainer lastRow title="yearLegendPosition">
          <SettingsToggle
            settings={settings}
            setSettings={setSettings}
            property="yearLegendPosition"
            defaultValue="before"
            options={legendPositionOptions}
          />
        </WidgetSettingContainer>
        <WidgetSettingContainer lastRow title="yearLegendOffset">
          <DbaSlider
            sx={{ width: 120 }}
            min={0}
            max={60}
            value={settings.diagram.yearLegendOffset ?? 10}
            onChange={(value) =>
              setSettings((prevState: any) => ({
                ...prevState,
                diagram: {
                  ...prevState.diagram,
                  yearLegendOffset: value,
                },
              }))
            }
          />
        </WidgetSettingContainer>
      </WidgetSettingAccordion>
      <WidgetSettingAccordion title="months">
        <WidgetSettingContainer lastRow title="monthSpacing">
          <DbaSlider
            sx={{ width: 120 }}
            min={0}
            max={160}
            value={settings.diagram.monthSpacing ?? 0}
            onChange={(value) =>
              setSettings((prevState: any) => ({
                ...prevState,
                diagram: {
                  ...prevState.diagram,
                  monthSpacing: value,
                },
              }))
            }
          />
        </WidgetSettingContainer>
        <WidgetSettingContainer lastRow title="monthBorderWidth">
          <DbaSlider
            sx={{ width: 120 }}
            min={0}
            max={20}
            value={settings.diagram.monthBorderWidth ?? 2}
            onChange={(value) =>
              setSettings((prevState: any) => ({
                ...prevState,
                diagram: {
                  ...prevState.diagram,
                  monthBorderWidth: value,
                },
              }))
            }
          />
        </WidgetSettingContainer>

        <WidgetSettingContainer lastRow title="monthBorderColor">
          <DbaColorInput
            value={settings.diagram.monthBorderColor}
            onChange={(event) =>
              setSettings((prevState: any) => ({
                ...prevState,
                diagram: {
                  ...prevState.diagram,
                  monthBorderColor: event.target.value,
                },
              }))
            }
          />
        </WidgetSettingContainer>
        <WidgetSettingContainer lastRow title="monthLegendPosition">
          <SettingsToggle
            settings={settings}
            setSettings={setSettings}
            property="monthLegendPosition"
            defaultValue="before"
            options={legendPositionOptions}
          />
        </WidgetSettingContainer>
        <WidgetSettingContainer lastRow title="monthLegendOffset">
          <DbaSlider
            sx={{ width: 100 }}
            min={0}
            max={36}
            value={settings.diagram.monthLegendOffset ?? 10}
            onChange={(value) =>
              setSettings((prevState: any) => ({
                ...prevState,
                diagram: {
                  ...prevState.diagram,
                  monthLegendOffset: value,
                },
              }))
            }
          />
        </WidgetSettingContainer>
      </WidgetSettingAccordion>
    </>
  );
};
