import type { ChartSettingsProps } from "../Types";
import {
  directionOptions,
  SettingsToggle,
  WidgetSettingContainer,
  MinMaxValueSettings,
} from "../../common";
import { DbaDatepicker } from "../../../../../../../DbaComponents";
import { useCallback, useEffect } from "react";
import { format, startOfYear } from "date-fns";
import { LoadingScreen } from "../../../../../../../components/common";

export const TimeRangeChartBaseSettings = ({
  settings,
  setSettings,
}: ChartSettingsProps) => {
  const setSettingsHandler = useCallback(
    (key: string, value: string): void => {
      setSettings((prevState: any) => ({
        ...prevState,
        diagram: {
          ...prevState.diagram,
          [key]: value,
        },
      }));
    },
    [setSettings]
  );

  useEffect(() => {
    if (!settings.diagram.from || !settings.diagram.to) {
      setSettingsHandler("from", startOfYear(Date.now()).toISOString());
      setSettingsHandler("to", format(Date.now(), "yyyy-MM-dd"));
    }
  }, [setSettingsHandler, settings.diagram.from, settings.diagram.to]);

  if (!settings.diagram.from || !settings.diagram.to) {
    return <LoadingScreen />;
  } else {
    return (
      <>
        <WidgetSettingContainer title="direction">
          <SettingsToggle
            settings={settings}
            setSettings={setSettings}
            property="direction"
            defaultValue="horizontal"
            options={directionOptions}
          />
        </WidgetSettingContainer>

        <WidgetSettingContainer title="startDate">
          <DbaDatepicker
            width="160px"
            dateFormat="dd.MM.yyyy HH:mm"
            defaultValue={new Date(settings.diagram.from)}
            label="chooseStartDate"
            setDate={(date: any) => setSettingsHandler("from", date)}
            date={new Date(settings.diagram.from)}
            maxDate={new Date(settings.diagram.to)}
          />
        </WidgetSettingContainer>

        <WidgetSettingContainer title="endDate">
          <DbaDatepicker
            width="160px"
            dateFormat="dd.MM.yyyy HH:mm"
            defaultValue={new Date(settings.diagram.to)}
            label="chooseEndDate"
            setDate={(date: any) => setSettingsHandler("to", date)}
            date={new Date(settings.diagram.to)}
            minDate={new Date(settings.diagram.from)}
          />
        </WidgetSettingContainer>
        {/* Выравнивание не работает в том числе и в документации https://nivo.rocks/time-range/ */}
        <WidgetSettingContainer title="min">
          <MinMaxValueSettings
            settings={settings}
            setSettings={setSettings}
            value={settings.diagram.minValue}
            property="minValue"
          />
        </WidgetSettingContainer>

        <WidgetSettingContainer title="max">
          <MinMaxValueSettings
            settings={settings}
            setSettings={setSettings}
            value={settings.diagram.maxValue}
            property="maxValue"
          />
        </WidgetSettingContainer>
      </>
    );
  }
};
