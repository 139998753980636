import React, { useEffect, useState, useCallback } from "react";
import {
  DbaButton,
  DbaDataFetchSelect,
  DbaModal,
} from "../../../DbaComponents";
import { useIntl } from "react-intl";
import { WidgetRelatedReportType } from "../../../features/serviceSlices/Widget/Types";
import { WidgetRelatedReportsActionTypes } from "../Widget/reducers/widgetRelatedReportsReducer";
import { CSSModalContent } from "./WidgetRelatedReports.styles";
import AddIcon from "@mui/icons-material/Add";

type WidgetRelatedReportModalProps = {
  open: boolean;
  setOpen: (arg: boolean) => void;
  dispatchWidgetRelatedReports: (arg: WidgetRelatedReportsActionTypes) => void;
  data: WidgetRelatedReportType | null;
};

const initialRelatedReport = { id: "", name: "" };

export const WidgetRelatedReportsModal = ({
  open,
  setOpen,
  dispatchWidgetRelatedReports,
  data,
}: WidgetRelatedReportModalProps) => {
  const editMode = Boolean(data);
  const intl = useIntl();

  const [relatedReport, setRelatedReport] =
    useState<WidgetRelatedReportType>(initialRelatedReport);

  const setInitialState = useCallback(() => {
    setRelatedReport(initialRelatedReport);
  }, []);

  useEffect(() => {
    if (editMode && data) {
      setRelatedReport({
        id: data.id,
        name: data.name,
      });
    }
    return () => setInitialState();
  }, [data, editMode, setInitialState]);

  const onSaveClick = () => {
    editMode && data
      ? dispatchWidgetRelatedReports({
          type: "changeWidgetRelatedReport",
          payload: relatedReport,
          initialObj: data,
        })
      : dispatchWidgetRelatedReports({
          type: "addWidgetRelatedReport",
          payload: relatedReport,
        });
    setOpen(false);
    setInitialState();
  };

  return (
    <DbaModal
      open={open}
      setOpen={setOpen}
      title={intl.messages["report"] as string}
    >
      <CSSModalContent>
        <DbaDataFetchSelect
          url="api/Report/GetAll"
          label="reports"
          labelIndex="id"
          keyIndex={relatedReport?.id ?? ""}
          selectedValue={relatedReport}
          setSelectedValue={setRelatedReport}
          minWidth={'500px'}
          isDynamicHeight={true}
        />
        <DbaButton
          text="save"
          onClick={onSaveClick}
          startIcon={<AddIcon />}
          disabled={!relatedReport?.id?.length ?? false}
        />
      </CSSModalContent>
    </DbaModal>
  );
};
