import { useState } from "react";
import {
  DataWidgetConstructorProps,
  WidgetElementTypes,
} from "./DataWidgetConstructor.types";
import { EditableDataWidget } from "../../../../components/Widgets/EditableWidgets";
import { WidgetConstructorToolbar } from "../WidgetConstructorToolbar";
import { DbaErrorBoundary } from "../../../../DbaComponents";
import {
  CSSConstructorContainer,
  CSSEditableWidgetContainer,
} from "./DataWidgetConstructor.styles";
import { Thresholds } from "../SettingsContainer/Thresholds/Thresholds";

export const DataWidgetConstructor = ({
  settings,
  setSettings,
  widgetData,
}: DataWidgetConstructorProps) => {
  const [selectedElement, setSelectedElement] =
    useState<WidgetElementTypes | null>(null);
  return (
    <CSSConstructorContainer>
      <WidgetConstructorToolbar
        widgetType="Default"
        settings={settings}
        setSettings={setSettings}
        selectedElement={selectedElement}
        setSelectedElement={setSelectedElement}
      />
      <CSSEditableWidgetContainer>
        <DbaErrorBoundary>
          {selectedElement === "thresholds" && (
            <Thresholds settings={settings} setSettings={setSettings} />
          )}

          <EditableDataWidget
            selectedElement={selectedElement}
            setSelectedElement={setSelectedElement}
            data={{
              description: widgetData.description,
              difference: "null",
              differenceStyle: "null",
              id: widgetData.id,
              label: widgetData.label,
              measure: widgetData.measure,
              name: widgetData.name,
              prevValue: widgetData.prevValue,
              prevValueCaption: widgetData.prevValueCaption,
              widgetSettings: {
                settings,
              },
              widgetType: "Default",
              value: widgetData.value,
            }}
          />
        </DbaErrorBoundary>
      </CSSEditableWidgetContainer>
    </CSSConstructorContainer>
  );
};
