import colors from "../../../../../../Variables.module.scss";
import { TPropsCreateCustomStyles } from "./DashboardHeader.types";

export const createCustomStyles = ({
  isDarkMode,
  isMobile,
}: TPropsCreateCustomStyles) => {
  return {
    control: (provided: {}, state: {}) => ({
      ...provided,
      background: isDarkMode ? colors.darkBlue : "inherit",
      borderColor: isDarkMode ? colors.purple500 : colors.darkBlue,
      width: isMobile ? "100%" : "350px",
      cursor: "pointer",
    }),
    input: (provided: {}, state: {}) => ({
      ...provided,
      color: isDarkMode ? colors.white : colors.darkBlue,
    }),
    singleValue: (provided: {}, state: {}) => ({
      ...provided,
      color: isDarkMode ? colors.white : colors.darkBlue,
    }),
    container: (provided: {}, state: {}) => ({
      ...provided,
      width: isMobile ? "100%" : "350px",
    }),
    menu: (provided: {}, state: {}) => ({
      ...provided,
      background: isDarkMode ? colors.darkBlue : "colors.white",
      color: isDarkMode ? colors.white : colors.darkBlue,
    }),
    option: (provided: {}, state: any) => {
      return {
        ...provided,
        cursor: "pointer",
        background: state.isFocused && isDarkMode ? colors.purple900 : "",
        "&:hover": {
          background: isDarkMode ? colors.purple900 : "",
        },
      };
    },
  };
};
