import styled from "@emotion/styled";
import colors from "../../../../../Variables.module.scss";

export const CSSModalContainer = styled.div`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 480px;
  padding: 40px;
  border-radius: 8px;
  color: ${({ theme }: any) =>
    theme.palette.mode === "light" ? colors.light : colors.white};

  background-color: ${({ theme }: any) =>
    theme.palette.mode === "light" ? colors.light : colors.darkBlue};
`;

export const CSSTextFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 54px;
`;

export const CSSBtnContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  & > button {
    width: 100%;
  }
`;
