import React, { useEffect, useState } from "react";
import {
  DataInputProps,
  TextType,
  GetSelectedFilterType,
  GetValueToSaveType,
} from "../../Types";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { CSSLabelRightPaddingInputWrapper } from "../../FilterWidget.styles";
import { parseJSON } from "../../../../../../utils/helpers/functions";

export const getSelectedFilter: GetSelectedFilterType = ({
  findObject,
  data,
}) => {
  return findObject[data.variable];
};

export const getValueToSave: GetValueToSaveType = ({
  value,
}: {
  value: TextType;
}) => value;

export const TextInput: React.FC<DataInputProps<TextType | null>> = ({
  data,
  selectedValue,
  handleChange,
}) => {
  const [value, setValue] = useState<string>("");

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  const commitChange = (e: any) => {
    handleChange(e.target.value);
    if (!value && selectedValue) {
      setValue(selectedValue);
    }
  };

  const onKeyPress = (e: any) => {
    if (e.charCode === 13) {
      commitChange(e);
    }
  };

  const onBlur = (e: any) => {
    commitChange(e);
  };

  const onReset = () => {
    const pseudoEvent = { target: { value: "" } };
    onChange(pseudoEvent);
    commitChange(pseudoEvent);
  };

  useEffect(() => {
    setValue(selectedValue as string);
  }, [selectedValue]);

  const parsedDefaultValue = parseJSON(data.defaultValue)?.value;
  const isDefaultValue =
    (!parsedDefaultValue && !selectedValue) ||
    parsedDefaultValue === selectedValue;

  return (
    <CSSLabelRightPaddingInputWrapper>
      <TextField
        value={value ?? ""}
        fullWidth={true}
        label={data.label}
        onChange={onChange}
        onKeyPress={onKeyPress}
        onBlur={onBlur}
        autoComplete="off"
        InputProps={{
          endAdornment: !isDefaultValue ? (
            <InputAdornment position="end">
              <IconButton onClick={onReset} size="small">
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ) : undefined,
        }}
      />
    </CSSLabelRightPaddingInputWrapper>
  );
};
