import { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import {
  useAddNewMapLayerMutation,
  useUpdateMapLayerMutation,
  useGetMapLayerByIdMutation,
  useGetTypesQuery,
  useGetAllMapLayersQuery,
} from "../../features/serviceSlices/serviceHooks";
import { SaveButton } from "../../components/common";
import {
  DbaSelect,
  DbaNumberInput,
  DbaTextField,
  DbaButton,
  DbaSimpleSelect,
} from "../../DbaComponents";
import { MapLayerType } from "../../features/serviceSlices/MapLayer/Types";
import Box from "@mui/material/Box";
import { Stack } from "@mui/material";

export const MapLayer = () => {
  const navigate = useNavigate();
  const params = useParams<Record<string, string | undefined>>();
  const editMode = Boolean(params.id);

  const [mapLayer, setMapLayer] = useState<MapLayerType | null>(null);
  const [apiKey, setApiKey] = useState("");
  const [lang, setLang] = useState("");
  const [scale, setScale] = useState(0.1);

  const [errorState, setErrorState] = useState(false);

  const languages = ["ru_RU", "en_RU", "en_US", "uk_UA", "ru_UA", "tr_TR"];
  const yandexApiKeyValidation = useMemo(
    () => /^(\w{8}(-\w{4}){3}-\w{12}){1,36}$/,
    []
  );

  const [addMapLayer, addMapLayerResponse] = useAddNewMapLayerMutation();
  const [updateMapLayer, updateMapLayerResponse] = useUpdateMapLayerMutation();
  const [getMapLayerById, getMapLayerByIdResponse] =
    useGetMapLayerByIdMutation();
  const typeQueryResponse = useGetTypesQuery("MapLayerType");
  const mapLayerResponse = useGetAllMapLayersQuery();

  useEffect(() => {
    if (editMode && params.id) {
      getMapLayerById(params.id);
    }
  }, [editMode, params.id, getMapLayerById]);

  useEffect(() => {
    if (getMapLayerByIdResponse.data && editMode) {
      setMapLayer({
        id: getMapLayerByIdResponse.data.id,
        name: getMapLayerByIdResponse.data.name,
      });
      setApiKey(getMapLayerByIdResponse.data.params.apikey);
      setLang(getMapLayerByIdResponse.data.params.lang);
      setScale(getMapLayerByIdResponse.data.params.scale);
    }
  }, [getMapLayerByIdResponse.data, editMode]);

  const onSubmitFormHandler = () => {
    if (
      mapLayer?.id === "Yandex" &&
      (!yandexApiKeyValidation.test(apiKey) ||
        lang.length === 0 ||
        scale < 0.1 ||
        scale > 4)
    ) {
      setErrorState(true);
      return;
    }
    setErrorState(false);
    switch (mapLayer!.id) {
      case "Yandex":
        editMode
          ? updateMapLayer({
              id: mapLayer!.id,
              params: {
                apikey: apiKey,
                lang: lang,
                scale: scale,
              },
            })
          : addMapLayer({
              id: mapLayer!.id,
              params: {
                apikey: apiKey,
                lang: lang,
                scale: scale,
              },
            });
        break;
      case "OSM":
        editMode
          ? updateMapLayer({
              id: mapLayer!.id,
              params: {},
            })
          : addMapLayer({
              id: mapLayer!.id,
              params: {},
            });
        break;
      default:
        return;
    }
  };

  return (
    <>
      <Box component="form" onSubmit={onSubmitFormHandler}>
        <Stack direction="row" spacing={2} sx={{ mb: "1rem" }}>
          <DbaButton color="error" onClick={() => navigate(-1)} text="cancel" />
          <SaveButton
            disabled={!mapLayer?.id}
            redirectUrl="/mapLayers"
            onClick={onSubmitFormHandler}
            status={
              editMode
                ? updateMapLayerResponse.status
                : addMapLayerResponse.status
            }
          />
        </Stack>
        <Stack direction="column" spacing={2}>
          <DbaSelect
            disabled={editMode}
            selectedValue={mapLayer}
            setSelectedValue={setMapLayer}
            options={
              typeQueryResponse.data && mapLayerResponse.data
                ? typeQueryResponse.data.filter(
                    (typeQuery) =>
                      !mapLayerResponse.data
                        ?.map((maplayer) => maplayer.id)
                        .includes(typeQuery.id)
                  )
                : []
            }
            label="typeMapsLayer"
          />
          {mapLayer && mapLayer.id === "Yandex" && (
            <>
              <DbaTextField
                required
                error={
                  errorState &&
                  (apiKey.length === 0 || !yandexApiKeyValidation.test(apiKey))
                }
                setError={(value) => value.length === 0 && setErrorState(true)}
                value={apiKey}
                setValue={setApiKey}
                label="apiKey"
                helperText="apiKeyFormat"
              />
              <DbaSimpleSelect
                required
                error={errorState && lang.length === 0}
                size="medium"
                options={languages}
                selectedValue={lang}
                setSelectedValue={setLang}
                label="languageInscriptionsMap"
              />
              <DbaNumberInput
                required
                error={errorState && (scale < 0.1 || scale > 4 || isNaN(scale))}
                round={1}
                step={0.1}
                label="ratioMapLayer"
                size="medium"
                value={scale}
                setValue={setScale}
                min={0.1}
                max={4}
                helperText={scale < 0.1 || scale > 4 ? "scaleValue" : ""}
              />
            </>
          )}
        </Stack>
      </Box>
    </>
  );
};
