import type { ChartSettingsProps } from "../Types";
import { WidgetSettingContainer, SettingsToggle } from "../../common";
import { DbaSlider } from "../../../../../../../DbaComponents";

const gridShapeOptions = [
  {
    value: "linear",
    label: "linear",
  },
  {
    value: "circular",
    label: "circular",
  },
];

export const RadarChartAxesSettings = ({
  settings,
  setSettings,
}: ChartSettingsProps) => {
  return (
    <>
      <WidgetSettingContainer title="gridLevels">
        <DbaSlider
          sx={{ width: 150 }}
          value={settings.diagram.gridLevels ?? 5}
          min={1}
          max={12}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                gridLevels: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="gridShape">
        <SettingsToggle
          settings={settings}
          setSettings={setSettings}
          property="gridShape"
          defaultValue="circular"
          options={gridShapeOptions}
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="gridLabelOffset" lastRow>
        <DbaSlider
          sx={{ width: 150 }}
          value={settings.diagram.gridLabelOffset ?? 16}
          min={0}
          max={60}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                gridLabelOffset: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>
    </>
  );
};
