import { DbaCalendarChart } from "../../../../common/Diagrams";
import { WidgetErrorMessage } from "../../../../../common";

export const CalendarChart = ({ data, settings, id }) => {
  if (!data?.[0]?.data?.[0]?.day) {
    return <WidgetErrorMessage id={id} widgetType={"Diagram"} />
  }
  const merged = [].concat.apply([], data.map((item) => {
    return item.data.map((item) => {
      return item
    })
  }));

  const objs = [].concat.apply([], merged.map((item) => {
    return {
      day: item.day.substring(0, item.day.indexOf('T')),
      value: item.value
    }
  }));

  return (
    <DbaCalendarChart
      data={objs}
      settings={settings}
    />
  )
}

