import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import { useIntl } from "react-intl";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { DbaErrorBoundary } from ".";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} {...props} />;
});

type DbaSnackbarProps = {
  error: boolean;
  success?: boolean;
  successMessage?: string;
  errorMessage?: string;
  duration?: number;
};
export const DbaSnackbar = ({
  error,
  success,
  successMessage,
  errorMessage,
  duration,
}: DbaSnackbarProps) => {
  const [open, setOpen] = React.useState(false);
  const intl = useIntl();

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  React.useEffect(() => {
    if (error || success) {
      setOpen(true);
    }
  }, [error, success]);

  return (
 <DbaErrorBoundary>    
<Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={open}
      autoHideDuration={duration ? duration : 3000}
      onClose={handleClose}
    >
      {success ? (
        <Alert
          elevation={6}
          onClose={handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessage
            ? intl.messages[successMessage] || successMessage
            : intl.messages["deleteSuccesResponse"]}
        </Alert>
      ) : error ? (
        <Alert
          onClose={handleClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage
            ? intl.messages[errorMessage] || errorMessage
            : intl.messages["errorMessage"]}
        </Alert>
      ) : (
        <></>
      )}
    </Snackbar>
</DbaErrorBoundary>
  );
};
