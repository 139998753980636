import type { ChartSettingsPropsWithMapping } from "../Types";
import { ValueFormatSettings, WidgetSettingContainer } from "../../common";
import { DbaSimpleSelect, DbaSlider } from "../../../../../../../DbaComponents";

export const RadialChartBaseSettings = ({
  settings,
  setSettings,
  dataMappingOptions,
}: ChartSettingsPropsWithMapping) => {
  return (
    <>
      <WidgetSettingContainer title="idWidgetSettings">
        <DbaSimpleSelect
          options={dataMappingOptions}
          selectedValue={settings.diagram.indexBy ?? "x"}
          setSelectedValue={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                indexBy: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="keyWidgetSettings">
        <DbaSimpleSelect
          options={dataMappingOptions}
          selectedValue={settings.diagram.value}
          setSelectedValue={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <ValueFormatSettings
        setSettings={setSettings}
        settings={settings}
        property="valueFormat"
      />

      <WidgetSettingContainer title="padding">
        <DbaSlider
          sx={{ width: 150 }}
          value={settings.diagram.padding}
          min={0.1}
          max={0.9}
          step={0.05}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                padding: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="startAngle">
        <DbaSlider
          sx={{ width: 170 }}
          value={settings.diagram.startAngle ?? 0}
          min={-180}
          max={360}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                startAngle: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="endAngle">
        <DbaSlider
          sx={{ width: 170 }}
          value={settings.diagram.endAngle ?? 360}
          min={-360}
          max={360}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                endAngle: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="innerRadius">
        <DbaSlider
          sx={{ width: 170 }}
          value={settings.diagram.innerRadius ?? 0}
          step={0.05}
          min={0}
          max={0.95}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                innerRadius: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="padAngle">
        <DbaSlider
          sx={{ width: 130 }}
          value={settings.diagram.padAngle ?? 0}
          min={0}
          max={45}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                padAngle: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="cornerRadius">
        <DbaSlider
          sx={{ width: 170 }}
          value={settings.diagram.cornerRadius ?? 0}
          min={0}
          max={45}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                cornerRadius: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>
    </>
  );
};
