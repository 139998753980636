import styled from "@emotion/styled";
import { useContext } from "react";
import { ThemeContext } from "../../../../../../utils/ThemeContext";
import colors from "../../../../../../Variables.module.scss";

type StyledCircleProps = {
  darkMode: boolean;
};

type AngleSettingsCircleProps = {
  angle: number;
  smallCircle?: boolean;
};

const CSSStyledCircle = styled.circle<StyledCircleProps>`
  fill: ${(props) => (props.darkMode ? colors.purple900 : colors.lavander500)};
  stroke-width: 1px;
  stroke: ${(props) => (props.darkMode ? colors.purple500 : colors.darkBlue)};
`;
const StyledLine = styled.line<StyledCircleProps>`
  stroke-width: 1px;
  stroke: ${(props) => (props.darkMode ? colors.lavander500 : colors.darkBlue)};
`;

const CSSStyledInnerCircle = styled.circle<StyledCircleProps>`
  fill: ${(props) => (props.darkMode ? colors.lavander500 : colors.darkBlue)};
`;

export const AngleSettingsCircle = ({
  angle,
  smallCircle,
}: AngleSettingsCircleProps) => {
  const { darkMode } = useContext(ThemeContext);
  return (
    <svg width="36" height="36">
      <CSSStyledCircle darkMode={darkMode} cx="18" cy="18" r="15"></CSSStyledCircle>
      <g transform="translate(18,18)">
        <g transform={`rotate(${smallCircle ? angle + 90 : angle})`}>
          <StyledLine darkMode={darkMode} y2="-15"></StyledLine>
          <CSSStyledInnerCircle r="1.5" darkMode={darkMode}></CSSStyledInnerCircle>
          <CSSStyledInnerCircle
            cy="-15"
            r="3"
            darkMode={darkMode}
          ></CSSStyledInnerCircle>
        </g>
      </g>
    </svg>
  );
};
