import React, { useMemo, useCallback } from "react";
import { useGetUserGroupsQuery } from "../../features/serviceSlices/serviceHooks";
import { DbaTable } from "../../DbaComponents";
import styled from "@emotion/styled";
import { LoadingScreen, ErrorMessage } from "../../components/common";
import { useDeleteUserGroupMutation } from "../../features/serviceSlices/serviceHooks";
import { DbaSnackbar } from "../../DbaComponents";
import { UserGroup } from "../../features/serviceSlices/Group/Types";
import { useIntl } from "react-intl";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import { ColumnDef } from "@tanstack/react-table";
import { EditButton } from "../../components/TableHelpers/EditButton";
import { DeleteButton } from "../../components/TableHelpers/DeleteButton";

const BIG_SIZE_TO_PUSH_EDIT_COLUMN = 2000;
const ACTION_COLUMN_SIZE = 44;

const CSSUserGroupContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const UserGroups = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [deleteUserGroup, deleteResponse] = useDeleteUserGroupMutation();
  const userGroups = useGetUserGroupsQuery();

  const onAddButtonClick = useCallback(() => {
    navigate("/userGroup");
  }, [navigate]);

  const deleteHandler = useCallback(
    (obj: UserGroup) => deleteUserGroup(obj.id),
    [deleteUserGroup]
  );

  const tableData = userGroups.data;

  const columns: ColumnDef<UserGroup>[] = useMemo(
    () => [
      {
        id: intl.messages["name"] as string,
        accessorFn: (row) => row.name,
        cell: (props) => {
          return (
            <Link
              underline="hover"
              component={RouterLink}
              to={{
                pathname: `/edit-usergroup/${props.row.original.id}`,
              }}
              sx={{ color: "inherit" }}
            >
              {props.row.original.name}
            </Link>
          );
        },
        size: BIG_SIZE_TO_PUSH_EDIT_COLUMN,
      },
      {
        id: intl.messages["description"] as string,
        accessorFn: (row) => row.description,
        size: BIG_SIZE_TO_PUSH_EDIT_COLUMN,
      },
      {
        id: "edition",
        header: "",
        cell: (props) => (
          <EditButton editLink="/edit-usergroup/" row={props.row} />
        ),
        size: ACTION_COLUMN_SIZE,
      },
      {
        id: "deletion",
        header: "",
        cell: (props) => (
          <DeleteButton row={props.row} deleteDataHandler={deleteHandler} />
        ),
        size: ACTION_COLUMN_SIZE,
      },
    ],
    [deleteHandler, intl.messages]
  );

  if (userGroups.isError) {
    return <ErrorMessage />;
  }
  if (userGroups.isLoading) {
    return <LoadingScreen />;
  }
  return (
    <CSSUserGroupContainer>
      <DbaTable
        id="user-groups-form-table"
        columns={columns}
        data={tableData ?? []}
        headToolbar={{ onAddButtonClick }}
        stylesSettings={{ autoColumnsWidth: false }}
      />
      <DbaSnackbar
        error={deleteResponse.isError}
        success={deleteResponse.isSuccess}
      />
    </CSSUserGroupContainer>
  );
};
