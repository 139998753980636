import { SettingsContainer } from "../../SettingsContainer";
import {
  DbaColorInput,
  DbaSimpleSelect,
  DbaSlider,
} from "../../../../../DbaComponents";
import { LegendSettingsProps } from "./Types";
import Switch from "@mui/material/Switch";
import { PositionSettingsRect } from "./common/PositionSettingsRect";
import { WidgetSettingContainer } from "./common/WidgetSettingContainer";
import { SettingsToggle } from "./common";
import { useIntl } from "react-intl";

const directionOptions = [
  {
    value: "column",
    label: "column",
  },
  {
    value: "row",
    label: "row",
  },
];

export const LegendSettings = ({
  settings,
  setSettings,
}: LegendSettingsProps) => {
  const intl = useIntl();

  const alignOptions = [
    {
      value: "left-to-right",
      label: intl.messages["right"],
    },
    {
      value: "right-to-left",
      label: intl.messages["left"],
    },
    {
      value: "top-to-bottom",
      label: intl.messages["bottom"],
    },
    {
      value: "bottom-to-top",
      label: intl.messages["top"],
    },
  ];
  return (
    <SettingsContainer title="legendSettings">
      <WidgetSettingContainer title="hide">
        <Switch
          checked={
            !settings.diagram?.legends?.length
          }
          onChange={(e) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                legends: e.target.checked
                  ? []
                  : [
                      {
                        anchor: "top-left",
                        direction: "column",
                        justify: false,
                        translateX: 0,
                        translateY: 0,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemsSpacing: 0,
                        symbolSize: 20,
                        itemDirection: "left-to-right",
                      },
                    ],
              },
            }))
          }
        />
      </WidgetSettingContainer>
      {settings.diagram.legends?.length > 0 && (
        <>
          <WidgetSettingContainer title="layoutMode">
            <PositionSettingsRect
              position={settings.diagram.legends[0].anchor}
              setPosition={(value) => {
                setSettings((prevState: any) => ({
                  ...prevState,
                  diagram: {
                    ...prevState.diagram,
                    legends: [
                      { ...prevState.diagram.legends[0], anchor: value },
                    ],
                  },
                }));
              }}
            />
          </WidgetSettingContainer>

          <WidgetSettingContainer title="direction">
            <SettingsToggle
              value={settings.diagram.legends[0].direction}
              onChange={(event, newValue) => {
                setSettings((prevState: any) => ({
                  ...prevState,
                  diagram: {
                    ...prevState.diagram,
                    legends: [
                      { ...prevState.diagram.legends[0], direction: newValue },
                    ],
                  },
                }));
              }}
              options={directionOptions}
            />
          </WidgetSettingContainer>

          <WidgetSettingContainer title="align">
            <Switch
              checked={settings.diagram.legends[0].justify}
              onChange={(e) =>
                setSettings((prevState: any) => ({
                  ...prevState,
                  diagram: {
                    ...prevState.diagram,
                    legends: [
                      {
                        ...prevState.diagram.legends[0],
                        justify: e.target.checked,
                      },
                    ],
                  },
                }))
              }
            />
          </WidgetSettingContainer>

          <WidgetSettingContainer title="legendTextLocation">
            <DbaSimpleSelect
              setSelectedValue={(value) => {
                setSettings((prevState: any) => ({
                  ...prevState,
                  diagram: {
                    ...prevState.diagram,
                    legends: [
                      {
                        ...prevState.diagram.legends[0],
                        itemDirection: value,
                      },
                    ],
                  },
                }));
              }}
              selectedValue={alignOptions.find(
                (option) =>
                  option.value === settings.diagram.legends[0].itemDirection
              )}
              options={alignOptions}
            />
          </WidgetSettingContainer>

          <WidgetSettingContainer title="horizontalLegendLocation">
            <DbaSlider
              sx={{ width: 120 }}
              value={settings.diagram.legends[0].translateX}
              min={-200}
              max={200}
              onChange={(value) =>
                setSettings((prevState: any) => ({
                  ...prevState,
                  diagram: {
                    ...prevState.diagram,
                    legends: [
                      {
                        ...prevState.diagram.legends[0],
                        translateX: value,
                      },
                    ],
                  },
                }))
              }
            />
          </WidgetSettingContainer>

          <WidgetSettingContainer title="verticalLegendLocation">
            <DbaSlider
              sx={{ width: 120 }}
              value={settings.diagram.legends[0].translateY}
              min={-200}
              max={200}
              onChange={(value) =>
                setSettings((prevState: any) => ({
                  ...prevState,
                  diagram: {
                    ...prevState.diagram,
                    legends: [
                      {
                        ...prevState.diagram.legends[0],
                        translateY: value,
                      },
                    ],
                  },
                }))
              }
            />
          </WidgetSettingContainer>

          <WidgetSettingContainer title="width">
            <DbaSlider
              sx={{ width: 170 }}
              value={settings.diagram.legends[0].itemWidth}
              min={10}
              max={200}
              onChange={(value) =>
                setSettings((prevState: any) => ({
                  ...prevState,
                  diagram: {
                    ...prevState.diagram,
                    legends: [
                      {
                        ...prevState.diagram.legends[0],
                        itemWidth: value,
                      },
                    ],
                  },
                }))
              }
            />
          </WidgetSettingContainer>

          <WidgetSettingContainer title="height">
            <DbaSlider
              sx={{ width: 170 }}
              value={settings.diagram.legends[0].itemHeight}
              min={10}
              max={200}
              onChange={(value) =>
                setSettings((prevState: any) => ({
                  ...prevState,
                  diagram: {
                    ...prevState.diagram,
                    legends: [
                      {
                        ...prevState.diagram.legends[0],
                        itemHeight: value,
                      },
                    ],
                  },
                }))
              }
            />
          </WidgetSettingContainer>

          <WidgetSettingContainer title="symbolSize">
            <DbaSlider
              sx={{ width: 170 }}
              value={settings.diagram.legends[0].symbolSize}
              min={2}
              max={60}
              onChange={(value) =>
                setSettings((prevState: any) => ({
                  ...prevState,
                  diagram: {
                    ...prevState.diagram,
                    legends: [
                      {
                        ...prevState.diagram.legends[0],
                        symbolSize: value,
                      },
                    ],
                  },
                }))
              }
            />
          </WidgetSettingContainer>

          <WidgetSettingContainer lastRow title="itemsSpacing">
            <DbaSlider
              sx={{ width: 300 }}
              fullWidth
              value={settings.diagram.legends[0].itemsSpacing}
              min={0}
              max={60}
              onChange={(value) =>
                setSettings((prevState: any) => ({
                  ...prevState,
                  diagram: {
                    ...prevState.diagram,
                    legends: [
                      {
                        ...prevState.diagram.legends[0],
                        itemsSpacing: value,
                      },
                    ],
                  },
                }))
              }
            />
          </WidgetSettingContainer>

          <WidgetSettingContainer title="legendTextFill">
            <DbaColorInput
              value={settings.diagram.legends[0].itemTextColor ?? ""}
              onChange={(event) =>
                setSettings((prevState: any) => ({
                  ...prevState,
                  diagram: {
                    ...prevState.diagram,
                    legends: [
                      {
                        ...prevState.diagram.legends[0],
                        itemTextColor: event.target.value,
                      },
                    ],
                  },
                }))
              }
            />
          </WidgetSettingContainer>
        </>
      )}
    </SettingsContainer>
  );
};
