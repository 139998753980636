import { WidgetSettingContainer } from "../../common";
import type { ChartSettingsProps } from "../Types";
import {
  DbaColorPaletteSelect,
  DbaSlider,
} from "../../../../../../../DbaComponents";
import { getColorSchemeOptions } from "../../common/ColorSettingsOptions";

export const AreaBumpChartStyleSettings = ({
  settings,
  setSettings,
}: ChartSettingsProps) => {
  const colorSchemeOptions = getColorSchemeOptions(true, true);
  return (
    <>
      <WidgetSettingContainer title="colorScheme">
        <DbaColorPaletteSelect
          setSelectedValue={(value) => {
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                colors: { scheme: value },
              },
            }));
          }}
          selectedValue={
            settings.diagram?.colors?.scheme === undefined
              ? colorSchemeOptions.find((option) => option.value === "nivo")
                  ?.value
              : colorSchemeOptions.find(
                  (option) => option.value === settings.diagram.colors.scheme
                )?.value
          }
          options={colorSchemeOptions}
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="borderWidth">
        <DbaSlider
          sx={{ width: 150 }}
          value={settings.diagram.borderWidth ?? 1}
          min={0}
          max={20}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                borderWidth: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="opacity">
        <DbaSlider
          sx={{ width: 150 }}
          value={settings.diagram.fillOpacity ?? 0.8}
          min={0}
          step={0.05}
          max={1}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                fillOpacity: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="activeFillOpacity">
        <DbaSlider
          sx={{ width: 120 }}
          value={
            settings.diagram.activeFillOpacity === undefined
              ? 1
              : settings.diagram.activeFillOpacity
          }
          min={0}
          step={0.05}
          max={1}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                activeFillOpacity: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="inactiveFillOpacity">
        <DbaSlider
          sx={{ width: 70 }}
          value={
            settings.diagram.inactiveFillOpacity === undefined
              ? 1
              : settings.diagram.inactiveFillOpacity
          }
          min={0}
          step={0.05}
          max={1}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                inactiveFillOpacity: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="activeBorderWidth">
        <DbaSlider
          sx={{ width: 70 }}
          value={
            settings.diagram.activeBorderWidth === undefined
              ? 1
              : settings.diagram.activeBorderWidth
          }
          min={0}
          max={20}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                activeBorderWidth: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>
    </>
  );
};
