import { SettingsContainerProps } from "./Types";
import { CSSContainer, CSSTitle, CSSSettings } from "./StyledComponents";
import { useIntl } from "react-intl";
import { useContext } from "react";
import { ThemeContext } from "../../../../utils/ThemeContext";

export const SettingsContainer = ({
  title,
  children,
}: SettingsContainerProps) => {
  const { darkMode } = useContext(ThemeContext);
  const intl = useIntl();
  return (
    <CSSContainer darkMode={darkMode}>
      <CSSTitle darkMode={darkMode}>{intl.messages[title]}</CSSTitle>
      <CSSSettings>{children}</CSSSettings>
    </CSSContainer>
  );
};
