import styled from "@emotion/styled";
import colors from "../../Variables.module.scss";
import {
  TStylesSettings,
  TPagination,
  THeadToolbar,
  TFixControls,
} from "./Types";

const HEADER_TOOLBAR_MIN_HEIGHT = "56px";
const FOOTER_TOOLBAR_HEIGHT = "42px";

const getBackgroundColorDarkThemeColor = (
  backgroundColorDarkThemeOverride: TStylesSettings["backgroundColorDarkThemeOverride"]
): TStylesSettings["backgroundColorDarkThemeOverride"] => {
  return backgroundColorDarkThemeOverride || colors.darkBlue;
};

export const CSSTableLayout = styled.div<{
  showPagination: TPagination["showPagination"];
  heightReducer: TStylesSettings["heightReducer"];
}>`
  position: relative;
  height: calc(
    100% - ${(props) => (props.showPagination ? FOOTER_TOOLBAR_HEIGHT : "0px")} -
      ${(props) => props.heightReducer}
  );

  // Выравнивание навигации по левому краю
  & .MuiTablePagination-spacer {
    flex: 0;
  }
`;

export const CSSTableWrapper = styled.div`
  display: block;
  height: 100%;
  width: 100%;
  overflow-x: auto;
  position: relative;
`;

export const CSSTableToolbar = styled.div<{
  darkMode: boolean;
  backgroundColorDarkThemeOverride: TStylesSettings["backgroundColorDarkThemeOverride"];
  enableFixControls: TFixControls["enableFixControls"];
}>`
  background: ${(props) => (props.darkMode ? colors.dark : colors.light)};
  border: ${(props) =>
    `1px solid ${
      props.darkMode
        ? getBackgroundColorDarkThemeColor(
            props.backgroundColorDarkThemeOverride
          )
        : colors.lavander500
    }`};
  border-bottom: 0;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
  min-height: ${HEADER_TOOLBAR_MIN_HEIGHT};
  position: sticky;
  top: ${(props) =>
    props.enableFixControls
      ? "0px"
      : // MAX_SAFE_INTEGER - корректное поведение header toolbar при выключенной fixControls в случае если
        // много контента по ширине и происходит скролл по горизонтали (иначе header toolbar скроллится вместе
        // с контентом по горизонтали, а не остается на месте)
        -Number.MAX_SAFE_INTEGER};
  left: 0;
  z-index: 10;
`;

export const CSSTable = styled.table<{
  darkMode: boolean;
  backgroundColorDarkThemeOverride: TStylesSettings["backgroundColorDarkThemeOverride"];
}>`
  width: 100%;
`;

export const CSSTableHead = styled.thead<{
  darkMode: boolean;
  backgroundColorDarkThemeOverride: TStylesSettings["backgroundColorDarkThemeOverride"];
  showHeadToolbar?: THeadToolbar["showHeadToolbar"];
  enableFixControls?: TFixControls["enableFixControls"];
}>`
  background: ${(props) =>
    props.darkMode
      ? getBackgroundColorDarkThemeColor(props.backgroundColorDarkThemeOverride)
      : colors.lavander500};
  position: ${(props) => (props.enableFixControls ? "sticky" : "relative")};
  top: ${(props) =>
    props.enableFixControls && props.showHeadToolbar
      ? HEADER_TOOLBAR_MIN_HEIGHT
      : 0};
  z-index: 10;
  height: 42px;
`;
export const CSSTableHeadRow = styled.tr`
  & th:first-of-type {
    padding-left: 0.5rem;
  }
`;

export const CSSTableBodyRow = styled.tr`
  & td:first-of-type {
    padding-left: 0.5rem;
  }
`;

export const CSSTableCell = styled.td<{
  darkMode: boolean;
  backgroundColorDarkThemeOverride: TStylesSettings["backgroundColorDarkThemeOverride"];
  showPagination?: TPagination["showPagination"];
}>`
  padding-right: 10px;

  border-bottom: ${(props) =>
    `1px solid ${
      props.darkMode
        ? getBackgroundColorDarkThemeColor(
            props.backgroundColorDarkThemeOverride
          )
        : colors.lavander500
    }`};

  &:first-of-type {
    border-left: ${(props) =>
      `1px solid ${
        props.darkMode
          ? getBackgroundColorDarkThemeColor(
              props.backgroundColorDarkThemeOverride
            )
          : colors.lavander500
      }`};
  }

  &:last-of-type {
    border-right: ${(props) =>
      `1px solid ${
        props.darkMode
          ? getBackgroundColorDarkThemeColor(
              props.backgroundColorDarkThemeOverride
            )
          : colors.lavander500
      }`};
  }

  tr:last-of-type & {
    border-bottom-color: ${(props) =>
      props.showPagination
        ? "transparent"
        : props.darkMode
        ? getBackgroundColorDarkThemeColor(
            props.backgroundColorDarkThemeOverride
          )
        : colors.lavander500};
  }
`;

export const CSSTableFootToolbar = styled.div<{
  enableFixControls: TFixControls["enableFixControls"];
  darkMode?: boolean;
  backgroundColorDarkThemeOverride: TStylesSettings["backgroundColorDarkThemeOverride"];
}>`
  position: sticky;
  left: 0;
  z-index: 10;

  .MuiTablePagination-root {
    background: ${(props) =>
      props.darkMode
        ? getBackgroundColorDarkThemeColor(
            props.backgroundColorDarkThemeOverride
          )
        : colors.lavander500};
    border-bottom: 0;

    .MuiToolbar-root {
      min-height: ${FOOTER_TOOLBAR_HEIGHT};
      height: ${FOOTER_TOOLBAR_HEIGHT};
    }
  }
`;

export const CSSLoaderContainer = styled.div`
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CSSSortTitle = styled.div<{
  enableSorting: boolean;
}>`
  cursor: ${(props) => (props.enableSorting ? "pointer" : "default")};
  display: flex;
  align-items: center;
  gap: 4px;
  padding-top: 1px;
  position: relative;
  z-index: 10;
`;
