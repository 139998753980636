import { DbaSlider } from "../../../../../../../DbaComponents";
import { WidgetSettingContainer } from "../../common";
import type { ChartSettingsProps } from "../Types";

export const AreaBumpChartLabelSettings = ({
  settings,
  setSettings,
}: ChartSettingsProps) => {
  return (
    <>
      <WidgetSettingContainer title="startLabelPadding">
        <DbaSlider
          sx={{ width: 120 }}
          min={0}
          max={30}
          value={settings.diagram.startLabelPadding ?? 12}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                startLabelPadding: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>
      <WidgetSettingContainer lastRow title="endLabelPadding">
        <DbaSlider
          sx={{ width: 120 }}
          min={0}
          max={30}
          value={settings.diagram.endLabelPadding ?? 12}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                endLabelPadding: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>
    </>
  );
};
