import {
  TBoundBox,
  TFeatureCollectionGeoObject,
} from "../../../../../features/serviceSlices/MapObjects/Types";
import { Point, Polygon, LineString, Position } from "geojson";
import { LatLngBoundsExpression } from "leaflet";
import { arrayMinMax } from "../../../../../utils/helpers/functions";
import constants from "../../../../../utils/constants";

type TGeometry = Point | Polygon | LineString;

// DEFAULT_BOUNDS выставляются при динамическом позиционировании в момент, когда на карте нет
// объектов для позиционирования
const DEFAULT_BOUNDS: LatLngBoundsExpression = constants.mapDefaultBounds;

const isPointGuard = (data: any): data is Point =>
  data && data.type === "Point";

const isPolygonGuard = (data: any): data is Polygon =>
  data && data.type === "Polygon";

const isLineStringGuard = (data: any): data is LineString =>
  data && data.type === "LineString";

const featuresCoordinatesExtractor = (
  geometry: TGeometry,
  position: "Lat" | "Lng"
): number[] => {
  const latOrLng = position === "Lat" ? 0 : 1;
  let result: number[] = [];
  if (isPointGuard(geometry)) {
    result = [geometry.coordinates[latOrLng]];
  } else if (isPolygonGuard(geometry)) {
    const coordinates: Position[] = [];
    geometry.coordinates.forEach((item) => coordinates.push(...item));
    result = coordinates.map((item) => item[latOrLng]);
  } else if (isLineStringGuard(geometry)) {
    result = geometry.coordinates.map((item) => item[latOrLng]);
  }
  return result;
};

export const getBoundBoxFromFeatureCollection = (
  data: TFeatureCollectionGeoObject
): TBoundBox => {
  const latitudes = data.features
    .map((feature) => featuresCoordinatesExtractor(feature.geometry, "Lat"))
    .flat();
  const minMaxLat = arrayMinMax(latitudes);
  const longitudes = data.features
    .map((feature) => featuresCoordinatesExtractor(feature.geometry, "Lng"))
    .flat();
  const minMaxLong = arrayMinMax(longitudes);
  return {
    minLatitude: minMaxLat[0],
    maxLatitude: minMaxLat[1],
    minLongitude: minMaxLong[0],
    maxLongitude: minMaxLong[1],
  };
};

export const getResultBoundFromBoundBoxesCollection = (
  data: TBoundBox[]
): LatLngBoundsExpression => {
  const minMinLongitude = Math.min(
    ...data.map((boundBox) => boundBox.minLongitude)
  );
  const minMinLatitude = Math.min(
    ...data.map((boundBox) => boundBox.minLatitude)
  );
  const maxMaxLongitude = Math.max(
    ...data.map((boundBox) => boundBox.maxLongitude)
  );
  const maxMaxLatitude = Math.max(
    ...data.map((boundBox) => boundBox.maxLatitude)
  );
  const isValid =
    data.length &&
    isFinite(minMinLongitude) &&
    isFinite(minMinLatitude) &&
    isFinite(maxMaxLongitude) &&
    isFinite(maxMaxLatitude);
  return isValid
    ? [
        [minMinLongitude, minMinLatitude],
        [maxMaxLongitude, maxMaxLatitude],
      ]
    : DEFAULT_BOUNDS;
};
