const legendPositionOptions = [
  {
    value: "before",
    label: "before",
  },
  {
    value: "after",
    label: "after",
  },
];

const labelOptions = [
  {
    value: "category",
    label: "category",
  },
  {
    value: "groupId",
    label: "groupId",
  },
  {
    value: "value",
    label: "value",
  },
  {
    value: "formattedValue",
    label: "formattedValue",
  },
];

export { legendPositionOptions, labelOptions };
