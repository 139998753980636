import { TDataQualityTable } from "../../Types"

export const initDataQualityTableData: TDataQualityTable[] = [
    {
      checkTypeLabel: "Дубли",
      checkTypeId: "Duplicates",
      isChoosen: true,
      parameters: [],
    },
    {
      checkTypeLabel: "Пропуски",
      checkTypeId: "Passes",
      isChoosen: true,
      parameters: [],
    },
    {
      checkTypeLabel: "Несовпадение типов данных",
      checkTypeId: "TypesMismatch",
      isChoosen: true,
      parameters: [],
    },
    {
      checkTypeLabel: "Выбросы",
      checkTypeId: "Outliers",
      isChoosen: true,
      parameters: [
        { id: "algorithm", value: "outlier_quantile" },
        { id: "threshold", value: 5 },
      ],
    },
  ];

  export const AlgorithmVariants = [
    {
      value: "outlier_quantile",
      label: "Интерквартильный размах",
    },
    {
      value: "outlier_std",
      label: "Стандартное отклонение",
    },
  ];