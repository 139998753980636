import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DbaButton, DbaSnackbar, DbaTable } from "../../../../DbaComponents";
import { MessageFormatElement, useIntl } from "react-intl";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { tabIndexes, TDataQualityTable, TMLDataQualityTab } from "../../Types";
import {
  AlgorithmVariants,
  initDataQualityTableData,
} from "./DataQualityTabInitialValues";
import { InfoOutlined, PlayArrow } from "@mui/icons-material";
import { ColumnDef } from "@tanstack/table-core";
import { useSendMlTaskMutation } from "../../../../features/serviceSlices/serviceHooks";
import { onlyNumbers } from "../../../../utils/helpers/functions";
import { isFetchBaseQueryErrorTypeGuard } from "../../../../utils/TypeGuards";

export const MLDataQualityTab: React.FC<TMLDataQualityTab> = ({
  mlDataSetSummary,
  setTabIndex,
}) => {
  const intl = useIntl();

  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  const [tableData, setTableData] = useState<TDataQualityTable[]>(
    initDataQualityTableData
  );
  const [validationMessage, setValidationMessage] = useState<
    string | MessageFormatElement[] | undefined
  >();
  const [sendMlTask, sendMlTaskResponse] = useSendMlTaskMutation();

  const handleChangeSelectedType = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    setValidationMessage(undefined);
    setTableData((prevState) => {
      const newState = prevState.map((obj) => {
        if (obj.checkTypeId === id) {
          return { ...obj, isChoosen: event.target.checked };
        }
        return obj;
      });
      return newState;
    });
  };

  const handleParameterChange = (
    checkId: string,
    parameterId: string,
    value: string | number
  ) => {
    setValidationMessage(undefined);
    if (parameterId === "threshold" && value) {
      if (value < 1) {
        value = 1;
      }
      if (!onlyNumbers(value.toString())) {
        value = isNaN(parseInt(value.toString()))
          ? 1
          : parseInt(value.toString());
      }
    }
    setTableData((prevState) => {
      const newState = prevState.map((obj) => {
        if (obj.checkTypeId === checkId) {
          return {
            ...obj,
            parameters: obj.parameters.map((p) => {
              if (p.id === parameterId) {
                return { ...p, value: value };
              }
              return p;
            }),
          };
        }
        return obj;
      });
      return newState;
    });
  };

  const onRunDataQualityButtonHandler = () => {
    if (isValidationSuccess()) {
      let args = tableData
        .filter((x) => x.isChoosen)
        .map((x) => ({
          checkType: x.checkTypeId,
          parameters: x.parameters,
        }));
      sendMlTask({
        mlDatasetId: mlDataSetSummary.id,
        taskType: "DataQualityCheck",
        args: JSON.stringify(args),
      });
    }
  };

  useEffect(() => {
    if (sendMlTaskResponse.isError) {
      if (isFetchBaseQueryErrorTypeGuard(sendMlTaskResponse.error)) {
        setSnackbarMessage(sendMlTaskResponse.error.data.message);
      }
    } else if (sendMlTaskResponse.isSuccess) {
      setSnackbarMessage(intl.messages["successMessage"] as string);
    }
  }, [intl.messages, sendMlTaskResponse]);

  const isValidationSuccess = (): boolean => {
    if (
      tableData.filter(
        (x) =>
          x.checkTypeId === "outliers" &&
          x.parameters.filter((p) => p.value.toString().length === 0).length !==
            0
      ).length !== 0
    ) {
      setValidationMessage(intl.messages["emptyValueForOutliersCheck"]);
      return false;
    }
    if (tableData.filter((x) => x.isChoosen === true).length === 0) {
      setValidationMessage(intl.messages["checkTypeMinCount"]);
      return false;
    }
    return true;
  };

  const getButtonTooltipMsg = () => {
    let message = intl.messages["runMlTaskButtonTooltip"];
    if (validationMessage !== undefined)
      message = intl.messages["runMlTaskButtonParamsTooltip"];
    if (mlDataSetSummary.typesDetected !== "Confirmed")
      message = intl.messages["runMlTaskButtonTypesUndefinedTooltip"];
    if (mlDataSetSummary.status === "InWork")
      message = intl.messages["runMlTaskButtonInWorkTooltip"];
    if (mlDataSetSummary.status === "InQueue")
      message = intl.messages["runMlTaskButtonInQueueTooltip"];
    return message;
  };

  const checkTypesCell = useCallback(
    (row: TDataQualityTable) => {
      return (
        <>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Checkbox
              checked={row.isChoosen}
              onChange={(e) => handleChangeSelectedType(e, row.checkTypeId)}
              disabled={false}
              sx={{ width: 50, minWidth: 50 }}
            />
            <span>{row.checkTypeLabel}</span>
            <Tooltip
              placement="right"
              arrow={true}
              title={intl.messages[`${row.checkTypeId.toLowerCase()}Tooltip`]}
            >
              <InfoOutlined sx={{ width: 15, height: 15, color: "#888888" }} />
            </Tooltip>
          </Stack>
        </>
      );
    },
    [intl.messages]
  );

  const isDataQualityTaskSent = sendMlTaskResponse.isSuccess ? (
    <Box sx={{ marginBottom: "16px" }}>
      <Alert severity="info">
        <Box>{intl.messages["dataQualityCheckIsRunning"]}</Box>
        <DbaButton
          sx={{ marginTop: "8px", marginRight: "16px" }}
          text="learnProcessingStatusFully"
          onClick={() => setTabIndex(tabIndexes.STATUS_TAB_INDEX)}
        />
      </Alert>
    </Box>
  ) : null;

  const parametersCell = useCallback(
    (row: TDataQualityTable) => {
      return (
        <Stack margin={2} spacing={2} sx={{ width: 320, minWidth: 320 }}>
          {row.parameters.map((parameter) => {
            switch (parameter.id) {
              case "algorithm":
                return (
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">
                      Алгоритм
                    </InputLabel>
                    <Select
                      id="demo-simple-select"
                      value={parameter.value}
                      label="Алгоритм"
                      onChange={(e) =>
                        handleParameterChange(
                          row.checkTypeId,
                          parameter.id,
                          e.target.value
                        )
                      }
                    >
                      {AlgorithmVariants.map((x) => (
                        <MenuItem value={x.value}>
                          <Tooltip
                            arrow={true}
                            placement="left"
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  inset: "0px 6px auto auto",
                                },
                              },
                            }}
                            title={intl.messages[`${x.value}Tooltip`]}
                          >
                            <span>{x.label}</span>
                          </Tooltip>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                );
              case "threshold":
                return (
                  <TextField
                    id="outlined-name"
                    label="Порог"
                    value={parameter.value}
                    onChange={(e) =>
                      handleParameterChange(
                        row.checkTypeId,
                        parameter.id,
                        e.target.value
                      )
                    }
                  />
                );
              default:
                return <></>;
            }
          })}
        </Stack>
      );
    },
    [intl.messages]
  );

  const columns: ColumnDef<TDataQualityTable>[] = useMemo(
    () => [
      {
        id: "Выбор типа проверки",
        accessorFn: (row) => row.checkTypeId,
        cell: (props) => checkTypesCell(props.row.original),
      },
      {
        id: "Параметры",
        accessorFn: (row) => row.parameters,
        cell: (props) => parametersCell(props.row.original),
      },
    ],
    [checkTypesCell, parametersCell]
  );
  return (
    <>
      {isDataQualityTaskSent}
      <Stack spacing={2}>
        <Stack spacing={2} direction="row" alignItems={"center"}>
          <Tooltip arrow={true} placement="right" title={getButtonTooltipMsg()}>
            <span>
              <Button
                endIcon={<PlayArrow />}
                variant="contained"
                onClick={onRunDataQualityButtonHandler}
                disabled={
                  mlDataSetSummary.status === "InQueue" ||
                  mlDataSetSummary.status === "InWork" ||
                  mlDataSetSummary.typesDetected !== "Confirmed" ||
                  validationMessage !== undefined
                }
              >
                {intl.messages["runDataQualityCheck"]}
              </Button>
            </span>
          </Tooltip>
          {validationMessage !== undefined && (
            <Typography color={"red"} variant="body1">
              {validationMessage}
            </Typography>
          )}
        </Stack>
        <DbaTable
          columns={columns}
          data={tableData}
          pagination={{ showPagination: false }}
          headToolbar={{ showHeadToolbar: false }}
          fixControls={{ enableFixControls: false }}
        />
        <DbaSnackbar
          error={sendMlTaskResponse.isError}
          success={sendMlTaskResponse.isSuccess}
          successMessage={snackbarMessage as string}
          errorMessage={snackbarMessage as string}
        />
      </Stack>
    </>
  );
};
