import styled from "@emotion/styled";

const CSSHeadersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
`;

const CSSButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

const CSSReferenceBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const CSSReferencesContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export { CSSHeadersContainer, CSSButtonContainer, CSSReferenceBox, CSSReferencesContainer };
