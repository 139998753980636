import { useIntl } from "react-intl";
import styled from "@emotion/styled";
import InputAdornment from "@mui/material/InputAdornment";
import { InputAdornmentProps } from "./Types";

const CSSContent = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const CSSText = styled.p`
  color: ${({ theme }: any) => theme.palette.text.primary};
`;

export const InputIcon = ({ icon, text }: InputAdornmentProps) => {
  const intl = useIntl();
  return (
    <InputAdornment position="start">
      <CSSContent>
        <CSSText>{intl.messages[text]}</CSSText>
        {icon}
      </CSSContent>
    </InputAdornment>
  );
};
