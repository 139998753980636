import { ProviderSettingsField } from "../../features/serviceSlices/Providers/Types";
import { isFetchBaseQueryErrorTypeGuard } from "../TypeGuards";
import { store } from "../store";
import jwtDecode from "jwt-decode";
import { AuthorizationInfo } from "../../features/serviceSlices/Authentication/Types";

export const findUnfilledInput: (
  state: string,
  fieldsArray: ProviderSettingsField[]
) => boolean | ProviderSettingsField = (state, fieldsArray) => {
  const requiredFieldsArray = fieldsArray.filter(
    (obj) => obj.isRequired === true
  );
  if (requiredFieldsArray.length === 0) {
    return false;
  }
  const componentState = JSON.parse(state);
  if (Object.keys(componentState).length === 0) {
    return true;
  }
  const unfilledInputExists = requiredFieldsArray.find(
    (field) => Boolean(componentState[field.name.toLowerCase()]) === false
  );
  if (unfilledInputExists) {
    return unfilledInputExists;
  }
  return false;
};

export const cleanSettings = (
  settings: string,
  requiredFields: ProviderSettingsField[]
) => {
  const state = JSON.parse(settings);
  const requiredFieldsArray = requiredFields.map((field) =>
    field.name.toLowerCase()
  );
  const filtered = Object.keys(state)
    .filter((key) => requiredFieldsArray.includes(key.toLowerCase()))
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: state[key],
      };
    }, {});

  return JSON.stringify(filtered);
};

export const hexToRgb = (hex: string) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const createRGBString = (rgb: {
  r: number;
  g: number;
  b: number;
  a?: number;
}): string => {
  const alphaString = rgb.a ? ` / ${rgb.a * 100}%` : "";
  return `${rgb.a ? "rgba" : "rgb"}(${rgb.r} ${rgb.g} ${rgb.b}${
    rgb.a ? alphaString : ""
  })`;
};

export function roundNumber(num: number, precision: number) {
  return +(Math.round(Number(num + "e+" + precision)) + "e-" + precision);
}

export const debounce = (callback: Function, wait: number) => {
  let timeoutId: any = null;
  return (...args: any) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
};

export const clampBuilder = (
  minWidthPx: number,
  maxWidthPx: number,
  fontSize: number
) => {
  // Делим на 16, чтобы перевести px в rem
  const maxFontSize = fontSize / 16;
  // Делим на 2, чтобы минимальный шрифт был вдвое меньше заданного пользователем максимального значения
  const minFontSize = fontSize / 2 / 16;

  const root = document.querySelector("html");
  const pixelsPerRem = Number(getComputedStyle(root!).fontSize.slice(0, -2));

  const minWidth = minWidthPx / pixelsPerRem;
  const maxWidth = maxWidthPx / pixelsPerRem;

  const slope = (maxFontSize - minFontSize) / (maxWidth - minWidth);
  const yAxisIntersection = -minWidth * slope + minFontSize;

  return `clamp( ${minFontSize}rem, ${yAxisIntersection}rem + ${
    slope * 100
  }vw, ${maxFontSize}rem )`;
};

export const getErrorMessage = (dataResponse: any) => {
  if (
    !dataResponse ||
    !(
      dataResponse.isError && isFetchBaseQueryErrorTypeGuard(dataResponse.error)
    )
  ) {
    return;
  }
  let errorMessage = [];
  if (dataResponse?.error?.data?.message) {
    errorMessage.push(dataResponse?.error?.data?.message);
  }
  if (dataResponse?.error?.data?.innerException) {
    errorMessage.push(dataResponse?.error?.data?.innerException);
  }
  return errorMessage.join(" ");
};

export function CSSString(string: string): Record<string, string | number> {
  let css_json = `{"${string
    .trim()
    .replaceAll("\n", " ")
    .replaceAll(";", '", "')
    .replaceAll(":", '": "')
    .replace(";", "")}"}`;

  css_json = css_json.replace(', ""', "");

  const obj = JSON.parse(css_json);

  const keyValues = Object.keys(obj).map((key) => {
    const camelCased = key.replace(/-[a-z]/g, (g) => g[1].toUpperCase()).trim();
    return { [camelCased]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
}

export const arrayMinMax = (arr: number[]) =>
  arr.reduce(
    ([min, max], val) => [Math.min(min, val), Math.max(max, val)],
    [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY]
  );

export const onlyNumbers = (str: string) => {
  return /^[0-9]+$/.test(str);
};

export const getUserRoleFromToken = (
  token: AuthorizationInfo["token"] | undefined
): string => {
  let userRole = "";
  const parsedToken: Record<string, any> | undefined =
    typeof token === "string" ? jwtDecode(token) : token;
  if (parsedToken) {
    userRole = parsedToken.role;
  }
  return userRole;
};

export const hasRights = (allowedRoles: string[]): boolean => {
  const token = store.getState().app.authorizationInfo?.token;
  const userRole = getUserRoleFromToken(token);
  return allowedRoles.includes(userRole);
};

export const parseJSON = (str: any): Record<string, any> | undefined => {
  let parsed = undefined;
  try {
    parsed = JSON.parse(str);
  } catch (e) {}
  return parsed;
};
