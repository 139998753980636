import { PaletteType } from "./ColorSelector";
import { intl } from "../../../../../../utils/locale";

type PaletteOptionsType = PaletteType[];

const PaletteOptions: PaletteOptionsType = [
  {
    label: intl.messages["red"] as string,
    colors: ["#c4161a", "#e02f43", "#f2494c", "#ff7382", "#ffa6b1"],
  },
  {
    label: intl.messages["orange"] as string,
    colors: ["#fa6401", "#ff781a", "#ff9831", "#ffb358", "#ffcb6d"],
  },
  {
    label: intl.messages["yellow"] as string,
    colors: ["#e0b400", "#f2cc0c", "#fade2a", "#ffee52", "#fff899"],
  },
  {
    label: intl.messages["green"] as string,
    colors: ["#37873d", "#56a65b", "#73bf68", "#96d99d", "#c8f2c3"],
  },
  {
    label: intl.messages["blue"] as string,
    colors: ["#1f60c3", "#3274d8", "#5794f1", "#8ab9ff", "#c0d9ff"],
  },
  {
    label: intl.messages["purple"] as string,
    colors: ["#8f3bb7", "#a351cc", "#b877d8", "#ca95e4", "#deb6f1"],
  },
];

export { PaletteOptions };
