import React, { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { LoadingScreen, ErrorMessage } from "../../components/common";
import {
  useDeleteReportMutation,
  useGetAllReportsQuery,
} from "../../features/serviceSlices/serviceHooks";
import { DbaSnackbar, DbaTable } from "../../DbaComponents";
import { CSSReportsContainer } from "./Reports.styles";
import { TReport } from "../../features/serviceSlices/Reports/Types";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import { ColumnDef } from "@tanstack/react-table";
import { EditButton } from "../../components/TableHelpers/EditButton";
import { DeleteButton } from "../../components/TableHelpers/DeleteButton";

const BIG_SIZE_TO_PUSH_EDIT_COLUMN = 2000;
const ACTION_COLUMN_SIZE = 44;

export const Reports = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [isShowDeleted, setIsShowDeleted] = useState(false);
  const reports = useGetAllReportsQuery(isShowDeleted);
  const [deleteReport, deleteResponse] = useDeleteReportMutation();

  const onAddButtonClick = useCallback(() => {
    navigate("/report");
  }, [navigate]);

  const deleteHandler = useCallback(
    (data: TReport) => deleteReport(data.id),
    [deleteReport]
  );

  const changeIsDeletedHandler = useCallback(
    (event: React.BaseSyntheticEvent) => setIsShowDeleted(event.target.checked),
    []
  );

  const tableData = reports.data;

  const columns: ColumnDef<TReport>[] = useMemo(
    () => [
      {
        id: intl.messages["name"] as string,
        accessorFn: (row) => row.name,
        cell: (props) => {
          return (
            <Link
              underline="hover"
              component={RouterLink}
              to={{
                pathname: `/report/${props.row.original.id}`,
              }}
              sx={{ color: "inherit" }}
            >
              {props.row.original.name}
            </Link>
          );
        },
        size: BIG_SIZE_TO_PUSH_EDIT_COLUMN,
      },
      {
        id: intl.messages["description"] as string,
        accessorFn: (row) => row.description,
        size: BIG_SIZE_TO_PUSH_EDIT_COLUMN,
      },
      {
        id: intl.messages["status"] as string,
        accessorFn: (row) =>
          row.isDeleted ? intl.messages["deleted"] : intl.messages["active"],
      },
      {
        id: "edition",
        header: "",
        cell: (props) => <EditButton editLink="/report/" row={props.row} />,
        size: ACTION_COLUMN_SIZE,
      },
      {
        id: "deletion",
        header: "",
        cell: (props) => (
          <DeleteButton row={props.row} deleteDataHandler={deleteHandler} />
        ),
        size: ACTION_COLUMN_SIZE,
      },
    ],
    [intl.messages, deleteHandler]
  );

  if (reports.isError) {
    return <ErrorMessage />;
  }
  if (reports.isLoading) {
    return <LoadingScreen />;
  }

  return (
    <CSSReportsContainer>
      <DbaTable
        id="reports-form-table"
        columns={columns}
        data={tableData ?? []}
        headToolbar={{
          onAddButtonClick: onAddButtonClick,
          showDeletedSwitch: true,
          deletedSwitchValue: isShowDeleted,
          onDeletedSwitchChange: changeIsDeletedHandler,
        }}
        stylesSettings={{ autoColumnsWidth: false }}
      />
      <DbaSnackbar
        error={deleteResponse.isError}
        success={deleteResponse.isSuccess}
      />
    </CSSReportsContainer>
  );
};
