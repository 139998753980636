import styled from "@emotion/styled";
import colors from "../../../../Variables.module.scss";
import { hexToRgb } from "../../../../utils/helpers/functions";
import { WidgetContainerProps } from "./Types";

export const WIDGET_CONTAINER_MARGIN = "1.5rem";

export const CSSWidgetContainer = styled.div<WidgetContainerProps>`
  label: WidgetContainer;
  border-radius: ${({ body }) =>
    body?.borderRadius || body?.borderRadius === 0
      ? `${body.borderRadius}px`
      : "0.5rem"};
  padding: ${WIDGET_CONTAINER_MARGIN};
  background: ${({ darkMode, body }) => {
    const rgbColor = hexToRgb(
      body?.backgroundColor
        ? body.backgroundColor
        : darkMode
        ? colors.darkBlue
        : colors.white
    )!;
    return body?.visible && rgbColor
      ? `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, ${
          body?.backgroundOpacity ? body.backgroundOpacity : 1
        })`
      : "";
  }};
  box-shadow: ${({ darkMode, body }) =>
    darkMode || !body?.visible ? "none" : colors.bsCardLight};
  backdrop-filter: blur(${({ body }) => (body?.blur ? body.blur : 0)}px);
  color: ${(props) => (props.darkMode ? colors.white : colors.darkBlue)};
  width: ${(props) => {
    let width = "fit-content";
    if (
      props.type === "Diagram" ||
      props.type === "Filter" ||
      props.type === "Map"
    ) {
      width = "500px";
    } else if (props.type === "Table") {
      width = "100%";
    }
    return width;
  }};
  height: ${(props) => {
    let height = "fit-content";
    if (
      props.type === "Diagram" ||
      props.type === "Map" ||
      props.type === "Table"
    ) {
      height = "400px";
    }
    return height;
  }};

  button,
  span {
    transition: 0.2s opacity;
  }
  button.MuiButtonBase-root svg.MuiSvgIcon-root {
    color: ${(props) => props.resizeHandlerColor};
  }
  span.react-resizable-handle::after {
    border-color: ${(props) => props.resizeHandlerColor};
  }

  &:hover button {
    opacity: 1;
  }

  & > span {
    opacity: 0;
  }
  &:hover > span {
    opacity: 1;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1000;
    display: ${(props) => (props.blockUI ? "block" : "none")};
  }

  @media print {
    position: absolute !important;
    top: 0 !important;
    max-width: 100vw !important;
    background: none !important;
    transform: none !important;
    .leaflet-control {
      display: none;
    }
    p {
      color: black;
    }
  }
`;
