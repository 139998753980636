export const notSelectedConditionType = "notSelected";

export const booleanFilterOptions = [
  {
    label: "notSelected",
    value: notSelectedConditionType,
  },
  {
    label: "equals",
    value: "Equals",
  },
];
