import { OptionType } from "./Types";

const markers: OptionType[] = [
  {
    value: "pin1",
    label: "pin1",
  },
  {
    value: "pin2",
    label: "pin2",
  },
  {
    value: "square",
    label: "square",
  },
  {
    value: "circle",
    label: "circle",
  },
  {
    value: "triangleUp",
    label: "triangleUp",
  },
  {
    value: "triangleDown",
    label: "triangleDown",
  },
  {
    value: "rhombus",
    label: "rhombus",
  },
  {
    value: "cross",
    label: "cross",
  },
];

export default markers;
