import styled from "@emotion/styled";
import colors from "../../../../Variables.module.scss";
import { TitleType, WrapperType, CardHeaderType } from "./WidgetLayout.types";
import { clampBuilder } from "../../../../utils/helpers/functions";

export const NON_DEFAULT_WIDGET_LAYOUT_GAP = "0.5rem";

const CSSTitle = styled.p<TitleType>`
  cursor: ${({ editMode, widgetEditorMode }) =>
    editMode && !widgetEditorMode ? "move" : "pointer"};
  font-weight: ${(props) => props.fontWeight};
  display: ${({ visible }) => (visible ? "" : "none")};
  color: ${({ fontColor, darkMode }) =>
    fontColor ? fontColor : darkMode ? colors.white : colors.darkBlue};
  line-height: 1.5;
  text-align: ${(props) => props.textAlign};
  outline: ${({ selected }) => (selected ? `2px solid ${colors.outline}` : "")};
  border-radius: ${({ selected }) => (selected ? "4px" : "")};
  background: ${(props) =>
    props?.gradientColor
      ? `linear-gradient(to right, ${
          props?.fontColor
            ? props.fontColor
            : props.darkMode
            ? colors.white
            : colors.darkBlue
        }, ${props.gradientColor})`
      : "none"};
  background-clip: ${(props) => (props?.gradientColor ? "text" : "none")};
  text-fill-color: ${(props) =>
    props?.gradientColor ? "transparent" : "none"};

  @media (max-width: 1000px) {
    font-size: ${(props) => clampBuilder(1, 1000, props.fontSize / 1.25)};
  }
  @media (max-width: 1920px) {
    font-size: ${(props) => clampBuilder(1001, 1920, props.fontSize)};
  }
  @media (min-width: 1921px) {
    font-size: ${(props) => clampBuilder(1921, 3840, props.fontSize * 2)};
  }
`;

const CSSDiagramContainer = styled.div<WrapperType>`
  height: ${({ type, fontSize = 16, visible }) =>
    type === "Default"
      ? "100%"
      : visible
      ? `calc(100% - ${fontSize * 1.5}px)`
      : "100%"};
`;

const CSSWrapper = styled.div<WrapperType>`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${(props) =>
    props.type === "Default" ? "1.25rem" : NON_DEFAULT_WIDGET_LAYOUT_GAP};
`;

const CSSCardHeader = styled.div<CardHeaderType>`
  min-height: ${({ visible }) => (visible ? "24px" : "0")};
  margin-right: 20px;
  &:hover {
    border-radius: ${(props) => (props.editMode ? "4px" : "")};
    background-color: ${({ darkMode, editMode, widgetEditorMode }) => {
      if (!editMode || widgetEditorMode) {
        return "";
      }
      return darkMode ? colors.purple900 : colors.lavander500;
    }};
  }
`;

const CSSLoaderContainer = styled.div`
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export {
  CSSTitle,
  CSSDiagramContainer,
  CSSWrapper,
  CSSCardHeader,
  CSSLoaderContainer,
};
