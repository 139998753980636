
import { DbaAreaBumpChart } from '../../../../common/Diagrams';

export const AreaBumpChart = ({ data, settings }) => {

  return (
    <DbaAreaBumpChart
      data={data}
      settings={settings}
    />
  )
}

