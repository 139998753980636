import { TPeriodOption } from "./Types";

export const getPeriodOptions = (intl: any): TPeriodOption[] => {
  return [
    {
      id: "fifteenSeconds",
      name: `15 ${intl.messages["secondsGenitive"].toLowerCase()}`,
      value: "0h 0m 15s",
    },
    {
      id: "thirtySeconds",
      name: `30 ${intl.messages["secondsGenitive"].toLowerCase()}`,
      value: "0h 0m 30s",
    },
    {
      id: "oneMinute",
      name: `1 ${intl.messages["minute"].toLowerCase()}`,
      value: "0h 1m 0s",
    },
    {
      id: "threeMinutes",
      name: `3 ${intl.messages["minuteGenitive"].toLowerCase()}`,
      value: "0h 3m 0s",
    },
    {
      id: "fiveMinutes",
      name: `5 ${intl.messages["minutesGenitive"].toLowerCase()}`,
      value: "0h 5m 0s",
    },
    {
      id: "fiveMinutes",
      name: `10 ${intl.messages["minutesGenitive"].toLowerCase()}`,
      value: "0h 10m 0s",
    },
  ];
};
