type TDownloadFileParams = {
  url: string;
  name: string;
  extension: string;
};
type TDownloadFile = (params: TDownloadFileParams) => Promise<void>;

export const downloadBlobFile: TDownloadFile = async ({
  url,
  name,
  extension,
}): Promise<void> => {
  return await fetch(url, {
    headers: {
      accept: "*/*",
      authorization: localStorage.getItem("x-access-token") as string,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.blob();
    })
    .then((data) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${name}.${extension}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
};
