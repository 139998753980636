import React, { useState, useEffect, useRef, memo } from "react";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import DoneIcon from "@mui/icons-material/Done";
import { TFilterClearControl } from "./Types";
import { CSSFilterClearControl } from "./FilterClearControl.styles";
import {
  useAppSelector,
  useAppDispatch,
} from "../../../../../../utils/reduxHooks";
import {
  addFilter,
  deleteFilter,
} from "../../../../../../features/slices/App/appSlice";
import { Tooltip } from "@mui/material";
import { useIntl } from "react-intl";
import { parseJSON } from "../../../../../../utils/helpers/functions";

const SHOWING_OK_TIME = 1000;

export const FilterClearControl: React.FC<TFilterClearControl> = memo(
  ({ filterKey, baseLayer, baseLayers = [], setFilterValue }) => {
    const intl = useIntl();

    const dispatch = useAppDispatch();
    const { filters } = useAppSelector((state) => state.app);

    const [isOkShown, setIsOkShown] = useState<boolean>(false);
    const [isBaseLayerJustChanged, setIsBaseLayerJustChanged] =
      useState<boolean>(true);
    const isFilterWithFilterKeyAppliedPrev = useRef<boolean>(false);
    const isDefaultValueInFilterPrev = useRef<boolean>(false);
    const baseLayerPrev = useRef<TFilterClearControl["baseLayer"]>(null);

    const currentBaseLayer = baseLayers.find((layer) => layer.id === baseLayer);

    const filter = filterKey
      ? filters.find((filter) => Object.keys(filter).includes(filterKey))
      : undefined;

    const isFilterWithFilterKeyApplied: boolean = filterKey ? !!filter : false;

    const defaultValueParsed = parseJSON(
      baseLayers.find((layer) => layer.id === baseLayer)?.defaultValue
    )?.value;
    const currentFilter = (filter ?? {})[filterKey ?? ""];

    const isAtLeastOneOfDefaultValueOrCurrentFilterValueNotEmpty =
      defaultValueParsed || currentFilter;
    const isDefaultValueInFilter = defaultValueParsed === currentFilter;

    const onClearClick = () => {
      if (filterKey) {
        if (setFilterValue) {
          setFilterValue(null);
        }
        const defaultCurrentBaseLayerFilterValue = parseJSON(
          currentBaseLayer?.defaultValue
        )?.value;
        if (currentBaseLayer && defaultCurrentBaseLayerFilterValue) {
          dispatch(
            addFilter({
              [String(currentBaseLayer.variable)]:
                defaultCurrentBaseLayerFilterValue,
            })
          );
        } else if (currentBaseLayer) {
          dispatch(deleteFilter(String(currentBaseLayer.variable)));
        }
      }
    };

    useEffect(() => {
      if (baseLayer !== baseLayerPrev.current) {
        setIsBaseLayerJustChanged(false);
        setTimeout(() => setIsBaseLayerJustChanged(true), SHOWING_OK_TIME);
      }
    }, [baseLayer]);

    useEffect(() => {
      const filterWasClearedForCurrentLayer =
        isBaseLayerJustChanged &&
        ((!isFilterWithFilterKeyApplied &&
          isFilterWithFilterKeyAppliedPrev.current) ||
          (isDefaultValueInFilter && !isDefaultValueInFilterPrev.current));

      if (filterWasClearedForCurrentLayer) {
        setIsOkShown(true);
        setTimeout(() => setIsOkShown(false), SHOWING_OK_TIME);
      }
      isFilterWithFilterKeyAppliedPrev.current = isFilterWithFilterKeyApplied;
      isDefaultValueInFilterPrev.current = isDefaultValueInFilter;
      baseLayerPrev.current = baseLayer;
    }, [
      baseLayer,
      isDefaultValueInFilter,
      isFilterWithFilterKeyApplied,
      isBaseLayerJustChanged,
    ]);

    let content;

    if (isOkShown && (!defaultValueParsed || currentFilter)) {
      content =
        isBaseLayerJustChanged && baseLayer ? (
          <Tooltip title={intl.messages["isDone"]} placement="top" arrow>
            <CSSFilterClearControl isOk={true}>
              <DoneIcon />
            </CSSFilterClearControl>
          </Tooltip>
        ) : null;
    } else {
      content =
        isAtLeastOneOfDefaultValueOrCurrentFilterValueNotEmpty &&
        !isDefaultValueInFilter &&
        currentBaseLayer?.variable &&
        filterKey &&
        baseLayer ? (
          <Tooltip title={intl.messages["clearFilter"]} placement="top" arrow>
            <CSSFilterClearControl isOk={false}>
              <CleaningServicesIcon onClick={onClearClick} />
            </CSSFilterClearControl>
          </Tooltip>
        ) : null;
    }

    return content;
  }
);
