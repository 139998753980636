import { useIntl } from "react-intl";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import { DbaPopoverProps } from "./Types";
import { CSSPopoverContainer, CSSButtonContainer } from "./StyledComponents";
import { Stack, Typography } from "@mui/material";

export const DbaPopover = ({
  confirmAgain,
  onCloseModal,
  onDeleteItem,
  anchorEl,
  setAnchorEl,
  customConfirmMessage,
  actionMessage,
  noIntl,
  styles,
  adviceMessage,
}: DbaPopoverProps) => {
  const intl = useIntl();
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const keyboardEventHandler = (event: React.KeyboardEvent<HTMLElement>) => {
    event.preventDefault();
    switch (event.key) {
      case "Enter":
        return onDeleteItem();
      case "Escape":
        return onCloseModal();
      default:
        return;
    }
  };

  const getCustomConfirmMessage = () => {
    if (confirmAgain) {
      return intl.messages["confirmDeleteAgain"];
    }
    if (customConfirmMessage) {
      return noIntl
        ? customConfirmMessage
        : intl.messages[customConfirmMessage];
    }
    return intl.messages["confirmDeletion"];
  };

  return (
    <Popover
      open={open}
      sx={{ ...styles }}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      onKeyDown={keyboardEventHandler}
    >
      <CSSPopoverContainer>
        <Stack spacing={1}>
          {adviceMessage ? (
            <Typography fontSize={14} maxWidth={370}>
              {intl.messages[adviceMessage]}
            </Typography>
          ) : null}
          <Typography fontSize={14} maxWidth={370}>
            {getCustomConfirmMessage()}
          </Typography>
        </Stack>
        <CSSButtonContainer>
          <Button
            sx={{ fontSize: 12 }}
            disableElevation
            size="small"
            variant="contained"
            onClick={onCloseModal}
          >
            {intl.messages["cancel"]}
          </Button>
          <Button
            sx={{ fontSize: 12 }}
            disableElevation
            size="small"
            variant="contained"
            color="error"
            onClick={onDeleteItem}
          >
            {actionMessage
              ? intl.messages[actionMessage]
              : intl.messages["delete"]}
          </Button>
        </CSSButtonContainer>
      </CSSPopoverContainer>
    </Popover>
  );
};
