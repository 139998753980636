import { useEffect } from "react";
import { useGetFilterWidgetDataQuery } from "../../../../features/serviceSlices/serviceHooks";
import { LoadingScreen, ErrorMessage } from "../../../common";
import type { WidgetProps } from "../Widgets.types";
import { FilterWidget } from "../../common/FilterWidget";
import { useAppSelector } from "../../../../utils/reduxHooks";
import { skipToken } from "@reduxjs/toolkit/query";
import { CSSWrapper } from "../../common/WidgetLayout/WidgetLayout.styles";
import { AuthGuard } from "../../../Guards/AuthGuard/AuthGuard";
import { WidgetMenu } from "../../common/WidgetMenu";
import { FetchBaseQueryErrorType } from "../../../../features/serviceSlices/SharedTypes";

export const Filter = ({ id, setBody, setResizeHandlerColor }: WidgetProps) => {
  const { type, startDate, endDate, role, editMode, authorizationInfo } =
    useAppSelector((state) => state.app);

  const FilterWidgetData = useGetFilterWidgetDataQuery(
    type !== "NotSet" && type
      ? {
          widgetId: id,
          dashboardId: role.id,
          startDate,
          endDate,
          periodType: type,
        }
      : skipToken
  );

  useEffect(() => {
    if (
      FilterWidgetData.data &&
      FilterWidgetData.data?.widgetData.widgetSettings?.settings?.widget?.body
    ) {
      setBody(
        FilterWidgetData.data.widgetData.widgetSettings.settings.widget.body
      );
    }
  }, [FilterWidgetData.data, setBody]);

  useEffect(() => {
    if (
      FilterWidgetData.data &&
      FilterWidgetData.data?.widgetData.widgetSettings?.settings?.widget
        ?.fontColor
    ) {
      setResizeHandlerColor(
        FilterWidgetData.data.widgetData.widgetSettings.settings.widget
          ?.fontColor
      );
    }
  }, [FilterWidgetData.data, setResizeHandlerColor]);

  const getErrorMessage = () => {
    return (
      <CSSWrapper>
        <ErrorMessage
          error={FilterWidgetData.error as FetchBaseQueryErrorType}
          isShowTooltip={!!authorizationInfo?.isAdmin}
        />
        <AuthGuard>
          <WidgetMenu
            dashboardId={role.id}
            widgetID={id}
            type={"Filter"}
            editMode={editMode}
            isError={true}
          />
        </AuthGuard>
      </CSSWrapper>
    );
  };

  if (FilterWidgetData.isError) {
    return getErrorMessage();
  }
  if (FilterWidgetData.isLoading || FilterWidgetData.isFetching) {
    return <LoadingScreen />;
  }
  return FilterWidgetData.isSuccess ? (
    <FilterWidget data={FilterWidgetData.data.widgetData} />
  ) : null;
};
