import styled from "@emotion/styled";
import {
  TextSettings,
  ValueDifferenceContainerType,
  ValueSpanProps,
} from "./DataWidget.types";
import { clampBuilder } from "../../../../utils/helpers/functions";
import colors from "../../../../Variables.module.scss";

const defultValueFontSize = "clamp(1.75rem, 1.75vw, 3rem)";

const CSSTextContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.25rem;
  color: ${({ theme }: any) =>
    theme.palette.mode === "light" ? colors.lightColorSecondary : colors.white};
`;

const CSSValueContainer = styled.div<TextSettings>`
  color: ${({ theme, fontColor }: any) =>
    fontColor
      ? fontColor
      : theme.palette.mode === "light"
      ? colors.darkBlue
      : ""};
  font-weight: ${({ settings }) => settings?.fontWeight};
  display: ${({ settings }) => (settings?.visible ? "" : "none")};
  text-align: ${({ settings }) =>
    settings?.textAlign ? settings?.textAlign : "start"};
  outline: ${({ selected }) => (selected ? `2px solid ${colors.outline}` : "")};
  border-radius: ${({ selected }) => (selected ? "4px" : "")};
  background: ${({ theme, ...props }: any) =>
    props?.gradientColor
      ? `linear-gradient(to right, ${
          props?.fontColor
            ? props.fontColor
            : theme.palette.mode === "dark"
            ? colors.white
            : colors.darkBlue
        }, ${props.gradientColor})`
      : "none"};
  background-clip: ${(props) => (props?.gradientColor ? "text" : "none")};
  text-fill-color: ${(props) =>
    props?.gradientColor ? "transparent" : "none"};
  @media (max-width: 1000px) {
    font-size: ${({ settings }) =>
      settings?.fontSize
        ? clampBuilder(1, 1000, settings.fontSize / 1.25)
        : defultValueFontSize};
  }
  @media (max-width: 1920px) {
    font-size: ${({ settings }) =>
      settings?.fontSize
        ? clampBuilder(1001, 1920, settings.fontSize)
        : defultValueFontSize};
  }
  @media (min-width: 1921px) {
    font-size: ${({ settings }) =>
      settings?.fontSize
        ? clampBuilder(1921, 3840, settings.fontSize * 2)
        : defultValueFontSize};
  }
`;

const CSSMeasureContainer = styled.span<TextSettings>`
  cursor: ${(props) => (props.editMode ? "pointer" : "default")};
  color: ${({ theme, fontColor }: any) =>
    fontColor
      ? fontColor
      : theme.palette.mode === "light"
      ? colors.darkBlue
      : ""};
  font-weight: ${({ settings }) => settings?.fontWeight};
  display: ${({ settings }) => (settings?.visible ? "" : "none")};
  text-align: center;
  outline: ${({ selected }) => (selected ? `2px solid ${colors.outline}` : "")};
  border-radius: ${({ selected }) => (selected ? "4px" : "")};
  background: ${({ theme, ...props }: any) =>
    props?.gradientColor
      ? `linear-gradient(to right, ${
          props?.fontColor
            ? props.fontColor
            : theme.palette.mode === "dark"
            ? colors.white
            : colors.darkBlue
        }, ${props.gradientColor})`
      : "none"};
  background-clip: ${(props) => (props?.gradientColor ? "text" : "none")};
  text-fill-color: ${(props) =>
    props?.gradientColor ? "transparent" : "none"};
  @media (max-width: 1000px) {
    font-size: ${({ settings }) =>
      settings?.fontSize
        ? clampBuilder(1, 1000, settings.fontSize / 1.25)
        : defultValueFontSize};
  }
  @media (max-width: 1920px) {
    font-size: ${({ settings }) =>
      settings?.fontSize
        ? clampBuilder(1001, 1920, settings.fontSize)
        : defultValueFontSize};
  }
  @media (min-width: 1921px) {
    font-size: ${({ settings }) =>
      settings?.fontSize
        ? clampBuilder(1921, 3840, settings.fontSize * 2)
        : defultValueFontSize};
  }
`;

const CSSValueDifferenceContainer = styled.p<ValueDifferenceContainerType>`
  label: ValueDifferenceContainer;
  display: flex;
  align-items: center;
  justify-content: ${({ settings }) =>
    settings?.textAlign
      ? settings.textAlign === "center"
        ? "center"
        : settings.textAlign === "right"
        ? "flex-end"
        : "flex-start"
      : "flex-start"};
  gap: 0.75rem;
  color: ${({ positiveTrend, fontColor, theme }: any) => {
    if (positiveTrend === null) {
      return fontColor
        ? fontColor
        : theme.palette.mode === "light"
        ? colors.darkBlue
        : "inherit";
    }
    return positiveTrend ? colors.green500 : colors.red500;
  }};
  font-weight: ${({ settings }) => settings?.fontWeight};
  display: ${({ settings }) => (settings?.visible ? "" : "none")};
  border-radius: ${({ selected }) => (selected ? "4px" : "")};
  cursor: ${(props) => (props.editMode ? "pointer" : "default")};
  outline: ${({ selected }) => (selected ? `2px solid ${colors.outline}` : "")};
  background: ${({ theme, ...props }: any) => {
    if (props.positiveTrend !== null || !props?.gradientColor) {
      return "none";
    }
    return `linear-gradient(to right, ${
      props?.fontColor
        ? props.fontColor
        : theme.palette.mode === "dark"
        ? colors.white
        : colors.darkBlue
    }, ${props.gradientColor})`;
  }};
  background-clip: ${(props) => {
    if (props.positiveTrend !== null || !props?.gradientColor) {
      return "none";
    }
    return "text";
  }};
  text-fill-color: ${(props) => {
    if (props.positiveTrend !== null || !props?.gradientColor) {
      return "none";
    }
    return "transparent";
  }};
  @media (max-width: 1000px) {
    font-size: ${({ settings }) =>
      settings?.fontSize
        ? clampBuilder(1, 1000, settings.fontSize / 1.25)
        : "16px"};
  }
  @media (max-width: 1920px) {
    font-size: ${({ settings }) =>
      settings?.fontSize
        ? clampBuilder(1001, 1920, settings.fontSize)
        : "16px"};
  }
  @media (min-width: 1921px) {
    font-size: ${({ settings }) =>
      settings?.fontSize
        ? clampBuilder(1921, 3840, settings.fontSize * 2)
        : "16px"};
  }
`;

const CSSPreviousValue = styled.p<TextSettings>`
  display: flex;
  font-weight: ${({ settings }) => settings?.fontWeight};
  display: ${({ settings }) => (settings?.visible ? "" : "none")};
  border-radius: ${({ selected }) => (selected ? "4px" : "")};
  cursor: ${(props) => (props.editMode ? "pointer" : "default")};
  color: ${({ fontColor }) => (fontColor ? fontColor : "inherit")};
  justify-content: ${({ settings }) =>
    settings?.textAlign
      ? settings.textAlign === "center"
        ? "center"
        : settings.textAlign === "right"
        ? "flex-end"
        : "flex-start"
      : "flex-start"};
  outline: ${({ selected }) => (selected ? `2px solid ${colors.outline}` : "")};
  background: ${({ theme, ...props }: any) =>
    props?.gradientColor
      ? `linear-gradient(to right, ${
          props?.fontColor
            ? props.fontColor
            : theme.palette.mode === "dark"
            ? colors.white
            : colors.darkBlue
        }, ${props.gradientColor})`
      : "none"};
  background-clip: ${(props) => (props?.gradientColor ? "text" : "none")};
  text-fill-color: ${(props) =>
    props?.gradientColor ? "transparent" : "none"};
  @media (max-width: 1000px) {
    font-size: ${({ settings }) =>
      settings?.fontSize
        ? clampBuilder(1, 1000, settings.fontSize / 1.25)
        : "16px"};
  }
  @media (max-width: 1920px) {
    font-size: ${({ settings }) =>
      settings?.fontSize
        ? clampBuilder(1001, 1920, settings.fontSize )
        : "16px"};
  }
  @media (min-width: 1921px) {
    font-size: ${({ settings }) =>
      settings?.fontSize
        ? clampBuilder(1921, 3840, settings.fontSize * 2)
        : "16px"};
  }
`;

const CSSValueBox = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const CSSValueSpan = styled.span<ValueSpanProps>`
  cursor: ${(props) => (props.editMode ? "pointer" : "default")};
  border-radius: ${({ selected }) => (selected ? "4px" : "")};
  outline: ${({ selected }) => (selected ? `2px solid ${colors.outline}` : "")};
`;

export {
  CSSValueContainer,
  CSSMeasureContainer,
  CSSValueDifferenceContainer,
  CSSPreviousValue,
  CSSTextContainer,
  CSSValueBox,
  CSSValueSpan,
};
