import React, { ChangeEvent, useMemo, useState, useCallback } from "react";
import { useIntl } from "react-intl";
import { LoadingScreen, ErrorMessage } from "../../components/common";
import {
  useGetAllFilesQuery,
  useDeleteFileMutation,
  useReUploadFileMutation,
} from "../../features/serviceSlices/serviceHooks";
import { DbaTable } from "../../DbaComponents";
import { DbaSnackbar } from "../../DbaComponents";
import { CSSFilesContainer } from "./Files.styles";
import {
  TFile,
  TFileGetAllResponse,
} from "../../features/serviceSlices/Files/Types";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { downloadBlobFile } from "../../features/services/FileDownload/fileDownloadService";
import { DbaInstantFileUploader } from "../../DbaComponents/DbaUploaders/DbaInstantFileUploader/DbaInstantFileUploader";
import constants from "../../utils/constants";
import { getErrorMessage } from "../../utils/helpers/functions";
import CircularProgress from "@mui/material/CircularProgress";
import { ColumnDef } from "@tanstack/react-table";
import { EditButton } from "../../components/TableHelpers/EditButton";
import { DeleteButton } from "../../components/TableHelpers/DeleteButton";

const BIG_SIZE_TO_PUSH_EDIT_COLUMN = 2000;
const ACTION_COLUMN_SIZE = 44;

export const Files = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const files = useGetAllFilesQuery();
  const [reUpload, reUploadResponse] = useReUploadFileMutation();
  const [deleteFile, deleteResponse] = useDeleteFileMutation();

  const [downloadingFileId, setDownloadingFileId] = useState<string>("");

  const [clientError, setClientError] = useState<string>("");
  const [downloadError, setDownloadError] = useState<string>("");

  const errorMessageReUpload = getErrorMessage(reUploadResponse);
  const errorMessageDelete = getErrorMessage(deleteResponse);

  const onAddButtonClick = useCallback(() => {
    navigate("/file");
  }, [navigate]);

  const deleteHandler = useCallback(
    (data: TFile) => deleteFile(data.id),
    [deleteFile]
  );

  const onFileChange = useCallback(
    (a: TFile) => (e: ChangeEvent<HTMLInputElement>, error?: string) => {
      if (e.target.files && e.target.files[0] && !error) {
        reUpload({
          id: a.id,
          file: e.target.files[0],
        });
      }
      setClientError(error ?? "");
    },
    [reUpload]
  );

  const tableData = useMemo<TFileGetAllResponse | undefined>(
    () =>
      [...(files.data ?? [])].sort((a, b) => {
        let nameA = a.name.toLowerCase();
        let nameB = b.name.toLowerCase();
        return nameA < nameB ? -1 : 1;
      }),
    [files.data]
  );

  const columns: ColumnDef<TFile>[] = useMemo(
    () => [
      {
        id: intl.messages["name"] as string,
        accessorFn: (row) => row.name,
        cell: (props) => {
          return (
            <Link
              underline="hover"
              component={RouterLink}
              to={{
                pathname: `/file/${props.row.original.id}`,
              }}
              sx={{ color: "inherit" }}
            >
              {props.row.original.name}.{props.row.original.extension}
            </Link>
          );
        },
        size: BIG_SIZE_TO_PUSH_EDIT_COLUMN,
      },
      {
        id: intl.messages["category"] as string,
        accessorFn: (row) => row.categoryName,
        size: BIG_SIZE_TO_PUSH_EDIT_COLUMN,
      },
      {
        id: intl.messages["fileUploadDate"] as string,
        accessorFn: (row) => new Date(row.uploadedDate).toLocaleString(),
        size: BIG_SIZE_TO_PUSH_EDIT_COLUMN,
      },
      {
        id: intl.messages["updateFile"] as string,
        cell: (props) => {
          return (
            <DbaInstantFileUploader
              maxSize={constants.maxFileSize}
              onChange={onFileChange(props.row.original)}
              isLoading={
                reUploadResponse.isLoading &&
                reUploadResponse?.originalArgs?.id === props.row.original.id
              }
              disabled={reUploadResponse.isLoading}
            />
          );
        },
        size: BIG_SIZE_TO_PUSH_EDIT_COLUMN,
      },
      {
        id: "download",
        header: "",
        cell: (props) => {
          const url = `${window.__env__.REACT_APP_BASE_URL}api/file/download?ID=${props.row.original.id}`;
          return (
            <RouterLink
              to={url}
              onClick={async (event) => {
                event.preventDefault();
                if (!downloadingFileId) {
                  setDownloadingFileId(props.row.original.id);
                  setDownloadError("");
                  await downloadBlobFile({
                    name: props.row.original.name,
                    url,
                    extension: props.row.original.extension,
                  }).catch((error) => setDownloadError(error.message));
                  setDownloadingFileId("");
                }
              }}
            >
              <IconButton
                size="small"
                style={{ cursor: !downloadingFileId ? "pointer" : "default" }}
              >
                {downloadingFileId === props.row.original.id ? (
                  <CircularProgress size={20} />
                ) : (
                  <FileDownloadIcon />
                )}
              </IconButton>
            </RouterLink>
          );
        },
        size: ACTION_COLUMN_SIZE,
      },
      {
        id: "edition",
        header: "",
        cell: (props) => <EditButton editLink="/file/" row={props.row} />,
        size: ACTION_COLUMN_SIZE,
      },
      {
        id: "deletion",
        header: "",
        cell: (props) => (
          <DeleteButton row={props.row} deleteDataHandler={deleteHandler} />
        ),
        size: ACTION_COLUMN_SIZE,
      },
    ],
    [
      intl.messages,
      onFileChange,
      reUploadResponse.isLoading,
      reUploadResponse?.originalArgs?.id,
      downloadingFileId,
      deleteHandler,
    ]
  );

  if (files.isError) {
    return <ErrorMessage />;
  }
  if (files.isLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <CSSFilesContainer>
        <DbaTable
          id="files-form-table"
          columns={columns}
          data={tableData ?? []}
          headToolbar={{ onAddButtonClick }}
          stylesSettings={{ autoColumnsWidth: false }}
          pagination={{ autoResetPagination: false }}
        />
      </CSSFilesContainer>
      <DbaSnackbar errorMessage={downloadError} error={!!downloadError} />
      <DbaSnackbar errorMessage={clientError} error={!!clientError} />
      <DbaSnackbar
        successMessage={"fileSuccessUpload"}
        errorMessage={errorMessageReUpload}
        error={reUploadResponse.isError}
        success={reUploadResponse.isSuccess}
      />
      <DbaSnackbar
        successMessage={"deleteSuccesResponse"}
        errorMessage={errorMessageDelete}
        error={deleteResponse.isError}
        success={deleteResponse.isSuccess}
      />
    </>
  );
};
