import React, { useCallback, useContext, useMemo } from "react";
import { useIntl } from "react-intl";
import { LoadingScreen, ErrorMessage } from "../../../components/common";
import { useGetAllPlaylistsQuery } from "../../../features/serviceSlices/serviceHooks";
import { useDeletePlaylistMutation } from "../../../features/serviceSlices/serviceHooks";
import { DbaTable } from "../../../DbaComponents";
import { DbaSnackbar } from "../../../DbaComponents";
import { TPlaylist } from "../../../features/serviceSlices/Playlists/Types";
import { CSSPlaylistsContainer, CSSPlaylistsTable } from "./Playlists.styles";
import { useNavigate } from "react-router-dom";
import { Card, Box, IconButton, Tooltip, Alert } from "@mui/material";
import { getErrorMessage } from "../../../utils/helpers/functions";
import { ColumnDef } from "@tanstack/react-table";
import { EditButton } from "../../../components/TableHelpers/EditButton";
import { DeleteButton } from "../../../components/TableHelpers/DeleteButton";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { RoleGuard } from "../../../components/Guards/RoleGuard/RoleGuard";
import { hasRights } from "../../../utils/helpers/functions";
import { ThemeContext } from "../../../utils/ThemeContext";

const MIN_DASHBOARDS_TO_START = 2;

export const Playlists = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { darkMode } = useContext(ThemeContext);

  const playlists = useGetAllPlaylistsQuery();
  const [deletePlaylist, deleteResponse] = useDeletePlaylistMutation();

  const onAddButtonClick = useCallback(() => {
    navigate("/playlist");
  }, [navigate]);

  const deleteHandler = useCallback(
    (obj: TPlaylist) => deletePlaylist(obj.id),
    [deletePlaylist]
  );

  const onStartPlaylistClick = useCallback(
    (playlist: TPlaylist) => () => {
      navigate(
        `/${playlist.dashboards[0].dashboardId}?playlist=${playlist.id}`
      );
    },
    [navigate]
  );

  const errorMessage = getErrorMessage(deleteResponse);

  const tableData = useMemo(
    () => (playlists.data ? [...playlists.data] : []),
    [playlists.data]
  );

  const columns: ColumnDef<TPlaylist>[] = useMemo(
    () => [
      {
        id: intl.messages["name"] as string,
        accessorFn: (row) => row.name,
        cell: (props) => {
          const playlist = props.row.original;
          const isNotEnoughDashboardsToStart =
            playlist.dashboards?.length < MIN_DASHBOARDS_TO_START;
          return (
            <Card
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: "16px",
                p: "20px",
              }}
            >
              <Box
                sx={{
                  mr: "12px",
                  display: "flex",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                <Tooltip
                  title={
                    !isNotEnoughDashboardsToStart
                      ? intl.messages["startPlaylistPlay"]
                      : `${
                          intl.messages[
                            "notEnoughDashboardsToStartPlaylistMessage"
                          ]
                        }: ${
                          MIN_DASHBOARDS_TO_START -
                          (playlist.dashboards?.length ?? 0)
                        }`
                  }
                  placement="top"
                  arrow={true}
                >
                  <IconButton
                    sx={{
                      mr: "12px",
                      cursor: !isNotEnoughDashboardsToStart
                        ? "pointer"
                        : "default",
                    }}
                    onClick={
                      !isNotEnoughDashboardsToStart
                        ? onStartPlaylistClick(playlist)
                        : undefined
                    }
                    disableRipple={isNotEnoughDashboardsToStart}
                  >
                    <PlayCircleOutlineIcon
                      color={
                        isNotEnoughDashboardsToStart ? "disabled" : undefined
                      }
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title={playlist.name} placement="top" arrow={true}>
                  <Box
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {playlist.name}
                  </Box>
                </Tooltip>
              </Box>
              <RoleGuard allowedRoles={["Admin"]}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <EditButton editLink="/playlist/" row={props.row} />
                  <DeleteButton
                    row={props.row}
                    deleteDataHandler={deleteHandler}
                  />
                </Box>
              </RoleGuard>
            </Card>
          );
        },
      },
    ],
    [deleteHandler, intl.messages, onStartPlaylistClick]
  );

  if (playlists.isError) {
    return <ErrorMessage />;
  }
  if (playlists.isLoading) {
    return <LoadingScreen />;
  }
  return (
    <CSSPlaylistsContainer>
      <CSSPlaylistsTable darkMode={darkMode}>
        <DbaTable
          columns={columns}
          data={tableData}
          headToolbar={{
            onAddButtonClick: hasRights(["Admin"])
              ? onAddButtonClick
              : undefined,
            showHeadToolbar: hasRights(["Admin"]) ? true : !!tableData.length,
          }}
          pagination={{ showPagination: false }}
          fixControls={{ enableFixControls: false }}
        />
        {!tableData.length ? (
          <Box>
            <Alert sx={{ mt: "20px" }} severity="info">
              {intl.messages["playlistsNotFound"]}
            </Alert>
          </Box>
        ) : null}
      </CSSPlaylistsTable>
      <DbaSnackbar
        errorMessage={errorMessage}
        error={deleteResponse.isError}
        success={deleteResponse.isSuccess}
      />
    </CSSPlaylistsContainer>
  );
};
