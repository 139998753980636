import React from "react";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";

type TEditButton = {
  editLink?: string;
  editDataHandler?: (data: any) => void;
  row: any;
  searchField?: string;
};

export const EditButton: React.FC<TEditButton> = ({
  editLink,
  editDataHandler,
  row,
  searchField,
}) => {
  let content = null;
  if (editLink)
    content = (
      <Link
        to={{
          pathname: `${editLink}${row.original.id}`,
          search: searchField
            ? `?${searchField}=${row.original[searchField]}`
            : "",
        }}
      >
        <IconButton size="small">
          <EditIcon />
        </IconButton>
      </Link>
    );
  if (editDataHandler) {
    content = (
      <IconButton size="small" onClick={() => editDataHandler(row.original)}>
        <EditIcon />
      </IconButton>
    );
  }
  return content;
};
