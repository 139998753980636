import React, { useContext } from "react";
import { ThemeContext } from "../../../../../../utils/ThemeContext";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Popover, Box } from "@mui/material";
import { TFilter, TFilterData } from "../../../../Types";
import { StringFilter } from "./StringFilter/StringFilter";
import { NumberFilter } from "./NumberFilter/NumberFilter";
import { BooleanFilter } from "./BooleanFilter/BooleanFilter";
import { DateFilter } from "./DateFilter/DateFilter";
import CloseIcon from "@mui/icons-material/Close";
import colors from "../../../../../../Variables.module.scss";
import { EReferenceHeaderDateFormats } from "../../../../../../features/serviceSlices/SharedTypes";

export const Filter: React.FC<TFilter> = ({
  header,
  filtersList = [],
  onChange,
}) => {
  const { darkMode } = useContext(ThemeContext);

  const fieldName = header.column.columnDef.id;
  const fieldType = header.column.columnDef.meta?.dataType;
  const filter = filtersList.find((item) => item.fieldName === fieldName);
  const isFiltersApplied = !!filter;

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const onOpenFilter = (event: React.BaseSyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseFilter = () => {
    setAnchorEl(null);
  };

  const onFilterIconClick = (event: React.BaseSyntheticEvent) => {
    event.stopPropagation();
    onOpenFilter(event);
  };

  const onPopoverClick = (event: React.BaseSyntheticEvent) =>
    event.stopPropagation();

  const onFilterChange = (filter: TFilterData) => {
    let updatedFilters: TFilterData[] = [];
    if (filter.value) {
      updatedFilters = filtersList.find(
        (item) => item.fieldName === filter.fieldName
      )
        ? filtersList.map((item) =>
            item.fieldName === filter.fieldName ? filter : item
          )
        : [...filtersList, filter];
    } else {
      updatedFilters = filtersList.filter(
        (item) => item.fieldName !== filter.fieldName
      );
    }
    if (onChange) {
      onChange(updatedFilters);
    }
    onCloseFilter();
  };

  const isOpen = Boolean(anchorEl);

  const iconButtonColor = darkMode ? colors.outline : colors.purple500;
  const iconButton = (
    <FilterAltIcon
      style={{
        transform: "scale(0.75) translateY(1px)",
        marginLeft: "-4px",
        marginRight: "-3px",
        paddingRight: "0.3em",
        width: "1.3em",
        color: isFiltersApplied ? iconButtonColor : undefined,
      }}
      onClick={onFilterIconClick}
    />
  );

  let filterComponent = null;
  const defaultFilterValue: TFilterData = {
    fieldName: header.id,
    fieldType: fieldType!,
    type: undefined,
    value: undefined,
  };
  switch (fieldType) {
    case "String":
      filterComponent = (
        <StringFilter
          filter={filter ?? defaultFilterValue}
          onChange={onFilterChange}
        />
      );
      break;

    case "Number":
      filterComponent = (
        <NumberFilter
          filter={filter ?? defaultFilterValue}
          onChange={onFilterChange}
        />
      );
      break;

    case "Boolean":
      filterComponent = (
        <BooleanFilter
          filter={filter ?? defaultFilterValue}
          onChange={onFilterChange}
        />
      );
      break;

    case EReferenceHeaderDateFormats.DATE_AND_TIME:
    case EReferenceHeaderDateFormats.ONLY_DATE:
      filterComponent = (
        <DateFilter
          filter={filter ?? defaultFilterValue}
          onChange={onFilterChange}
        />
      );
      break;
  }

  const closer = (
    <CloseIcon
      onClick={onCloseFilter}
      sx={{
        position: "absolute",
        right: "7px",
        top: "7px",
        cursor: "pointer",
      }}
    />
  );

  return filterComponent ? (
    <>
      {iconButton}
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={onCloseFilter}
        onClick={onPopoverClick}
      >
        <Box sx={{ width: "300px", padding: "40px 20px 20px" }}>
          {closer}
          {filterComponent}
        </Box>
      </Popover>
    </>
  ) : null;
};
