import { useContext, useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import { useIntl } from "react-intl";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { format } from "date-fns";
import ru from "date-fns/locale//ru";
import { ThemeContext } from "../../utils/ThemeContext";
import "react-calendar/dist/Calendar.css";
import colors from "../../Variables.module.scss";
import { CSSDatepickerRow, CSSCalendarElement } from "./StyledComponents";
import Popover from "@mui/material/Popover";

export const DbaDatepicker = ({
  date,
  setDate,
  dateFormat = "dd.MM.yyyy",
  clearBtn = false,
  label = "",
  helperText = "",
  size = "small",
  filter = "century",
  defaultValue = new Date(),
  width = "auto",
  minWidth = false,
  sx = {},
  disabled = false,
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "center",
  },
  ...rest
}) => {
  const { darkMode } = useContext(ThemeContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const popoverActions = useRef();

  const intl = useIntl();

  const open = Boolean(anchorEl);
  const [viewCalendar, setViewCalendar] = useState(filter);

  useEffect(
    () =>
      setViewCalendar(
        filter === "year" ? "decade" : filter === "month" ? "year" : "month"
      ),
    [filter]
  );

  const formatDate = () => format(date, dateFormat, { locale: ru });

  const onViewChange = (obj) => {
    switch (filter) {
      case "year":
        setViewCalendar(
          obj.view !== "year" && obj.action !== "drillDown"
            ? obj.view
            : "decade"
        );
        return;
      case "month":
        setViewCalendar(
          obj.view !== "month" && obj.action !== "drillDown" ? obj.view : "year"
        );
        return;
      case "century":
        setViewCalendar(obj.view);
        return;
      default:
        return;
    }
  };

  const fieldsetStyle = darkMode ? colors.purple500 : colors.darkBlue;

  const endAdornment = date && clearBtn && (
    <InputAdornment position="end">
      <IconButton onClick={() => setDate(null)} size="small">
        <CloseIcon />
      </IconButton>
    </InputAdornment>
  );

  const onChangeHandler = (value) => {
    setDate(value);
    setAnchorEl(null);
  };

  const updatePopoverPosition = () => {
    if (popoverActions.current) {
      popoverActions.current.updatePosition();
    }
  };

  const onInputClick = (event) => {
    if (disabled) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const value = (date && formatDate()) || "";

  return (
    <CSSDatepickerRow cursor={disabled ? "default" : "pointer"}>
      <TextField
        InputProps={{
          endAdornment: endAdornment,
        }}
        InputLabelProps={{ shrink: !!value }}
        onClick={onInputClick}
        value={value}
        label={intl.messages[label] || label}
        size={size === "small" ? "small" : "medium"}
        helperText={helperText}
        disabled={disabled}
        sx={{
          minWidth: minWidth ? 300 : "",
          width: width && width,
          "& fieldset": {
            borderColor: fieldsetStyle,
          },
          ...sx,
        }}
        autoComplete="off"
      />

      <Popover
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onClose={() => setAnchorEl(null)}
        id="Calendar"
        open={open}
        anchorEl={anchorEl}
        action={popoverActions}
      >
        <CSSCalendarElement
          onChange={onChangeHandler}
          value={date}
          defaultValue={defaultValue}
          view={viewCalendar}
          onViewChange={onViewChange}
          onClickYear={(value) => filter === "year" && onChangeHandler(value)}
          onClickMonth={(value) => filter === "month" && onChangeHandler(value)}
          locale={"ru-RU"}
          onActiveStartDateChange={updatePopoverPosition}
          {...rest}
        />
      </Popover>
    </CSSDatepickerRow>
  );
};
