import { useIntl } from "react-intl";
import { DbaButtonProps } from "./Types";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

export const DbaButton = ({
  text,
  sx,
  variant = "contained",
  color = "primary",
  startIcon,
  endIcon,
  size,
  isLoading,
  ...rest
}: DbaButtonProps) => {
  const intl = useIntl();
  return (
    <Button
      size={size}
      startIcon={startIcon}
      endIcon={endIcon}
      {...rest}
      color={color}
      sx={{ ...sx, width: "fit-content" }}
      variant={variant}
    >
      {isLoading && <CircularProgress size={16} sx={{ mr: "6px" }} />}
      {intl.messages[text]}
    </Button>
  );
};
