import React, { useMemo, useCallback } from "react";
import {
  useGetWidgetsQuery,
  useDeleteWidgetMutation,
} from "../../features/serviceSlices/serviceHooks";
import { LoadingScreen, ErrorMessage } from "../../components/common";
import { DbaTable, DbaSnackbar } from "../../DbaComponents";
import { useIntl } from "react-intl";
import { WidgetResponse } from "../../features/serviceSlices/Widget/Types";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import { ColumnDef } from "@tanstack/react-table";
import { EditButton } from "../../components/TableHelpers/EditButton";
import { DeleteButton } from "../../components/TableHelpers/DeleteButton";

export const Widgets = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const widgets = useGetWidgetsQuery();
  const [deleteWidget, deleteResponse] = useDeleteWidgetMutation();

  const onAddButtonClick = useCallback(() => {
    navigate("/widget");
  }, [navigate]);

  const deleteHandler = useCallback(
    (widget: WidgetResponse) => {
      deleteWidget({ id: widget.id });
    },
    [deleteWidget]
  );

  const columns: ColumnDef<WidgetResponse>[] = useMemo(
    () => [
      {
        id: intl.messages["name"] as string,
        accessorFn: (row) => row.name,
        cell: (props) => {
          return (
            <Link
              underline="hover"
              component={RouterLink}
              to={{
                pathname: `/widget/${props.row.original.id}`,
                search: `?widgetType=${props.row.original.widgetType}`,
              }}
              sx={{ color: "inherit" }}
            >
              {props.row.original.name}
            </Link>
          );
        },
      },
      {
        id: intl.messages["showedName"] as string,
        accessorFn: (row) => row.label,
      },
      {
        id: intl.messages["widgetType"] as string,
        accessorFn: (row) => row.widgetType,
      },
      {
        id: intl.messages["ownedByDashboards"] as string,
        accessorFn: (row) => row.dashboardsCount,
      },
      {
        id: "edition",
        header: "",
        cell: (props) => (
          <EditButton
            editLink="/widget/"
            row={props.row}
            searchField="widgetType"
          />
        ),
      },
      {
        id: "deletion",
        header: "",
        cell: (props) => (
          <DeleteButton row={props.row} deleteDataHandler={deleteHandler} />
        ),
      },
    ],
    [deleteHandler, intl.messages]
  );

  if (widgets.isError) {
    return <ErrorMessage />;
  }
  if (widgets.isLoading) {
    return <LoadingScreen />;
  }
  return (
    <>
      <DbaTable
        id="widgets-form-table"
        data={widgets.data ?? []}
        columns={columns}
        headToolbar={{ onAddButtonClick }}
      />
      <DbaSnackbar
        error={deleteResponse.isError}
        success={deleteResponse.isSuccess}
      />
    </>
  );
};
