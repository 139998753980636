import React, { useEffect, useRef } from "react";
import { VideoWidgetProps } from "./VideoWidget.types";
import { WidgetLayout } from "../";
import { DbaAlert } from "../../../../DbaComponents";
import { CSSVideo } from "./VideoWidget.styles";
import Hls from "hls.js";

export const VideoWidget = ({
  editMode,
  data,
  selectedElement,
  setSelectedElement,
}: VideoWidgetProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    let videoElement = videoRef.current;
    let hls = new Hls();
    if (videoElement && data.sourceUrl && Hls.isSupported()) {
      hls.loadSource(data.sourceUrl);
      hls.attachMedia(videoElement);
      videoElement.play();
    }
    return () => hls.destroy();
  }, [data.sourceUrl]);

  const isThresholdFontColor =
    data.widgetSettings?.settings.widget?.colorScheme === "thresholds";

  const getShowedColor = () => {
    if (
      isThresholdFontColor &&
      data.widgetSettings?.settings.widget?.thresholds
    ) {
      return data.widgetSettings.settings.widget.tresholdBaseColor ?? "#FF0000";
    }
    return data.widgetSettings?.settings?.widget?.fontColor;
  };

  const showedFontColor = getShowedColor();

  let content;

  if (!data.sourceUrl) {
    content = <DbaAlert severity="warning" text="emptyData" />;
  } else {
    content = (
      <CSSVideo>
        <video ref={videoRef} controls muted />
      </CSSVideo>
    );
  }

  return (
    <WidgetLayout
      settings={data.widgetSettings?.settings.widget!}
      label={data.label}
      data={data}
      type="Video"
      editMode={editMode}
      setSelectedElement={setSelectedElement}
      selectedElement={selectedElement}
      settingsId={data.widgetSettings?.id}
      dashboardId={data.widgetSettings?.dashboardID}
      widgetID={data.widgetSettings?.widgetID}
      fontColor={showedFontColor}
      thresholdFontColor={isThresholdFontColor}
    >
      {content}
    </WidgetLayout>
  );
};
