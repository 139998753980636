import styled from "@emotion/styled";
import colors from "../../../../Variables.module.scss";

const CSSToolbarContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: fit-content;
`;

const CSSSettingsToolbar = styled.div`
  border-radius: 4px;
  border: 1px solid ${colors.purple500};
  background: ${({ theme }: any) =>
    theme.palette.mode === "dark" ? colors.darkBlue : colors.white};
  height: 3.5rem;
  width: 100%;
  display: flex;
  align-items: center;
`;

const CSSSettingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0 1rem;
`;
const CSSSliderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0 1rem;
  min-width: 300px;
`;

export { CSSSettingsToolbar, CSSToolbarContainer, CSSSettingContainer, CSSSliderContainer };
