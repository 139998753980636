import type { DbaColorInputProps } from "./Types";
import "./DbaColorInput.css";
export const DbaColorInput = ({
  value,
  onChange,
  defaultValue = "ffffff",
  onClick,
}: DbaColorInputProps) => {
  return (
    <input
      className="dbaColorInput"
      type="color"
      value={value ?? defaultValue}
      onChange={onChange}
      onClick={onClick}
    />
  );
};
