import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import colors from "../../../../../../../Variables.module.scss";

export const CSSMenuItem = styled(NavLink)`
  &.active svg {
    color: ${({ theme }: any) =>
      theme.palette.mode === "dark" ? colors.purple500 : colors.purple900};
  }
  &.openPopoverHover li {
    background-color: ${({ theme }: any) =>
      theme.palette.mode === "dark" ? colors.purple200 : colors.grayLight};
  }
  & li:hover {
    background-color: ${({ theme }: any) =>
      theme.palette.mode === "dark" ? colors.purple200 : colors.grayLight};
  }
`;

export const CSSSubMenuItem = styled(NavLink)`
  display: block;
  background-color: ${({ theme }: any) =>
    theme.palette.mode === "dark" ? colors.darkBlue : colors.white};
  padding: 7px 18px;
  &:hover {
    background-color: ${({ theme }: any) =>
      theme.palette.mode === "dark" ? colors.purple200 : colors.grayLight};
  }
`;

export const CSSListItemIcon = styled(ListItemIcon)(({ theme }: any) => ({
  color: theme.palette.mode === "dark" ? colors.light : colors.darkBlue,
}));
