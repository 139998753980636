import Divider from "@mui/material/Divider";
import { useIntl } from "react-intl";
import styled from "@emotion/styled";
import { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import IconButton from "@mui/material/IconButton";

type SettingContainerProps = {
  children: React.ReactNode;
  lastRow?: boolean;
  title: string;
};

const CSSSettingRow = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem 1rem 0;
  flex-wrap: wrap;
`;
const CSSChildrenContainer = styled.div`
  width: 100%;
`;

export const WidgetSettingAccordion = ({
  title,
  children,
  lastRow,
}: SettingContainerProps) => {
  const [open, setOpen] = useState(false);
  const intl = useIntl();
  return (
    <>
      <CSSSettingRow>
        <span>{intl.messages[title]}</span>
        <IconButton onClick={() => setOpen((prevState) => !prevState)}>
          {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </IconButton>
        {open && <CSSChildrenContainer>{children}</CSSChildrenContainer>}
      </CSSSettingRow>

      {!lastRow && <Divider variant="middle" />}
    </>
  );
};
