import { useEffect, useState } from "react";
import {
  DbaButton,
  DbaModal,
  DbaTextField,
  DbaSwitch,
  FetchSelect,
} from "../../../DbaComponents";
import { useIntl } from "react-intl";
import { WidgetLink } from "../../../features/serviceSlices/Widget/Types";
import { WidgetLinksActionTypes } from "../Widget/reducers/widgetLinksReducer";
import { CSSModalContent } from "./WidgetLinks.styles";
import AddIcon from "@mui/icons-material/Add";

type WidgetLinkModalProps = {
  open: boolean;
  setOpen: (arg: boolean) => void;
  dispatchWidgetLinks: (arg: WidgetLinksActionTypes) => void;
  data: WidgetLink | null;
};

export const WidgetLinkModal = ({
  open,
  setOpen,
  dispatchWidgetLinks,
  data,
}: WidgetLinkModalProps) => {
  const editMode = Boolean(data);
  const intl = useIntl();
  const baseUrl = window.location.origin;

  const [title, setTitle] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [isNewTab, setIsNewTab] = useState<boolean>(false);
  const [dashboardId, setDashboardId] = useState<string>("");
  const [error, setError] = useState<boolean>(false);

  const setInitialState = () => {
    setTitle("");
    setUrl("");
    setIsNewTab(false);
    setDashboardId("");
  };

  const onSaveLinkHandler = () => {
    if (title.trim() === "" || url.trim() === "") {
      setError(true);
      return;
    }
    setError(false);
    const widgetLink = {
      title,
      url,
      isNewTab,
    };
    editMode && data
      ? dispatchWidgetLinks({
          type: "changeWidgetLink",
          payload: widgetLink,
          initialObj: data,
        })
      : dispatchWidgetLinks({
          type: "addWidgetLink",
          payload: widgetLink,
        });
    setOpen(false);
    setInitialState();
  };

  useEffect(() => {
    if (editMode && data) {
      setTitle(data.title);
      setUrl(data.url);
      setIsNewTab(data.isNewTab);
    }
    return () => setInitialState();
  }, [baseUrl.length, data, editMode]);

  useEffect(() => {
    if (!open) {
      setInitialState();
    }
  }, [open]);

  useEffect(() => {
    if (dashboardId) {
      setUrl(baseUrl + "/" + dashboardId);
    }
  }, [baseUrl, dashboardId]);

  return (
    <DbaModal
      open={open}
      setOpen={setOpen}
      title={intl.messages["link"] as string}
    >
      <CSSModalContent>
        <DbaTextField
          value={title}
          setValue={setTitle}
          required={true}
          label="label"
          error={error && title.trim() === ""}
          helperText="fieldIsEmptyError"
        />
        <FetchSelect
          label="dashboards"
          url="api/dashboard/getavailable"
          optionLabel="name"
          optionValue="id"
          selectedValue={dashboardId}
          setSelectedValue={(value: any) => setDashboardId(value.id)}
        />
        <DbaTextField
          required
          value={url}
          setValue={setUrl}
          label="url"
          error={error && url.trim() === ""}
          helperText="fieldIsEmptyError"
          maxLength={512}
        />
        <DbaSwitch
          text="openInNewTab"
          checked={isNewTab}
          setChecked={setIsNewTab}
        />
        <DbaButton
          text="save"
          onClick={onSaveLinkHandler}
          startIcon={<AddIcon />}
        />
      </CSSModalContent>
    </DbaModal>
  );
};
