import { useContext, useRef } from "react";
import Editor from "@monaco-editor/react";
import { useIntl } from "react-intl";
import { ThemeContext } from "../../utils/ThemeContext";
import CircularProgress from "@mui/material/CircularProgress";

type DbaMonacoEditorProps = {
  className?: string;
  language: string;
  value: string;
  onChange: (value: string | undefined) => void;
  readOnly?: boolean;
};
export const DbaMonacoEditor = ({
  className,
  language,
  value,
  onChange,
  readOnly,
}: DbaMonacoEditorProps) => {
  const { darkMode } = useContext(ThemeContext);
  const intl = useIntl();
  const editorRef = useRef(null);

  function handleEditorDidMount(editor: any, monaco: any) {
    editorRef.current = editor;
    if (editorRef.current) {
      //@ts-ignore
      const messageContribution = editorRef.current.getContribution(
        "editor.contrib.messageController"
      );
      //@ts-ignore
      editorRef.current.onDidAttemptReadOnlyEdit(() => {
        messageContribution.showMessage(
          intl.messages["readOnly"],
          editor.getPosition()
        );
      });
    }
  }

  return (
    <Editor
      className={className}
      theme={darkMode ? "vs-dark" : "light"}
      height="50vh"
      defaultLanguage={language}
      value={value || ""}
      onMount={handleEditorDidMount}
      onChange={onChange}
      loading={<CircularProgress />}
      options={{
        readOnly,
        scrollbar: {
          alwaysConsumeMouseWheel: false,
        },
      }}
    />
  );
};
