import { rootApi } from "../../serviceRoot";
import { TGetWidgetDataRequest, WidgetDataRequest } from "../../Widget/Types";
import {
  DataWidgetResponse,
  DataWidgetSaveRequest,
  DataWidgetSaveResponse,
  TDataWidgetData,
  TDataWidgetDataResponse,
} from "./Types";

export const dataWidgetApiSlice = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getDataWidget: builder.mutation<DataWidgetResponse, WidgetDataRequest>({
      query: (payload) => ({
        url: "api/DataWidget/Get",
        method: "POST",
        body: payload,
      }),
    }),
    getDataWidgetData: builder.query<TDataWidgetDataResponse, TGetWidgetDataRequest>({
      query: (params) => ({
        url: "api/Widget/GetData",
        method: "GET",
        params: {
          widgetId: params.widgetId,
          dashboardId: params.dashboardId,
          startDate: params.startDate,
          endDate: params.endDate,
          periodType: params.periodType,
          filters: params.filters,
        },
      }),
      providesTags: (result, error, arg) =>
        result ? [{ id: result.widgetData.id, type: "dataWidget" }] : ["dataWidget"],
    }),
    createDataWidget: builder.mutation<
      TDataWidgetData,
      DataWidgetSaveRequest
    >({
      query: (payload) => ({
        url: "api/DataWidget/Create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Widgets"],
    }),
    updateDataWidget: builder.mutation<
      DataWidgetSaveResponse,
      DataWidgetSaveRequest
    >({
      query: (payload) => ({
        url: "api/DataWidget/Update",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: (result, error, arg) => {
        return result
          ? [{ type: "dataWidget", id: result.id }, "Widgets"]
          : ["dataWidget", "Widgets"];
      },
    }),
  }),
});

export const {
  useGetDataWidgetDataQuery,
  useCreateDataWidgetMutation,
  useUpdateDataWidgetMutation,
  useLazyGetDataWidgetDataQuery,
  useGetDataWidgetMutation,
} = dataWidgetApiSlice;
