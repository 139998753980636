import React, { ChangeEvent } from "react";
import { TDbaInstantFileUploader } from "./Types";
import { Box } from "@mui/material";
import { TextField } from "@mui/material";
import { useIntl } from "react-intl";
import CachedIcon from "@mui/icons-material/Cached";
import { CSSFileFieldWrapper } from "./DbaInstantFileUploader.styles";
import CircularProgress from "@mui/material/CircularProgress";

export const DbaInstantFileUploader: React.FC<TDbaInstantFileUploader> = ({
  onChange,
  maxSize,
  isLoading,
  disabled,
}) => {
  const intl = useIntl();

  const validateInnerRules = (file: File): string | undefined => {
    if (maxSize && (file?.size ?? 0) > maxSize) {
      return `${intl.messages["maxFileSizeMustNotExceed"]} ${
        maxSize / 1024 / 1024
      } MB`;
    }
    if (file?.name) {
      const splitFileName = (file?.name || "").split(".");
      if (splitFileName.length < 2) {
        return intl.messages["fileMustHaveExtension"] as string;
      }
    }
    return;
  };

  const onFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(
        event,
        event?.target?.files && event?.target?.files[0]
          ? validateInnerRules(event.target.files[0])
          : undefined
      );
    }
  };

  return (
    <Box
      sx={{
        display: "inline-block",
        verticalAlign: "top",
        overflow: "hidden",
        position: "relative",
      }}
    >
      {!isLoading ? <CachedIcon /> : <CircularProgress size={20} />}
      <CSSFileFieldWrapper isPointer={!isLoading && !disabled}>
        <TextField
          type="file"
          onChange={onFileInputChange}
          disabled={disabled || isLoading}
        />
      </CSSFileFieldWrapper>
    </Box>
  );
};
