import styled from "@emotion/styled";
import colors from "../../../../../../Variables.module.scss";
import red from "@mui/material/colors/red";
import green from "@mui/material/colors/green";

export const CSSFilterClearControl = styled.div<{ isOk: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${colors.white};
  background: ${(props) => (!props.isOk ? red[400] : green[500])};
  width: 30px;
  height: 30px;
  border-radius: 2px;
  box-shadow: ${colors.bsMapIconLight};
  cursor: pointer;
  margin-bottom: 5px;

  &:hover {
    background: ${(props) => (!props.isOk ? red[600] : green[500])};
  }
`;
