import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useIntl } from "react-intl";

type DbaCheckboxProps = {
  label: string;
  checked: boolean;
  setChecked: (value: boolean) => void;
};

export const DbaCheckbox = ({
  label,
  checked,
  setChecked,
}: DbaCheckboxProps) => {
  const intl = useIntl();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setChecked(event.target.checked);

  return (
    <FormControlLabel
      sx={{ width: "fit-content" }}
      control={<Checkbox checked={checked} onChange={handleChange} />}
      label={(intl.messages[label] as string) || label}
    />
  );
};
