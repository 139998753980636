import styled from "@emotion/styled";
import { clampBuilder } from "../../../../utils/helpers/functions";
import { TotalValueType } from "./DbaPieChart.types";

const CSSTotalValue = styled.text<TotalValueType>`
  font-weight: ${(props) => props.fontWeight};
  fill: url(#${(props) => props.gradientId});
  @media (max-width: 1000px) {
    font-size: ${(props) => clampBuilder(1, 1000, props.fontSize / 1.25)};
  }
  @media (max-width: 1920px) {
    font-size: ${(props) => clampBuilder(1001, 1920, props.fontSize)};
  }
  @media (min-width: 1921px) {
    font-size: ${(props) => clampBuilder(1921, 3840, props.fontSize * 2)};
  }
`;

export { CSSTotalValue };
