import { useContext, useState } from "react";
import {
  FilterWidgetConstructorProps,
  WidgetElementTypes,
} from "./FilterWidgetConstructor.types";
import { WidgetConstructorToolbar } from "../WidgetConstructorToolbar";
import { DbaErrorBoundary } from "../../../../DbaComponents";
import {
  CSSConstructorContainer,
  CSSEditableWidgetContainer,
} from "./FilterWidgetConstructor.styles";
import { FilterWidget } from "../../../../components/Widgets/common/FilterWidget";
import { WidgetContainer } from "../../../../components/Widgets/common";
import { ThemeContext } from "../../../../utils/ThemeContext";

export const FilterWidgetConstructor = ({
  settings,
  setSettings,
  widgetData,
}: FilterWidgetConstructorProps) => {
  const { darkMode } = useContext(ThemeContext);
  const [selectedElement, setSelectedElement] =
    useState<WidgetElementTypes | null>("body");
  return (
    <CSSConstructorContainer>
      <WidgetConstructorToolbar
        widgetType="Filter"
        settings={settings}
        setSettings={setSettings}
        selectedElement={selectedElement}
        setSelectedElement={setSelectedElement}
      />
      <CSSEditableWidgetContainer>
        <DbaErrorBoundary>
          <WidgetContainer
            type="Filter"
            darkMode={darkMode}
            body={settings.widget.body}
          >
            <FilterWidget data={widgetData} />
          </WidgetContainer>
        </DbaErrorBoundary>
      </CSSEditableWidgetContainer>
    </CSSConstructorContainer>
  );
};
