import Divider from "@mui/material/Divider";
import { useIntl } from "react-intl";
import styled from "@emotion/styled";

type SettingContainerProps = {
  children: React.ReactNode;
  lastRow?: boolean;
  title: string;
};

const CSSSettingRow = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem 1rem 0;
  flex-wrap: wrap;
  width: 100%;
`;
// проверить что ^width: 100%; ничего не ломает
export const WidgetSettingContainer = ({
  title,
  children,
  lastRow,
}: SettingContainerProps) => {
  const intl = useIntl();
  return (
    <>
      <CSSSettingRow>
        <span>{intl.messages[title]}</span>
        {children}
      </CSSSettingRow>
      {!lastRow && <Divider variant="middle" />}
    </>
  );
};
