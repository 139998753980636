import { rootApi } from "../serviceRoot";
import { DataSourceType, getAllDataSourcesResponse } from "./Types";

export const dataSourcesApiSlice = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getDataSource: builder.query<DataSourceType, string>({
      query: (id) => `api/datasource/get?id=${id}`,
    }),
    getAllDataSources: builder.query<getAllDataSourcesResponse, void>({
      query: () => "api/datasource/getall",
      providesTags: ["DataSources"],
    }),
    createDataSource: builder.mutation<any, DataSourceType>({
      query: (payload) => ({
        url: "api/datasource/create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["DataSources"],
    }),
    updateDataSource: builder.mutation<any, DataSourceType>({
      query: (payload) => ({
        url: "api/datasource/update",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["DataSources"],
    }),
    deleteDataSource: builder.mutation<any, { id: string }>({
      query: (payload) => ({
        url: "api/datasource/delete",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["DataSources"],
    }),
  }),
});

export const {
  useLazyGetDataSourceQuery,
  useCreateDataSourceMutation,
  useUpdateDataSourceMutation,
  useDeleteDataSourceMutation,
  useGetAllDataSourcesQuery,
} = dataSourcesApiSlice;
