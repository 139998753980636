import { useState, useMemo, useCallback, useEffect } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useIntl } from "react-intl";
import { Stack } from "@mui/material";
import { ErrorMessage, LoadingScreen } from "../../components/common";
import { DbaSelect, DbaTable } from "../../DbaComponents";
import { DeleteButton } from "../../components/TableHelpers/DeleteButton";
import {
  useGetAllPlaylistsQuery,
  useGetGroupPlaylistsQuery,
  useAddPlaylistToGroupMutation,
  useRemovePlaylistFromGroupMutation,
} from "../../features/serviceSlices/serviceHooks";
import { UserGroupTab } from "./Types";
import { TGroupPlaylist } from "../../features/serviceSlices/Group/Types";
import { TPlaylist } from "../../features/serviceSlices/Playlists/Types";

export const PlaylistTab: React.FC<UserGroupTab> = ({
  groupId,
  snackbarMessage,
  setSnackbarMessage,
}) => {
  const intl = useIntl();
  const playlists = useGetAllPlaylistsQuery();
  const groupPlaylists = useGetGroupPlaylistsQuery(groupId!);
  const [addPlaylist, addPlaylistResponse] = useAddPlaylistToGroupMutation();
  const [removePlaylist, removePlaylistResponse] =
    useRemovePlaylistFromGroupMutation();

  const [selectedPlaylist, setSelectedPlaylist] = useState<TPlaylist | null>(
    null
  );

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [error, setError] = useState(false);

  const onAddPlaylistHandler = (playlist: TPlaylist) => {
    setSnackbarMessage({
      ...snackbarMessage,
      open: false,
      severity: "success",
    });
    setSelectedPlaylist(playlist);
    if (!playlist?.id) {
      setError(true);
    } else if (
      groupPlaylists?.data &&
      groupPlaylists?.data.find((x) => x.playlistId === playlist.id)
    ) {
      setShowErrorMessage(true);
    } else {
      setShowErrorMessage(false);
      setError(false);
      addPlaylist({ playlistId: playlist.id, groupId: groupId! });
    }
  };

  const onRemovePlaylistHandler = useCallback(
    (obj: TGroupPlaylist) =>
      removePlaylist({ playlistId: obj.playlistId, groupId: groupId! }),
    [removePlaylist, groupId]
  );

  const groupPlaylistColumns: ColumnDef<TGroupPlaylist>[] = useMemo(
    () => [
      {
        id: intl.messages["name"] as string,
        accessorFn: (row) => row.name,
      },
      {
        id: intl.messages["description"] as string,
        accessorFn: (row) => row.description,
      },
      {
        id: "deletion",
        header: "",
        cell: (props) => (
          <div
            style={{
              textAlign: "right",
            }}
          >
            <DeleteButton
              row={props.row}
              deleteDataHandler={onRemovePlaylistHandler}
            />
          </div>
        ),
      },
    ],
    [intl.messages, onRemovePlaylistHandler]
  );

  useEffect(() => {
    if (addPlaylistResponse.isSuccess) {
      setSnackbarMessage({
        open: true,
        message: "playlistAddedSuccessfully",
        severity: "success",
      });
      setSelectedPlaylist(null);
    }
    if (addPlaylistResponse.isError) {
      setSnackbarMessage({
        open: true,
        message: "playlistAddedError",
        severity: "error",
      });
    }
  }, [addPlaylistResponse, setSnackbarMessage]);

  useEffect(() => {
    if (removePlaylistResponse.isSuccess) {
      setSnackbarMessage({
        open: true,
        message: "playlistRemovedSuccessfully",
        severity: "success",
      });
    }
    if (removePlaylistResponse.isError) {
      setSnackbarMessage({
        open: true,
        message: "playlistRemovedError",
        severity: "error",
      });
    }
  }, [removePlaylistResponse, setSnackbarMessage]);

  return (
    <>
      {groupPlaylists.isLoading || playlists.isLoading ? (
        <LoadingScreen />
      ) : groupPlaylists.isError || playlists.isError ? (
        <ErrorMessage />
      ) : (
        <Stack direction="column" spacing={2}>
          <DbaSelect
            required
            error={error && !selectedPlaylist}
            selectedValue={selectedPlaylist}
            setSelectedValue={onAddPlaylistHandler}
            options={playlists.data?.filter(
              (playlist) =>
                !groupPlaylists.data
                  ?.map((groupPlaylist) => groupPlaylist.playlistId)
                  .includes(playlist.id)
            )}
            label="choosePlaylist"
          />
          {showErrorMessage && <ErrorMessage title="addNewItemError" />}
          <DbaTable
            data={groupPlaylists.data ?? []}
            columns={groupPlaylistColumns}
            headToolbar={{ showHeadToolbar: false }}
            isLoading={groupPlaylists.isFetching}
            fixControls={{ enableFixControls: false }}
          />
        </Stack>
      )}
    </>
  );
};
