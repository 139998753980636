import type { ChartSettingsProps } from "../Types";
import {
  directionOptions,
  interpolationOptions,
  SettingsToggle,
  ValueFormatSettings,
  WidgetSettingContainer,
} from "../../common";
import { DbaSlider } from "../../../../../../../DbaComponents";

export const FunnelChartBaseSettings = ({
  settings,
  setSettings,
}: ChartSettingsProps) => {
  return (
    <>
      <WidgetSettingContainer title="direction">
        <SettingsToggle
          settings={settings}
          setSettings={setSettings}
          property="direction"
          defaultValue="vertical"
          options={directionOptions}
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="interpolation">
        <SettingsToggle
          settings={settings}
          setSettings={setSettings}
          property="interpolation"
          defaultValue="smooth"
          options={interpolationOptions}
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="spacing">
        <DbaSlider
          sx={{ width: 150 }}
          value={settings.diagram.spacing ?? 0}
          min={0}
          max={30}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                spacing: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="shapeBlending">
        <DbaSlider
          sx={{ width: 150 }}
          value={settings.diagram.shapeBlending ?? 0.66}
          min={0}
          max={1}
          step={0.01}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                shapeBlending: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <ValueFormatSettings
        setSettings={setSettings}
        settings={settings}
        property="valueFormat"
      />
    </>
  );
};
