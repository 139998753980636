import { DbaResponsiveBar } from "../../../../common/Diagrams";

export const BarChart = ({ data, settings, widgetSettings }) => {
  const indexes = [].concat.apply(
    [],
    data.map((item) => {
      return item.data.map((item) => {
        return item[settings.indexBy];
      });
    })
  );
  // const idxArr = data.data.map(item => item.data.map(nestedItem => nestedItem[settings.indexBy])).flat()

  const merged = [].concat.apply(
    [],
    data.map((item) => {
      return item.data.map((item) => {
        return item;
      });
    })
  );
  const objs = indexes.map((item) => {
    let obj = { [settings.indexBy]: item };
    const objects = merged.filter((x) => x[settings.indexBy] === item);
    objects.map((item) => (obj = { ...obj, ...item }));
    return obj;
  });
  return (
    <DbaResponsiveBar
      settings={settings}
      data={objs}
      widgetSettings={widgetSettings}
    />
  );
};
