import React from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

type TReturnButton = {
  color?: "primary" | "secondary" | "error" | "info" | "success" | "warning";
};

export const ReturnButton: React.FC<TReturnButton> = ({ color }) => {
  const navigate = useNavigate();
  const intl = useIntl();
  return (
    <Button
      startIcon={<ArrowBackIcon />}
      onClick={() => navigate(-1)}
      color={color ? color : "primary"}
      sx={{ width: "fit-content" }}
      variant="contained"
    >
      {intl.messages["goBack"]}
    </Button>
  );
};
