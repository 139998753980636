import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import colors from "../../Variables.module.scss";

export const CSSPageTemplate = styled(Box)`
  display: flex;
  background: ${({ theme }: any) =>
    theme.palette.mode === "dark" ? colors.dark : colors.light};
  height: 100%;
`;

export const CSSSidebarToolbar = styled(Toolbar)`
  background: ${({ theme }: any) =>
    theme.palette.mode === "dark" ? colors.dark : colors.light};
  padding: 0 16px !important;
  color: ${({ theme }: any) =>
    theme.palette.mode === "dark" ? colors.light : colors.darkBlue};
`;
