export const notSelectedConditionType = "notSelected";

export const stringFilterOptions = [
  {
    label: "notSelected",
    value: notSelectedConditionType,
  },
  {
    label: "textContains",
    value: "TextContains",
  },
  {
    label: "textNotContains",
    value: "TextNotContains",
  },
];
