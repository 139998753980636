import { TMLDataSetSummary } from "../../features/serviceSlices/MLDataSet/Types";

export enum tabIndexes {
  STATUS_TAB_INDEX = 0,
  RESULT_TAB_INDEX = 1,
  DATA_QUALITY_TAB_INDEX = 2,
  DATA_TYPES_TAB_INDEX = 3,
}

export type TMLStatusTab = {
  mlDataSetSummary: TMLDataSetSummary;
  setTabIndex: React.Dispatch<React.SetStateAction<number>>;
  isDataTypesTabDisabled: () => boolean;
  isDataQualityTabDisabled: () => boolean;
};

export type TMLResultTab = {
  mlDataSetSummary: TMLDataSetSummary;
};

export type TMLDataQualityTab = {
  mlDataSetSummary: TMLDataSetSummary;
  setTabIndex: (tabIndex: number) => void;
};

export type TMLDataTypesTab = {
  mlDataSetSummary: TMLDataSetSummary;
  setTabIndex: (tabIndex: number) => void;
};

export type TMLStatusTabRow = {
  rowFirstLabel: string;
  rowFirstTooltip: string;
  rowSecondLabel: number | string | null;
  buttonName?: string | undefined;
  buttonHandler?: (e?: any) => void | undefined;
  isButtonDisabled?: boolean;
  showTooltipOnFirstRow?: boolean;
  color?: string | undefined;
};

export type TMLStatusTabRows = TMLStatusTabRow[];

export type TCheckParameter = {
  id: string;
  value: string | number;
};

export type TDataQualityTable = {
  isChoosen: boolean;
  checkTypeId: string;
  checkTypeLabel: string;
  parameters: TCheckParameter[];
};
