import React, { useRef, useState } from "react";
import {
  DataInputProps,
  GetSelectedFilterType,
  GetValueToSaveType,
  AddressType,
} from "../../Types";
import { AddressSuggestions } from "react-dadata";
import "react-dadata/dist/react-dadata.css";
import constants from "../../../../../../utils/constants";
import { CSSAddressInputContainer } from "../../FilterWidget.styles";
import { StyledInput } from "./components/StyledInput";
import { TFilterAddressRestriction } from "../../../../../../features/serviceSlices/SharedTypes";

export const getSelectedFilter: GetSelectedFilterType = ({
  findObject,
  data,
}) => {
  // return findObject[data.variable];
  return undefined;
};

export const getValueToSave: GetValueToSaveType = ({
  value,
}: {
  value: AddressType;
}) => value?.data?.fias_id ?? undefined;

const DELAY_TO_FILTER_APPLY = 700;
const MIN_CHARS_TO_FILTER_APPLY = 3;

export const AddressInput: React.FC<
  DataInputProps<
    AddressType | undefined,
    { filterLocations: TFilterAddressRestriction[]; defaultQuery?: string }
  >
> = ({ data, selectedValue, handleChange, extra }) => {
  const [text, setText] = useState<string>("");

  const addressSuggestionsRef = useRef<AddressSuggestions>(null);

  const onInputTextChange = (event: any) => {
    setText(event.target.value);
  };

  const onInputTextBlur = () => {
    if (!text) {
      onReset();
    }
  };

  const onInputTextKeyPress = (event: any) => {
    if (event.key === "Enter" && !text) {
      onReset();
    }
  };

  const onReset = () => {
    handleChange(undefined);
    setTimeout(() => {
      if (addressSuggestionsRef.current) {
        addressSuggestionsRef.current.setInputValue(extra?.defaultQuery);
      }
    }, 0);
  };

  const isDefaultValue =
    !selectedValue || extra?.defaultQuery === selectedValue?.value;

  return (
    <CSSAddressInputContainer>
      <AddressSuggestions
        ref={addressSuggestionsRef}
        token={constants.daDataApiKey}
        defaultQuery={extra?.defaultQuery}
        value={selectedValue ?? undefined} // в соответствие с типами react-dadata
        onChange={handleChange}
        delay={DELAY_TO_FILTER_APPLY}
        minChars={MIN_CHARS_TO_FILTER_APPLY}
        filterFromBound="country"
        // Параметры в react-dadata не совпадают с параметрами оригинальной dadata
        // @ts-ignore
        filterToBound="house"
        inputProps={{
          onChange: onInputTextChange,
          onBlur: onInputTextBlur,
          onKeyDown: onInputTextKeyPress,
          label: data.label,
          // По другому не передать кастомные свойства
          //@ts-ignore
          options: {
            onCloserPress: onReset,
            isDefaultValue,
          },
        }}
        customInput={StyledInput}
        filterLocations={
          extra?.filterLocations
            ? extra.filterLocations
            : undefined
        }
      />
    </CSSAddressInputContainer>
  );
};
