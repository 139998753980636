import { ErrorMessage, LoadingScreen } from "../../../common";
import { useGetOperandsDescriptionQuery } from "../../../../features/serviceSlices/serviceHooks";
import { DbaIconButton, DbaTextField } from "../../../../DbaComponents";
import styled from "@emotion/styled";
import { ExtendedParts, ExtendedPart } from "../../QueryConstructor.types";
import { v4 as uuidv4 } from "uuid";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { useIntl } from "react-intl";
import { SelectPart } from "./SelectPart";
import colors from "./../../../../Variables.module.scss";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { WidgetTypes } from "../../../../features/serviceSlices/Widget/Types";
import Typography from "@mui/material/Typography";

const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
const { purple500, purple900, light, darkBlue } = colors;

const СSSSymbolContainer = styled.span`
  width: 30px;
  height: 30px;
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  &:hover {
    background: rgba(255, 255, 255, 0.08);
  }
`;

const CSSIconButtonIcon = styled.span`
  width: 30px;
  height: 30px;
`;

const CSSOperandsContainer = styled.div`
  border: 1px solid ${purple500};
  border-radius: 8px;
  display: flex;
  align-items: center;
  background: ${({ theme }: any) =>
    theme.palette.mode === "dark" ? purple900 : light};
  height: 50px;
  padding: 8px;
  max-width: fit-content;
  span {
    color: ${({ theme }: any) =>
    theme.palette.mode === "dark" ? light : darkBlue};
  }
`;

const CSSExtendedSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const CSSQueryContainer = styled.div`
  border: 1px solid ${purple500};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  padding: 12px;
  border-radius: 8px;
  background: ${({ theme }: any) =>
    theme.palette.mode === "dark" ? purple900 : light};
  min-height: 86px;
`;

const CSSFieldContainer = styled.div`
  border: 2px solid ${purple500};
  display: flex;
  gap: 8px;
  min-width: 300px;
  padding: 8px;
  border-radius: 4px;
  .MuiAutocomplete-root {
    width: 100%;
  }
`;

const CSSConstContainer = styled.div`
  border: 2px solid ${purple500};
  display: flex;
  gap: 8px;
  min-width: 150px;
  width: 150px;
  padding: 8px;
  border-radius: 4px;
  .MuiAutocomplete-root {
    width: 100%;
  }
`;

const CSSIconButtonText = styled.span`
  font-size: 16px;
  font-weight: 500;
  height: 28px;
  display: flex;
  align-items: center;
`;

const CSSTooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const CSSQueryBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

type ExtendedSelectProps = {
  data: ExtendedParts;
  onAddOperandHandler: (arg: ExtendedPart) => void;
  onDeleteOperandHandler: (arg: ExtendedPart) => void;
  dataSourceId: string;
  tableName: string;
  dispatchExtendedParts: (arg: any) => void;
  widgetType: WidgetTypes;
  error: boolean;
};

export const ExtendedSelect = ({
  data,
  onAddOperandHandler,
  onDeleteOperandHandler,
  dataSourceId,
  tableName,
  dispatchExtendedParts,
  widgetType,
  error,
}: ExtendedSelectProps) => {
  const intl = useIntl();
  const operandsDescription = useGetOperandsDescriptionQuery();

  function onDragEnd(result: DropResult) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newOrder = reorder(
      data,
      result.source.index,
      result.destination.index
    );
    dispatchExtendedParts({
      type: "loadExtendedParts",
      payload: newOrder,
    });
  }

  if (operandsDescription.isLoading) {
    return <LoadingScreen />;
  }
  if (operandsDescription.isError) {
    return <ErrorMessage />;
  }
  return operandsDescription.isSuccess ? (
    <CSSExtendedSelectContainer>
      <CSSOperandsContainer>
        {operandsDescription.data.map((operand) => (
          <DbaIconButton
            key={operand.id}
            icon={<CSSIconButtonIcon>{operand.symbol}</CSSIconButtonIcon>}
            onClick={() =>
              onAddOperandHandler({
                type: "Operand",
                operand: operand.id,
                column: null,
                function: null,
                id: uuidv4(),
              })
            }
          />
        ))}
        <DbaIconButton
          onClick={() =>
            onAddOperandHandler({
              type: "Const",
              operand: null,
              column: null,
              function: null,
              id: uuidv4(),
            })
          }
          icon={<CSSIconButtonText>{intl.messages["const"]}</CSSIconButtonText>}
        />
        <DbaIconButton
          onClick={() =>
            onAddOperandHandler({
              type: "Field",
              operand: null,
              column: null,
              function: null,
              id: uuidv4(),
            })
          }
          icon={<CSSIconButtonText>{intl.messages["field"]}</CSSIconButtonText>}
        />
        <DbaIconButton
          color="error"
          onClick={() =>
            dispatchExtendedParts({
              type: "loadExtendedParts",
              payload: [],
            })
          }
          icon={<DeleteIcon />}
        />
      </CSSOperandsContainer>
      <DragDropContext onDragEnd={onDragEnd}>
        <CSSQueryBox>
          <Typography>{intl.messages["queryCommand"]}:</Typography>

          <Droppable direction="horizontal" droppableId="list">
            {(provided) => (
              <CSSQueryContainer
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {data.map((object, index) => {
                  const operandData = operandsDescription.data.find(
                    (operand) => operand.id === object.operand
                  );
                  if (object.type === "Field") {
                    return (
                      <Draggable
                        key={object.id}
                        draggableId={object.id}
                        index={index}
                      >
                        {(provided) => (
                          <Tooltip
                            placement="top"
                            key={object.id}
                            title={
                              <CSSTooltipContent>
                                {`${intl.messages["field"]}: ${object.column ?? intl.messages["undefined"]
                                  }`}
                                <div>
                                  <DbaIconButton
                                    color="error"
                                    icon={<DeleteIcon />}
                                    onClick={() =>
                                      onDeleteOperandHandler(object)
                                    }
                                  />
                                </div>
                              </CSSTooltipContent>
                            }
                          >
                            <span
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <CSSFieldContainer>
                                <SelectPart
                                  error={error}
                                  tableName={tableName}
                                  dataSourceId={dataSourceId}
                                  data={object}
                                  widgetType={widgetType}
                                  onColumnChange={(value: any) =>
                                    dispatchExtendedParts({
                                      type: "columnChange",
                                      payload: value,
                                      id: object.id,
                                    })
                                  }
                                  onFunctionChange={(value: any) =>
                                    dispatchExtendedParts({
                                      type: "functionChange",
                                      payload: value,
                                      id: object.id,
                                    })
                                  }
                                />
                              </CSSFieldContainer>
                            </span>
                          </Tooltip>
                        )}
                      </Draggable>
                    );
                  }
                  if (object.type === "Const") {
                    return (
                      <Draggable
                        key={object.id}
                        draggableId={object.id}
                        index={index}
                      >
                        {(provided) => (
                          <Tooltip
                            placement="top"
                            key={object.id}
                            title={
                              <CSSTooltipContent>
                                {`${intl.messages["const"]}: ${object.column ?? intl.messages["undefined"]
                                  }`}
                                <div>
                                  <DbaIconButton
                                    color="error"
                                    icon={<DeleteIcon />}
                                    onClick={() =>
                                      onDeleteOperandHandler(object)
                                    }
                                  />
                                </div>
                              </CSSTooltipContent>
                            }
                          >
                            <span
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <CSSConstContainer>
                                <DbaTextField
                                  required
                                  size="small"
                                  label="const"
                                  value={object.column!}
                                  setValue={(value: any) =>
                                    dispatchExtendedParts({
                                      type: "columnChange",
                                      payload: { name: value },
                                      id: object.id,
                                    })}
                                />
                              </CSSConstContainer>
                            </span>
                          </Tooltip>
                        )}
                      </Draggable>
                    );
                  }
                  if (operandData) {
                    return (
                      <Draggable
                        key={object.id}
                        draggableId={object.id}
                        index={index}
                      >
                        {(provided) => (
                          <Tooltip
                            placement="top"
                            key={object.id}
                            title={
                              <CSSTooltipContent>
                                {operandData.name}
                                <div>
                                  <DbaIconButton
                                    color="error"
                                    icon={<DeleteIcon />}
                                    onClick={() =>
                                      onDeleteOperandHandler(object)
                                    }
                                  />
                                </div>
                              </CSSTooltipContent>
                            }
                          >
                            <СSSSymbolContainer
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              {operandData.symbol}
                            </СSSSymbolContainer>
                          </Tooltip>
                        )}
                      </Draggable>
                    );
                  }
                  return <p key={object.id}>{object.operand}</p>;
                })}
                {provided.placeholder}
              </CSSQueryContainer>
            )}
          </Droppable>
        </CSSQueryBox>
      </DragDropContext>
    </CSSExtendedSelectContainer>
  ) : null;
};
