import styled from "@emotion/styled";

export const CSSFilterWidgetContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const CSSAddressInputContainer = styled.div`
  & label:not(.MuiInputLabel-shrink) {
    padding-right: 33px;
  }
  width: 100%;
`;

export const CSSLabelRightPaddingInputWrapper = styled.div`
  & label:not(.MuiInputLabel-shrink) {
    padding-right: 33px;
  }
  width: 100%;
`;

export const CSSAutocompleteDropdownWrapper = styled.div`
  width: 100%;

  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
    .MuiOutlinedInput-root {
    padding-right: 14px !important;
  }
`;
