import styled from "@emotion/styled";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

const CSSModal = styled(Dialog)`
  min-width: 400px;
`;

const CSSDialogContainer = styled(DialogContent)`
  display: grid;
  grid-template-columns: 24px 1fr 40px;
  align-items: start;
  gap: 6px;
  & > svg {
    margin-top: 4px;
  }
  button {
    margin-top: -3px;
  }
`;

const CSSTextContainer = styled.div`
  h2 {
    margin-bottom: 6px;
  }
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export { CSSModal, CSSDialogContainer, CSSTextContainer };
