import React from "react";
import { useTheme } from "@mui/material/styles";
import { useIntl } from "react-intl";
import { FetchBaseQueryErrorType } from "../../features/serviceSlices/SharedTypes";
import { Tooltip } from "@mui/material";

type TErrorMessage = {
  title?: string;
  error?: FetchBaseQueryErrorType;
  isShowTooltip?: boolean;
};

export const ErrorMessage: React.FC<TErrorMessage> = ({
  title,
  error,
  isShowTooltip,
}) => {
  const theme = useTheme();
  const intl = useIntl();

  const defaultMessage = () => {
    return (
      <p style={{ color: theme.palette.error.main }}>
        {title ? intl.messages[title] : intl.messages["errorDataFetching"]}
      </p>
    );
  };

  return error && isShowTooltip ? (
    <Tooltip title={`${error.data?.type ?? ""}: ${error.data?.message ?? ""}`}>
      {defaultMessage()}
    </Tooltip>
  ) : (
    defaultMessage()
  );
};
