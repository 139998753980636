import { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { DbaSlider } from "../../../../../../DbaComponents";
import { WidgetSettingContainer } from "./WidgetSettingContainer";
import { DbaTextField } from "../../../../../../DbaComponents";

const initialState = {
  tickSize: 5,
  tickPadding: 5,
  tickRotation: 0,
  legend: "",
  legendOffset: -36,
};

const CSSAxisSettingContainer = styled.div`
  width: 100%;
`;

type AxisSettingType = {
  settings: any;
  setSettings: (arg: any) => void;
  axisLabel:
    | "axisTop"
    | "axisRight"
    | "axisBottom"
    | "axisLeft"
    | "radialAxisStart"
    | "radialAxisEnd"
    | "circularAxisInner"
    | "circularAxisOuter";
  diagramType?: "Radial";
};
export const AxisSetting = ({
  settings,
  setSettings,
  axisLabel,
  diagramType,
}: AxisSettingType) => {
  const [axisSetting, setAxisSetting] = useState(() =>
    !settings.diagram[axisLabel] ||
    Object.keys(settings.diagram[axisLabel]).length === 0
      ? initialState
      : settings.diagram[axisLabel]
  );

  useEffect(() => {
    setSettings((prevState: any) => ({
      ...prevState,
      diagram: {
        ...prevState.diagram,
        [axisLabel]: axisSetting,
      },
    }));
  }, [axisLabel, axisSetting, setSettings]);

  return (
    <CSSAxisSettingContainer>
      <WidgetSettingContainer lastRow title="tickSize">
        <DbaSlider
          sx={{ width: 150 }}
          value={axisSetting.tickSize}
          min={0}
          max={20}
          onChange={(value) =>
            setAxisSetting((prevState: any) => ({
              ...prevState,
              tickSize: value,
            }))
          }
        />
      </WidgetSettingContainer>
      <WidgetSettingContainer lastRow title="tickPadding">
        <DbaSlider
          sx={{ width: 150 }}
          value={axisSetting.tickPadding}
          min={0}
          max={20}
          onChange={(value) =>
            setAxisSetting((prevState: any) => ({
              ...prevState,
              tickPadding: value,
            }))
          }
        />
      </WidgetSettingContainer>
      <WidgetSettingContainer lastRow title="tickRotation">
        <DbaSlider
          showCircle
          sx={{ width: 150 }}
          value={axisSetting.tickRotation}
          min={-90}
          max={90}
          onChange={(value) =>
            setAxisSetting((prevState: any) => ({
              ...prevState,
              tickRotation: value,
            }))
          }
        />
      </WidgetSettingContainer>
      {diagramType !== "Radial" && (
        <>
          <WidgetSettingContainer lastRow title="legends">
            <DbaTextField
              label=""
              size="small"
              value={axisSetting.legend}
              setValue={(value) =>
                setAxisSetting((prevState: any) => ({
                  ...prevState,
                  legend: value,
                }))
              }
            />
          </WidgetSettingContainer>
          <WidgetSettingContainer lastRow title="legendOffset">
            <DbaSlider
              sx={{ width: 150 }}
              value={axisSetting.legendOffset}
              min={-45}
              max={45}
              onChange={(value) =>
                setAxisSetting((prevState: any) => ({
                  ...prevState,
                  legendOffset: value,
                }))
              }
            />
          </WidgetSettingContainer>
        </>
      )}
    </CSSAxisSettingContainer>
  );
};
