import Typography from "@mui/material/Typography";
import ErrorIcon from "@mui/icons-material/Error";
import { useIntl } from "react-intl";
import IntlMessageCombiner from "./IntlMessageCombiner";
import { CSSWidgetErrorMessageContainer } from "../../DbaComponents/DbaErrorBoundary/DbaErrorBoundary.styles";

type TWidgetErrorMessage = {
  id: string;
  widgetType: string;
};

export const WidgetErrorMessage: React.FC<TWidgetErrorMessage> = ({
  id,
  widgetType,
}) => {
  const intl = useIntl();
  return (
    <CSSWidgetErrorMessageContainer>
      <ErrorIcon color="error" />
      <Typography variant="body1">
        {IntlMessageCombiner({
          text: intl.messages["widgetErrorMessage"] as string,
          links: [`/widget/${id}?widgetType=${widgetType}`],
        })}
      </Typography>
    </CSSWidgetErrorMessageContainer>
  );
};
