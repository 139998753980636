const Russian_Locale = {
  everyText: "Каждый",
  emptyMonths: "месяц",
  emptyMonthDays: "число месяца",
  emptyMonthDaysShort: "число месяца",
  emptyWeekDays: "день недели",
  emptyWeekDaysShort: "день недели",
  emptyHours: "каждый час",
  emptyMinutes: "каждую минуту",
  emptyMinutesForHourPeriod: "минуты",
  yearOption: "год",
  monthOption: "месяц",
  weekOption: "неделя",
  dayOption: "день",
  hourOption: "час",
  minuteOption: "минута",
  rebootOption: "Очистить",
  prefixPeriod: "Период:",
  prefixMonths: "в",
  prefixMonthDays: "по",
  prefixWeekDays: "в",
  prefixWeekDaysForMonthAndYearPeriod: "и",
  prefixHours: "в",
  prefixMinutes: "",
  prefixMinutesForHourPeriod: "в",
  suffixMinutesForHourPeriod: "минут",
  errorInvalidCron: "Неверное cron-выражение",
  weekDays: [
    "Воскресенье",
    "Понедельник",
    "Вторник",
    "Среда",
    "Четверг",
    "Пятница",
    "Суббота",
  ],
  months: [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ],
  altWeekDays: ["Вc", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
  altMonths: [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ],
};

export { Russian_Locale };
