import styled from "@emotion/styled";
import Calendar from "react-calendar";
import colors from "../../Variables.module.scss";

const CSSDatepickerRow = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  input {
    cursor: ${(props) => props.cursor};
  }
`;

const CSSCalendarElement = styled(Calendar)`
  abbr[title] {
    text-decoration: none;
  }
  border-radius: 5px;
  border: ${({ theme }) =>
    theme.palette.mode === "dark" ? "none" : "1px solid #E5E8EC"};
  background-color: ${({ theme }) =>
    theme.palette.mode === "dark" && colors.purple200};
  .react-calendar__navigation__label {
    color: ${({ theme }) => theme.palette.mode === "dark" && colors.white};
  }
  .react-calendar__tile--hover {
    background-color: ${({ theme }) =>
      theme.palette.mode === "dark" && "#565689"};
  }
  .react-calendar__tile--active {
    color: ${colors.white} !important;
    background: #8676ff;
  }
  .react-calendar__tile {
    color: ${({ theme }) =>
      theme.palette.mode === "dark"
        ? "rgb(128, 191, 255)"
        : "rgb(90, 105, 120)"};
    &:hover {
      background-color: #A599FD;
      color: ${colors.white};
    }
  }
  .react-calendar__tile:disabled {
    background-color: ${({ theme }) =>
      theme.palette.mode === "dark" && "#7979A0"};
  }
  .react-calendar__tile--hasActive {
    color: ${colors.white};
    background-color: #8676ff;
  }
  .react-calendar__tile--now {
    color:  ${colors.white};
    background:  #6559BF;
    &:hover {
      background-color: #A599FD;
      color: ${colors.white};
    }
  }
  .react-calendar__month-view__days__day--weekend {
    color: ${({ theme }) =>
      theme.palette.mode === "dark" ? "rgb(255, 107, 107)" : "#FC4E04"};
  }
  .react-calendar__navigation__arrow {
    color: ${({ theme }) => theme.palette.mode === "dark" && colors.white};
  }
  .react-calendar__navigation button:disabled {
    background-color: ${({ theme }) =>
      theme.palette.mode === "dark" && "#7979A0"};
  }
  .react-calendar__navigation button:enabled:hover, 
  .react-calendar__navigation button:enabled:focus {
    background-color: ${({ theme }) =>
      theme.palette.mode === "dark" && "rgba(255, 255, 255, 0.08);"};
`;

export { CSSDatepickerRow, CSSCalendarElement };
