import { DiagramTypes } from "../../features/serviceSlices/SpecificWidgets/DiagramWidget/Types";
import { WidgetTypes } from "../../features/serviceSlices/Widget/Types"

const getSettingsObj = (diagramType?: DiagramTypes | null) => {
  switch (diagramType) {
    case "Pie":
      return {
        diagramType,
        id: "id",
        fit: true,
        defs: {},
        fill: {},
        value: "y",
        colors: {
          scheme: "nivo",
        },
        margin: {
          top: 20,
          left: 10,
          right: 10,
          bottom: 20,
        },
        animate: true,
        indexBy: "x",
        legends: [
          {
            anchor: "top-left",
            justify: false,
            direction: "column",
            itemWidth: 100,
            itemHeight: 18,
            symbolSize: 18,
            translateX: 0,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
          },
        ],
        arcLabel: "formattedValue",
        endAngle: 360,
        padAngle: 0,
        startAngle: 0,
        borderColor: {
          from: "color",
          modifiers: [["darker", 1]],
        },
        borderWidth: 1,
        innerRadius: 0.5,
        sortByValue: false,
        valueFormat: "",
        arcLinkLabel: "id",
        cornerRadius: 0,
        motionConfig: "gentle",
        isInteractive: true,
        transitionMode: "innerRadius",
        enableArcLabels: true,
        arcLabelsSkipAngle: 0,
        arcLabelsTextColor: {
          theme: "labels.text.fill",
        },
        arcLinkLabelsColor: {
          theme: "axis.ticks.line.stroke",
        },
        arcLinkLabelsOffset: 0,
        enableArcLinkLabels: true,
        arcLabelsRadiusOffset: 0.5,
        arcLinkLabelsSkipAngle: 0,
        arcLinkLabelsTextColor: {
          theme: "labels.text.fill",
        },
        arcLinkLabelsThickness: 2,
        activeInnerRadiusOffset: 0,
        activeOuterRadiusOffset: 0,
        arcLinkLabelsTextOffset: 6,
        arcLinkLabelsDiagonalLength: 16,
        arcLinkLabelsStraightLength: 24,
      };
    case "Line":
      return {
        diagramType,
        margin: { top: 50, right: 110, bottom: 50, left: 60 },
        xScale: { type: "point" },
        yScale: {
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: true,
          reverse: false,
        },
        yFormat: " >-.2f",
        axisTop: null,
        axisRight: null,
        axisBottom: {
          orient: "bottom",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "transportation",
          legendOffset: 36,
          legendPosition: "middle",
        },
        axisLeft: {
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "count",
          legendOffset: -40,
          legendPosition: "middle",
        },
        pointSize: 10,
        pointColor: { theme: "background" },
        pointBorderWidth: 2,
        pointBorderColor: { from: "serieColor" },
        pointLabelYOffset: -12,
        useMesh: true,
        legends: [
          {
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ],
      };
    case "Bar":
      return {
        diagramType,
        keys: ["hot dog", "burger", "sandwich", "kebab", "fries", "donut"],
        indexBy: "x",
        margin: { top: 50, right: 130, bottom: 50, left: 60 },
        padding: 0.3,
        valueScale: { type: "linear" },
        indexScale: { type: "band", round: true },
        colors: { scheme: "nivo" },
        defs: [
          {
            id: "dots",
            type: "patternDots",
            background: "inherit",
            color: "#38bcb2",
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: "lines",
            type: "patternLines",
            background: "inherit",
            color: "#eed312",
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ],
        fill: [
          {
            match: {
              id: "fries",
            },
            id: "dots",
          },
          {
            match: {
              id: "sandwich",
            },
            id: "lines",
          },
        ],
        borderColor: { from: "color", modifiers: [["darker", 1.6]] },
        axisTop: null,
        axisRight: null,
        axisBottom: {
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "country",
          legendPosition: "middle",
          legendOffset: 32,
        },
        axisLeft: {
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "food",
          legendPosition: "middle",
          legendOffset: -40,
        },
        labelSkipWidth: 12,
        labelSkipHeight: 12,
        legends: [
          {
            dataFrom: "keys",
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ],
      };
    case "Radar":
      return {
        diagramType,
        keys: ["chardonay", "carmenere", "syrah"],
        indexBy: "taste",
        valueFormat: ">-.2f",
        margin: { top: 70, right: 80, bottom: 40, left: 80 },
        borderColor: { from: "color" },
        gridLabelOffset: 36,
        dotSize: 10,
        dotColor: { theme: "background" },
        dotBorderWidth: 2,
        colors: { scheme: "nivo" },
        blendMode: "multiply",
        motionConfig: "wobbly",
        legends: [
          {
            anchor: "top-left",
            direction: "column",
            translateX: -50,
            translateY: -40,
            itemWidth: 80,
            itemHeight: 20,
            itemTextColor: "#999",
            symbolSize: 12,
            symbolShape: "circle",
            effects: [
              {
                on: "hover",
                style: {
                  itemTextColor: "#000",
                },
              },
            ],
          },
        ],
      };
    case "Funnel":
      return {
        diagramType,
        margin: { top: 20, right: 20, bottom: 20, left: 20 },
        valueFormat: ">-.4s",
        colors: { scheme: "spectral" },
        borderWidth: 20,
        labelColor: { from: "color", modifiers: [["darker", 3]] },
        beforeSeparatorLength: 100,
        beforeSeparatorOffset: 20,
        afterSeparatorLength: 100,
        afterSeparatorOffset: 20,
        currentPartSizeExtension: 10,
        currentBorderWidth: 40,
        motionConfig: "wobbly",
      };
    case "AreaBump":
      return {
        diagramType,
        margin: { top: 40, right: 100, bottom: 40, left: 100 },
        spacing: 8,
        colors: { scheme: "nivo" },
        blendMode: "multiply",
        defs: [
          {
            id: "dots",
            type: "patternDots",
            background: "inherit",
            color: "#38bcb2",
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: "lines",
            type: "patternLines",
            background: "inherit",
            color: "#eed312",
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ],
        fill: [
          {
            match: {
              id: "CoffeeScript",
            },
            id: "dots",
          },
          {
            match: {
              id: "TypeScript",
            },
            id: "lines",
          },
        ],
        startLabel: "id",
        axisTop: {
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "",
          legendPosition: "middle",
          legendOffset: -36,
        },
        axisBottom: {
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "",
          legendPosition: "middle",
          legendOffset: 32,
        },
      };
    case "Calendar":
      return {
        diagramType,
        from: "2015-03-01",
        to: "2016-07-12",
        emptyColor: "#eeeeee",
        colors: ["#61cdbb", "#97e3d5", "#e8c1a0", "#f47560"],
        margin: { top: 40, right: 40, bottom: 40, left: 40 },
        yearSpacing: 40,
        monthBorderColor: "#ffffff",
        dayBorderWidth: 2,
        dayBorderColor: "#ffffff",
        legends: [
          {
            anchor: "bottom-right",
            direction: "row",
            translateY: 36,
            itemCount: 4,
            itemWidth: 42,
            itemHeight: 36,
            itemsSpacing: 14,
            itemDirection: "right-to-left",
          },
        ],
      };
    case "HeatMap":
      return {
        diagramType,
        margin: { top: 100, right: 60, bottom: 60, left: 60 },
        forceSquare: true,
        axisTop: {
          orient: "top",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -90,
          legend: "",
          legendOffset: 36,
        },
        axisRight: null,
        axisBottom: null,
        axisLeft: {
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "country",
          legendPosition: "middle",
          legendOffset: -40,
        },
        animate: true,
        motionConfig: "wobbly",
        motionStiffness: 80,
        motionDamping: 9,
        hoverTarget: "cell",
        colors: {
          type: "sequential",
          scheme: "spectral",
        },
      };
    case "TimeRange":
      return {
        diagramType,
        from: "2018-04-01",
        to: "2018-08-12",
        emptyColor: "#eeeeee",
        colors: ["#61cdbb", "#97e3d5", "#e8c1a0", "#f47560"],
        margin: { top: 40, right: 40, bottom: 100, left: 40 },
        dayBorderWidth: 2,
        dayBorderColor: "#ffffff",
        legends: [
          {
            anchor: "bottom-right",
            direction: "row",
            justify: false,
            itemCount: 4,
            itemWidth: 42,
            itemHeight: 36,
            itemsSpacing: 14,
            itemDirection: "right-to-left",
            translateX: -60,
            translateY: -60,
            symbolSize: 20,
          },
        ],
      };
    case "RadialBar":
      return {
        diagramType,
        valueFormat: ">-.2f",
        padding: 0.4,
        cornerRadius: 2,
        margin: { top: 40, right: 120, bottom: 40, left: 40 },
        radialAxisStart: { tickSize: 5, tickPadding: 5, tickRotation: 0 },
        circularAxisOuter: { tickSize: 5, tickPadding: 12, tickRotation: 0 },
        legends: [
          {
            anchor: "right",
            direction: "column",
            justify: false,
            translateX: 80,
            translateY: 0,
            itemsSpacing: 6,
            itemDirection: "left-to-right",
            itemWidth: 100,
            itemHeight: 18,
            itemTextColor: "#999",
            symbolSize: 18,
            symbolShape: "square",
            effects: [
              {
                on: "hover",
                style: {
                  itemTextColor: "#000",
                },
              },
            ],
          },
        ],
      };
  }
};

const getInitialSettingsState = (
  widgetType: WidgetTypes,
  diagramType?: DiagramTypes | null
) => {
  switch (widgetType) {
    case "Default":
      return {
        diagram: null,
        widget: {
          body: {
            visible: true,
          },
          difference: {
            visible: true,
            fontSize: 16,
            fontWeight: 400,
            textAlign: "left",
          },
          header: {
            visible: true,
            fontSize: 16,
            fontWeight: 400,
            textAlign: "left",
          },
          measure: {
            visible: true,
            fontSize: 16,
            fontWeight: 400,
            textAlign: "left",
          },
          previousValue: {
            visible: true,
            fontSize: 16,
            fontWeight: 400,
            textAlign: "left",
          },
          value: {
            visible: true,
            fontSize: 32,
            fontWeight: 700,
            textAlign: "left",
          },
        },
      };
    case "Diagram":
      return {
        diagram: getSettingsObj(diagramType),
        widget: {
          body: {
            visible: true,
          },
          header: {
            visible: true,
            fontSize: 16,
            fontWeight: 400,
            textAlign: "left",
          },
        },
      };
    default:
      return {};
  }
};

export { getInitialSettingsState };
