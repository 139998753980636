import { DbaPortalWrapperProps } from "./Types";
import { createPortal } from "react-dom";
import React, { useEffect, useRef, memo } from "react";

//мощность сдвига
const MULTIPLIER_FOR_INVERSE_FUNCTION = 3000;

export const DbaPortalWrapper: React.FC<DbaPortalWrapperProps> = memo(
  ({ top, left, children }) => {
    const portalRootRef = useRef<HTMLElement | null>(null);

    useEffect(() => {
      portalRootRef.current = document.createElement("div");
      document.body.appendChild(portalRootRef.current);

      return () => {
        if (portalRootRef?.current?.parentElement) {
          portalRootRef?.current?.parentElement?.removeChild(
            portalRootRef.current
          );
        }
      };
    }, []);

    const getOffset = () => {
      return (
        (1 / (window.innerWidth - left)) *
        MULTIPLIER_FOR_INVERSE_FUNCTION *
        (left < window.innerWidth / 2 ? 1 : -1)
      );
    };

    return portalRootRef.current
      ? createPortal(
          <div
            id="js-portalDivId"
            style={{
              transform: `translate(${-50 + getOffset()}%,-150%)`,
              position: "absolute",
              top: top,
              left: left,
              zIndex: 9999,
              pointerEvents: "none",
            }}
          >
            {children}
          </div>,
          portalRootRef.current
        )
      : null;
  }
);
