import { DbaSlider, DbaSimpleSelect } from "../../../../../../../DbaComponents";
import {
  directionOptions,
  groupModeOptions,
  SettingsToggle,
  MinMaxValueSettings,
  ValueFormatSettings,
  WidgetSettingContainer,
} from "../../common";
import Switch from "@mui/material/Switch";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useIntl } from "react-intl";
import type { ChartSettingsPropsWithMapping } from "../Types";

export const BarChartBaseSettings = ({
  settings,
  setSettings,
  dataMappingOptions,
}: ChartSettingsPropsWithMapping) => {
  const intl = useIntl();
  return (
    <>
      <WidgetSettingContainer title="idWidgetSettings">
        <DbaSimpleSelect
          options={dataMappingOptions}
          selectedValue={settings.diagram.indexBy}
          setSelectedValue={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                indexBy: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>
      <WidgetSettingContainer title="keysWidgetSettings">
        <DbaSimpleSelect
          options={dataMappingOptions}
          selectedValue={dataMappingOptions.filter((option) =>
            settings.diagram?.keys?.includes(option)
          )}
          multiple
          setSelectedValue={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                keys: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>
      <ValueFormatSettings
        setSettings={setSettings}
        settings={settings}
        property="valueFormat"
      />
      <WidgetSettingContainer title="groupMode">
        <SettingsToggle
          settings={settings}
          setSettings={setSettings}
          property="groupMode"
          defaultValue="stacked"
          options={groupModeOptions}
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="layoutMode">
        <SettingsToggle
          settings={settings}
          setSettings={setSettings}
          property="layout"
          defaultValue="vertical"
          options={directionOptions}
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="valueScale">
        <ToggleButtonGroup
          size="small"
          value={settings.diagram.valueScale?.type ?? "linear"}
          exclusive
          onChange={(event, newValue) => {
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                valueScale:
                  newValue === null ? { type: "linear" } : { type: newValue },
              },
            }));
          }}
        >
          <ToggleButton value="linear" aria-label="left aligned">
            {intl.messages["linearScale"]}
          </ToggleButton>
          <ToggleButton value="symlog" aria-label="left aligned">
            {intl.messages["symlog"]}
          </ToggleButton>
        </ToggleButtonGroup>
      </WidgetSettingContainer>

      <WidgetSettingContainer title="reverseMode">
        <Switch
          checked={settings.diagram.reverse}
          onChange={(e) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                reverse: e.target.checked,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="innerPadding">
        <DbaSlider
          sx={{ width: 150 }}
          value={settings.diagram.innerPadding ?? 0}
          min={0}
          max={10}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                innerPadding: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>
      <WidgetSettingContainer title="padding">
        <DbaSlider
          sx={{ width: 150 }}
          value={settings.diagram.padding}
          min={0.1}
          max={0.9}
          step={0.05}
          onChange={(value) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                padding: value,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="min">
        <MinMaxValueSettings
          settings={settings}
          setSettings={setSettings}
          value={settings.diagram.minValue}
          property="minValue"
        />
      </WidgetSettingContainer>
      <WidgetSettingContainer title="max">
        <MinMaxValueSettings
          settings={settings}
          setSettings={setSettings}
          value={settings.diagram.maxValue}
          property="maxValue"
        />
      </WidgetSettingContainer>
    </>
  );
};
