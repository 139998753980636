import { SettingsContainer } from "../../SettingsContainer";
import { BaseSettingsProps } from "./Types";
import { SettingsType } from "../../../../../features/serviceSlices/WidgetSettings/Types"
import {
  LineChartStyleSettings,
  BarChartStyleSettings,
  PieChartStyleSettings,
  RadarChartStyleSettings,
  FunnelChartStyleSettings,
  AreaBumpChartStyleSettings,
  CalendarChartStyleSettings,
  HeatmapChartStyleSettings,
  RadialChartStyleSettings,
  TimeRangeChartStyleSettings,
} from "./Charts";
import { DiagramTypes } from "../../../../../features/serviceSlices/SpecificWidgets/DiagramWidget/Types";

const getSettings = (
  diagramType: DiagramTypes,
  settings: SettingsType,
  setSettings: (arg: any) => void
) => {
  switch (diagramType) {
    case "Line":
      return (
        <LineChartStyleSettings settings={settings} setSettings={setSettings} />
      );
    case "Bar":
      return (
        <BarChartStyleSettings settings={settings} setSettings={setSettings} />
      );
    case "Pie":
      return (
        <PieChartStyleSettings settings={settings} setSettings={setSettings} />
      );
    case "Radar":
      return (
        <RadarChartStyleSettings
          settings={settings}
          setSettings={setSettings}
        />
      );
    case "Funnel":
      return (
        <FunnelChartStyleSettings
          settings={settings}
          setSettings={setSettings}
        />
      );
    case "AreaBump":
      return (
        <AreaBumpChartStyleSettings
          settings={settings}
          setSettings={setSettings}
        />
      );
    case "Calendar":
      return (
        <CalendarChartStyleSettings
          settings={settings}
          setSettings={setSettings}
        />
      );
    case "HeatMap":
      return (
        <HeatmapChartStyleSettings
          settings={settings}
          setSettings={setSettings}
        />
      );
    case "RadialBar":
      return (
        <RadialChartStyleSettings
          settings={settings}
          setSettings={setSettings}
        />
      );
    case "TimeRange":
      return (
        <TimeRangeChartStyleSettings
          settings={settings}
          setSettings={setSettings}
        />
      );
    default:
      return "";
  }
};

export const StyleSettings = ({
  settings,
  setSettings,
  diagramType,
}: BaseSettingsProps) => {
  return (
    <SettingsContainer title="styleSettings">
      {getSettings(diagramType, settings, setSettings)}
    </SettingsContainer>
  );
};
