import { useEffect, useState } from "react";
import { ExtendedPart } from "../../QueryConstructor.types";
import { FetchSelect } from "../../../../DbaComponents";
import { WidgetTypes } from "../../../../features/serviceSlices/Widget/Types";

type ExtendedPartProps = {
  data: ExtendedPart;
  dataSourceId: string;
  tableName: string;
  onColumnChange: (arg: any) => void;
  onFunctionChange: (arg: any) => void;
  widgetType: WidgetTypes;
  error?: boolean;
};
export const SelectPart = ({
  data,
  dataSourceId,
  tableName,
  onColumnChange,
  onFunctionChange,
  widgetType,
  error,
}: ExtendedPartProps) => {
  const [column, setColumn] = useState<any>(data.column);
  const [aggregateFunction, setAggregateFunction] = useState<any>(
    data.function
  );

  useEffect(() => setColumn(data.column), [data.column]);
  useEffect(() => setAggregateFunction(data.function), [data.function]);

  if (data.type === "Field") {
    return (
      <>
        <FetchSelect
          required={widgetType === "Default"}
          error={
            error &&
            widgetType === "Default" &&
            (aggregateFunction === "" || aggregateFunction === null)
          }
          label="functions"
          url="api/Types/Get?Name=AggregateFunctions"
          optionLabel="name"
          optionValue="id"
          selectedValue={aggregateFunction}
          setSelectedValue={(value: any) => onFunctionChange(value)}
          size="small"
          disableClearable={widgetType === "Default" ? true : false}
        />
        <FetchSelect
          required
          label="field"
          url={`api/Query/GetFields?DataSourceID=${dataSourceId}&TableName=${tableName}`}
          optionLabel="name"
          optionValue="name"
          selectedValue={column}
          setSelectedValue={(value: any) => onColumnChange(value)}
          size="small"
          error={error && !column?.name && !column}
          disableClearable
        />
      </>
    );
  }
  return null;
};
