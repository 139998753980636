import { rootApi } from "../../serviceRoot";
import {
  DiagramWidgetResponse,
  DiagramWidgetSaveRequest,
  DiagramWidgetSaveResponse,
  TDiagramWidgetDataResponse,
} from "./Types";
import { TGetWidgetDataRequest, WidgetDataRequest } from "../../Widget/Types";

export const diagramWidgetApiSlice = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getDiagramWidget: builder.mutation<
      DiagramWidgetResponse,
      WidgetDataRequest
    >({
      query: (payload) => ({
        url: "api/DiagramWidget/Get",
        method: "POST",
        body: payload,
      }),
    }),
    getDiagramWidgetData: builder.query<
      TDiagramWidgetDataResponse,
      TGetWidgetDataRequest
    >({
      query: (params) => ({
        url: "api/Widget/GetData",
        method: "GET",
        params: {
          widgetId: params.widgetId,
          dashboardId: params.dashboardId,
          startDate: params.startDate,
          endDate: params.endDate,
          periodType: params.periodType,
          filters: params.filters,
        },
      }),
      providesTags: (result, error, arg) =>
        result ? [{ id: result.widgetData.id, type: "diagramWidget" }] : ["diagramWidget"],
    }),
    createDiagramWidget: builder.mutation<any, DiagramWidgetSaveRequest>({
      query: (payload) => ({
        url: "api/DiagramWidget/Create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Widgets"],
    }),
    updateDiagramWidget: builder.mutation<
      DiagramWidgetSaveResponse,
      DiagramWidgetSaveRequest
    >({
      query: (payload) => ({
        url: "api/DiagramWidget/Update",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: (result, error, arg) => {
        return result
          ? [{ type: "diagramWidget", id: result.id }, "Widgets"]
          : ["diagramWidget", "Widgets"];
      },
    }),
  }),
});

export const {
  useGetDiagramWidgetDataQuery,
  useCreateDiagramWidgetMutation,
  useUpdateDiagramWidgetMutation,
  useLazyGetDiagramWidgetDataQuery,
  useGetDiagramWidgetMutation,
} = diagramWidgetApiSlice;
