import styled from "@emotion/styled";

const CSSWidgetEditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

const CSSButtonBlock = styled.div`
  display: flex;
  gap: 1rem;
`;

const CSSWidgetConstructorContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export { CSSButtonBlock, CSSWidgetEditorContainer, CSSWidgetConstructorContainer };
