import { rootApi } from "../../serviceRoot";
import { GetFilterWidgetDataRequest } from "../FilterWidget/Types";

import {
  TVideoWidgetDataResponse,
  VideoWidgetResponse,
  VideoWidgetSaveRequest,
  VideoWidgetSaveResponse,
} from "./Types";
import { WidgetDataRequest } from "../../Widget/Types";

export const videoWidgetApiSlice = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getVideoWidget: builder.mutation<
      VideoWidgetResponse,
      WidgetDataRequest
    >({
      query: (payload) => ({
        url: "api/VideoWidget/Get",
        method: "POST",
        body: payload,
      }),
    }),
    getVideoWidgetData: builder.query<
      TVideoWidgetDataResponse,
      GetFilterWidgetDataRequest
    >({
      query: (params) => ({
        url: "api/Widget/GetData",
        method: "GET",
        params: {
          widgetId: params.widgetId,
          dashboardId: params.dashboardId,
        },
      }),
      providesTags: (result, error, arg) =>
        result
          ? [{ id: result.widgetData.id, type: "videoWidget" }]
          : ["videoWidget"],
    }),
    createVideoWidget: builder.mutation<any, VideoWidgetSaveRequest>({
      query: (payload) => ({
        url: "api/VideoWidget/Create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Widgets"],
    }),
    updateVideoWidget: builder.mutation<
      VideoWidgetSaveResponse,
      VideoWidgetSaveRequest
    >({
      query: (payload) => ({
        url: "api/VideoWidget/Update",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: (result, error, arg) => {
        return result
          ? [{ type: "videoWidget", id: result.id }, "Widgets"]
          : ["videoWidget", "Widgets"];
      },
    }),
  }),
});

export const {
  useGetVideoWidgetDataQuery,
  useCreateVideoWidgetMutation,
  useUpdateVideoWidgetMutation,
  useGetVideoWidgetMutation,
} = videoWidgetApiSlice;
