import { ResponsiveFunnel } from "@nivo/funnel";
import { useRef } from "react";
import { DbaPortalWrapper } from "../../../../DbaComponents/DbaPortalWrapper";
import { DbaDiagramTooltip } from "./DbaDiagramTooltip";
import { GetTheme } from "../Theme";

export const DbaFunnelChart = ({ data, settings }) => {
  const tooltipContainerRef = useRef(null);
  return (
    <ResponsiveFunnel
      data={data}
      margin={settings.margin}
      direction={settings.direction}
      interpolation={settings.interpolation}
      spacing={settings.spacing}
      shapeBlending={settings.shapeBlending}
      valueFormat={settings.valueFormat}
      colors={settings.colors}
      fillOpacity={settings.fillOpacity}
      borderWidth={settings.borderWidth}
      borderColor={settings.borderColor}
      tooltip={(props) => {
        return (
          <div ref={tooltipContainerRef}>
            <DbaPortalWrapper
              left={tooltipContainerRef.current?.getBoundingClientRect().left}
              top={tooltipContainerRef.current?.getBoundingClientRect().top}
            >
              <DbaDiagramTooltip
                markerColor={props.part.color}
                label={props.part.data.label}
                value={props.part.formattedValue}
              />
            </DbaPortalWrapper>
          </div>
        );
      }}
      borderOpacity={settings.borderOpacity}
      enableLabel={settings.enableLabel}
      labelColor={settings.labelColor ?? "black"}
      enableBeforeSeparators={settings.enableBeforeSeparators}
      beforeSeparatorLength={settings.beforeSeparatorLength}
      beforeSeparatorOffset={settings.beforeSeparatorOffset}
      enableAfterSeparators={settings.enableAfterSeparators}
      afterSeparatorLength={settings.afterSeparatorLength}
      afterSeparatorOffset={settings.afterSeparatorOffset}
      isInteractive={settings.isInteractive}
      currentPartSizeExtension={settings.currentPartSizeExtension}
      currentBorderWidth={settings.currentBorderWidth}
      animate={settings.animate}
      motionConfig={settings.motionConfig}
      theme={GetTheme(settings)}
    />
  );
};
