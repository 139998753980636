import { useState, useEffect, useLayoutEffect } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCreateMeasureMutation,
  useUpdateMeasureMutation,
  useLazyGetMeasureQuery,
} from "../../features/serviceSlices/serviceHooks";
import { SaveButton, LoadingScreen } from "../../components/common";
import Box from "@mui/material/Box";
import { Stack } from "@mui/material";
import Button from "@mui/material/Button";
import { DbaTextField } from "../../DbaComponents/DbaTextField";

export const Measure = () => {
  const params = useParams<Record<string, string | undefined>>();
  const editMode = Object.keys(params).length > 0;
  const intl = useIntl();
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const [getMeasureData, measureData] = useLazyGetMeasureQuery();
  const [addMeasure, response] = useCreateMeasureMutation();
  const [updateMeasure, updateResponse] = useUpdateMeasureMutation();
  const [error, setError] = useState(false);

  const onSubmitFormHandler = () => {
    if (editMode ?
      updateResponse.status === "pending" || updateResponse.status === "fulfilled"
      : response.status === "pending" || response.status === "fulfilled")
      return;
    if (!name.trim()) {
      setError(true);
    } else {
      setError(false);
      editMode
        ? updateMeasure({ payload: { id: params.id, name: name.trim() } })
        : addMeasure({ payload: { name: name.trim() } });
    }
  };

  useLayoutEffect(() => {
    if (editMode) {
      getMeasureData(params.id!);
    }
  }, [editMode, params.id, getMeasureData]);

  useEffect(() => {
    if (measureData.data?.name) {
      setName(measureData.data?.name);
    }
  }, [measureData.data?.name]);

  return editMode && measureData.isLoading && name.length === 0 ? (
    <LoadingScreen />
  ) : (
    <Box component="form" onSubmit={onSubmitFormHandler}>
      <Stack direction="row" spacing={2} sx={{ mb: "1rem" }}>
        <Button
          onClick={() => navigate(-1)}
          sx={{ width: "fit-content" }}
          color="error"
          variant="contained"
        >
          {intl.messages["cancel"]}
        </Button>
        <SaveButton
          redirectUrl="/measures"
          onClick={onSubmitFormHandler}
          status={editMode ? updateResponse.status : response.status}
        />
      </Stack>
      <Stack direction="column" spacing={2}>
        <DbaTextField
          required
          error={error && !name.trim()}
          value={name}
          setValue={setName}
          size="medium"
          label="label"
          helperText="fieldIsEmptyError"
        />
      </Stack>
    </Box>
  );
};
