import styled from "@emotion/styled";

const CSSConstructorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-height: 75vh;
  width: 100%;
`;
const CSSEditableWidgetContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;
export { CSSConstructorContainer, CSSEditableWidgetContainer };
