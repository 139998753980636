import React, { useEffect, useReducer, useState } from "react";
import { SettingsContainer } from "../SettingsContainer";
import {
  DbaButton,
  DbaNumberInput,
  DbaTextField,
  DbaRadioGroup,
} from "../../../../../DbaComponents";
import { Switch, Typography } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { Threshold } from "../../../../../features/serviceSlices/WidgetSettings/Types";

import {
  thresholdsActionTypes,
  ThresholdsProps,
  SettingsType,
} from "./Thresholds.types";
import {
  CSSThresholdContainer,
  CSSThresholdsContainer,
  CSSColorBox,
  CSSDiagramThresholdsSetting,
  CSSThresholdSettingsContainer,
} from "./Thresholds.styles";
import AddIcon from "@mui/icons-material/Add";
import { ColorSelector } from "./ColorSelector/ColorSelector";
import { useIntl } from "react-intl";
import { ThresholdRow } from "./ThresholdRow";

const baseNewColor = '#73bf68';
const baseColor = '#f2494c';

const thresholdsSettingsReducer = (
  state: Threshold[] | null,
  action: thresholdsActionTypes
) => {
  if (!state) {
    const initialThresholdState = {
      id: uuidv4(),
      value: 100,
      color: baseNewColor,
    };
    return [initialThresholdState];
  }

  switch (action.type) {
    case "addThreshold":
      const initialThresholdState = {
        id: uuidv4(),
        value: 100,
        color: baseNewColor,
      };
      const newState = [...state];
      newState.push(initialThresholdState);
      newState.sort((a, b) => {
        return b.value - a.value;
      });
      return newState;
    case "deleteThreshold":
      return [...state].filter((item) => item.id !== action.payload);
    case "colorChange":
      const objectToEditIdx = state.findIndex(
        (item) => item.id === action.payload.id
      );
      if (objectToEditIdx !== -1) {
        const newState = [...state];
        const newObj = {
          ...state[objectToEditIdx],
          color: action.payload.newColor,
        };
        newState.splice(objectToEditIdx, 1, newObj);
        return newState;
      }
      return state;
    case "valueChange":
      const editIdx = state.findIndex((item) => item.id === action.payload.id);
      if (editIdx !== -1) {
        const newState = [...state];
        const newObj = { ...state[editIdx], value: action.payload.newValue };
        newState.splice(editIdx, 1, newObj);
        newState.sort((a, b) => {
          return b.value - a.value;
        });
        return newState;
      }
      return state;
    default:
      return state;
  }
};

const axesOptions = [
  {
    id: "x",
    name: "X",
  },
  {
    id: "y",
    name: "Y",
  },
];

export const Thresholds = ({
  settings,
  setSettings,
  diagram,
}: ThresholdsProps) => {
  const [thresholdsSettings, dispatchThresholdsSettings] = useReducer(
    thresholdsSettingsReducer,
    settings.widget.thresholds ?? null
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const intl = useIntl();

  const onAddThresholdHandler = () =>
    dispatchThresholdsSettings({ type: "addThreshold" });

  useEffect(() => {
    setSettings((prevState: SettingsType) => ({
      ...prevState,
      widget: { ...prevState.widget, thresholds: thresholdsSettings },
    }));
  }, [setSettings, thresholdsSettings]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (
    <SettingsContainer title="thresholds">
      <CSSThresholdSettingsContainer>
        {diagram && (
          <>
            <CSSDiagramThresholdsSetting>
              <Typography>{intl.messages["showThresholds"]}</Typography>
              <Switch
                checked={settings.diagram?.showThresholds}
                onChange={(e) =>
                  setSettings((prevState: any) => ({
                    ...prevState,
                    diagram: {
                      ...prevState.diagram,
                      showThresholds: e.target.checked,
                    },
                  }))
                }
              />
            </CSSDiagramThresholdsSetting>

            {settings.diagram?.showThresholds && (
              <>
                <CSSDiagramThresholdsSetting>
                  <Typography>{intl.messages["thresholdAxe"]}</Typography>
                  <DbaRadioGroup
                    labels={axesOptions}
                    value={settings.diagram?.thresholdSettings?.axe ?? "y"}
                    setValue={(value) =>
                      setSettings((prevState: any) => ({
                        ...prevState,
                        diagram: {
                          ...prevState.diagram,
                          thresholdSettings: {
                            ...prevState.diagram.thresholdSettings,
                            axe: value,
                          },
                        },
                      }))
                    }
                    row
                  />
                </CSSDiagramThresholdsSetting>

                <CSSDiagramThresholdsSetting>
                  <Typography>{intl.messages["dashedLine"]}</Typography>
                  <Switch
                    checked={
                      settings.diagram?.thresholdSettings?.dashedLine ?? false
                    }
                    onChange={(e) =>
                      setSettings((prevState: any) => ({
                        ...prevState,
                        diagram: {
                          ...prevState.diagram,
                          thresholdSettings: {
                            ...prevState.diagram.thresholdSettings,
                            dashedLine: e.target.checked,
                          },
                        },
                      }))
                    }
                  />
                </CSSDiagramThresholdsSetting>

                <CSSDiagramThresholdsSetting>
                  <Typography>{intl.messages["lineWidth"]}</Typography>
                  <DbaNumberInput
                    round={1}
                    size="small"
                    value={settings.diagram?.thresholdSettings?.width ?? 2}
                    setValue={(value) =>
                      setSettings((prevState: any) => ({
                        ...prevState,
                        diagram: {
                          ...prevState.diagram,
                          thresholdSettings: {
                            ...prevState.diagram.thresholdSettings,
                            width: value,
                          },
                        },
                      }))
                    }
                    min={0}
                  />
                </CSSDiagramThresholdsSetting>

                <CSSDiagramThresholdsSetting>
                  <Typography>{intl.messages["fontSize"]}</Typography>
                  <DbaNumberInput
                    round={1}
                    size="small"
                    value={settings.diagram?.thresholdSettings?.fontSize ?? 16}
                    setValue={(value) =>
                      setSettings((prevState: any) => ({
                        ...prevState,
                        diagram: {
                          ...prevState.diagram,
                          thresholdSettings: {
                            ...prevState.diagram.thresholdSettings,
                            fontSize: value,
                          },
                        },
                      }))
                    }
                    min={0}
                  />
                </CSSDiagramThresholdsSetting>
              </>
            )}
          </>
        )}
        <CSSThresholdsContainer>
          <DbaButton
            startIcon={<AddIcon />}
            text="addThreshold"
            onClick={onAddThresholdHandler}
          />
          {thresholdsSettings &&
            thresholdsSettings.map((item) => (
              <ThresholdRow
                data={item}
                key={item.id}
                dispatchThresholdsSettings={dispatchThresholdsSettings}
              />
            ))}
          <CSSThresholdContainer>
            <CSSColorBox
              color={settings.widget.tresholdBaseColor ?? baseColor}
              onClick={handleClick}
            />
            <DbaTextField
              label=""
              disabled
              size="small"
              value={intl.messages["baseColor"] as string}
              setValue={() => {
                return;
              }}
            />
            <ColorSelector
              color={settings.widget.tresholdBaseColor ?? baseColor}
              onColorChangeHandler={(value) => {
                setSettings((prevState: SettingsType) => ({
                  ...prevState,
                  widget: {
                    ...prevState.widget,
                    tresholdBaseColor: value,
                  },
                }));
              }}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
            />
          </CSSThresholdContainer>
        </CSSThresholdsContainer>
      </CSSThresholdSettingsContainer>
    </SettingsContainer>
  );
};
