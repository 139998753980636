import { ResponsiveHeatMap } from '@nivo/heatmap';
import { GetTheme } from '../Theme';
import { DbaPortalWrapper } from "../../../../DbaComponents/DbaPortalWrapper";
import { DbaDiagramTooltip } from "./DbaDiagramTooltip";
import { useRef } from "react";

export const DbaHeatMapChart = ({ data, settings }) => {

  const tooltipContainerRef = useRef(null);

  return (
    <ResponsiveHeatMap
      data={data}
      forceSquare={settings.forceSquare}
      sizeVariation={settings.sizeVariation}
      margin={settings.margin}
      enableLabels={settings.enableLabels}
      colors={settings.colors}
      enableGridX={settings.enableGridX}
      enableGridY={settings.enableGridY}
      axisTop={settings.axisTop}
      axisRight={settings.axisRight}
      axisBottom={settings.axisBottom}
      axisLeft={settings.axisLeft}
      tooltip={(props) => {
        return (
          <div ref={tooltipContainerRef}>
            <DbaPortalWrapper
              left={tooltipContainerRef.current?.getBoundingClientRect().left}
              top={tooltipContainerRef.current?.getBoundingClientRect().top}>
              <DbaDiagramTooltip
                markerColor={props.cell.color}
                label={props.cell.label}
                value={props.cell.formattedValue}/>
            </DbaPortalWrapper>
          </div>
        );
      }}
      isInteractive={settings.isInteractive}
      hoverTarget={settings.hoverTarget}
      animate={settings.animate}
      motionConfig={settings.motionConfig}
      theme={GetTheme(settings)}
    />
  )
}

