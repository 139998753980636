import React, { useEffect } from "react";
import { CSSTableHead, CSSTableHeadRow } from "../../DbaTable.styles";
import { TTableHead } from "../../Types";
import { TableDraggableColumnHeader } from "./components/TableDraggableColumnHeader/TableDraggableColumnHeader";

export const TableHead: React.FC<TTableHead> = ({
  table,
  darkMode = false,
  backgroundColorDarkThemeOverride,
  autoColumnsWidth,
  enableSorting,
  onSortingChange,
  sortingState,
  enableColumnSorting,
  onColumnSortingChange,
  enableFilters,
  filtersList,
  onFiltersListChange,
  showHeadToolbar,
  enableFixControls,
}) => {
  useEffect(() => {
    if (enableSorting && onSortingChange && sortingState) {
      sortingState[0]
        ? onSortingChange({
            columnName: sortingState[0].id,
            orderType: sortingState[0].desc ? "Desc" : "Asc",
          })
        : onSortingChange(undefined);
    }
  }, [enableSorting, onSortingChange, sortingState]);

  return (
    <CSSTableHead
      darkMode={darkMode}
      backgroundColorDarkThemeOverride={backgroundColorDarkThemeOverride}
      showHeadToolbar={showHeadToolbar}
      enableFixControls={enableFixControls}
    >
      {table.getHeaderGroups().map((headerGroup) => (
        <CSSTableHeadRow key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <TableDraggableColumnHeader
              key={header.id}
              table={table}
              header={header}
              enableSorting={enableSorting}
              autoColumnsWidth={autoColumnsWidth}
              enableColumnSorting={enableColumnSorting}
              onColumnSortingChange={onColumnSortingChange}
              enableFilters={enableFilters}
              filtersList={filtersList}
              onFiltersListChange={onFiltersListChange}
            />
          ))}
        </CSSTableHeadRow>
      ))}
    </CSSTableHead>
  );
};
