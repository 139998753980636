import React, { useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import { store } from "../../utils/store";
import { useIntl } from "react-intl";
import { To, useNavigate } from "react-router-dom";
import { useAuthenticateMutation, useForgetPasswordMutation } from "../../features/serviceSlices/serviceHooks";
import { changeAuth } from "../../features/slices/App/appSlice";
import { FetchBaseQueryErrorType } from "../../features/serviceSlices/SharedTypes";

type LoginFormStore = {
  login: string;
  password: string;
  showPassword: boolean;
  openSnackbar: boolean;
  snackbarMessage: string;
  error: boolean;
  errorMessage: string;
  openForgetForm: boolean;
  usernameOrEmail: string;
  handleSubmit: (event: React.SyntheticEvent) => void;
  handleForget: (event: React.SyntheticEvent) => void;
  onForgetInputChangeHandler: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  setOpenForgetForm: React.Dispatch<React.SetStateAction<boolean>>;
  onFieldChangeHandler: (
    value: string,
    setter: React.Dispatch<React.SetStateAction<string>>
  ) => void;
  setLogin: React.Dispatch<React.SetStateAction<string>>;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  setShowPassword: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  forgetError: boolean;
  authenticateError: boolean;
};

interface LoginFormLogicProps {
  from: To;
}

export default function useLoginFormLogic({
  from,
}: LoginFormLogicProps): LoginFormStore {
  const intl = useIntl();
  const navigate = useNavigate();

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [login, setLogin] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [authenticate, authenticateResponse] = useAuthenticateMutation();

  const [openForgetForm, setOpenForgetForm] = useState<boolean>(false);
  const [usernameOrEmail, setUsernameOrEmail] = useState<string>("");
  const [forget, forgetResponse] = useForgetPasswordMutation();

  const isFetchBaseQueryErrorType = (
    error: any
  ): error is FetchBaseQueryErrorType => "status" in error;

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (login === "" || password === "") {
      setError(true);
    } else {
      setError(false);
      authenticate({
        userName: login.trim(),
        password: password.trim(),
      });
    }
  };

  const handleForget = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (usernameOrEmail === "") {
      setError(true);
    } else {
      setError(false);
      forget({
        userNameOrEmail: usernameOrEmail.trim(),
      });
    }
  };

  useEffect(() => {
    if (authenticateResponse.error) {
      if (isFetchBaseQueryErrorType(authenticateResponse.error)) {
        setSnackbarMessage(
          authenticateResponse.error.data?.message
            ? authenticateResponse.error.data?.message
            : (intl.messages["error"] as string)
        );
        setOpenSnackbar(true);
      }
    } else if (authenticateResponse.isSuccess) {
      if (
        authenticateResponse.data.jwtToken &&
        authenticateResponse.data.jwtToken.length !== 0
      ) {
        localStorage.setItem(
          "x-access-token",
          authenticateResponse.data.jwtToken
        );
        let storageToken = localStorage.getItem("x-access-token");
        if (storageToken) {
          let decodedToken: any = jwtDecode(storageToken);
          store.dispatch(
            changeAuth({
              isAuth: true,
              token: authenticateResponse.data.jwtToken,
              isAdmin: decodedToken.role?.includes("Admin"),
            })
          );
          navigate(from, { replace: true });
          setLogin("");
          setPassword("");
        }
      }
    }
  }, [authenticateResponse, from, intl.messages, navigate]);

  useEffect(() => {
    if (forgetResponse.isError) {
      if (isFetchBaseQueryErrorType(forgetResponse.error)) {
        setSnackbarMessage(
          forgetResponse.error.data?.message
            ? forgetResponse.error.data?.message
            : (intl.messages["error"] as string)
        );
        setOpenSnackbar(true);
      }
    } else if (forgetResponse.isSuccess) {
      setSnackbarMessage(
        `${intl.messages["forgetSuccess"]} ${forgetResponse.data ?? ""}`
      );
      setOpenSnackbar(true);
    }
  }, [forgetResponse, intl.messages]);

  const onFieldChangeHandler = (
    value: string,
    setter: React.Dispatch<React.SetStateAction<string>>
  ) => {
    if (errorMessage !== "") {
      setErrorMessage("");
    }
    setter(value);
  };

  const onForgetInputChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (errorMessage !== "") {
      setErrorMessage("");
    }
    setUsernameOrEmail(e.target.value);
  };

  return {
    login,
    password,
    showPassword,
    error,
    errorMessage,
    openForgetForm,
    openSnackbar,
    snackbarMessage,
    usernameOrEmail,
    handleSubmit,
    handleForget,
    onForgetInputChangeHandler,
    setOpenForgetForm,
    onFieldChangeHandler,
    setLogin,
    setShowPassword,
    setPassword,
    setOpenSnackbar,
    forgetError: forgetResponse.isError,
    authenticateError: authenticateResponse.isError,
  };
}
