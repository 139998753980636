import styled from "@emotion/styled";
import Button from "@mui/material/ToggleButton";
import colors from "../../Variables.module.scss";

export const CSSTimezonePickerButton = styled(Button)((theme: any) => {
  return {
    color: theme.theme.palette.mode === "dark" ? "" : colors.darkBlue,
    border:
      theme.theme.palette.mode === "dark"
        ? `1px solid ${colors.purple500}`
        : `1px solid ${colors.darkBlue}`,
    "&.Mui-selected": {
      color: theme.theme.palette.mode === "dark" ? colors.light : "",
      background: theme.theme.palette.mode === "dark" ? colors.purple500 : "",
    },
  };
});
