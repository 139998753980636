import { rootApi } from "../serviceRoot";
import { GetTypesRequest, GetTypesResponse } from "./Types";

export const typesFeatureApiSlice = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getTypes: builder.query<GetTypesResponse, GetTypesRequest>({
      query: (id) => `api/Types/get?name=${id}`,
    }),
  }),
});

export const { useGetTypesQuery } = typesFeatureApiSlice;
