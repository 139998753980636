import React from "react";
import { TAuthGuard } from "./Types";
import { useAppSelector } from "../../../utils/reduxHooks";

export const AuthGuard: React.FC<TAuthGuard> = ({
  children,
  placeholder = null,
}) => {
  const isAuth = useAppSelector(
    (state) => !!state.app.authorizationInfo?.isAuth
  );
  return isAuth ? <>{children}</> : <>{placeholder}</>;
};
