import jwt from "jwt-decode";
import { store } from "../utils/store";
import { changeAuth, reset } from "../features/slices/App/appSlice";

export const LoadAuthInfo = () => {
  let token = localStorage.getItem("x-access-token");
  if (token) {
    let decodedToken = jwt(token);
    store.dispatch(
      changeAuth({
        isAuth: true,
        token: decodedToken,
        isAdmin: decodedToken.role?.includes("Admin"),
      })
    );
    return true;
  }
  return false;
};

export function Logout() {
  localStorage.clear();
  store.dispatch(reset());
  window.location.reload();
}
