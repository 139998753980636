import React, { useState } from "react";
import { ThresholdRowProps } from "./Thresholds.types";
import {
    CSSThresholdContainer,
    CSSColorBox,
  } from "./Thresholds.styles";
import { DbaNumberInput } from "../../../../../DbaComponents/DbaNumberInput";
import { DbaIconButton } from "../../../../../DbaComponents/DbaIconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { ColorSelector } from "./ColorSelector/ColorSelector";

export const ThresholdRow = ({
    data,
    dispatchThresholdsSettings,
  }: ThresholdRowProps) => 
  {
    const { color, id, value } = data;
  
    const onDeleteHandler = () =>
      dispatchThresholdsSettings({
        type: "deleteThreshold",
        payload: id,
      });
  
    const onColorChangeHandler = (value: string) =>
      dispatchThresholdsSettings({
        type: "colorChange",
        payload: { newColor: value, id },
      });
  
    const onValueChangeHandler = (newValue: number) =>
      dispatchThresholdsSettings({
        type: "valueChange",
        payload: { newValue, id },
      });
  
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };
  
    return (
        <CSSThresholdContainer>
        <CSSColorBox color={color} onClick={handleClick} />
        <DbaNumberInput
          size="small"
          value={value}
          setValue={onValueChangeHandler}
        />
        <DbaIconButton
          sx={{ borderRadius: "0 4px 4px 0" }}
          color="error"
          onClick={onDeleteHandler}
          icon={<DeleteIcon />}
        />
        <ColorSelector
          color={color}
          onColorChangeHandler={onColorChangeHandler}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
      </CSSThresholdContainer>
    );
  };


 