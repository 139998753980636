import { PeriodSelectorProps } from "../../../DbaComponents/DbaPeriodSelector/Types";
import { CSSWidgetEditorPeriodSelectorContainer } from "./StyledComponents";
import { PeriodSelector } from "../../../DbaComponents";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useContext } from "react";
import { ThemeContext } from "../../../utils/ThemeContext";
import { useIntl } from "react-intl";

export const WidgetEditorPeriodSelector = ({
  type,
  onStartDateChange,
  onEndDateChange,
  onPeriodChange,
  startDate,
  endDate,
}: PeriodSelectorProps) => {
  const intl = useIntl();
  const { darkMode } = useContext(ThemeContext);
  return (
    <CSSWidgetEditorPeriodSelectorContainer>
      <Alert
        sx={{ border: darkMode ? "1px solid #ffb74d" : "1px solid #ff9800" }}
        severity="warning"
      >
        <AlertTitle>
          {intl.messages["emptyDataArrayWarningWidgetEditor"]}
        </AlertTitle>
        {intl.messages["emptyDataSuggestionWidgetEditor"]}
      </Alert>

      <PeriodSelector
        onStartDateChange={onStartDateChange}
        onEndDateChange={onEndDateChange}
        onPeriodChange={onPeriodChange}
        startDate={startDate}
        endDate={endDate}
        type={type}
        isShowAllPeriods={true}
      />
    </CSSWidgetEditorPeriodSelectorContainer>
  );
};
