import React from "react";
import { Box, IconButton } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TOrderCell } from "./Types";

export const OrderCell: React.FC<TOrderCell> = ({
  row,
  playlistDashboards,
  onUpDashboardClick,
  onDownDashboardClick,
  isUpdating,
}) => {
  const isFirst =
    playlistDashboards.findIndex((dashboard) => dashboard === row) === 0;
  const isLast =
    playlistDashboards.findIndex((dashboard) => dashboard === row) ===
    playlistDashboards.length - 1;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "40px",
        py: "5px",
      }}
    >
      <IconButton
        disabled={playlistDashboards.length <= 1 || isFirst || isUpdating}
        onClick={onUpDashboardClick(row)}
      >
        <ExpandLessIcon />
      </IconButton>
      <IconButton
        disabled={playlistDashboards.length <= 1 || isLast || isUpdating}
        onClick={onDownDashboardClick(row)}
      >
        <ExpandMoreIcon />
      </IconButton>
    </Box>
  );
};
