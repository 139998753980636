import { Navigate } from "react-router-dom";
import { useAppSelector } from "../utils/reduxHooks";
import { LoadAuthInfo } from "./AuthService";

export const PublicRoute = ({ children }: { children: JSX.Element }) => {
  const isAuth = useAppSelector(
    (state) => !!state.app.authorizationInfo?.isAuth
  );

  return isAuth || LoadAuthInfo() ? <Navigate to="/" /> : children;
};
