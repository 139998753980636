import styled from "@emotion/styled";

const CSSWidgetLinksContainer = styled.div`
  label: WidgetLinksContainer;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const CSSWidgetLinksChipContainer = styled.div`
  label: WidgetLinksChipContainer;
  display: flex;
  gap: 16px;
`;

const CSSModalContent = styled.div`
  label: ModalContent;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export { CSSWidgetLinksContainer, CSSWidgetLinksChipContainer, CSSModalContent };
