import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { DbaButton } from "../../../DbaComponents";
import { useIntl } from "react-intl";
import { WidgetRelatedReportType } from "../../../features/serviceSlices/Widget/Types";
import AddIcon from "@mui/icons-material/Add";
import { WidgetRelatedReportsModal } from "./WidgetRelatedReportsModal";
import Chip from "@mui/material/Chip";
import { WidgetRelatedReportsActionTypes } from "../Widget/reducers/widgetRelatedReportsReducer";
import {
  CSSWidgetRelatedReportsChipContainer,
  CSSWidgetRelatedReportsContainer,
} from "./WidgetRelatedReports.styles";

type WidgetRelatedReportsGeneratorType = {
  widgetRelatedReports: WidgetRelatedReportType[] | null;
  dispatchWidgetRelatedReports: (arg: WidgetRelatedReportsActionTypes) => void;
};

export const WidgetRelatedReportsGenerator = ({
  widgetRelatedReports,
  dispatchWidgetRelatedReports,
}: WidgetRelatedReportsGeneratorType) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedWidgetRelatedReport, setSelectedWidgetRelatedReport] =
    useState<WidgetRelatedReportType | null>(null);

  const intl = useIntl();

  const onDeleteClick = (payload: WidgetRelatedReportType) => () =>
    dispatchWidgetRelatedReports({
      type: "deleteWidgetRelatedReport",
      payload,
    });

  const onChipClick = (item: WidgetRelatedReportType) => () => {
    setSelectedWidgetRelatedReport(item);
    setOpen(true);
  };

  const onAddClick = () => setOpen(true);

  useEffect(() => {
    if (!open) {
      setSelectedWidgetRelatedReport(null);
    }
  }, [open]);

  return (
    <CSSWidgetRelatedReportsContainer>
      <Typography variant="h6">{intl.messages["reports"]}</Typography>
      <CSSWidgetRelatedReportsChipContainer>
        {widgetRelatedReports ? (
          widgetRelatedReports.map((item) => (
            <Chip
              key={item.id}
              label={item.name}
              onClick={onChipClick(item)}
              onDelete={onDeleteClick(item)}
            />
          ))
        ) : (
          <div />
        )}
      </CSSWidgetRelatedReportsChipContainer>
      <DbaButton
        text="addReport"
        startIcon={<AddIcon />}
        onClick={onAddClick}
      />
      <WidgetRelatedReportsModal
        open={open}
        setOpen={setOpen}
        data={selectedWidgetRelatedReport}
        dispatchWidgetRelatedReports={dispatchWidgetRelatedReports}
      />
    </CSSWidgetRelatedReportsContainer>
  );
};
