import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { DbaButton } from "../../../DbaComponents";
import { useIntl } from "react-intl";
import { WidgetLinks, WidgetLink } from "../../../features/serviceSlices/Widget/Types";
import AddIcon from "@mui/icons-material/Add";
import { WidgetLinkModal } from "./WidgetLinkModal";
import Chip from "@mui/material/Chip";
import { WidgetLinksActionTypes } from "../Widget/reducers/widgetLinksReducer";
import {
  CSSWidgetLinksChipContainer,
  CSSWidgetLinksContainer,
} from "./WidgetLinks.styles";

type WidgetLinksGeneratorType = {
  widgetLinks: WidgetLinks | null;
  dispatchWidgetLinks: (arg: WidgetLinksActionTypes) => void;
};

export const WidgetLinksGenerator = ({
  widgetLinks,
  dispatchWidgetLinks,
}: WidgetLinksGeneratorType) => {
  const [open, setOpen] = useState(false);
  const [selectedLink, setSelectedLink] = useState<WidgetLink | null>(null);

  const intl = useIntl();

  const onDeleteLinkHandler = (payload: WidgetLink) =>
    dispatchWidgetLinks({ type: "deleteWidgetLink", payload });

  useEffect(() => {
    if (!open) {
      setSelectedLink(null);
    }
  }, [open]);

  return (
    <CSSWidgetLinksContainer>
      <Typography variant="h6">{intl.messages["links"]}</Typography>
      <CSSWidgetLinksChipContainer>
        {widgetLinks &&
          widgetLinks.map((item) => (
            <Chip
              key={item.url}
              label={item.title}
              onClick={() => {
                setSelectedLink(item);
                setOpen(true);
              }}
              onDelete={() => onDeleteLinkHandler(item)}
            />
          ))}
      </CSSWidgetLinksChipContainer>
      <DbaButton
        text="addLink"
        startIcon={<AddIcon />}
        onClick={() => setOpen(true)}
      />
      <WidgetLinkModal
        open={open}
        setOpen={setOpen}
        data={selectedLink}
        dispatchWidgetLinks={dispatchWidgetLinks}
      />
    </CSSWidgetLinksContainer>
  );
};
