import {
    MiddlewareAPI,
    isRejectedWithValue,
    Middleware,
  } from '@reduxjs/toolkit'
import {changeAuth} from "../features/slices/App/appSlice";

  /**
   * Log a warning and show a toast!
   */

  export const rtkQueryErrorHandler: Middleware =
    (api: MiddlewareAPI) => (next) => (action) => {
      // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
      if (isRejectedWithValue(action)) {
          if (action.payload.status === 401)
          {
            console.warn('Unauthorized');
            localStorage.removeItem("x-access-token");
            api.dispatch(changeAuth({isAuth: false, isAdmin: false, token:''}));
          }        
      }  
      return next(action) 
    }