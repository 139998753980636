import { TooltipProps } from "@mui/material";
import { useIntl } from "react-intl";
import Tooltip from "@mui/material/Tooltip";

type DbaTooltipProps = {
  children: React.ReactElement;
  title: string;
} & TooltipProps;

export const DbaTooltip = ({ children, title, ...rest }: DbaTooltipProps) => {
  const intl = useIntl();
  return (
    <Tooltip {...rest} title={intl.messages[title] || title}>
      <span>{children}</span>
    </Tooltip>
  );
};
