import { WidgetSettingContainer } from "../../common/WidgetSettingContainer";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useState, useEffect } from "react";
import Switch from "@mui/material/Switch";
import { DbaSlider } from "../../../../../../../DbaComponents";

const initialStateX = {
  type: "point",
};
const initialStateY = {
  type: "linear",
  min: "auto",
  max: "auto",
  stacked: false,
  reverse: false,
};

type LineChartScaleConfigurationProps = {
  settings: any;
  setSettings: (arg: any) => void;
  label: "yScale" | "xScale";
};
export const LineChartScaleConfiguration = ({
  settings,
  setSettings,
  label,
}: LineChartScaleConfigurationProps) => {
  const [config, setConfig] = useState(() =>
    !settings[label] || Object.keys(settings[label]).length === 0
      ? label === "xScale"
        ? initialStateX
        : initialStateY
      : settings[label]
  );

  useEffect(() => {
    setSettings((prevState: any) => ({
      ...prevState,
      diagram: {
        ...prevState.diagram,
        [label]: config,
      },
    }));
  }, [label, config, setSettings]);

  return (
    <>
      <WidgetSettingContainer lastRow title="type">
        <ToggleButtonGroup
          size="small"
          value={config.type}
          exclusive
          onChange={(event, newValue) =>
            setConfig((prevState: any) => ({
              ...prevState,
              type:
                newValue === null
                  ? label === "xScale"
                    ? "point"
                    : "linear"
                  : newValue,
            }))
          }
        >
          <ToggleButton value="linear" aria-label="left aligned">
            Линейный
          </ToggleButton>
          <ToggleButton value="point" aria-label="left aligned">
            Точки
          </ToggleButton>
        </ToggleButtonGroup>
      </WidgetSettingContainer>

      {label === "yScale" && (
        <>
          <WidgetSettingContainer lastRow title="stacked">
            <Switch
              checked={config.stacked}
              onChange={(e) =>
                setConfig((prevState: any) => ({
                  ...prevState,
                  stacked: e.target.checked,
                }))
              }
            />
          </WidgetSettingContainer>
          <WidgetSettingContainer lastRow title="min">
            <Switch
              checked={config.min !== "auto"}
              onChange={(e) =>
                setConfig((prevState: any) => ({
                  ...prevState,
                  min: e.target.checked === false ? "auto" : 0,
                }))
              }
            />
            {config.min !== "auto" && (
              <DbaSlider
                fullWidth
                sx={{ width: 300 }}
                value={config.min}
                min={-2000}
                max={2000}
                onChange={(value) =>
                  setConfig((prevState: any) => ({
                    ...prevState,
                    min: value,
                  }))
                }
              />
            )}
          </WidgetSettingContainer>
          <WidgetSettingContainer lastRow title="max">
            <Switch
              checked={config.max !== "auto"}
              onChange={(e) =>
                setConfig((prevState: any) => ({
                  ...prevState,
                  max: e.target.checked === false ? "auto" : 1200,
                }))
              }
            />
            {config.max !== "auto" && (
              <DbaSlider
                fullWidth
                sx={{ width: 300 }}
                value={config.max}
                min={-2000}
                max={2000}
                onChange={(value) =>
                  setConfig((prevState: any) => ({
                    ...prevState,
                    max: value,
                  }))
                }
              />
            )}
          </WidgetSettingContainer>
        </>
      )}
    </>
  );
};
