import React from "react";
import { IconButton, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";

type TStyledInput = {
  [key: string]: any;
  options: Record<string, any>;
};

export const StyledInput: React.FC<TStyledInput> = (props) => {
  return (
    <TextField
      {...props}
      sx={{ height: "20%" }}
      InputProps={{
        endAdornment: !props.options?.isDefaultValue ? (
          <InputAdornment position="end">
            <IconButton onClick={props.options?.onCloserPress} size="small">
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ) : undefined,
      }}
    />
  );
};
