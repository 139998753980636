import { ChartGridSettings } from "../../common/ChartGridSettings";
import type { ChartSettingsProps } from "../Types";

export const LineChartAxesSettings = ({
  settings,
  setSettings,
}: ChartSettingsProps) => {
  return (
    <ChartGridSettings
      settings={settings}
      setSettings={setSettings}
      diagramType="Line"
    />
  );
};
