import React, { useEffect } from "react";
import { TablePagination } from "@mui/material";
import { ALL_ITEMS_PER_PAGE, TTableFootToolbar } from "../../Types";
import { useIntl } from "react-intl";
import { CSSTableFootToolbar } from "../../DbaTable.styles";

export const TableFootToolbar: React.FC<TTableFootToolbar> = ({
  data,
  table,
  darkMode,
  backgroundColorDarkThemeOverride,
  showPagination,
  showRowsPerPageSelector,
  rowsPerPageSelector,
  enableServerPagination,
  serverRowsCount,
  serverPageIndex,
  onServerPageChange,
  serverPaginationState,
  setServerPaginationState,
  enableFixControls,
  isLoading,
}) => {
  const intl = useIntl();

  const getRowsPerPageOptions = () => {
    if (!showRowsPerPageSelector) {
      return [];
    }
    const all = { label: "Все", value: data.length };
    const defaultOptions = [5, 10, 25, all];
    return rowsPerPageSelector?.options
      ? rowsPerPageSelector.options.map((option) =>
          option === ALL_ITEMS_PER_PAGE ? all : option
        )
      : defaultOptions;
  };

  const handleClientPageChange = (event: any, newPage: number) =>
    table.setPageIndex(newPage);

  const handleServerPageChange = (event: any, newPage: number) => {
    if (
      onServerPageChange &&
      setServerPaginationState &&
      serverPaginationState
    ) {
      onServerPageChange(event, newPage);
      setServerPaginationState({
        ...serverPaginationState,
        pageIndex: newPage,
      });
    }
  };

  useEffect(() => {
    if (!showPagination) {
      table.setPageSize(data.length);
    }
  }, [data.length, showPagination, table]);

  useEffect(() => {
    if (
      showPagination &&
      rowsPerPageSelector?.defaultValue &&
      !enableServerPagination
    ) {
      table.setPageSize(rowsPerPageSelector.defaultValue);
    }
  }, [
    enableServerPagination,
    rowsPerPageSelector?.defaultValue,
    showPagination,
    table,
  ]);

  useEffect(() => {
    if (showPagination && enableServerPagination) {
      table.setPageSize(serverPaginationState.pageSize as number);
    }
  }, [
    enableServerPagination,
    serverPaginationState.pageSize,
    showPagination,
    table,
  ]);

  useEffect(() => {
    if (
      showPagination &&
      enableServerPagination &&
      setServerPaginationState &&
      typeof serverPageIndex === "number" &&
      serverPageIndex !== serverPaginationState.pageIndex
    ) {
      setServerPaginationState({
        ...serverPaginationState,
        pageIndex: serverPageIndex,
      });
    }
  }, [
    enableServerPagination,
    serverPageIndex,
    serverPaginationState,
    setServerPaginationState,
    showPagination,
  ]);

  const rowsPerPageOptions = getRowsPerPageOptions();

  const clientPagination = (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      labelRowsPerPage={intl.messages["labelRowsPerPage"]}
      count={data.length}
      rowsPerPage={table.getState().pagination.pageSize}
      page={table.getState().pagination.pageIndex}
      labelDisplayedRows={({ from, to, count }) => `${from} -${to} / ${count}`}
      SelectProps={{
        inputProps: {
          "aria-label": "rows per page",
        },
        MenuProps: {
          sx: {
            ".MuiTablePagination-menuItem": {
              height: "25px",
              fontSize: 14,
            },
          },
        },
      }}
      backIconButtonProps={
        isLoading
          ? {
              disabled: isLoading,
            }
          : undefined
      }
      nextIconButtonProps={
        isLoading
          ? {
              disabled: isLoading,
            }
          : undefined
      }
      onPageChange={handleClientPageChange}
      onRowsPerPageChange={(event) =>
        table.setPageSize(Number(event.target.value))
      }
      component="div"
    />
  );

  const serverPagination = (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      labelRowsPerPage={intl.messages["labelRowsPerPage"]}
      count={serverRowsCount as number}
      rowsPerPage={serverPaginationState.pageSize as number}
      page={serverPaginationState.pageIndex as number}
      labelDisplayedRows={({ from, to, count }) => `${from} -${to} / ${count}`}
      SelectProps={{
        inputProps: {
          "aria-label": "rows per page",
        },
        MenuProps: {
          sx: {
            ".MuiTablePagination-menuItem": {
              height: "25px",
              fontSize: 14,
            },
          },
        },
      }}
      backIconButtonProps={
        isLoading
          ? {
              disabled: isLoading,
            }
          : undefined
      }
      nextIconButtonProps={
        isLoading
          ? {
              disabled: isLoading,
            }
          : undefined
      }
      onPageChange={handleServerPageChange}
      component="div"
    />
  );

  return (
    <CSSTableFootToolbar
      enableFixControls={enableFixControls}
      darkMode={darkMode}
      backgroundColorDarkThemeOverride={backgroundColorDarkThemeOverride}
    >
      {showPagination && !enableServerPagination ? clientPagination : null}
      {showPagination && enableServerPagination ? serverPagination : null}
    </CSSTableFootToolbar>
  );
};
