import ReportIcon from "@mui/icons-material/Report";
import { useIntl } from "react-intl";
import {
  CSSTableImage,
  CSSInfoTable,
  CSSWrongDataContainer,
} from "./ModalDataForm.styles";

export const ModalDataForm = ({
  data,
  headers,
}: {
  data: any[];
  headers?: any[];
}) => {
  const intl = useIntl();
  const entries = Object.entries(data[0]);

  const sortedData = entries.sort((firstEl, secondEl): any => {
    if (headers) {
      const firstElementPosition = headers.find(
        (el) => el.field === firstEl[0]
      );
      const secondElementPosition = headers.find(
        (el) => el.field === secondEl[0]
      );
      if (firstElementPosition && secondElementPosition) {
        const firstElOrder = firstElementPosition.order;
        const secondElOrder = secondElementPosition.order;
        if (secondElOrder > firstElOrder) {
          return -1;
        }
        return 1;
      }
    }
    return 0;
  });
  if (!headers) {
    return (
      <CSSInfoTable cellPadding="0" cellSpacing="0">
        <tbody>
          {sortedData.map((array: any) => {
            let type = "";
            return (
              <tr>
                {array.map((value: any) => {
                  if (typeof value === "boolean") {
                    return (
                      <td>
                        {value ? intl.messages["yes"] : intl.messages["no"]}
                      </td>
                    );
                  }
                  if (typeof value === "object") {
                    return (
                      <td align="center">
                        <CSSWrongDataContainer>
                          <ReportIcon color="error" fontSize="small" />
                          {intl.messages["wrongDataType"]}
                        </CSSWrongDataContainer>
                      </td>
                    );
                  }
                  if (type === "File" || type === "Video" || type === "Image") {
                    return (
                      <td>
                        <a href={value} target="_blank" rel="noreferrer">
                          {intl.messages["link"]}
                        </a>
                      </td>
                    );
                  }
                  return <td>{value}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </CSSInfoTable>
    );
  }
  return (
    <CSSInfoTable cellPadding="0" cellSpacing="0">
      <tbody>
        {sortedData.map((array: any) => {
          let type = "";
          return (
            <tr>
              {array.map((value: any) => {
                const referenceHeader = headers.find(
                  (refHeader: any) => refHeader.field === value
                );
                if (referenceHeader) {
                  type = referenceHeader.type;
                  return <td>{referenceHeader.headerName}</td>;
                }
                if (type === "Boolean" || typeof value === "boolean") {
                  return (
                    <td>
                      {typeof value === "boolean"
                        ? value
                          ? intl.messages["yes"]
                          : intl.messages["no"]
                        : ""}
                    </td>
                  );
                }
                if (typeof value === "object") {
                  return (
                    <td>
                      <CSSWrongDataContainer>
                        <ReportIcon
                          sx={{ alignSelf: "center" }}
                          color="error"
                          fontSize="small"
                        />
                        {intl.messages["wrongDataType"]}
                      </CSSWrongDataContainer>
                    </td>
                  );
                }
                if (type === "File" || type === "Video") {
                  return (
                    <td>
                      <a href={value} target="_blank" rel="noreferrer">
                        {intl.messages["link"]}
                      </a>
                    </td>
                  );
                }
                if (type === "Image") {
                  return (
                    <td>
                      <CSSTableImage src={value} />
                    </td>
                  );
                }
                return <td>{value}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </CSSInfoTable>
  );
};
