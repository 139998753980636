import { useState, useContext } from "react";
import Menu from "@mui/material/Menu";
import { DbaIconButton } from "../../../DbaComponents";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import { ThemeContext } from "../../../utils/ThemeContext";
import { useIntl } from "react-intl";
import colors from "../../../Variables.module.scss";
import { CSSQueryConstructorAddButton } from "../QueryConstructor.styles";

export const MenuButton = ({
  disabled,
  onAddExpressionHandler,
  onAddMacrosHandler,
}: {
  disabled: boolean;
  onAddExpressionHandler: () => void;
  onAddMacrosHandler: () => void;
}) => {
  const intl = useIntl();
  const { darkMode } = useContext(ThemeContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const iconColor = darkMode ? "" : colors.darkBlue;

  const onExpressionOpenHandler = () => {
    onAddExpressionHandler();
    handleClose();
  };

  const onMacrosOpenHandler = () => {
    onAddMacrosHandler();
    handleClose();
  };

  return (
    <CSSQueryConstructorAddButton>
      <DbaIconButton
        disabled={disabled}
        size="small"
        icon={<AddIcon sx={{ color: iconColor }} fontSize="large" />}
        onClick={handleClick}
      />
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={onExpressionOpenHandler}>
          {intl.messages["expression"]}
        </MenuItem>
        <MenuItem onClick={onMacrosOpenHandler}>
          {intl.messages["macros"]}
        </MenuItem>
      </Menu>
    </CSSQueryConstructorAddButton>
  );
};
