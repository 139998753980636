import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { LoadingScreen, ErrorMessage } from "../../components/common";
import { useGetAllFileCategoriesQuery } from "../../features/serviceSlices/serviceHooks";
import { useDeleteFileCategoryMutation } from "../../features/serviceSlices/serviceHooks";
import { DbaTable } from "../../DbaComponents";
import { DbaSnackbar } from "../../DbaComponents";
import { TFileCategory } from "../../features/serviceSlices/FileCategories/Types";
import { CSSFileCategoriesContainer } from "./FileCategories.styles";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import { getErrorMessage } from "../../utils/helpers/functions";
import { ColumnDef } from "@tanstack/react-table";
import { EditButton } from "../../components/TableHelpers/EditButton";
import { DeleteButton } from "../../components/TableHelpers/DeleteButton";

export const FileCategories = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [deleteFileCategory, deleteResponse] = useDeleteFileCategoryMutation();
  const fileCategories = useGetAllFileCategoriesQuery();

  const onAddButtonClick = useCallback(() => {
    navigate("/file-category");
  }, [navigate]);

  const deleteHandler = useCallback(
    (obj: TFileCategory) => deleteFileCategory(obj.id),
    [deleteFileCategory]
  );

  const errorMessage = getErrorMessage(deleteResponse);

  const tableData = fileCategories.data;

  const columns: ColumnDef<TFileCategory>[] = useMemo(
    () => [
      {
        id: intl.messages["name"] as string,
        accessorFn: (row) => row.name,
        cell: (props) => {
          return (
            <Link
              underline="hover"
              component={RouterLink}
              to={{
                pathname: `/edit-file-category/${props.row.original.id}`,
              }}
              sx={{ color: "inherit" }}
            >
              {props.row.original.name}
            </Link>
          );
        },
        size: Number.MAX_SAFE_INTEGER,
      },
      {
        id: "edition",
        header: "",
        cell: (props) => (
          <EditButton editLink="/edit-file-category/" row={props.row} />
        ),
      },
      {
        id: "deletion",
        header: "",
        cell: (props) => (
          <DeleteButton row={props.row} deleteDataHandler={deleteHandler} />
        ),
      },
    ],
    [deleteHandler, intl.messages]
  );

  if (fileCategories.isError) {
    return <ErrorMessage />;
  }
  if (fileCategories.isLoading) {
    return <LoadingScreen />;
  }
  return (
    <CSSFileCategoriesContainer>
      <DbaTable
        id="file-categories-form-table"
        columns={columns}
        data={tableData ?? []}
        headToolbar={{ onAddButtonClick }}
        stylesSettings={{ autoColumnsWidth: false }}
      />
      <DbaSnackbar
        errorMessage={errorMessage}
        error={deleteResponse.isError}
        success={deleteResponse.isSuccess}
      />
    </CSSFileCategoriesContainer>
  );
};
