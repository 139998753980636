import React, { useCallback, useEffect, useState } from "react";
import { QueryCommandObject } from "../../../features/serviceSlices/SharedTypes";
import { useLazyGetQueryCommandsDataQuery } from "../../../features/serviceSlices/WidgetSettings/widgetSettingsService";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useIntl } from "react-intl";
import { useSnackbar } from "../../../utils/hooks/useSnackbar";
import { Alert, Snackbar } from "@mui/material";

type TFilterSelectTypeDefaultValue = {
  value?: string;
  setValue: (value: TFilterSelectTypeDefaultValue["value"]) => void;
  queryCommand?: QueryCommandObject;
};

export const FilterSelectTypeDefaultValue: React.FC<
  TFilterSelectTypeDefaultValue
> = ({ value, setValue, queryCommand }) => {
  const intl = useIntl();

  const [isValid, setIsValid] = useState(false);

  const [getTableData, tableDataResponse] = useLazyGetQueryCommandsDataQuery();

  const { isShowSnackbar, snackbarMessage, snackbarSeverity, blinkSnackbar } =
    useSnackbar();

  const finallyOnLoad = useCallback(
    (data: any) => {
      if (
        !data[0]?.data?.length ||
        !data[0]?.data[0].key ||
        !data[0]?.data[0].label
      ) {
        setIsValid(false);
        blinkSnackbar(
          intl.messages["noConditionsForTheSearch"] as string,
          "error"
        );
      } else {
        setIsValid(true);
      }
    },
    [blinkSnackbar, intl.messages]
  );

  useEffect(() => {
    if (queryCommand) {
      getTableData({
        queriesCommands: [queryCommand],
        filters: [],
      })
        .unwrap()
        .then(finallyOnLoad, finallyOnLoad);
    }
  }, [finallyOnLoad, getTableData, queryCommand]);

  const snackbar = (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={isShowSnackbar}
      autoHideDuration={null}
    >
      <Alert elevation={6} severity={snackbarSeverity} sx={{ width: "100%" }}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );

  return (
    <>
      <Autocomplete
        fullWidth
        disabled={!isValid}
        value={
          (
            (tableDataResponse.data && tableDataResponse.data[0]?.data) ??
            []
          ).find((item: any) => item.key === value) ?? ""
        }
        onChange={(event: React.BaseSyntheticEvent, newValue) => {
          setValue(newValue?.key ?? undefined);
        }}
        onReset={() => setValue(undefined)}
        options={(
          (tableDataResponse.data && tableDataResponse.data[0]?.data) ??
          []
        ).filter((item: any) => item.key !== null && item.label !== null)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={intl.messages["defaultValue"] as string}
          />
        )}
      />
      {snackbar}
    </>
  );
};
