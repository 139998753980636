import { rootApi } from "../serviceRoot";
import {
  TPlaylistGetAllResponse,
  TPlaylistGetResponse,
  TPlaylistCreateResponse,
  TPlaylistCreateRequest,
  TPlaylistUpdateResponse,
  TPlaylistUpdateRequest,
  TPlaylistDeleteResponse,
  TPlaylistDeleteRequest,
} from "./Types";

export const playlistsApiSlice = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllPlaylists: builder.query<TPlaylistGetAllResponse, void>({
      query: () => "api/playlist/getall",
      providesTags: ["Playlists"],
    }),
    getPlaylist: builder.query<TPlaylistGetResponse, string>({
      query: (id) => `api/playlist/get?Id=${id}`,
      providesTags: ["Playlists"],
    }),
    createPlaylist: builder.mutation<
      TPlaylistCreateResponse,
      TPlaylistCreateRequest
    >({
      query: (payload) => ({
        url: "api/playlist/create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Playlists"],
    }),
    updatePlaylist: builder.mutation<
      TPlaylistUpdateResponse,
      TPlaylistUpdateRequest
    >({
      query: (payload) => ({
        url: "api/playlist/update",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Playlists"],
    }),
    deletePlaylist: builder.mutation<
      TPlaylistDeleteResponse,
      TPlaylistDeleteRequest
    >({
      query: (id) => ({
        url: "api/playlist/delete",
        method: "POST",
        body: { id },
      }),
      invalidatesTags: ["Playlists"],
    }),
  }),
});

export const {
  useGetAllPlaylistsQuery,
  useCreatePlaylistMutation,
  useGetPlaylistQuery,
  useLazyGetPlaylistQuery,
  useUpdatePlaylistMutation,
  useDeletePlaylistMutation,
} = playlistsApiSlice;
