import React, { useState, useEffect } from "react";
import { DeleteByPeriodModalProps, DatasetFieldType } from "./Types";
import {
  DbaButton,
  DbaDataRangePicker,
  DbaModal,
  DbaSnackbar,
} from "../../../DbaComponents";
import { useIntl } from "react-intl";
import { DbaDataFetchSelect } from "../../../DbaComponents";
import { useMediaQuery } from "@mui/material";
import {
  CSSDataSetFields,
  CSSButtons,
  CSSDateRangePicker,
} from "./DeleteByPeriodModal.styles";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { useDeleteDataFromDataSetMutation } from "../../../features/serviceSlices/DataSet/dataSetService";
import { getErrorMessage } from "../../../utils/helpers/functions";
import { DbaPopover } from "../../../DbaComponents/DbaPopover";

export const DeleteByPeriodModal: React.FC<DeleteByPeriodModalProps> = ({
  dataSetId,
  isOpen,
  setIsOpen,
  onSuccess,
}) => {
  const mobileView = useMediaQuery("(max-width:1000px)");
  const intl = useIntl();

  const [deleteData, deleteDataResponse] = useDeleteDataFromDataSetMutation();

  const [dataSetField, setDataSetField] = useState<DatasetFieldType>();
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());

  const [deleteButton, setDeleteButton] = useState<HTMLElement | null>(null);

  const isValidationPassed = dataSetField && startDate && dataSetField;

  const onCancelClick = () => setIsOpen(false);

  const onDeleteConfirmClick = (e: React.BaseSyntheticEvent) => {
    setDeleteButton(e.target);
  };

  const onDeleteCancelClick = () => {
    setDeleteButton(null);
  };

  const onDeleteClick = () => {
    setDeleteButton(null);
    if (isValidationPassed) {
      deleteData({
        dataSetId: dataSetId,
        whereParts: [
          {
            field: dataSetField.name,
            condition: "MoreThanOrEqual",
            values: startDate,
          },
          {
            field: dataSetField.name,
            condition: "LessThanOrEqual",
            values: endDate,
          },
        ],
      });
    }
  };

  const errorMessage = getErrorMessage(deleteDataResponse);

  useEffect(() => {
    if (onSuccess && deleteDataResponse.isSuccess) {
      onSuccess();
    }
  }, [deleteDataResponse.isSuccess, onSuccess]);

  return (
    <DbaModal
      title={intl.messages["deleteDataByPeriod"] as string}
      open={isOpen}
      setOpen={setIsOpen}
    >
      <CSSDataSetFields>
        <DbaDataFetchSelect
          url={`api/dataset/fields/${dataSetId}`}
          label="field"
          selectedValue={dataSetField}
          setSelectedValue={setDataSetField}
          disabled={deleteDataResponse.isSuccess}
        />
      </CSSDataSetFields>

      <CSSDateRangePicker>
        <DbaDataRangePicker
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          mobileView={mobileView}
          startDateLabel="fromDate"
          endDateLabel="toDate"
          disabled={deleteDataResponse.isSuccess}
        />
      </CSSDateRangePicker>

      <DbaSnackbar
        errorMessage={errorMessage}
        error={deleteDataResponse.isError}
        success={deleteDataResponse.isSuccess}
        successMessage={intl.messages["deleteSuccessMessagePlural"] as string}
      />

      <CSSButtons>
        <DbaButton
          text={deleteDataResponse.isSuccess ? "done" : "cancel"}
          onClick={onCancelClick}
          size="medium"
        />
        <DbaButton
          color="error"
          text="delete"
          onClick={onDeleteConfirmClick}
          endIcon={<DeleteIcon />}
          size="medium"
          disabled={!isValidationPassed || deleteDataResponse.isSuccess}
        />
      </CSSButtons>

      <DbaPopover
        styles={{ margin: "10px 0 0 0" }}
        noIntl={true}
        customConfirmMessage={`${
          intl.messages["youSureYouWantToDeleteDataByPeriod"]
        } ${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}?`}
        onCloseModal={onDeleteCancelClick}
        onDeleteItem={onDeleteClick}
        anchorEl={deleteButton}
        setAnchorEl={setDeleteButton}
      />
    </DbaModal>
  );
};
