import { WidgetSettingContainer } from "../../common";
import type { ChartSettingsProps } from "../Types";
import Switch from "@mui/material/Switch";

export const TimeRangeChartInteractivitySettings = ({
  settings,
  setSettings,
}: ChartSettingsProps) => {
  return (
    <WidgetSettingContainer lastRow title="isInteractive">
      <Switch
        checked={
          settings.diagram.isInteractive === undefined
            ? true
            : settings.diagram.isInteractive
        }
        onChange={(e) =>
          setSettings((prevState: any) => ({
            ...prevState,
            diagram: {
              ...prevState.diagram,
              isInteractive: e.target.checked,
            },
          }))
        }
      />
    </WidgetSettingContainer>
  );
};
