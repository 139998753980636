import React, { useMemo, useCallback } from "react";
import {
  useGetAllDataSourcesQuery,
  useDeleteDataSourceMutation,
} from "../../features/serviceSlices/serviceHooks";
import { LoadingScreen, ErrorMessage } from "../../components/common";
import { DbaTable, DbaSnackbar } from "../../DbaComponents";
import { useIntl } from "react-intl";
import { DataSourceType } from "../../features/serviceSlices/DataSources/Types";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import { ColumnDef } from "@tanstack/react-table";
import { EditButton } from "../../components/TableHelpers/EditButton";
import { DeleteButton } from "../../components/TableHelpers/DeleteButton";

const BIG_SIZE_TO_PUSH_EDIT_COLUMN = 2000;
const ACTION_COLUMN_SIZE = 44;

export const DataSources = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const datasources = useGetAllDataSourcesQuery();
  const [deleteDatasource, deleteResponse] = useDeleteDataSourceMutation();

  const onAddButtonClick = useCallback(() => {
    navigate("/datasource");
  }, [navigate]);

  const deleteHandler = useCallback(
    (obj: DataSourceType) => {
      if (obj?.id !== undefined) {
        deleteDatasource({ id: obj.id });
      }
    },
    [deleteDatasource]
  );

  const columns: ColumnDef<DataSourceType>[] = useMemo(
    () => [
      {
        id: intl.messages["name"] as string,
        accessorFn: (row) => row.name,
        cell: (props) => {
          return (
            <Link
              underline="hover"
              component={RouterLink}
              to={{
                pathname: `/edit-datasource/${props.row.original.id}`,
              }}
              sx={{ color: "inherit" }}
            >
              {props.row.original.name}
            </Link>
          );
        },
        size: BIG_SIZE_TO_PUSH_EDIT_COLUMN,
      },
      {
        id: intl.messages["description"] as string,
        accessorFn: (row) => row.description,
        size: BIG_SIZE_TO_PUSH_EDIT_COLUMN,
      },
      {
        id: intl.messages["plugin"] as string,
        accessorFn: (row) => row.pluginType,
        size: BIG_SIZE_TO_PUSH_EDIT_COLUMN,
      },
      {
        id: "edition",
        header: "",
        cell: (props) => (
          <EditButton editLink="/edit-datasource/" row={props.row} />
        ),
        size: ACTION_COLUMN_SIZE,
      },
      {
        id: "deletion",
        header: "",
        cell: (props) => (
          <DeleteButton row={props.row} deleteDataHandler={deleteHandler} />
        ),
        size: ACTION_COLUMN_SIZE,
      },
    ],
    [deleteHandler, intl.messages]
  );

  if (datasources.isError) {
    return <ErrorMessage />;
  }
  if (datasources.isLoading) {
    return <LoadingScreen />;
  }
  return (
    <>
      <DbaTable
        id="data-sources-form-table"
        columns={columns}
        data={datasources.data ?? []}
        headToolbar={{ onAddButtonClick }}
        stylesSettings={{ autoColumnsWidth: false }}
      />
      <DbaSnackbar
        error={deleteResponse.isError}
        success={deleteResponse.isSuccess}
      />
    </>
  );
};
