import { AddCircle, Delete } from "@mui/icons-material";
import {
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DbaDictionaryInputProps,
  DictionaryElement,
} from "./DbaDictionaryInput.types";
import { useIntl } from "react-intl";
import { v4 as uuidv4 } from "uuid";
import { useEffect } from "react";

const DbaDictionaryInput = ({
  data,
  setData,
  nameOfArrayPropertyWithKeys,
  keyPropertyName,
  initData,
}: DbaDictionaryInputProps) => {
  const intl = useIntl();

  const addNewPair = () => {
    const newElement: DictionaryElement = { id: uuidv4(), key: "", value: "" };
    setData([...data, newElement]);
  };

  useEffect(() => {
    if (initData && nameOfArrayPropertyWithKeys && keyPropertyName) {
      let initFilters = undefined;
      try {
        const initDataObj = JSON.parse(initData);
        initFilters = JSON.parse(initDataObj[nameOfArrayPropertyWithKeys]);
      } catch (e) {}
      if (initFilters !== undefined && Array.isArray(initFilters)) {
        let initStateArray: DictionaryElement[] = [];
        initFilters.forEach((element) => {
          if (keyPropertyName in element) {
            const newElement: DictionaryElement = {
              id: uuidv4(),
              key: element[keyPropertyName],
              value: "",
            };
            initStateArray = [...initStateArray, newElement];
          }
        });
        setData(initStateArray);
      }
    }
  }, []);

  const onChangeKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newData = data.map((x) => {
      if (x.id === event.target.id) {
        return { ...x, key: event.target.value };
      }
      return x;
    });
    setData(newData);
  };

  const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newData = data.map((x) => {
      if (x.id === event.target.id) {
        return { ...x, value: event.target.value };
      }
      return x;
    });
    setData(newData);
  };

  const deletePair = (id: string) => {
    const newData = data.filter((x) => x.id !== id);
    setData(newData);
  };

  return (
    <>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>{intl.messages["filters"]}</Typography>
        <Tooltip title={intl.messages["addFilter"]} placement="right">
          <IconButton onClick={addNewPair}>
            <AddCircle />
          </IconButton>
        </Tooltip>
      </Stack>
      {data.map((x) => {
        return (
          <Stack spacing={1} direction="row" alignItems="center">
            <TextField
              id={x.id}
              label={intl.messages["variable"]}
              value={x.key}
              onChange={onChangeKey}
            />
            <TextField
              id={x.id}
              label={intl.messages["value"]}
              value={x.value}
              onChange={onChangeValue}
            />
            <Tooltip title={intl.messages["deleteFilter"]} placement="right">
              <IconButton
                style={{ height: "40px", width: "40px" }}
                onClick={() => deletePair(x.id)}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      })}
    </>
  );
};

export default DbaDictionaryInput;
