import {
  WidgetSettingContainer,
  crosshairTypeOptions,
  slicesOptions,
} from "../../common";
import type { ChartSettingsProps } from "../Types";
import { DbaSimpleSelect } from "../../../../../../../DbaComponents";
import Switch from "@mui/material/Switch";
import { MotionConfigSettings } from "../../common";

export const LineChartInteractivitySettings = ({
  settings,
  setSettings,
}: ChartSettingsProps) => {
  return (
    <>
      <WidgetSettingContainer title="animate">
        <Switch
          checked={
            settings.diagram.animate === undefined
              ? true
              : settings.diagram.animate
          }
          onChange={(e) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                animate: e.target.checked,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="isInteractive">
        <Switch
          checked={
            settings.diagram.isInteractive === undefined
              ? true
              : settings.diagram.isInteractive
          }
          onChange={(e) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                isInteractive: e.target.checked,
              },
            }))
          }
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="motionConfig">
        <MotionConfigSettings
          settings={settings}
          setSettings={setSettings}
          defaultValue="default"
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer title="enableSlices">
        <DbaSimpleSelect
          setSelectedValue={(value) => {
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                enableSlices: value,
              },
            }));
          }}
          selectedValue={
            settings.diagram.enableSlices === undefined
              ? slicesOptions.find((option) => option.value === false)
              : slicesOptions.find(
                  (option) => option.value === settings.diagram.enableSlices
                )
          }
          options={slicesOptions}
        />
      </WidgetSettingContainer>

      <WidgetSettingContainer lastRow title="enableCrosshair">
        <Switch
          checked={settings.diagram.enableCrosshair ?? true}
          onChange={(e) =>
            setSettings((prevState: any) => ({
              ...prevState,
              diagram: {
                ...prevState.diagram,
                enableCrosshair: e.target.checked,
              },
            }))
          }
        />
        {settings.diagram.enableCrosshair && (
          <WidgetSettingContainer title="crosshairType">
            <DbaSimpleSelect
              setSelectedValue={(value) => {
                setSettings((prevState: any) => ({
                  ...prevState,
                  diagram: {
                    ...prevState.diagram,
                    crosshairType: value,
                  },
                }));
              }}
              selectedValue={
                settings.diagram.crosshairType === undefined
                  ? crosshairTypeOptions.find(
                      (option) => option.value === "bottom-left"
                    )
                  : crosshairTypeOptions.find(
                      (option) =>
                        option.value === settings.diagram.crosshairType
                    )
              }
              options={crosshairTypeOptions}
            />
          </WidgetSettingContainer>
        )}
      </WidgetSettingContainer>
    </>
  );
};
