import styled from "@emotion/styled";
import colors from "../../../../../../Variables.module.scss";

export type PositionTypes =
  | "top-left"
  | "left"
  | "bottom-left"
  | "center"
  | "top"
  | "bottom"
  | "top-right"
  | "right"
  | "bottom-right";

type PositionSettingsRectProps = {
  position: PositionTypes;
  setPosition: (arg: PositionTypes) => void;
};

type CircleProps = PositionSettingsRectProps & {
  circlePosition: PositionTypes;
};

type StyledCircleProps = {
  active: boolean;
};

const CSSStyledRect = styled.rect`
  fill: none;
  stroke: rgb(102, 102, 102);
  stroke-width: 2px;
  stroke-opacity: 0.6;
`;

const CSSStyledInnerCircle = styled.circle<StyledCircleProps>`
  fill: ${(props) => (props.active ? colors.purple500 : "rgb(102, 102, 102)")};
`;
const CSSStyledOuterCircle = styled.circle<StyledCircleProps>`
  cursor: pointer;
  fill: red;
  fill-opacity: 0;
  stroke-width: 2px;
  stroke: ${(props) =>
    props.active ? colors.purple500 : "rgb(102, 102, 102)"};
  stroke-opacity: ${(props) => (props.active ? 1 : 0)};
  &:hover {
    stroke-opacity: 1;
  }
`;

const Circle = ({ position, setPosition, circlePosition }: CircleProps) => {
  return (
    <>
      <CSSStyledInnerCircle
        active={position === circlePosition}
        r="2"
      ></CSSStyledInnerCircle>
      <CSSStyledOuterCircle
        active={position === circlePosition}
        onClick={() => setPosition(circlePosition)}
        r="8"
      ></CSSStyledOuterCircle>
    </>
  );
};

export const PositionSettingsRect = ({
  position,
  setPosition,
}: PositionSettingsRectProps) => {
  return (
    <svg width="100" height="70">
      <g transform="translate(10,10)">
        <CSSStyledRect width="80" height="50"></CSSStyledRect>
        <g transform="translate(40,25)">
          <Circle
            position={position}
            setPosition={setPosition}
            circlePosition="center"
          />
        </g>
        <g transform="translate(0,0)">
          <Circle
            position={position}
            setPosition={setPosition}
            circlePosition="top-left"
          />
        </g>
        <g transform="translate(40,0)">
          <Circle
            position={position}
            setPosition={setPosition}
            circlePosition="top"
          />
        </g>
        <g transform="translate(80,0)">
          <Circle
            position={position}
            setPosition={setPosition}
            circlePosition="top-right"
          />
        </g>
        <g transform="translate(80,25)">
          <Circle
            position={position}
            setPosition={setPosition}
            circlePosition="right"
          />
        </g>
        <g transform="translate(80,50)">
          <Circle
            position={position}
            setPosition={setPosition}
            circlePosition="bottom-right"
          />
        </g>
        <g transform="translate(40,50)">
          <Circle
            position={position}
            setPosition={setPosition}
            circlePosition="bottom"
          />
        </g>
        <g transform="translate(0,50)">
          <Circle
            position={position}
            setPosition={setPosition}
            circlePosition="bottom-left"
          />
        </g>
        <g transform="translate(0,25)">
          <Circle
            position={position}
            setPosition={setPosition}
            circlePosition="left"
          />
        </g>
      </g>
    </svg>
  );
};
